// OpenAIDescriptionGenerator.js

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,
  Grid,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  Tooltip,
  Box,
  LinearProgress,
  InputLabel,
  FormControl,
  ListItemIcon,
  Pagination,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';
import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import designTemplates from './designTemplates';
import DescriptionValidator from './DescriptionValidator';
import DOMPurify from 'dompurify';
import PQueue from 'p-queue';

const BATCH_SIZE = 5;
const INITIAL_RETRY_DELAY = 1000;
const MAX_RETRIES = 5;
const CONCURRENCY = 2;
const INTERVAL = 1000;
const INTERVAL_CAP = 5;

const OpenAIDescriptionGenerator = ({ spreadsheetId, sheetName, token, fetchData, apiKey }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [focusedProduct, setFocusedProduct] = useState(null);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [previewProduct, setPreviewProduct] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState('professional');
  const [progress, setProgress] = useState(0);
  const [cache, setCache] = useState({});
  const [customPromptDialogOpen, setCustomPromptDialogOpen] = useState(false);
  const [customPrompt, setCustomPrompt] = useState('');
  const [customOptions, setCustomOptions] = useState({
    maxTokens: 200,
    temperature: 0.1,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
    model: 'gpt-4o-2024-08-06',
    language: '英語',
    style: '最適化された',
    additionalInstructions: '',
    systemMessageTemplate: `You are eBay's AI assistant specializing in product description optimization.`,
    userMessageTemplate: `以下の日本語の商品説明を分析し、eBayの商品説明として{{style}}{{language}}の説明を生成してください。出力は{{maxTokens}}トークン以内に収めてください：「{{jpDesc}}」`,
    maxJpDescLength: 200,
  });

  const [sentencesToDelete, setSentencesToDelete] = useState([]);
  const [wordsToReplace, setWordsToReplace] = useState([]); // [{ from: '', to: '' }]
  const [wordsToDeleteSentencesContaining, setWordsToDeleteSentencesContaining] = useState([]);




  const presetFilters = [
    {
      label: '発送に関する情報を除外する',
      patterns: ['発送.*', '送料.*', '配送.*'],
    },
    {
      label: '広告文を除外する',
      patterns: ['ご覧いただきありがとうございます', 'お問い合わせください', 'お買い得'],
    },
  ];

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [customFilterInput, setCustomFilterInput] = useState('');
  const [customFilters, setCustomFilters] = useState([]);

  const queue = useMemo(
    () =>
      new PQueue({
        concurrency: CONCURRENCY,
        interval: INTERVAL,
        intervalCap: INTERVAL_CAP,
      }),
    []
  );

  // 表示件数選択用のオプションと状態を追加
  const itemsPerPageOptions = [50, 100, 'All'];
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);

  useEffect(() => {
    if (open) {
      fetchSheetData();
    }
  }, [open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFocusedProduct(null);
  };

  const fetchSheetData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = response.data.values;
      const headers = data[0];
      const titleIndex = headers.findIndex(header => header.toLowerCase() === 'title');
      const jpDescIndex = headers.findIndex(header => header.toLowerCase() === 'jp_desc');
      const descriptionIndex = headers.findIndex(header => header.toLowerCase() === 'description');

      if (titleIndex === -1 || jpDescIndex === -1 || descriptionIndex === -1) {
        throw new Error('必要な列（Title, JP_Desc, Description）が見つかりません');
      }

      const productData = data.slice(1).map((row, index) => {
        const originalJpDesc = row[jpDescIndex] || '';
        const filteredJpDesc = applyFilters(originalJpDesc);
        return {
          id: index,
          title: row[titleIndex],
          jpDesc: originalJpDesc,
          editableJpDesc: filteredJpDesc,
          description: row[descriptionIndex] || '',
          generatedFeatures: '',
          editableGeneratedFeatures: '',
          updatedDescription: '',
          selected: true,
          editableFields: headers.reduce((acc, header, idx) => {
            if (header.startsWith('C:')) {
              acc[header] = row[idx] || '';
            }
            return acc;
          }, {}),
        };
      });

      setProducts(productData);
      setSelectedProducts(productData.map(product => product.id));
      setSnackbarMessage('シートデータを正常に取得しました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('シートデータの取得エラー:', err);
      setError(`シートデータの取得に失敗しました: ${err.message}`);
      setSnackbarMessage(`エラー: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const applyFilters = useCallback(
    (jpDesc) => {
      let filteredDesc = jpDesc;
      selectedFilters.forEach((filterLabel) => {
        const filter = presetFilters.find((f) => f.label === filterLabel);
        if (filter) {
          filter.patterns.forEach((pattern) => {
            const regex = new RegExp(pattern, 'gi');
            filteredDesc = filteredDesc.replace(regex, '');
          });
        }
      });
      customFilters.forEach((pattern) => {
        const regex = new RegExp(pattern, 'gi');
        filteredDesc = filteredDesc.replace(regex, '');
      });
      return filteredDesc;
    },
    [selectedFilters, customFilters]
  );

  const applyFiltersToProducts = useCallback(() => {
    setProducts((prevProducts) =>
      prevProducts.map((product) => {
        const filteredDesc = applyFilters(product.jpDesc);
        return { ...product, editableJpDesc: filteredDesc };
      })
    );
  }, [applyFilters]);

  const handleFilterChange = useCallback(
    (label) => {
      setSelectedFilters((prev) =>
        prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]
      );
      applyFiltersToProducts();
    },
    [applyFiltersToProducts]
  );

  const handleAddCustomFilter = () => {
    if (customFilterInput.trim() !== '') {
      setCustomFilters([...customFilters, customFilterInput.trim()]);
      setCustomFilterInput('');
      applyFiltersToProducts();
    }
  };

  const handleDeleteCustomFilter = (index) => {
    const updatedFilters = [...customFilters];
    updatedFilters.splice(index, 1);
    setCustomFilters(updatedFilters);
    applyFiltersToProducts();
  };

  const handleEditJpDesc = useCallback((productId, newJpDesc) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId ? { ...product, editableJpDesc: newJpDesc } : product
      )
    );
  }, []);

  const sanitizeDescription = (description) => {
    return description.replace(/<\/?(meta|link)(.|\s)*?>/gi, '');
  };

  const generateProductFeatures = async () => {
    if (!apiKey) {
      setError('OpenAI APIキーが設定されていません');
      setSnackbarMessage('OpenAI APIキーが必要です');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (selectedProducts.length === 0) {
      setError('少なくとも一つの商品を選択してください');
      setSnackbarMessage('商品が選択されていません');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    setError('');
    setMessage('');
    setProgress(0);

    const selectedProductsData = products.filter((product) => selectedProducts.includes(product.id));
    let processedCount = 0;

    for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
      const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
      try {
        const updatedBatch = await processBatch(batch);
        setProducts((prevProducts) => {
          const newProducts = [...prevProducts];
          updatedBatch.forEach((updatedProduct) => {
            const index = newProducts.findIndex((p) => p.id === updatedProduct.id);
            if (index !== -1) {
              newProducts[index] = updatedProduct;
            }
          });
          return newProducts;
        });
        processedCount += batch.length;
        setProgress((processedCount / selectedProductsData.length) * 100);
      } catch (err) {
        console.error('バッチ処理エラー:', err);
        setError(`バッチ処理に失敗しました: ${err.message}`);
        setSnackbarMessage(`エラー: ${err.message}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        break;
      }
    }

    setLoading(false);
    setMessage('商品特徴の生成が完了しました');
    setSnackbarMessage('商品特徴の生成が完了しました');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    setShowPreviewDialog(true);
  };

  const processBatch = async (batch) => {
    return await Promise.all(
      batch.map(async (product) => {
        let generatedFeatures;
        const jpDescToUse = product.editableJpDesc || product.jpDesc;
        if (cache[jpDescToUse]) {
          generatedFeatures = cache[jpDescToUse];
        } else {
          generatedFeatures = await generateFeaturesWithRetry(jpDescToUse);
          setCache((prevCache) => ({ ...prevCache, [jpDescToUse]: generatedFeatures }));
        }

        const sanitizedDescription = sanitizeDescription(product.description);

        const updatedDescription = generateFormattedDescription(
          product.title,
          generatedFeatures,
          sanitizedDescription,
          product.editableFields
        );
        return {
          ...product,
          generatedFeatures,
          editableGeneratedFeatures: generatedFeatures,
          updatedDescription,
        };
      })
    );
  };

  const generateFeaturesWithRetry = async (jpDesc, retryCount = 0) => {
    try {
      return await queue.add(() => generateFeatures(jpDesc));
    } catch (error) {
      if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
        const retryAfter = error.response.headers['retry-after'];
        const delay = retryAfter ? parseInt(retryAfter) * 1000 : INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
        const jitter = Math.random() * 1000;
        await new Promise((resolve) => setTimeout(resolve, delay + jitter));
        return generateFeaturesWithRetry(jpDesc, retryCount + 1);
      }
      throw error;
    }
  };

  const generateFeatures = async (jpDesc, maxTokens = 200) => {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-2024-08-06',
        messages: [
          {
            role: 'system',
            content: `You are eBay's AI assistant specializing in product description optimization. Create effective English product descriptions based on the given Japanese product information. Please provide accurate information to buyers, including the following elements

1. product description (if applicable)
   - Brand name (if applicable)
   - Product type
   - Model or product name
2. detailed features (if applicable)
   - Color, material, size
3. condition of the item (if applicable)
   - Details of usage, scratches, stains, etc. (if used)

Notes:
- No content other than the above will be output.
- Additional information is not required.
- Do not output information related to shipping or options.
- Use clear and concise English.
- Use bullet points to ensure readability.
- If information is unclear or ambiguous, omit the item.
- Use only reliable information and do not include speculative or uncertain information
- If there is nothing in the Japanese product description, please do not output anything in particular. Only the title is acceptable.

Analyze the given product information and follow the guidelines above to generate the best possible product description in English. Try to provide a detailed and engaging description that will help buyers make a purchase decision. Stay within the specified token count limit.`,
          },
          {
            role: 'user',
            content: `以下の日本語の商品説明を分析し、eBayの商品説明として最適化された英語の説明を生成してください。出力は${maxTokens}トークン以内に収めてください：「${jpDesc}」`,
          },
        ],
        max_tokens: maxTokens,
        temperature: 0.1,
      },
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data.choices[0].message.content.trim();
  };

  const cleanHTML = (html) => {
    // <style>タグを削除
    let cleanedHtml = html.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '');

    // class属性を削除
    cleanedHtml = cleanedHtml.replace(/\sclass="[^"]*"/gi, '');

    // 不要な空白を削除
    cleanedHtml = cleanedHtml.replace(/\s+/g, ' ').trim();

    // タグ間の空白を削除
    cleanedHtml = cleanedHtml.replace(/>\s+</g, '><');

    return cleanedHtml;
  };

  const generateFormattedDescription = (title, features, description, specifications) => {
    const template = designTemplates[selectedTemplate];
    const html = template.generateHTML(title, features, description, specifications);

    // 生成されたHTMLをクリーンアップ
    const cleanedHtml = cleanHTML(html);

    return cleanedHtml;
  };

  const handleSelectAll = useCallback(
    (event) => {
      const updatedProducts = products.map((product) => ({
        ...product,
        selected: event.target.checked,
      }));
      setProducts(updatedProducts);
      setSelectedProducts(event.target.checked ? updatedProducts.map((p) => p.id) : []);
    },
    [products]
  );

  const handleSelectProduct = useCallback(
    (productId) => {
      const updatedProducts = products.map((product) =>
        product.id === productId ? { ...product, selected: !product.selected } : product
      );
      setProducts(updatedProducts);
      setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
    },
    [products]
  );

  const handleFocusProduct = useCallback((productId) => {
    setFocusedProduct(productId);
  }, []);

  const removeLeadingWhitespace = async (productId) => {
    setLoading(true);
    setError('');

    try {
      const product = products.find((p) => p.id === productId);
      if (!product) throw new Error('商品が見つかりません');

      const trimmedDescription = product.description.replace(/^\s*(<div style[\s\S]*?>)/, '$1');

      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = response.data.values;
      const headers = data[0];
      const descriptionIndex = headers.findIndex((header) => header.toLowerCase() === 'description');
      const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');

      if (descriptionIndex === -1 || titleIndex === -1) {
        throw new Error('必要な列が見つかりません');
      }

      const rowIndex = data.findIndex((row) => row[titleIndex] === product.title);
      if (rowIndex === -1) throw new Error('商品行が見つかりません');

      await axios.put(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!${String.fromCharCode(
          65 + descriptionIndex
        )}${rowIndex + 1}`,
        {
          values: [[trimmedDescription]],
        },
        {
          params: { valueInputOption: 'RAW' },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setProducts((prevProducts) =>
        prevProducts.map((p) => (p.id === productId ? { ...p, description: trimmedDescription } : p))
      );

      setSnackbarMessage('先頭の余白を削除しました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('先頭の余白削除エラー:', err);
      setError(`先頭の余白削除に失敗しました: ${err.message}`);
      setSnackbarMessage(`エラー: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const removeAllLeadingWhitespace = async () => {
    setLoading(true);
    setError('');

    try {
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = response.data.values;
      const headers = data[0];
      const descriptionIndex = headers.findIndex((header) => header.toLowerCase() === 'description');

      if (descriptionIndex === -1) {
        throw new Error('Description列が見つかりません');
      }

      const updatedData = data.map((row, index) => {
        if (index === 0) return row;
        const newRow = [...row];
        newRow[descriptionIndex] = newRow[descriptionIndex].replace(/^\s*(<div style[\s\S]*?>)/, '$1');
        return newRow;
      });

      await axios.put(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!A1`,
        {
          values: updatedData,
        },
        {
          params: { valueInputOption: 'RAW' },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setProducts((prevProducts) =>
        prevProducts.map((p) => ({ ...p, description: p.description.replace(/^\s*(<div style[\s\S]*?>)/, '$1') }))
      );

      setSnackbarMessage('全商品の先頭余白を削除しました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchData();
    } catch (err) {
      console.error('全商品の先頭余白削除エラー:', err);
      setError(`全商品の先頭余白削除に失敗しました: ${err.message}`);
      setSnackbarMessage(`エラー: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const applyUpdatedDescriptions = async () => {
    setLoading(true);
    setError('');
    setMessage('');

    try {
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const currentData = response.data.values;

      const headers = currentData[0];
      const descriptionIndex = headers.findIndex((header) => header.toLowerCase() === 'description');

      if (descriptionIndex === -1) {
        throw new Error('Description列が見つかりません');
      }

      const updatedData = currentData.map((row, index) => {
        if (index === 0) return row;

        const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
        const product = products.find((p) => p.title === row[titleIndex]);
        if (product && product.selected && product.updatedDescription) {
          const newRow = [...row];
          newRow[descriptionIndex] = product.updatedDescription;
          return newRow;
        }
        return row;
      });

      await axios.put(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!A1`,
        {
          values: updatedData,
        },
        {
          params: { valueInputOption: 'RAW' },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setMessage('説明文を正常に更新しました');
      setSnackbarMessage('説明文を正常に更新しました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchData();
    } catch (err) {
      console.error('説明文更新エラー:', err);
      setError(`説明文の更新に失敗しました: ${err.message}`);
      setSnackbarMessage(`エラー: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePreviewOpen = (product) => {
    setPreviewProduct(product);
    setShowPreviewDialog(true);
  };

  const handlePreviewClose = () => {
    setPreviewProduct(null);
    setShowPreviewDialog(false);
  };

  const renderEbayPreview = (product) => {
    return <div dangerouslySetInnerHTML={{ __html: product.updatedDescription || product.description }} />;
  };

  const handleEditFeatures = useCallback((productId, newFeatures) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId
          ? {
              ...product,
              editableGeneratedFeatures: newFeatures,
              updatedDescription: generateFormattedDescription(
                product.title,
                newFeatures,
                product.description,
                product.editableFields
              ),
            }
          : product
      )
    );
  }, []);

  const clearCache = () => {
    setCache({});
    setSnackbarMessage('キャッシュを正常にクリアしました');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };

  const handleGenerateWithCustomPrompt = async () => {
    if (!apiKey) {
      setError('OpenAI APIキーが設定されていません');
      setSnackbarMessage('OpenAI APIキーが必要です');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (selectedProducts.length === 0) {
      setError('少なくとも一つの商品を選択してください');
      setSnackbarMessage('商品が選択されていません');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    if (!customPrompt.trim()) {
      setError('カスタムプロンプトが入力されていません');
      setSnackbarMessage('カスタムプロンプトを入力してください');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    setError('');
    setMessage('');
    setProgress(0);

    const selectedProductsData = products.filter((product) => selectedProducts.includes(product.id));
    let processedCount = 0;

    try {
      for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
        const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
        const updatedBatch = await processBatchWithCustomPrompt(batch, customPrompt, customOptions);
        setProducts((prevProducts) => {
          const newProducts = [...prevProducts];
          updatedBatch.forEach((updatedProduct) => {
            const index = newProducts.findIndex((p) => p.id === updatedProduct.id);
            if (index !== -1) {
              newProducts[index] = updatedProduct;
            }
          });
          return newProducts;
        });
        processedCount += batch.length;
        setProgress((processedCount / selectedProductsData.length) * 100);
      }

      setMessage('カスタムプロンプトによる商品特徴の生成が完了しました');
      setSnackbarMessage('カスタムプロンプトによる商品特徴の生成が完了しました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setShowPreviewDialog(true);
    } catch (err) {
      console.error('カスタムプロンプトバッチ処理エラー:', err);
      setError(`カスタムプロンプトバッチ処理に失敗しました: ${err.message}`);
      setSnackbarMessage(`エラー: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const processBatchWithCustomPrompt = async (batch, prompt, options) => {
    return await Promise.all(
      batch.map(async (product) => {
        let generatedFeatures;
        const jpDescToUse = product.editableJpDesc || product.jpDesc;
        if (cache[jpDescToUse]) {
          generatedFeatures = cache[jpDescToUse];
        } else {
          generatedFeatures = await generateFeaturesWithRetryCustomPrompt(jpDescToUse, prompt, options);
          setCache((prevCache) => ({ ...prevCache, [jpDescToUse]: generatedFeatures }));
        }

        const sanitizedDescription = sanitizeDescription(product.description);

        const updatedDescription = generateFormattedDescription(
          product.title,
          generatedFeatures,
          sanitizedDescription,
          product.editableFields
        );
        return {
          ...product,
          generatedFeatures,
          editableGeneratedFeatures: generatedFeatures,
          updatedDescription,
        };
      })
    );
  };

  const generateFeaturesWithRetryCustomPrompt = async (jpDesc, prompt, options, retryCount = 0) => {
    try {
      return await queue.add(() => generateFeaturesCustomPrompt(jpDesc, prompt, options));
    } catch (error) {
      if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
        const retryAfter = error.response.headers['retry-after'];
        const delay = retryAfter ? parseInt(retryAfter) * 1000 : INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
        const jitter = Math.random() * 1000;
        await new Promise((resolve) => setTimeout(resolve, delay + jitter));
        return generateFeaturesWithRetryCustomPrompt(jpDesc, prompt, options, retryCount + 1);
      }
      throw error;
    }
  };

  const generateFeaturesCustomPrompt = async (jpDesc, prompt, options) => {
    const {
      maxTokens = 200,
      temperature = 0.1,
      top_p = 1,
      frequency_penalty = 0,
      presence_penalty = 0,
      model = 'gpt-4o-2024-08-06',
      language = '英語',
      style = '最適化された',
      additionalInstructions = '',
      systemMessageTemplate = `You are eBay's AI assistant specializing in product description optimization.`,
      userMessageTemplate = `以下の日本語の商品説明を分析し、eBayの商品説明として{{style}}{{language}}の説明を生成してください。出力は{{maxTokens}}トークン以内に収めてください：「{{jpDesc}}」`,
      maxJpDescLength = 200,
    } = options;

    const systemMessage = `${systemMessageTemplate} ${prompt} ${additionalInstructions}`;

    let truncatedJpDesc = jpDesc;
    if (maxJpDescLength > 0) {
      truncatedJpDesc = jpDesc.substring(0, maxJpDescLength);
    }

    const userMessage = userMessageTemplate
      .replace('{{style}}', style)
      .replace('{{language}}', language)
      .replace('{{maxTokens}}', maxTokens)
      .replace('{{jpDesc}}', truncatedJpDesc);

    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: model,
        messages: [
          {
            role: 'system',
            content: systemMessage,
          },
          {
            role: 'user',
            content: userMessage,
          },
        ],
        max_tokens: maxTokens,
        temperature: temperature,
        top_p: top_p,
        frequency_penalty: frequency_penalty,
        presence_penalty: presence_penalty,
      },
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data.choices[0].message.content.trim();
  };

  // 商品リストのレンダリング部分を修正
  const indexOfLastItem =
    currentPage * (itemsPerPage === 'All' ? products.length : itemsPerPage);
  const indexOfFirstItem = indexOfLastItem - (itemsPerPage === 'All' ? products.length : itemsPerPage);
  const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);

  // 「表示されている商品を全て選択」の関数を追加
  const handleSelectAllOnPage = useCallback(
    (event) => {
      const isSelected = event.target.checked;
      const updatedProducts = products.map((product) => {
        if (currentProducts.some((p) => p.id === product.id)) {
          return { ...product, selected: isSelected };
        }
        return product;
      });
      setProducts(updatedProducts);
      setSelectedProducts(updatedProducts.filter((p) => p.selected).map((p) => p.id));
    },
    [products, currentProducts]
  );

  const handleValidateAll = () => {
    const productsToValidate = products.filter((product) => selectedProducts.includes(product.id));
    setLoading(true);
    const updatedProducts = [...products];

    productsToValidate.forEach((product) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(product.updatedDescription || product.description, 'text/html');
      const aiContentElement = doc.getElementById('ai-generated-content');

      if (aiContentElement) {
        // AI生成部分のHTMLを取得
        let aiHtml = aiContentElement.innerHTML;

        // 空白削除処理を適用
        aiHtml = aiHtml
          .replace(/\s+/g, ' ')        // 連続する空白を単一の空白に置換
          .replace(/>\s+</g, '><')     // タグ間の空白を削除
          .trim();                     // 先頭と末尾の空白を削除

        // クリーンアップされたHTMLをAI生成部分に反映
        aiContentElement.innerHTML = aiHtml;

        // 修正後の全体のHTMLを取得
        const updatedHtml = doc.body.innerHTML;

        // 修正後のHTMLを商品データに反映
        const index = updatedProducts.findIndex((p) => p.id === product.id);
        if (index !== -1) {
          updatedProducts[index] = {
            ...updatedProducts[index],
            updatedDescription: updatedHtml,
          };
        }
      }
    });

    setProducts(updatedProducts);
    setSnackbarMessage('空白の削除が完了しました');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    setLoading(false);
  };

  const handleEditDescription = (productId, newDescription) => {
    setProducts((prevProducts) =>
      prevProducts.map((p) =>
        p.id === productId ? { ...p, editableDescription: newDescription } : p
      )
    );
  };

  const applyCustomTransformations = (text) => {
    let transformedText = text;
  
    // 指定した文章の削除
    sentencesToDelete.forEach((sentence) => {
      if (sentence.trim() !== '') {
        const escapedSentence = sentence.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // 正規表現用にエスケープ
        const regex = new RegExp(escapedSentence, 'g');
        transformedText = transformedText.replace(regex, '');
      }
    });
  
    // 指定したワードの置換
    wordsToReplace.forEach(({ from, to }) => {
      if (from.trim() !== '') {
        const escapedFrom = from.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(escapedFrom, 'g');
        transformedText = transformedText.replace(regex, to);
      }
    });
  
    // 指定したワードが含まれる文章の削除
    wordsToDeleteSentencesContaining.forEach((word) => {
      if (word.trim() !== '') {
        const regex = new RegExp(`[^.?!]*${word}[^.?!]*[.?!]`, 'g');
        transformedText = transformedText.replace(regex, '');
      }
    });
  
    return transformedText;
  };

  const applyTransformationsToSelectedProducts = () => {
    const updatedProducts = products.map((product) => {
      if (product.selected) {
        const transformedFeatures = applyCustomTransformations(product.generatedFeatures);
        const updatedDescription = generateFormattedDescription(
          product.title,
          transformedFeatures,
          sanitizeDescription(product.description),
          product.editableFields
        );
        return {
          ...product,
          editableGeneratedFeatures: transformedFeatures,
          updatedDescription,
        };
      }
      return product;
    });
    setProducts(updatedProducts);
  };
  
  

  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        mt: 1,
        mb: 1,
        maxWidth: '100%',
        margin: '0 auto',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ maxWidth: '1600px', margin: '0 auto' }}>
        {/* ヘッダーセクション */}
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              AIを活用した商品説明生成
            </Typography>
            <Typography variant="body1" paragraph>
              日本語の商品説明から英語の商品特徴を自動生成します。
              余白削除はebayに出品するために使います。
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              主な機能：
            </Typography>
            <Grid container spacing={1}>
              {[
                '日本語の商品説明から英語の商品特徴を抽出',
                'デザインテンプレートからデザインを選択',
                '複数の商品を一括で処理',
                '説明のプレビューと編集',
              ].map((feature, index) => (
                <Grid item xs={6} key={index}>
                  <Typography variant="body2">• {feature}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        {/* アクションボタンセクション */}
        <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
          <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<AddIcon />}>
            商品説明をAIで生成する
          </Button>
          <Button variant="outlined" color="primary" onClick={clearCache} startIcon={<CachedIcon />}>
            キャッシュをクリア
          </Button>
        </Box>

        {/* ダイアログセクション */}
        <Dialog open={open} onClose={handleClose} fullScreen>
          <DialogTitle>AIで商品説明を生成する</DialogTitle>
          <DialogContent>
            {/* レイアウトを左右に分割 */}
            <Grid container spacing={2}>
              {/* 左側のセクション */}
              <Grid item xs={12} md={3} sx={{
                    maxHeight: 'calc(100vh - 32px)', // 必要に応じて調整
                    overflowY: 'auto',
                  }}>
                {/* フィルタリングオプション */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">フィルタリングオプション</Typography>
                    {presetFilters.map((filter, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={selectedFilters.includes(filter.label)}
                            onChange={() => handleFilterChange(filter.label)}
                          />
                        }
                        label={filter.label}
                      />
                    ))}
                  </Grid>
                  {/* カスタムフィルターの入力フィールド */}
                  <Grid item xs={12}>
                    <TextField
                      label="カスタムフィルターを追加"
                      type="text"
                      fullWidth
                      value={customFilterInput}
                      onChange={(e) => setCustomFilterInput(e.target.value)}
                      variant="outlined"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddCustomFilter}
                      disabled={!customFilterInput.trim()}
                      sx={{ mt: 1 }}
                    >
                      フィルターを追加
                    </Button>
                  </Grid>
                  {/* カスタムフィルター一覧 */}
                  {customFilters.length > 0 && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">カスタムフィルター一覧</Typography>
                      <List>
                        {customFilters.map((filter, index) => (
                          <ListItem key={index} dense>
                            <ListItemText primary={filter} />
                            <ListItemSecondaryAction>
                              <IconButton edge="end" onClick={() => handleDeleteCustomFilter(index)}>
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  )}
                </Grid>

                {/* 選択オプション */}
                <Grid container spacing={2} alignItems="center" style={{ marginTop: '20px' }}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedProducts.length === products.length}
                          onChange={handleSelectAll}
                          indeterminate={selectedProducts.length > 0 && selectedProducts.length < products.length}
                        />
                      }
                      label="全て選択"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={currentProducts.every((product) => product.selected)}
                          onChange={handleSelectAllOnPage}
                          indeterminate={
                            currentProducts.some((product) => product.selected) &&
                            !currentProducts.every((product) => product.selected)
                          }
                        />
                      }
                      label="全て選択（表示されている商品）"
                    />
                  </Grid>
       
                  <Grid container spacing={2} style={{ marginTop: '20px' }}>
                    <Grid item xs={12}>
                      <Typography variant="h6">生成された文章のカスタマイズ</Typography>
                    </Grid>

                    {/* 指定した文章の削除 */}
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">削除したい文章（1行に1つ）</Typography>
                      <TextField
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        placeholder="削除したい文章を入力..."
                        value={sentencesToDelete.join('\n')}
                        onChange={(e) => setSentencesToDelete(e.target.value.split('\n'))}
                      />
                    </Grid>

                    {/* 指定したワードの置換 */}
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">置換したいワード</Typography>
                      {wordsToReplace.map((item, index) => (
                        <Grid container spacing={1} key={index} alignItems="center">
                          <Grid item xs={5}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              placeholder="置換前"
                              value={item.from}
                              onChange={(e) => {
                                const newWordsToReplace = [...wordsToReplace];
                                newWordsToReplace[index].from = e.target.value;
                                setWordsToReplace(newWordsToReplace);
                              }}
                            />
                          </Grid>
                          <Grid item xs={5}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              placeholder="置換後"
                              value={item.to}
                              onChange={(e) => {
                                const newWordsToReplace = [...wordsToReplace];
                                newWordsToReplace[index].to = e.target.value;
                                setWordsToReplace(newWordsToReplace);
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              onClick={() => {
                                const newWordsToReplace = [...wordsToReplace];
                                newWordsToReplace.splice(index, 1);
                                setWordsToReplace(newWordsToReplace);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))}
                      <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={() => setWordsToReplace([...wordsToReplace, { from: '', to: '' }])}
                        sx={{ mt: 1 }}
                      >
                        置換ペアを追加
                      </Button>
                    </Grid>
                </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>デザインテンプレートを選択</InputLabel>
                      <Select
                        value={selectedTemplate}
                        onChange={(e) => setSelectedTemplate(e.target.value)}
                        fullWidth
                        label="デザインテンプレートを選択"
                      >
                        {Object.entries(designTemplates).map(([key, template]) => (
                          <MenuItem key={key} value={key}>
                            {template.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* 表示件数選択コンポーネントを追加 */}
                  <Grid item xs={12}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel id="items-per-page-label">表示件数</InputLabel>
                      <Select
                        labelId="items-per-page-label"
                        value={itemsPerPage}
                        onChange={(e) => {
                          setItemsPerPage(e.target.value);
                          setCurrentPage(1);
                        }}
                        label="表示件数"
                      >
                        {itemsPerPageOptions.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option === 'All' ? '全て' : `${option}件ずつ`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* 商品リスト */}
                <List>
                  {currentProducts.map((product) => (
                    <ListItem
                      key={product.id}
                      button
                      onClick={() => handleFocusProduct(product.id)}
                      selected={focusedProduct === product.id}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={product.selected}
                          tabIndex={-1}
                          disableRipple
                          onChange={() => handleSelectProduct(product.id)}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </ListItemIcon>
                      <ListItemText primary={product.title} />
                      <ListItemSecondaryAction>
                        <Tooltip title="先頭の余白を削除">
                          <IconButton edge="end" onClick={() => removeLeadingWhitespace(product.id)} disabled={loading}>
                            <FormatColorResetIcon />
                          </IconButton>
                        </Tooltip>
                        <IconButton edge="end" onClick={() => handlePreviewOpen(product)}>
                          <VisibilityIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>

                {/* ページネーションコンポーネント */}
                {itemsPerPage !== 'All' && (
                  <Pagination
                    count={Math.ceil(products.length / itemsPerPage)}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    color="primary"
                    style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
                  />
                )}
              </Grid>

              {/* 右側のセクション */}
              <Grid item xs={12} md={9} sx={{
                  maxHeight: 'calc(100vh - 32px)', // 左右の高さを一致させる場合
                  overflowY: 'auto',
                }}>
                {/* アクションボタン */}
                <Grid container spacing={2} style={{ marginBottom: '20px' }}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={generateProductFeatures}
                      disabled={loading || !apiKey || selectedProducts.length === 0}
                    >
                      AIで商品説明を生成する
                    </Button>
                  </Grid>
                  {/* 変換を適用するボタンを追加 */}
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={applyTransformationsToSelectedProducts}
                      disabled={loading || selectedProducts.length === 0}
                    >
                      変換を適用
                    </Button>
                  </Grid>
          
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={applyUpdatedDescriptions}
                      disabled={loading || selectedProducts.length === 0}
                    >
                      商品説明をスプレッドシートに更新する
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => setCustomPromptDialogOpen(true)}
                      startIcon={<EditIcon />}
                      disabled={loading || selectedProducts.length === 0}
                    >
                      カスタムプロンプトで生成
                    </Button>
                  </Grid>

                  

                </Grid>

                {/* 進捗状況表示 */}
                {loading && (
                  <Box sx={{ width: '100%', mb: 2 }}>
                    <LinearProgress variant="determinate" value={progress} />
                    <Typography variant="body2" color="text.secondary" align="center">
                      {`${Math.round(progress)}%`}
                    </Typography>
                  </Box>
                )}

                {/* エラーメッセージ */}
                {error && <Typography color="error" style={{ marginTop: '20px' }}>{error}</Typography>}
                {/* 成功メッセージ */}
                {message && <Typography color="primary" style={{ marginTop: '20px' }}>{message}</Typography>}

                {/* 選択された商品の詳細情報 */}
                {focusedProduct !== null ? (
                  (() => {
                    const product = products.find((p) => p.id === focusedProduct);
                    return (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">{product.title}</Typography>
                        </Grid>
                        {/* 編集可能なjpDescフィールド */}
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">Japanese Description (Editable)</Typography>
                          <TextField
                            multiline
                            rows={4}
                            fullWidth
                            variant="outlined"
                            value={product.editableJpDesc}
                            onChange={(e) => handleEditJpDesc(product.id, e.target.value)}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="subtitle1">Final Description (Editable)</Typography>
                          <TextField
                            multiline
                            rows={10}
                            fullWidth
                            variant="outlined"
                            value={product.editableDescription || product.updatedDescription || product.description}
                            onChange={(e) => handleEditDescription(product.id, e.target.value)}
                          />
                        </Grid>

                        {/* 生成されたFeatures */}
                        {product.generatedFeatures && (
                          <Grid item xs={12}>
                            <Typography variant="subtitle1">Generated Features (Editable)</Typography>
                            <TextField
                              multiline
                              rows={8}
                              fullWidth
                              variant="outlined"
                              value={product.editableGeneratedFeatures}
                              onChange={(e) => handleEditFeatures(product.id, e.target.value)}
                            />
                          </Grid>
                        )}
                        {/* 更新されたDescription */}
                        {product.updatedDescription && (
                          <>
                            <Grid item xs={12}>
                              <Typography variant="subtitle1">Updated Description</Typography>
                              <div dangerouslySetInnerHTML={{ __html: product.updatedDescription }} />
                            </Grid>
                            {/* DescriptionValidator を追加 */}
                            <Grid item xs={12}>
                              <DescriptionValidator
                                htmlContent={product.updatedDescription || product.description}
                                onUpdate={(updatedHtml) => {
                                  // 修正後のHTMLで商品データを更新
                                  setProducts((prevProducts) =>
                                    prevProducts.map((p) =>
                                      p.id === product.id ? { ...p, updatedDescription: updatedHtml } : p
                                    )
                                  );
                                }}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    );
                  })()
                ) : (
                  <Typography variant="body1">商品を選択してください</Typography>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>閉じる</Button>
          </DialogActions>
        </Dialog>

        {/* カスタムプロンプトダイアログを追加 */}
        <Dialog
          open={customPromptDialogOpen}
          onClose={() => setCustomPromptDialogOpen(false)}
          fullScreen
          aria-labelledby="custom-prompt-dialog-title"
        >
          <DialogTitle id="custom-prompt-dialog-title">カスタムプロンプトで商品説明を生成</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  label="カスタムプロンプト"
                  type="text"
                  fullWidth
                  multiline
                  rows={4}
                  value={customPrompt}
                  onChange={(e) => setCustomPrompt(e.target.value)}
                  placeholder="ここにカスタムプロンプトを入力してください..."
                  variant="outlined"
                />
              </Grid>
              {/* その他のオプションフィールド */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="JP説明の最大文字数"
                  type="number"
                  fullWidth
                  value={customOptions.maxJpDescLength}
                  onChange={(e) =>
                    setCustomOptions({ ...customOptions, maxJpDescLength: parseInt(e.target.value) })
                  }
                  variant="outlined"
                  InputProps={{ inputProps: { min: 1 } }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Maximum Tokens"
                  type="number"
                  fullWidth
                  value={customOptions.maxTokens}
                  onChange={(e) => setCustomOptions({ ...customOptions, maxTokens: parseInt(e.target.value) })}
                  variant="outlined"
                  InputProps={{ inputProps: { min: 1 } }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Temperature"
                  type="number"
                  fullWidth
                  value={customOptions.temperature}
                  onChange={(e) => setCustomOptions({ ...customOptions, temperature: parseFloat(e.target.value) })}
                  variant="outlined"
                  InputProps={{ inputProps: { min: 0, max: 1, step: 0.01 } }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Top_p"
                  type="number"
                  fullWidth
                  value={customOptions.top_p}
                  onChange={(e) => setCustomOptions({ ...customOptions, top_p: parseFloat(e.target.value) })}
                  variant="outlined"
                  InputProps={{ inputProps: { min: 0, max: 1, step: 0.01 } }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>モデルを選択</InputLabel>
                  <Select
                    value={customOptions.model}
                    onChange={(e) => setCustomOptions({ ...customOptions, model: e.target.value })}
                    label="モデルを選択"
                  >
                    <MenuItem value="gpt-4o-mini">GPT-4o-mini</MenuItem>
                    <MenuItem value="gpt-4o-2024-08-06">gpt-4o-2024-08-06</MenuItem>
                    <MenuItem value="o1-mini">o1-mini</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="言語"
                  type="text"
                  fullWidth
                  value={customOptions.language}
                  onChange={(e) => setCustomOptions({ ...customOptions, language: e.target.value })}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="スタイル"
                  type="text"
                  fullWidth
                  value={customOptions.style}
                  onChange={(e) => setCustomOptions({ ...customOptions, style: e.target.value })}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="追加の指示"
                  type="text"
                  fullWidth
                  multiline
                  rows={2}
                  value={customOptions.additionalInstructions}
                  onChange={(e) => setCustomOptions({ ...customOptions, additionalInstructions: e.target.value })}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="システムメッセージテンプレート"
                  type="text"
                  fullWidth
                  multiline
                  rows={3}
                  value={customOptions.systemMessageTemplate}
                  onChange={(e) => setCustomOptions({ ...customOptions, systemMessageTemplate: e.target.value })}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="ユーザーメッセージテンプレート"
                  type="text"
                  fullWidth
                  multiline
                  rows={3}
                  value={customOptions.userMessageTemplate}
                  onChange={(e) => setCustomOptions({ ...customOptions, userMessageTemplate: e.target.value })}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCustomPromptDialogOpen(false)}>キャンセル</Button>
            <Button
              onClick={handleGenerateWithCustomPrompt}
              variant="contained"
              color="primary"
              disabled={!customPrompt.trim() || loading}
            >
              生成
            </Button>
          </DialogActions>
        </Dialog>

        {/* プレビューダイアログ */}
        <Dialog open={showPreviewDialog} onClose={handlePreviewClose} maxWidth="md" fullWidth>
          <DialogTitle>eBay Preview</DialogTitle>
          <DialogContent>商品説明の生成が完了しました</DialogContent>
          <DialogActions>
            <Button onClick={handlePreviewClose}>閉じる</Button>
          </DialogActions>
        </Dialog>

        

        {/* スナックバー通知 */}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Paper>
  );
};

export default OpenAIDescriptionGenerator;