import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Snackbar,
  Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.common.white,
}));

const PriceCalculationSettings = ({ customSettings = {}, spreadsheetData, onUpdatePrices, fetchSpreadsheetData }) => {
  const [calculatedPrices, setCalculatedPrices] = useState([]);
  const [isCalculating, setIsCalculating] = useState(false);
  const [error, setError] = useState(null);
  const [showPriceSimulation, setShowPriceSimulation] = useState(false);
  const [showRevenueSimulation, setShowRevenueSimulation] = useState(false);
  const [simulationResults, setSimulationResults] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [exchangeRate, setExchangeRate] = useState(140); // 初期値
  const [bestOfferAutoAcceptPercentage, setBestOfferAutoAcceptPercentage] = useState(95);
  const [minimumBestOfferPercentage, setMinimumBestOfferPercentage] = useState(80);

  useEffect(() => {
    if (customSettings) {
      setBestOfferAutoAcceptPercentage(customSettings.bestOfferAutoAcceptPercentage || 95);
      setMinimumBestOfferPercentage(customSettings.minimumBestOfferPercentage || 80);
      setExchangeRate(customSettings.exchangeRate || 140); // 追加
    }
    console.log('Loaded customSettings:', customSettings);
  }, [customSettings]);

  // customSettings のデフォルト値を設定
  const defaultSettings = {
    fees: {
      ebayFinalValue: 0,
      payoneer: 0,
      promotedListing: 0,
      ebayTransactionFee: 0
    },
    targetProfitMargin: 0,
    discountRate: 0,
    shippingRateTemplates: [],
    selectedShippingTemplateId: 'default',
    categories: {}
  };

  // customSettings が null または undefined の場合はデフォルト値を使用
  const settings = customSettings && typeof customSettings === 'object' ? customSettings : defaultSettings;

  // 価格を .99 に調整する関数
  const adjustPriceTo99 = (price) => {
    return Math.floor(price) + 0.99;
  };

  const calculatePrices = useCallback(() => {
    setIsCalculating(true);
    setError(null);

    try {
      if (!customSettings || !spreadsheetData || spreadsheetData.length === 0) {
        throw new Error('必要なデータが不足しています。');
      }

      const headers = spreadsheetData[0];
      const startPriceIndex = headers.findIndex(header => header.toLowerCase() === 'startprice');

      if (startPriceIndex === -1) {
        throw new Error('StartPrice列が見つかりません。');
      }

      const selectedShippingTemplateId = settings.selectedShippingTemplateId || 'default';
      const shippingRateTemplates = settings.shippingRateTemplates || [];

      const selectedShippingTemplate = shippingRateTemplates.find(
        template => template.id === selectedShippingTemplateId
      ) || shippingRateTemplates[0];

      const newPrices = spreadsheetData.slice(1).map(row => {
        const startPrice = parseFloat(row[startPriceIndex]);
        if (isNaN(startPrice)) {
          return null;
        }

        let totalCost = startPrice; // USDで管理
        let totalPercentage = 0;

        // categoriesが存在するか確認し、存在する場合のみ処理
        if (settings.categories && typeof settings.categories === 'object') {
          Object.values(settings.categories).flat().forEach(item => {
            if (item.type === 'fixed') {
              totalCost += parseFloat(item.value); // 固定費用（USD）
            } else if (item.type === 'percentage') {
              totalPercentage += parseFloat(item.value); // 割合費用（%）
            }
          });
        }

        // 手数料を追加（USD）
        totalPercentage += parseFloat(settings.fees.ebayFinalValue) + 
                           parseFloat(settings.fees.payoneer) + 
                           parseFloat(settings.fees.promotedListing);
        totalCost += parseFloat(settings.fees.ebayTransactionFee); // eBay取引手数料（USD）

        // 送料を追加（USD）
        const shippingRate = selectedShippingTemplate.shippingRates.find(
          rate => startPrice >= parseFloat(rate.minPriceUSD) && startPrice < parseFloat(rate.maxPriceUSD)
        );
        if (shippingRate) {
          console.log('送料 (USD):', shippingRate.costUSD); // デバッグ用ログ
          totalCost += parseFloat(shippingRate.costUSD); // costUSD を追加
        }

        // 目標利益率を追加
        const targetProfitMargin = parseFloat(settings.targetProfitMargin);
        totalPercentage += targetProfitMargin;

        // 販売価格を計算
        let calculatedPrice = totalCost / (1 - totalPercentage / 100);
        calculatedPrice = adjustPriceTo99(calculatedPrice); // .99 に調整

        // 割引後価格を計算
        const discountedPrice = calculatedPrice * (1 - parseFloat(settings.discountRate) / 100);
        const finalPrice = adjustPriceTo99(discountedPrice); // .99 に調整

        // ベストオファー価格を計算
        const bestOfferAutoAcceptPrice = finalPrice * (bestOfferAutoAcceptPercentage / 100);
        const minimumBestOfferPrice = finalPrice * (minimumBestOfferPercentage / 100);

        // 利益と利益率を計算
        const profit = finalPrice - totalCost;
        const profitMargin = (profit / finalPrice) * 100;

        return {
          originalStartPrice: startPrice.toFixed(2), // USDで表示
          calculatedPrice: calculatedPrice.toFixed(2),
          discountedPrice: finalPrice.toFixed(2),
          bestOfferAutoAcceptPrice: bestOfferAutoAcceptPrice.toFixed(2),
          minimumBestOfferPrice: minimumBestOfferPrice.toFixed(2),
          profit: profit.toFixed(2),
          profitMargin: profitMargin.toFixed(2)
        };
      });

      setCalculatedPrices(newPrices.filter(price => price !== null));
      console.log('Calculated Prices:', newPrices.filter(price => price !== null));
    } catch (err) {
      setError(err.message);
      console.error('Error in calculatePrices:', err);
    } finally {
      setIsCalculating(false);
    }
  }, [settings, spreadsheetData, bestOfferAutoAcceptPercentage, minimumBestOfferPercentage]);

  const handleApplyPrices = async () => {
    try {
      const headers = spreadsheetData[0];
      const startPriceIndex = headers.findIndex(header => header.toLowerCase() === 'startprice');
      const bestOfferAutoAcceptIndex = headers.findIndex(header => header.toLowerCase() === 'bestofferautoacceptprice');
      const minimumBestOfferIndex = headers.findIndex(header => header.toLowerCase() === 'minimumbestofferprice');

      if (startPriceIndex === -1) {
        throw new Error('StartPrice列が見つかりません。');
      }

      const updatedSpreadsheetData = spreadsheetData.map((row, index) => {
        if (index === 0) return row; // ヘッダー行はそのまま
        const calculatedPrice = calculatedPrices[index - 1];
        if (!calculatedPrice) return row; // 計算された価格がない場合は元の行をそのまま返す

        const updatedRow = [...row];
        updatedRow[startPriceIndex] = calculatedPrice.discountedPrice;
        if (bestOfferAutoAcceptIndex !== -1) {
          updatedRow[bestOfferAutoAcceptIndex] = calculatedPrice.bestOfferAutoAcceptPrice;
        }
        if (minimumBestOfferIndex !== -1) {
          updatedRow[minimumBestOfferIndex] = calculatedPrice.minimumBestOfferPrice;
        }
        return updatedRow;
      });

      await onUpdatePrices(updatedSpreadsheetData);
      await fetchSpreadsheetData();
      setSnackbar({ open: true, message: '価格が更新されました', severity: 'success' });
    } catch (err) {
      setError('価格の更新に失敗しました: ' + err.message);
      console.error('Error in handleApplyPrices:', err);
    }
  };

  const runSimulation = () => {
    const totalRevenue = calculatedPrices.reduce((sum, price) => sum + parseFloat(price.discountedPrice), 0);
    const totalProfit = calculatedPrices.reduce((sum, price) => sum + parseFloat(price.profit), 0);
    const averageProfitMargin = calculatedPrices.reduce((sum, price) => sum + parseFloat(price.profitMargin), 0) / calculatedPrices.length;

    const results = {
      totalRevenue,
      totalProfit,
      averageProfitMargin,
      itemCount: calculatedPrices.length
    };

    setSimulationResults(results);
    console.log('Simulation Results:', results);

    setShowRevenueSimulation(true);
  };

  const closePriceSimulation = () => setShowPriceSimulation(false);
  const closeRevenueSimulation = () => setShowRevenueSimulation(false);

  const formatCurrency = (value, currency = 'USD') => {
    if (value === undefined || value === null) {
      return currency === 'USD' ? '$0.00' : '¥0';
    }
    if (currency === 'USD') {
      return `$${parseFloat(value).toFixed(2)}`;
    } else if (currency === 'JPY') {
      return `¥${Math.round(parseFloat(value) * exchangeRate)}`;
    }
  };

  const SettingItem = ({ label, value }) => (
    <Typography variant="body2">
      <strong>{label}:</strong> {value}
    </Typography>
  );

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', p: 0 }}>
      <Typography variant="h6" gutterBottom>
        価格計算と収益シミュレーション
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          現在の設定
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SettingItem label="目標利益率" value={`${settings.targetProfitMargin}%`} />
            <SettingItem label="割引率" value={`${settings.discountRate}%`} />
            <SettingItem label="eBay最終価値手数料" value={`${settings.fees.ebayFinalValue}%`} />
            <SettingItem label="Payoneer手数料" value={`${settings.fees.payoneer}%`} />
            <SettingItem label="広告出品手数料" value={`${settings.fees.promotedListing}%`} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SettingItem 
              label="eBay取引手数料" 
              value={`${formatCurrency(settings.fees.ebayTransactionFee)} (${formatCurrency(settings.fees.ebayTransactionFee, 'JPY')})`} 
            />
            <SettingItem label="為替レート" value={`1 USD = ${exchangeRate.toFixed(2)} JPY`} />
            <SettingItem label="Best Offer自動承認価格" value={`${bestOfferAutoAcceptPercentage}%`} />
            <SettingItem label="最小Best Offer価格" value={`${minimumBestOfferPercentage}%`} />
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={calculatePrices}
            disabled={isCalculating}
            fullWidth
          >
            {isCalculating ? <CircularProgress size={24} /> : '価格を再計算'}
          </Button>
        </Grid>
      </Grid>

      {calculatedPrices.length > 0 && (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={4}>
            <Button 
              variant="contained" 
              color="secondary" 
              onClick={() => setShowPriceSimulation(true)}
              fullWidth
            >
              価格シミュレーション結果
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button 
              variant="contained" 
              color="secondary" 
              onClick={handleApplyPrices}
              fullWidth
            >
              計算した価格を適用
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button 
              variant="outlined" 
              color="primary" 
              onClick={runSimulation}
              fullWidth
            >
              収益シミュレーション実行
            </Button>
          </Grid>
        </Grid>
      )}

      {/* 価格シミュレーション結果ダイアログ */}
      <Dialog
        open={showPriceSimulation}
        onClose={closePriceSimulation}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>価格シミュレーション結果</DialogTitle>
        <DialogContent sx={{ height: '90vh' }}>
          <TableContainer component={Paper} sx={{ maxHeight: '100%', overflow: 'auto' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell>元の価格 (USD)</StyledTableCell>
                  <StyledTableCell>計算後の価格 (USD)</StyledTableCell>
                  {/* <StyledTableCell>割引後価格 (USD)</StyledTableCell> */}
                  <StyledTableCell>ベストオファー自動承認価格 (USD)</StyledTableCell>
                  <StyledTableCell>最小ベストオファー価格 (USD)</StyledTableCell>
                  <StyledTableCell>利益 (USD)</StyledTableCell>
                  <StyledTableCell>利益率 (%)</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {calculatedPrices.map((price, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {formatCurrency(price.originalStartPrice)} ({formatCurrency(price.originalStartPrice, 'JPY')})
                    </TableCell>
                    <TableCell>
                      {formatCurrency(price.calculatedPrice)} ({formatCurrency(price.calculatedPrice, 'JPY')})
                    </TableCell>
                    {/* <TableCell>
                      {formatCurrency(price.discountedPrice)} ({formatCurrency(price.discountedPrice, 'JPY')})
                    </TableCell> */}
                    <TableCell>
                      {formatCurrency(price.bestOfferAutoAcceptPrice)} ({formatCurrency(price.bestOfferAutoAcceptPrice, 'JPY')})
                    </TableCell>
                    <TableCell>
                      {formatCurrency(price.minimumBestOfferPrice)} ({formatCurrency(price.minimumBestOfferPrice, 'JPY')})
                    </TableCell>
                    <TableCell>
                      {formatCurrency(price.profit)} ({formatCurrency(price.profit, 'JPY')})
                    </TableCell>
                    <TableCell>{price.profitMargin}%</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={closePriceSimulation}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* 収益シミュレーション結果ダイアログ */}
      <Dialog 
        open={showRevenueSimulation} 
        onClose={closeRevenueSimulation} 
        maxWidth="md" 
        fullWidth
      >
        <DialogTitle>収益シミュレーション結果</DialogTitle>
        <DialogContent>
          {simulationResults && (
            <Box>
              <Typography variant="h6" gutterBottom>概要</Typography>
              <Typography>
                総売上: {formatCurrency(simulationResults.totalRevenue)} ({formatCurrency(simulationResults.totalRevenue, 'JPY')})
              </Typography>
              <Typography>
                総利益: {formatCurrency(simulationResults.totalProfit)} ({formatCurrency(simulationResults.totalProfit, 'JPY')})
              </Typography>
              <Typography>平均利益率: {simulationResults.averageProfitMargin.toFixed(2)}%</Typography>
              <Typography>商品数: {simulationResults.itemCount}</Typography>

              <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>詳細分析</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>指標</StyledTableCell>
                      <StyledTableCell>値 (USD)</StyledTableCell>
                      <StyledTableCell>値 (JPY)</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>1商品あたりの平均売上</TableCell>
                      <TableCell>
                        {formatCurrency(simulationResults.totalRevenue / simulationResults.itemCount)}
                      </TableCell>
                      <TableCell>
                        {formatCurrency(simulationResults.totalRevenue / simulationResults.itemCount, 'JPY')}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>1商品あたりの平均利益</TableCell>
                      <TableCell>
                        {formatCurrency(simulationResults.totalProfit / simulationResults.itemCount)}
                      </TableCell>
                      <TableCell>
                        {formatCurrency(simulationResults.totalProfit / simulationResults.itemCount, 'JPY')}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>総売上に対する総利益の割合</TableCell>
                      <TableCell colSpan={2}>
                        {((simulationResults.totalProfit / simulationResults.totalRevenue) * 100).toFixed(2)}%
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeRevenueSimulation}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PriceCalculationSettings;



// import React, { useState, useEffect, useCallback } from 'react';
// import {
//   Box,
//   Typography,
//   Button,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   CircularProgress,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Alert,
//   Snackbar,
//   Grid
// } from '@mui/material';
// import { styled } from '@mui/material/styles';

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   fontWeight: 'bold',
//   backgroundColor: theme.palette.primary.light,
//   color: theme.palette.common.white,
// }));

// const PriceCalculationSettings = ({ customSettings = {}, spreadsheetData, onUpdatePrices, fetchSpreadsheetData }) => {
//   const [calculatedPrices, setCalculatedPrices] = useState([]);
//   const [isCalculating, setIsCalculating] = useState(false);
//   const [error, setError] = useState(null);
//   const [showPriceSimulation, setShowPriceSimulation] = useState(false);
//   const [showRevenueSimulation, setShowRevenueSimulation] = useState(false);
//   const [simulationResults, setSimulationResults] = useState(null);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
//   const [exchangeRate, setExchangeRate] = useState(140); // 初期値
//   const [bestOfferAutoAcceptPercentage, setBestOfferAutoAcceptPercentage] = useState(95);
//   const [minimumBestOfferPercentage, setMinimumBestOfferPercentage] = useState(80);

//   useEffect(() => {
//     if (customSettings) {
//       setBestOfferAutoAcceptPercentage(customSettings.bestOfferAutoAcceptPercentage || 95);
//       setMinimumBestOfferPercentage(customSettings.minimumBestOfferPercentage || 80);
//       setExchangeRate(customSettings.exchangeRate || 140); // 追加
//     }
//     console.log('Loaded customSettings:', customSettings);
//   }, [customSettings]);

//   // customSettings のデフォルト値を設定
//   const defaultSettings = {
//     fees: {
//       ebayFinalValue: 0,
//       payoneer: 0,
//       promotedListing: 0,
//       ebayTransactionFee: 0
//     },
//     targetProfitMargin: 0,
//     discountRate: 0,
//     shippingRates: [],
//     categories: {}
//   };

//   // customSettings が null または undefined の場合はデフォルト値を使用
//   const settings = customSettings && typeof customSettings === 'object' ? customSettings : defaultSettings;

//   // 価格を .99 に調整する関数
//   const adjustPriceTo99 = (price) => {
//     return Math.floor(price) + 0.99;
//   };

//   const calculatePrices = useCallback(() => {
//     setIsCalculating(true);
//     setError(null);

//     try {
//       if (!customSettings || !spreadsheetData || spreadsheetData.length === 0) {
//         throw new Error('必要なデータが不足しています。');
//       }

//       const headers = spreadsheetData[0];
//       const startPriceIndex = headers.findIndex(header => header.toLowerCase() === 'startprice');

//       if (startPriceIndex === -1) {
//         throw new Error('StartPrice列が見つかりません。');
//       }

//       const newPrices = spreadsheetData.slice(1).map(row => {
//         const startPrice = parseFloat(row[startPriceIndex]);
//         if (isNaN(startPrice)) {
//           return null;
//         }

//         let totalCost = startPrice; // USDで管理
//         let totalPercentage = 0;

//         // categoriesが存在するか確認し、存在する場合のみ処理
//         if (settings.categories && typeof settings.categories === 'object') {
//           Object.values(settings.categories).flat().forEach(item => {
//             if (item.type === 'fixed') {
//               totalCost += parseFloat(item.value); // 固定費用（USD）
//             } else if (item.type === 'percentage') {
//               totalPercentage += parseFloat(item.value); // 割合費用（%）
//             }
//           });
//         }

//         // 手数料を追加（USD）
//         totalPercentage += settings.fees.ebayFinalValue + 
//                            settings.fees.payoneer + 
//                            settings.fees.promotedListing;
//         totalCost += parseFloat(settings.fees.ebayTransactionFee); // eBay取引手数料（USD）

//         // 送料を追加（USD）
//         const shippingRate = settings.shippingRates.find(
//           rate => startPrice >= rate.minPriceUSD && startPrice < rate.maxPriceUSD
//         );
//         if (shippingRate) {
//           console.log('送料 (USD):', shippingRate.costUSD); // デバッグ用ログ
//           totalCost += parseFloat(shippingRate.costUSD); // costUSD を追加
//         }

//         // 目標利益率を追加
//         const targetProfitMargin = parseFloat(settings.targetProfitMargin);
//         totalPercentage += targetProfitMargin;

//         // 販売価格を計算
//         let calculatedPrice = totalCost / (1 - totalPercentage / 100);
//         calculatedPrice = adjustPriceTo99(calculatedPrice); // .99 に調整

//         // 割引後価格を計算
//         const discountedPrice = calculatedPrice * (1 - parseFloat(settings.discountRate) / 100);
//         const finalPrice = adjustPriceTo99(discountedPrice); // .99 に調整

//         // ベストオファー価格を計算
//         const bestOfferAutoAcceptPrice = finalPrice * (bestOfferAutoAcceptPercentage / 100);
//         const minimumBestOfferPrice = finalPrice * (minimumBestOfferPercentage / 100);

//         // 利益と利益率を計算
//         const profit = finalPrice - totalCost;
//         const profitMargin = (profit / finalPrice) * 100;

//         return {
//           originalStartPrice: startPrice.toFixed(2), // USDで表示
//           calculatedPrice: calculatedPrice.toFixed(2),
//           discountedPrice: finalPrice.toFixed(2),
//           bestOfferAutoAcceptPrice: bestOfferAutoAcceptPrice.toFixed(2),
//           minimumBestOfferPrice: minimumBestOfferPrice.toFixed(2),
//           profit: profit.toFixed(2),
//           profitMargin: profitMargin.toFixed(2)
//         };
//       });

//       setCalculatedPrices(newPrices.filter(price => price !== null));
//       console.log('Calculated Prices:', newPrices.filter(price => price !== null));
//     } catch (err) {
//       setError(err.message);
//       console.error('Error in calculatePrices:', err);
//     } finally {
//       setIsCalculating(false);
//     }
//   }, [settings, spreadsheetData, bestOfferAutoAcceptPercentage, minimumBestOfferPercentage]);

//   const handleApplyPrices = async () => {
//     try {
//       const headers = spreadsheetData[0];
//       const startPriceIndex = headers.findIndex(header => header.toLowerCase() === 'startprice');
//       const bestOfferAutoAcceptIndex = headers.findIndex(header => header.toLowerCase() === 'bestofferautoacceptprice');
//       const minimumBestOfferIndex = headers.findIndex(header => header.toLowerCase() === 'minimumbestofferprice');

//       if (startPriceIndex === -1) {
//         throw new Error('StartPrice列が見つかりません。');
//       }

//       const updatedSpreadsheetData = spreadsheetData.map((row, index) => {
//         if (index === 0) return row; // ヘッダー行はそのまま
//         const calculatedPrice = calculatedPrices[index - 1];
//         if (!calculatedPrice) return row; // 計算された価格がない場合は元の行をそのまま返す

//         const updatedRow = [...row];
//         updatedRow[startPriceIndex] = calculatedPrice.discountedPrice;
//         if (bestOfferAutoAcceptIndex !== -1) {
//           updatedRow[bestOfferAutoAcceptIndex] = calculatedPrice.bestOfferAutoAcceptPrice;
//         }
//         if (minimumBestOfferIndex !== -1) {
//           updatedRow[minimumBestOfferIndex] = calculatedPrice.minimumBestOfferPrice;
//         }
//         return updatedRow;
//       });

//       await onUpdatePrices(updatedSpreadsheetData);
//       await fetchSpreadsheetData();
//       setSnackbar({ open: true, message: '価格が更新されました', severity: 'success' });
//     } catch (err) {
//       setError('価格の更新に失敗しました: ' + err.message);
//       console.error('Error in handleApplyPrices:', err);
//     }
//   };

//   const runSimulation = () => {
//     const totalRevenue = calculatedPrices.reduce((sum, price) => sum + parseFloat(price.discountedPrice), 0);
//     const totalProfit = calculatedPrices.reduce((sum, price) => sum + parseFloat(price.profit), 0);
//     const averageProfitMargin = calculatedPrices.reduce((sum, price) => sum + parseFloat(price.profitMargin), 0) / calculatedPrices.length;

//     const results = {
//       totalRevenue,
//       totalProfit,
//       averageProfitMargin,
//       itemCount: calculatedPrices.length
//     };

//     setSimulationResults(results);
//     console.log('Simulation Results:', results);

//     setShowRevenueSimulation(true);
//   };

//   const closePriceSimulation = () => setShowPriceSimulation(false);
//   const closeRevenueSimulation = () => setShowRevenueSimulation(false);

//   const formatCurrency = (value, currency = 'USD') => {
//     if (value === undefined || value === null) {
//       return currency === 'USD' ? '$0.00' : '¥0';
//     }
//     if (currency === 'USD') {
//       return `$${parseFloat(value).toFixed(2)}`;
//     } else if (currency === 'JPY') {
//       return `¥${Math.round(parseFloat(value) * exchangeRate)}`;
//     }
//   };

//   const SettingItem = ({ label, value }) => (
//     <Typography variant="body2">
//       <strong>{label}:</strong> {value}
//     </Typography>
//   );

//   return (
//     <Box sx={{ maxWidth: 800, margin: 'auto', p: 0 }}>
//       <Typography variant="h6" gutterBottom>
//         価格計算と収益シミュレーション
//       </Typography>

//       {error && (
//         <Alert severity="error" sx={{ mb: 2 }}>
//           {error}
//         </Alert>
//       )}

//       <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
//         <Typography variant="h6" gutterBottom>
//           現在の設定
//         </Typography>
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={6}>
//             <SettingItem label="目標利益率" value={`${settings.targetProfitMargin}%`} />
//             <SettingItem label="割引率" value={`${settings.discountRate}%`} />
//             <SettingItem label="eBay最終価値手数料" value={`${settings.fees.ebayFinalValue}%`} />
//             <SettingItem label="Payoneer手数料" value={`${settings.fees.payoneer}%`} />
//             <SettingItem label="広告出品手数料" value={`${settings.fees.promotedListing}%`} />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <SettingItem 
//               label="eBay取引手数料" 
//               value={`${formatCurrency(customSettings?.fees.ebayTransactionFee)} (${formatCurrency(customSettings?.fees.ebayTransactionFee, 'JPY')})`} 
//             />
//             <SettingItem label="為替レート" value={`1 USD = ${exchangeRate.toFixed(2)} JPY`} />
//             <SettingItem label="Best Offer自動承認価格" value={`${bestOfferAutoAcceptPercentage}%`} />
//             <SettingItem label="最小Best Offer価格" value={`${minimumBestOfferPercentage}%`} />
//           </Grid>
//         </Grid>
//       </Paper>

//       <Grid container spacing={2} sx={{ mb: 2 }}>
//         <Grid item xs={12} sm={6}>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={calculatePrices}
//             disabled={isCalculating}
//             fullWidth
//           >
//             {isCalculating ? <CircularProgress size={24} /> : '価格を再計算'}
//           </Button>
//         </Grid>
//       </Grid>

//       {calculatedPrices.length > 0 && (
//         <Grid container spacing={2} sx={{ mt: 2 }}>
//           <Grid item xs={12} sm={4}>
//             <Button 
//               variant="contained" 
//               color="secondary" 
//               onClick={() => setShowPriceSimulation(true)}
//               fullWidth
//             >
//               価格シミュレーション結果
//             </Button>
//           </Grid>
//           <Grid item xs={12} sm={4}>
//             <Button 
//               variant="contained" 
//               color="secondary" 
//               onClick={handleApplyPrices}
//               fullWidth
//             >
//               計算した価格を適用
//             </Button>
//           </Grid>
//           <Grid item xs={12} sm={4}>
//             <Button 
//               variant="outlined" 
//               color="primary" 
//               onClick={runSimulation}
//               fullWidth
//             >
//               収益シミュレーション実行
//             </Button>
//           </Grid>
//         </Grid>
//       )}

//       {/* 価格シミュレーション結果ダイアログ */}
//       <Dialog
//         open={showPriceSimulation}
//         onClose={closePriceSimulation}
//         maxWidth="lg"
//         fullWidth
//       >
//         <DialogTitle>価格シミュレーション結果</DialogTitle>
//         <DialogContent sx={{ height: '90vh' }}>
//           <TableContainer component={Paper} sx={{ maxHeight: '100%', overflow: 'auto' }}>
//             <Table stickyHeader>
//               <TableHead>
//                 <TableRow>
//                   <StyledTableCell>元の価格 (USD)</StyledTableCell>
//                   <StyledTableCell>計算後の価格 (USD)</StyledTableCell>
//                   <StyledTableCell>割引後価格 (USD)</StyledTableCell>
//                   <StyledTableCell>ベストオファー自動承認価格 (USD)</StyledTableCell>
//                   <StyledTableCell>最小ベストオファー価格 (USD)</StyledTableCell>
//                   <StyledTableCell>利益 (USD)</StyledTableCell>
//                   <StyledTableCell>利益率 (%)</StyledTableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {calculatedPrices.map((price, index) => (
//                   <TableRow key={index}>
//                     <TableCell>
//                       {formatCurrency(price.originalStartPrice)} ({formatCurrency(price.originalStartPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.calculatedPrice)} ({formatCurrency(price.calculatedPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.discountedPrice)} ({formatCurrency(price.discountedPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.bestOfferAutoAcceptPrice)} ({formatCurrency(price.bestOfferAutoAcceptPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.minimumBestOfferPrice)} ({formatCurrency(price.minimumBestOfferPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.profit)} ({formatCurrency(price.profit, 'JPY')})
//                     </TableCell>
//                     <TableCell>{price.profitMargin}%</TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closePriceSimulation}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* 収益シミュレーション結果ダイアログ */}
//       <Dialog 
//         open={showRevenueSimulation} 
//         onClose={closeRevenueSimulation} 
//         maxWidth="md" 
//         fullWidth
//       >
//         <DialogTitle>収益シミュレーション結果</DialogTitle>
//         <DialogContent>
//           {simulationResults && (
//             <Box>
//               <Typography variant="h6" gutterBottom>概要</Typography>
//               <Typography>
//                 総売上: {formatCurrency(simulationResults.totalRevenue)} ({formatCurrency(simulationResults.totalRevenue, 'JPY')})
//               </Typography>
//               <Typography>
//                 総利益: {formatCurrency(simulationResults.totalProfit)} ({formatCurrency(simulationResults.totalProfit, 'JPY')})
//               </Typography>
//               <Typography>平均利益率: {simulationResults.averageProfitMargin.toFixed(2)}%</Typography>
//               <Typography>商品数: {simulationResults.itemCount}</Typography>

//               <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>詳細分析</Typography>
//               <TableContainer component={Paper}>
//                 <Table>
//                   <TableHead>
//                     <TableRow>
//                       <StyledTableCell>指標</StyledTableCell>
//                       <StyledTableCell>値 (USD)</StyledTableCell>
//                       <StyledTableCell>値 (JPY)</StyledTableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     <TableRow>
//                       <TableCell>1商品あたりの平均売上</TableCell>
//                       <TableCell>
//                         {formatCurrency(simulationResults.totalRevenue / simulationResults.itemCount)}
//                       </TableCell>
//                       <TableCell>
//                         {formatCurrency(simulationResults.totalRevenue / simulationResults.itemCount, 'JPY')}
//                       </TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell>1商品あたりの平均利益</TableCell>
//                       <TableCell>
//                         {formatCurrency(simulationResults.totalProfit / simulationResults.itemCount)}
//                       </TableCell>
//                       <TableCell>
//                         {formatCurrency(simulationResults.totalProfit / simulationResults.itemCount, 'JPY')}
//                       </TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell>総売上に対する総利益の割合</TableCell>
//                       <TableCell colSpan={2}>
//                         {((simulationResults.totalProfit / simulationResults.totalRevenue) * 100).toFixed(2)}%
//                       </TableCell>
//                     </TableRow>
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </Box>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closeRevenueSimulation}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbar for notifications */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default PriceCalculationSettings;









// // PriceCalculationSettings.js

// import React, { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';
// import {
//   Box,
//   Typography,
//   Button,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   CircularProgress,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Alert,
//   Snackbar,
//   Grid
// } from '@mui/material';
// import { styled } from '@mui/material/styles';

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   fontWeight: 'bold',
//   backgroundColor: theme.palette.primary.light,
//   color: theme.palette.common.white,
// }));

// const PriceCalculationSettings = ({ customSettings = {}, spreadsheetData, onUpdatePrices, fetchSpreadsheetData }) => {
//   const [calculatedPrices, setCalculatedPrices] = useState([]);
//   const [isCalculating, setIsCalculating] = useState(false);
//   const [error, setError] = useState(null);
//   const [showPriceSimulation, setShowPriceSimulation] = useState(false);
//   const [showRevenueSimulation, setShowRevenueSimulation] = useState(false);
//   const [simulationResults, setSimulationResults] = useState(null);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
//   const [exchangeRate, setExchangeRate] = useState(140); // 初期値
//   const [isLoadingExchangeRate, setIsLoadingExchangeRate] = useState(false);
//   const [bestOfferAutoAcceptPercentage, setBestOfferAutoAcceptPercentage] = useState(95);
//   const [minimumBestOfferPercentage, setMinimumBestOfferPercentage] = useState(80);

//   useEffect(() => {
//     let isMounted = true;
//     fetchExchangeRate().then(() => {
//       if (isMounted) {
//         // 状態を更新
//       }
//     });
//     return () => {
//       isMounted = false;
//     };
//   }, []);

//   useEffect(() => {
//     if (customSettings) {
//       setBestOfferAutoAcceptPercentage(customSettings.bestOfferAutoAcceptPercentage || 95);
//       setMinimumBestOfferPercentage(customSettings.minimumBestOfferPercentage || 80);
//     }
//     console.log('Loaded customSettings:', customSettings);
//   }, [customSettings]);

//   // customSettings のデフォルト値を設定
//   const defaultSettings = {
//     fees: {
//       ebayFinalValue: 0,
//       payoneer: 0,
//       promotedListing: 0,
//       ebayTransactionFee: 0
//     },
//     targetProfitMargin: 0,
//     discountRate: 0,
//     shippingRates: [],
//     categories: {} // 追加
//   };

//   // customSettings が null または undefined の場合はデフォルト値を使用
//   const settings = customSettings && typeof customSettings === 'object' ? customSettings : defaultSettings;

//   const fetchExchangeRate = async () => {
//     setIsLoadingExchangeRate(true);
//     try {
//       const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
//       const newRate = response.data.rates.JPY;
//       setExchangeRate(newRate);
//       setSnackbar({ open: true, message: `為替レートを更新しました: 1 USD = ${newRate} JPY`, severity: 'info' });
//     } catch (error) {
//       console.error('Failed to fetch exchange rate:', error);
//       setError('為替レートの取得に失敗しました。デフォルトのレートを使用します。');
//     } finally {
//       setIsLoadingExchangeRate(false);
//     }
//   };

//   // 価格を .99 に調整する関数
//   const adjustPriceTo99 = (price) => {
//     return Math.floor(price) + 0.99;
//   };

//   const calculatePrices = useCallback(() => {
//     setIsCalculating(true);
//     setError(null);

//     try {
//       if (!customSettings || !spreadsheetData || spreadsheetData.length === 0) {
//         throw new Error('必要なデータが不足しています。');
//       }

//       const headers = spreadsheetData[0];
//       const startPriceIndex = headers.findIndex(header => header.toLowerCase() === 'startprice');

//       if (startPriceIndex === -1) {
//         throw new Error('StartPrice列が見つかりません。');
//       }

//       const newPrices = spreadsheetData.slice(1).map(row => {
//         const startPrice = parseFloat(row[startPriceIndex]);
//         if (isNaN(startPrice)) {
//           return null;
//         }

//         let totalCost = startPrice; // USDで管理
//         let totalPercentage = 0;

//         // categoriesが存在するか確認し、存在する場合のみ処理
//         if (settings.categories && typeof settings.categories === 'object') {
//           Object.values(settings.categories).flat().forEach(item => {
//             if (item.type === 'fixed') {
//               totalCost += parseFloat(item.value); // 固定費用（USD）
//             } else if (item.type === 'percentage') {
//               totalPercentage += parseFloat(item.value); // 割合費用（%）
//             }
//           });
//         }

//         // 手数料を追加（USD）
//         totalPercentage += settings.fees.ebayFinalValue + 
//                            settings.fees.payoneer + 
//                            settings.fees.promotedListing;
//         totalCost += parseFloat(settings.fees.ebayTransactionFee); // eBay取引手数料（USD）

//         // 送料を追加（USD）
//         const shippingRate = settings.shippingRates.find(
//           rate => startPrice >= rate.minPriceUSD && startPrice < rate.maxPriceUSD
//         );
//         if (shippingRate) {
//           console.log('送料 (USD):', shippingRate.costUSD); // デバッグ用ログ
//           totalCost += parseFloat(shippingRate.costUSD); // costUSD を追加
//         }

//         // 目標利益率を追加
//         const targetProfitMargin = parseFloat(settings.targetProfitMargin);
//         totalPercentage += targetProfitMargin;

//         // 販売価格を計算
//         let calculatedPrice = totalCost / (1 - totalPercentage / 100);
//         calculatedPrice = adjustPriceTo99(calculatedPrice); // .99 に調整

//         // 割引後価格を計算
//         const discountedPrice = calculatedPrice * (1 - parseFloat(settings.discountRate) / 100);
//         const finalPrice = adjustPriceTo99(discountedPrice); // .99 に調整

//         // ベストオファー価格を計算
//         const bestOfferAutoAcceptPrice = finalPrice * (bestOfferAutoAcceptPercentage / 100);
//         const minimumBestOfferPrice = finalPrice * (minimumBestOfferPercentage / 100);

//         // 利益と利益率を計算
//         const profit = finalPrice - totalCost;
//         const profitMargin = (profit / finalPrice) * 100;

//         return {
//           originalStartPrice: startPrice.toFixed(2), // USDで表示
//           calculatedPrice: calculatedPrice.toFixed(2),
//           discountedPrice: finalPrice.toFixed(2),
//           bestOfferAutoAcceptPrice: bestOfferAutoAcceptPrice.toFixed(2),
//           minimumBestOfferPrice: minimumBestOfferPrice.toFixed(2),
//           profit: profit.toFixed(2),
//           profitMargin: profitMargin.toFixed(2)
//         };
//       });

//       setCalculatedPrices(newPrices.filter(price => price !== null));
//       console.log('Calculated Prices:', newPrices.filter(price => price !== null));
//     } catch (err) {
//       setError(err.message);
//       console.error('Error in calculatePrices:', err);
//     } finally {
//       setIsCalculating(false);
//     }
//   }, [settings, spreadsheetData, bestOfferAutoAcceptPercentage, minimumBestOfferPercentage, exchangeRate]);

//   const handleApplyPrices = async () => {
//     try {
//       const headers = spreadsheetData[0];
//       const startPriceIndex = headers.findIndex(header => header.toLowerCase() === 'startprice');
//       const bestOfferAutoAcceptIndex = headers.findIndex(header => header.toLowerCase() === 'bestofferautoacceptprice');
//       const minimumBestOfferIndex = headers.findIndex(header => header.toLowerCase() === 'minimumbestofferprice');

//       if (startPriceIndex === -1) {
//         throw new Error('StartPrice列が見つかりません。');
//       }

//       const updatedSpreadsheetData = spreadsheetData.map((row, index) => {
//         if (index === 0) return row; // ヘッダー行はそのまま
//         const calculatedPrice = calculatedPrices[index - 1];
//         if (!calculatedPrice) return row; // 計算された価格がない場合は元の行をそのまま返す

//         const updatedRow = [...row];
//         updatedRow[startPriceIndex] = calculatedPrice.discountedPrice;
//         if (bestOfferAutoAcceptIndex !== -1) {
//           updatedRow[bestOfferAutoAcceptIndex] = calculatedPrice.bestOfferAutoAcceptPrice;
//         }
//         if (minimumBestOfferIndex !== -1) {
//           updatedRow[minimumBestOfferIndex] = calculatedPrice.minimumBestOfferPrice;
//         }
//         return updatedRow;
//       });

//       await onUpdatePrices(updatedSpreadsheetData);
//       await fetchSpreadsheetData();
//       setSnackbar({ open: true, message: '価格が更新されました', severity: 'success' });
//     } catch (err) {
//       setError('価格の更新に失敗しました: ' + err.message);
//       console.error('Error in handleApplyPrices:', err);
//     }
//   };

//   const runSimulation = () => {
//     const totalRevenue = calculatedPrices.reduce((sum, price) => sum + parseFloat(price.discountedPrice), 0);
//     const totalProfit = calculatedPrices.reduce((sum, price) => sum + parseFloat(price.profit), 0);
//     const averageProfitMargin = calculatedPrices.reduce((sum, price) => sum + parseFloat(price.profitMargin), 0) / calculatedPrices.length;

//     const results = {
//       totalRevenue,
//       totalProfit,
//       averageProfitMargin,
//       itemCount: calculatedPrices.length
//     };

//     setSimulationResults(results);
//     console.log('Simulation Results:', results);

//     setShowRevenueSimulation(true);
//   };

//   const closePriceSimulation = () => setShowPriceSimulation(false);
//   const closeRevenueSimulation = () => setShowRevenueSimulation(false);

//   const formatCurrency = (value, currency = 'USD') => {
//     if (value === undefined || value === null) {
//       return currency === 'USD' ? '$0.00' : '¥0';
//     }
//     if (currency === 'USD') {
//       return `$${parseFloat(value).toFixed(2)}`;
//     } else if (currency === 'JPY') {
//       return `¥${Math.round(parseFloat(value) * exchangeRate)}`;
//     }
//   };

//   const SettingItem = ({ label, value }) => (
//     <Typography variant="body2">
//       <strong>{label}:</strong> {value}
//     </Typography>
//   );

//   return (
//     <Box sx={{ maxWidth: 800, margin: 'auto', p: 2 }}>
//       <Typography variant="h6" gutterBottom>
//         価格計算と収益シミュレーション
//       </Typography>

//       {error && (
//         <Alert severity="error" sx={{ mb: 2 }}>
//           {error}
//         </Alert>
//       )}

//       <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
//         <Typography variant="h6" gutterBottom>
//           現在の設定
//         </Typography>
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={6}>
//             <SettingItem label="目標利益率" value={`${settings.targetProfitMargin}%`} />
//             <SettingItem label="割引率" value={`${settings.discountRate}%`} />
//             <SettingItem label="eBay最終価値手数料" value={`${settings.fees.ebayFinalValue}%`} />
//             <SettingItem label="Payoneer手数料" value={`${settings.fees.payoneer}%`} />
//             <SettingItem label="広告出品手数料" value={`${settings.fees.promotedListing}%`} />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <SettingItem 
//               label="eBay取引手数料" 
//               value={`${formatCurrency(customSettings?.fees.ebayTransactionFee)} (${formatCurrency(customSettings?.fees.ebayTransactionFee, 'JPY')})`} 
//             />
//             <SettingItem label="為替レート" value={`1 USD = ${exchangeRate.toFixed(2)} JPY`} />
//             <SettingItem label="Best Offer自動承認価格" value={`${bestOfferAutoAcceptPercentage}%`} />
//             <SettingItem label="最小Best Offer価格" value={`${minimumBestOfferPercentage}%`} />
//           </Grid>
//         </Grid>
//       </Paper>

//       <Grid container spacing={2} sx={{ mb: 2 }}>
//         <Grid item xs={12} sm={6}>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={calculatePrices}
//             disabled={isCalculating}
//             fullWidth
//           >
//             {isCalculating ? <CircularProgress size={24} /> : '価格を再計算'}
//           </Button>
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           <Button
//             variant="outlined"
//             color="primary"
//             onClick={fetchExchangeRate}
//             disabled={isLoadingExchangeRate}
//             fullWidth
//           >
//             {isLoadingExchangeRate ? <CircularProgress size={24} /> : '為替レートを更新'}
//           </Button>
//         </Grid>
//       </Grid>

//       {calculatedPrices.length > 0 && (
//         <Grid container spacing={2} sx={{ mt: 2 }}>
//           <Grid item xs={12} sm={4}>
//             <Button 
//               variant="contained" 
//               color="secondary" 
//               onClick={() => setShowPriceSimulation(true)}
//               fullWidth
//             >
//               価格シミュレーション結果
//             </Button>
//           </Grid>
//           <Grid item xs={12} sm={4}>
//             <Button 
//               variant="contained" 
//               color="secondary" 
//               onClick={handleApplyPrices}
//               fullWidth
//             >
//               計算した価格を適用
//             </Button>
//           </Grid>
//           <Grid item xs={12} sm={4}>
//             <Button 
//               variant="outlined" 
//               color="primary" 
//               onClick={runSimulation}
//               fullWidth
//             >
//               収益シミュレーション実行
//             </Button>
//           </Grid>
//         </Grid>
//       )}

//       {/* 価格シミュレーション結果ダイアログ */}
//       <Dialog
//         open={showPriceSimulation}
//         onClose={closePriceSimulation}
//         maxWidth="lg"
//         fullWidth
//       >
//         <DialogTitle>価格シミュレーション結果</DialogTitle>
//         <DialogContent sx={{ height: '90vh' }}>
//           <TableContainer component={Paper} sx={{ maxHeight: '100%', overflow: 'auto' }}>
//             <Table stickyHeader>
//               <TableHead>
//                 <TableRow>
//                   <StyledTableCell>元の価格 (USD)</StyledTableCell>
//                   <StyledTableCell>計算後の価格 (USD)</StyledTableCell>
//                   <StyledTableCell>割引後価格 (USD)</StyledTableCell>
//                   <StyledTableCell>ベストオファー自動承認価格 (USD)</StyledTableCell>
//                   <StyledTableCell>最小ベストオファー価格 (USD)</StyledTableCell>
//                   <StyledTableCell>利益 (USD)</StyledTableCell>
//                   <StyledTableCell>利益率 (%)</StyledTableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {calculatedPrices.map((price, index) => (
//                   <TableRow key={index}>
//                     <TableCell>
//                       {formatCurrency(price.originalStartPrice)} ({formatCurrency(price.originalStartPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.calculatedPrice)} ({formatCurrency(price.calculatedPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.discountedPrice)} ({formatCurrency(price.discountedPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.bestOfferAutoAcceptPrice)} ({formatCurrency(price.bestOfferAutoAcceptPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.minimumBestOfferPrice)} ({formatCurrency(price.minimumBestOfferPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.profit)} ({formatCurrency(price.profit, 'JPY')})
//                     </TableCell>
//                     <TableCell>{price.profitMargin}%</TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closePriceSimulation}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* 収益シミュレーション結果ダイアログ */}
//       <Dialog 
//         open={showRevenueSimulation} 
//         onClose={closeRevenueSimulation} 
//         maxWidth="md" 
//         fullWidth
//       >
//         <DialogTitle>収益シミュレーション結果</DialogTitle>
//         <DialogContent>
//           {simulationResults && (
//             <Box>
//               <Typography variant="h6" gutterBottom>概要</Typography>
//               <Typography>
//                 総売上: {formatCurrency(simulationResults.totalRevenue)} ({formatCurrency(simulationResults.totalRevenue, 'JPY')})
//               </Typography>
//               <Typography>
//                 総利益: {formatCurrency(simulationResults.totalProfit)} ({formatCurrency(simulationResults.totalProfit, 'JPY')})
//               </Typography>
//               <Typography>平均利益率: {simulationResults.averageProfitMargin.toFixed(2)}%</Typography>
//               <Typography>商品数: {simulationResults.itemCount}</Typography>

//               <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>詳細分析</Typography>
//               <TableContainer component={Paper}>
//                 <Table>
//                   <TableHead>
//                     <TableRow>
//                       <StyledTableCell>指標</StyledTableCell>
//                       <StyledTableCell>値 (USD)</StyledTableCell>
//                       <StyledTableCell>値 (JPY)</StyledTableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     <TableRow>
//                       <TableCell>1商品あたりの平均売上</TableCell>
//                       <TableCell>
//                         {formatCurrency(simulationResults.totalRevenue / simulationResults.itemCount)}
//                       </TableCell>
//                       <TableCell>
//                         {formatCurrency(simulationResults.totalRevenue / simulationResults.itemCount, 'JPY')}
//                       </TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell>1商品あたりの平均利益</TableCell>
//                       <TableCell>
//                         {formatCurrency(simulationResults.totalProfit / simulationResults.itemCount)}
//                       </TableCell>
//                       <TableCell>
//                         {formatCurrency(simulationResults.totalProfit / simulationResults.itemCount, 'JPY')}
//                       </TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell>総売上に対する総利益の割合</TableCell>
//                       <TableCell colSpan={2}>
//                         {((simulationResults.totalProfit / simulationResults.totalRevenue) * 100).toFixed(2)}%
//                       </TableCell>
//                     </TableRow>
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </Box>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closeRevenueSimulation}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbar for notifications */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default PriceCalculationSettings;




// // PriceCalculationSettings.js

// import React, { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';
// import {
//   Box,
//   Typography,
//   Button,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   CircularProgress,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Alert,
//   Snackbar,
//   Grid
// } from '@mui/material';
// import { styled } from '@mui/material/styles';

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   fontWeight: 'bold',
//   backgroundColor: theme.palette.primary.light,
//   color: theme.palette.common.white,
// }));

// const PriceCalculationSettings = ({ customSettings = {}, spreadsheetData, onUpdatePrices, fetchSpreadsheetData }) => {
//   const [calculatedPrices, setCalculatedPrices] = useState([]);
//   const [isCalculating, setIsCalculating] = useState(false);
//   const [error, setError] = useState(null);
//   const [showPriceSimulation, setShowPriceSimulation] = useState(false);
//   const [showRevenueSimulation, setShowRevenueSimulation] = useState(false);
//   const [simulationResults, setSimulationResults] = useState(null);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
//   const [exchangeRate, setExchangeRate] = useState(140); // 初期値
//   const [isLoadingExchangeRate, setIsLoadingExchangeRate] = useState(false);
//   const [bestOfferAutoAcceptPercentage, setBestOfferAutoAcceptPercentage] = useState(95);
//   const [minimumBestOfferPercentage, setMinimumBestOfferPercentage] = useState(80);

//   useEffect(() => {
//     let isMounted = true;
//     fetchExchangeRate().then(() => {
//       if (isMounted) {
//         // 状態を更新
//       }
//     });
//     return () => {
//       isMounted = false;
//     };
//   }, []);

//   useEffect(() => {
//     if (customSettings) {
//       setBestOfferAutoAcceptPercentage(customSettings.bestOfferAutoAcceptPercentage || 95);
//       setMinimumBestOfferPercentage(customSettings.minimumBestOfferPercentage || 80);
//     }
//     console.log('Loaded customSettings:', customSettings);
//   }, [customSettings]);

//   // customSettings のデフォルト値を設定
//   const defaultSettings = {
//     fees: {
//       ebayFinalValue: 0,
//       payoneer: 0,
//       promotedListing: 0,
//       ebayTransactionFee: 0
//     },
//     targetProfitMargin: 0,
//     discountRate: 0,
//     shippingRates: [],
//     categories: {} // 追加
//   };

//   // customSettings が null または undefined の場合はデフォルト値を使用
//   const settings = customSettings && typeof customSettings === 'object' ? customSettings : defaultSettings;

//   const fetchExchangeRate = async () => {
//     setIsLoadingExchangeRate(true);
//     try {
//       const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
//       const newRate = response.data.rates.JPY;
//       setExchangeRate(newRate);
//       setSnackbar({ open: true, message: `為替レートを更新しました: 1 USD = ${newRate} JPY`, severity: 'info' });
//     } catch (error) {
//       console.error('Failed to fetch exchange rate:', error);
//       setError('為替レートの取得に失敗しました。デフォルトのレートを使用します。');
//     } finally {
//       setIsLoadingExchangeRate(false);
//     }
//   };

//   const calculatePrices = useCallback(() => {
//     setIsCalculating(true);
//     setError(null);

//     try {
//       if (!customSettings || !spreadsheetData || spreadsheetData.length === 0) {
//         throw new Error('必要なデータが不足しています。');
//       }

//       const headers = spreadsheetData[0];
//       const startPriceIndex = headers.findIndex(header => header.toLowerCase() === 'startprice');

//       if (startPriceIndex === -1) {
//         throw new Error('StartPrice列が見つかりません。');
//       }

//       const newPrices = spreadsheetData.slice(1).map(row => {
//         const startPrice = parseFloat(row[startPriceIndex]);
//         if (isNaN(startPrice)) {
//           return null;
//         }

//         let totalCost = startPrice; // USDで管理
//         let totalPercentage = 0;

//         // categoriesが存在するか確認し、存在する場合のみ処理
//         if (settings.categories && typeof settings.categories === 'object') {
//           Object.values(settings.categories).flat().forEach(item => {
//             if (item.type === 'fixed') {
//               totalCost += parseFloat(item.value); // 固定費用（USD）
//             } else if (item.type === 'percentage') {
//               totalPercentage += parseFloat(item.value); // 割合費用（%）
//             }
//           });
//         }

//         // 手数料を追加（USD）
//         totalPercentage += settings.fees.ebayFinalValue + 
//                            settings.fees.payoneer + 
//                            settings.fees.promotedListing;
//         totalCost += parseFloat(settings.fees.ebayTransactionFee); // eBay取引手数料（USD）

//         // 送料を追加（USD）
//         const shippingRate = settings.shippingRates.find(
//           rate => startPrice >= rate.minPriceUSD && startPrice < rate.maxPriceUSD
//         );
//         if (shippingRate) {
//           console.log('送料 (USD):', shippingRate.costUSD); // デバッグ用ログ
//           totalCost += parseFloat(shippingRate.costUSD); // costUSD を追加
//         }

//         // 目標利益率を追加
//         const targetProfitMargin = parseFloat(settings.targetProfitMargin);
//         totalPercentage += targetProfitMargin;

//         // 販売価格を計算
//         let calculatedPrice = totalCost / (1 - totalPercentage / 100);
//         calculatedPrice = Math.ceil(calculatedPrice * 100) / 100; // 小数点以下2桁に丸め

//         // 割引後価格を計算
//         const discountedPrice = calculatedPrice * (1 - parseFloat(settings.discountRate) / 100);
//         const finalPrice = Math.ceil(discountedPrice * 100) / 100;

//         // ベストオファー価格を計算
//         const bestOfferAutoAcceptPrice = finalPrice * (bestOfferAutoAcceptPercentage / 100);
//         const minimumBestOfferPrice = finalPrice * (minimumBestOfferPercentage / 100);

//         // 利益と利益率を計算
//         const profit = finalPrice - totalCost;
//         const profitMargin = (profit / finalPrice) * 100;

//         return {
//           originalStartPrice: startPrice.toFixed(2), // USDで表示
//           calculatedPrice: calculatedPrice.toFixed(2),
//           discountedPrice: finalPrice.toFixed(2),
//           bestOfferAutoAcceptPrice: bestOfferAutoAcceptPrice.toFixed(2),
//           minimumBestOfferPrice: minimumBestOfferPrice.toFixed(2),
//           profit: profit.toFixed(2),
//           profitMargin: profitMargin.toFixed(2)
//         };
//       });

//       setCalculatedPrices(newPrices.filter(price => price !== null));
//       console.log('Calculated Prices:', newPrices.filter(price => price !== null));
//     } catch (err) {
//       setError(err.message);
//       console.error('Error in calculatePrices:', err);
//     } finally {
//       setIsCalculating(false);
//     }
//   }, [settings, spreadsheetData, bestOfferAutoAcceptPercentage, minimumBestOfferPercentage, exchangeRate]);

//   const handleApplyPrices = async () => {
//     try {
//       const headers = spreadsheetData[0];
//       const startPriceIndex = headers.findIndex(header => header.toLowerCase() === 'startprice');
//       const bestOfferAutoAcceptIndex = headers.findIndex(header => header.toLowerCase() === 'bestofferautoacceptprice');
//       const minimumBestOfferIndex = headers.findIndex(header => header.toLowerCase() === 'minimumbestofferprice');

//       if (startPriceIndex === -1) {
//         throw new Error('StartPrice列が見つかりません。');
//       }

//       const updatedSpreadsheetData = spreadsheetData.map((row, index) => {
//         if (index === 0) return row; // ヘッダー行はそのまま
//         const calculatedPrice = calculatedPrices[index - 1];
//         if (!calculatedPrice) return row; // 計算された価格がない場合は元の行をそのまま返す

//         const updatedRow = [...row];
//         updatedRow[startPriceIndex] = calculatedPrice.discountedPrice;
//         if (bestOfferAutoAcceptIndex !== -1) {
//           updatedRow[bestOfferAutoAcceptIndex] = calculatedPrice.bestOfferAutoAcceptPrice;
//         }
//         if (minimumBestOfferIndex !== -1) {
//           updatedRow[minimumBestOfferIndex] = calculatedPrice.minimumBestOfferPrice;
//         }
//         return updatedRow;
//       });

//       await onUpdatePrices(updatedSpreadsheetData);
//       await fetchSpreadsheetData();
//       setSnackbar({ open: true, message: '価格が更新されました', severity: 'success' });
//     } catch (err) {
//       setError('価格の更新に失敗しました: ' + err.message);
//       console.error('Error in handleApplyPrices:', err);
//     }
//   };

//   const runSimulation = () => {
//     const totalRevenue = calculatedPrices.reduce((sum, price) => sum + parseFloat(price.discountedPrice), 0);
//     const totalProfit = calculatedPrices.reduce((sum, price) => sum + parseFloat(price.profit), 0);
//     const averageProfitMargin = calculatedPrices.reduce((sum, price) => sum + parseFloat(price.profitMargin), 0) / calculatedPrices.length;

//     const results = {
//       totalRevenue,
//       totalProfit,
//       averageProfitMargin,
//       itemCount: calculatedPrices.length
//     };

//     setSimulationResults(results);
//     console.log('Simulation Results:', results);

//     setShowRevenueSimulation(true);
//   };

//   const closePriceSimulation = () => setShowPriceSimulation(false);
//   const closeRevenueSimulation = () => setShowRevenueSimulation(false);

//   const formatCurrency = (value, currency = 'USD') => {
//     if (value === undefined || value === null) {
//       return currency === 'USD' ? '$0.00' : '¥0';
//     }
//     if (currency === 'USD') {
//       return `$${parseFloat(value).toFixed(2)}`;
//     } else if (currency === 'JPY') {
//       return `¥${Math.round(parseFloat(value) * exchangeRate)}`;
//     }
//   };

//   const SettingItem = ({ label, value }) => (
//     <Typography variant="body2">
//       <strong>{label}:</strong> {value}
//     </Typography>
//   );

//   return (
//     <Box sx={{ maxWidth: 800, margin: 'auto', p: 2 }}>
//       <Typography variant="h6" gutterBottom>
//         価格計算と収益シミュレーション
//       </Typography>

//       {error && (
//         <Alert severity="error" sx={{ mb: 2 }}>
//           {error}
//         </Alert>
//       )}

//       <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
//         <Typography variant="h6" gutterBottom>
//           現在の設定
//         </Typography>
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={6}>
//             <SettingItem label="目標利益率" value={`${settings.targetProfitMargin}%`} />
//             <SettingItem label="割引率" value={`${settings.discountRate}%`} />
//             <SettingItem label="eBay最終価値手数料" value={`${settings.fees.ebayFinalValue}%`} />
//             <SettingItem label="Payoneer手数料" value={`${settings.fees.payoneer}%`} />
//             <SettingItem label="広告出品手数料" value={`${settings.fees.promotedListing}%`} />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <SettingItem 
//               label="eBay取引手数料" 
//               value={`${formatCurrency(customSettings?.fees.ebayTransactionFee)} (${formatCurrency(customSettings?.fees.ebayTransactionFee, 'JPY')})`} 
//             />
//             <SettingItem label="為替レート" value={`1 USD = ${exchangeRate.toFixed(2)} JPY`} />
//             <SettingItem label="Best Offer自動承認価格" value={`${bestOfferAutoAcceptPercentage}%`} />
//             <SettingItem label="最小Best Offer価格" value={`${minimumBestOfferPercentage}%`} />
//           </Grid>
//         </Grid>
//       </Paper>

//       <Grid container spacing={2} sx={{ mb: 2 }}>
//         <Grid item xs={12} sm={6}>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={calculatePrices}
//             disabled={isCalculating}
//             fullWidth
//           >
//             {isCalculating ? <CircularProgress size={24} /> : '価格を再計算'}
//           </Button>
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           <Button
//             variant="outlined"
//             color="primary"
//             onClick={fetchExchangeRate}
//             disabled={isLoadingExchangeRate}
//             fullWidth
//           >
//             {isLoadingExchangeRate ? <CircularProgress size={24} /> : '為替レートを更新'}
//           </Button>
//         </Grid>
//       </Grid>

//       {calculatedPrices.length > 0 && (
//         <Grid container spacing={2} sx={{ mt: 2 }}>
//           <Grid item xs={12} sm={4}>
//             <Button 
//               variant="contained" 
//               color="secondary" 
//               onClick={() => setShowPriceSimulation(true)}
//               fullWidth
//             >
//               価格シミュレーション結果
//             </Button>
//           </Grid>
//           <Grid item xs={12} sm={4}>
//             <Button 
//               variant="contained" 
//               color="secondary" 
//               onClick={handleApplyPrices}
//               fullWidth
//             >
//               計算した価格を適用
//             </Button>
//           </Grid>
//           <Grid item xs={12} sm={4}>
//             <Button 
//               variant="outlined" 
//               color="primary" 
//               onClick={runSimulation}
//               fullWidth
//             >
//               収益シミュレーション実行
//             </Button>
//           </Grid>
//         </Grid>
//       )}

//       {/* 価格シミュレーション結果ダイアログ */}
//       <Dialog
//         open={showPriceSimulation}
//         onClose={closePriceSimulation}
//         maxWidth="lg"
//         fullWidth
//       >
//         <DialogTitle>価格シミュレーション結果</DialogTitle>
//         <DialogContent sx={{ height: '90vh' }}>
//           <TableContainer component={Paper} sx={{ maxHeight: '100%', overflow: 'auto' }}>
//             <Table stickyHeader>
//               <TableHead>
//                 <TableRow>
//                   <StyledTableCell>元の価格 (USD)</StyledTableCell>
//                   <StyledTableCell>計算後の価格 (USD)</StyledTableCell>
//                   <StyledTableCell>割引後価格 (USD)</StyledTableCell>
//                   <StyledTableCell>ベストオファー自動承認価格 (USD)</StyledTableCell>
//                   <StyledTableCell>最小ベストオファー価格 (USD)</StyledTableCell>
//                   <StyledTableCell>利益 (USD)</StyledTableCell>
//                   <StyledTableCell>利益率 (%)</StyledTableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {calculatedPrices.map((price, index) => (
//                   <TableRow key={index}>
//                     <TableCell>
//                       {formatCurrency(price.originalStartPrice)} ({formatCurrency(price.originalStartPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.calculatedPrice)} ({formatCurrency(price.calculatedPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.discountedPrice)} ({formatCurrency(price.discountedPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.bestOfferAutoAcceptPrice)} ({formatCurrency(price.bestOfferAutoAcceptPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.minimumBestOfferPrice)} ({formatCurrency(price.minimumBestOfferPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.profit)} ({formatCurrency(price.profit, 'JPY')})
//                     </TableCell>
//                     <TableCell>{price.profitMargin}%</TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closePriceSimulation}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* 収益シミュレーション結果ダイアログ */}
//       <Dialog 
//         open={showRevenueSimulation} 
//         onClose={closeRevenueSimulation} 
//         maxWidth="md" 
//         fullWidth
//       >
//         <DialogTitle>収益シミュレーション結果</DialogTitle>
//         <DialogContent>
//           {simulationResults && (
//             <Box>
//               <Typography variant="h6" gutterBottom>概要</Typography>
//               <Typography>
//                 総売上: {formatCurrency(simulationResults.totalRevenue)} ({formatCurrency(simulationResults.totalRevenue, 'JPY')})
//               </Typography>
//               <Typography>
//                 総利益: {formatCurrency(simulationResults.totalProfit)} ({formatCurrency(simulationResults.totalProfit, 'JPY')})
//               </Typography>
//               <Typography>平均利益率: {simulationResults.averageProfitMargin.toFixed(2)}%</Typography>
//               <Typography>商品数: {simulationResults.itemCount}</Typography>

//               <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>詳細分析</Typography>
//               <TableContainer component={Paper}>
//                 <Table>
//                   <TableHead>
//                     <TableRow>
//                       <StyledTableCell>指標</StyledTableCell>
//                       <StyledTableCell>値 (USD)</StyledTableCell>
//                       <StyledTableCell>値 (JPY)</StyledTableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     <TableRow>
//                       <TableCell>1商品あたりの平均売上</TableCell>
//                       <TableCell>
//                         {formatCurrency(simulationResults.totalRevenue / simulationResults.itemCount)}
//                       </TableCell>
//                       <TableCell>
//                         {formatCurrency(simulationResults.totalRevenue / simulationResults.itemCount, 'JPY')}
//                       </TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell>1商品あたりの平均利益</TableCell>
//                       <TableCell>
//                         {formatCurrency(simulationResults.totalProfit / simulationResults.itemCount)}
//                       </TableCell>
//                       <TableCell>
//                         {formatCurrency(simulationResults.totalProfit / simulationResults.itemCount, 'JPY')}
//                       </TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell>総売上に対する総利益の割合</TableCell>
//                       <TableCell colSpan={2}>
//                         {((simulationResults.totalProfit / simulationResults.totalRevenue) * 100).toFixed(2)}%
//                       </TableCell>
//                     </TableRow>
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </Box>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closeRevenueSimulation}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbar for notifications */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default PriceCalculationSettings;




// import React, { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';
// import {
//   Box,
//   Typography,
//   Button,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   CircularProgress,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Alert,
//   Snackbar,
//   Grid
// } from '@mui/material';
// import { styled } from '@mui/material/styles';

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   fontWeight: 'bold',
//   backgroundColor: theme.palette.primary.light,
//   color: theme.palette.common.white,
// }));

// const PriceCalculationSettings = ({ customSettings = {}, spreadsheetData, onUpdatePrices, fetchSpreadsheetData }) => {
//   const [calculatedPrices, setCalculatedPrices] = useState([]);
//   const [isCalculating, setIsCalculating] = useState(false);
//   const [error, setError] = useState(null);
//   const [showPriceSimulation, setShowPriceSimulation] = useState(false);
//   const [showRevenueSimulation, setShowRevenueSimulation] = useState(false);
//   const [simulationResults, setSimulationResults] = useState(null);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
//   const [exchangeRate, setExchangeRate] = useState(140);
//   const [isLoadingExchangeRate, setIsLoadingExchangeRate] = useState(false);
//   const [bestOfferAutoAcceptPercentage, setBestOfferAutoAcceptPercentage] = useState(95);
//   const [minimumBestOfferPercentage, setMinimumBestOfferPercentage] = useState(80);

//   // useEffect(() => {
//   //   fetchExchangeRate();
//   // }, []);

//   useEffect(() => {
//     let isMounted = true;
//     fetchExchangeRate().then(() => {
//       if (isMounted) {
//         // 状態を更新
//       }
//     });
//     return () => {
//       isMounted = false;
//     };
//   }, []);

//   useEffect(() => {
//     if (customSettings) {
//       setBestOfferAutoAcceptPercentage(customSettings.bestOfferAutoAcceptPercentage || 95);
//       setMinimumBestOfferPercentage(customSettings.minimumBestOfferPercentage || 80);
//     }
//     console.log('Loaded customSettings:', customSettings);
//   }, [customSettings]);

//   // customSettings のデフォルト値を設定
//   const defaultSettings = {
//     fees: {
//       ebayFinalValue: 0,
//       payoneer: 0,
//       promotedListing: 0,
//       ebayTransactionFee: 0
//     },
//     targetProfitMargin: 0,
//     discountRate: 0,
//     shippingRates: []
//   };

//   // customSettings が null または undefined の場合はデフォルト値を使用
//   const settings = customSettings && typeof customSettings === 'object' ? customSettings : defaultSettings;

//   const fetchExchangeRate = async () => {
//     setIsLoadingExchangeRate(true);
//     try {
//       const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
//       const newRate = response.data.rates.JPY;
//       setExchangeRate(newRate);
//       setSnackbar({ open: true, message: `為替レートを更新しました: 1 USD = ${newRate} JPY`, severity: 'info' });
//     } catch (error) {
//       console.error('Failed to fetch exchange rate:', error);
//       setError('為替レートの取得に失敗しました。デフォルトのレートを使用します。');
//     } finally {
//       setIsLoadingExchangeRate(false);
//     }
//   };

//   const calculatePrices = useCallback(() => {
//     setIsCalculating(true);
//     setError(null);

//     try {
//       if (!customSettings || !spreadsheetData || spreadsheetData.length === 0) {
//         throw new Error('必要なデータが不足しています。');
//       }

//       const headers = spreadsheetData[0];
//       const startPriceIndex = headers.findIndex(header => header.toLowerCase() === 'startprice');

//       if (startPriceIndex === -1) {
//         throw new Error('StartPrice列が見つかりません。');
//       }

//       const newPrices = spreadsheetData.slice(1).map(row => {
//         const startPrice = parseFloat(row[startPriceIndex]);
//         if (isNaN(startPrice)) {
//           return null;
//         }

//         let totalCost = startPrice;
//         let totalPercentage = 0;

//         Object.values(customSettings.categories).flat().forEach(item => {
//           if (item.type === 'fixed') {
//             totalCost += item.value;
//           } else if (item.type === 'percentage') {
//             totalPercentage += item.value;
//           }
//         });

//         totalPercentage += settings.fees.ebayFinalValue + 
//                        settings.fees.payoneer + 
//                        settings.fees.promotedListing;
//         totalCost += settings.fees.ebayTransactionFee;

//         const shippingRate = settings.shippingRates.find(
//           rate => startPrice >= rate.minPrice && startPrice < rate.maxPrice
//         );
//         if (shippingRate) {
//           totalCost += shippingRate.cost;
//         }

//         const targetProfitMargin = settings.targetProfitMargin;
//         totalPercentage += targetProfitMargin;
//         let calculatedPrice = totalCost / (1 - totalPercentage / 100);

//         calculatedPrice = Math.ceil(calculatedPrice) - 0.01;

//         const discountedPrice = calculatedPrice * (1 - customSettings.discountRate / 100);

//         const finalPrice = Math.ceil(discountedPrice) - 0.01;

//         const bestOfferAutoAcceptPrice = finalPrice * (bestOfferAutoAcceptPercentage / 100);
//         const minimumBestOfferPrice = finalPrice * (minimumBestOfferPercentage / 100);

//         const profit = finalPrice - totalCost;
//         const profitMargin = (profit / finalPrice) * 100;

//         return {
//           originalStartPrice: startPrice,
//           calculatedPrice: calculatedPrice.toFixed(2),
//           discountedPrice: finalPrice.toFixed(2),
//           bestOfferAutoAcceptPrice: bestOfferAutoAcceptPrice.toFixed(2),
//           minimumBestOfferPrice: minimumBestOfferPrice.toFixed(2),
//           profit: profit.toFixed(2),
//           profitMargin: profitMargin.toFixed(2)
//         };
//       });

//       setCalculatedPrices(newPrices.filter(price => price !== null));
//       console.log('Calculated Prices:', newPrices.filter(price => price !== null));
//     } catch (err) {
//       setError(err.message);
//       console.error('Error in calculatePrices:', err);
//     } finally {
//       setIsCalculating(false);
//     }
//   }, [settings, spreadsheetData, bestOfferAutoAcceptPercentage, minimumBestOfferPercentage]);

//   const handleApplyPrices = async () => {
//     try {
//       const headers = spreadsheetData[0];
//       const startPriceIndex = headers.findIndex(header => header.toLowerCase() === 'startprice');
//       const bestOfferAutoAcceptIndex = headers.findIndex(header => header.toLowerCase() === 'bestofferautoacceptprice');
//       const minimumBestOfferIndex = headers.findIndex(header => header.toLowerCase() === 'minimumbestofferprice');

//       if (startPriceIndex === -1) {
//         throw new Error('StartPrice列が見つかりません。');
//       }

//       const updatedSpreadsheetData = spreadsheetData.map((row, index) => {
//         if (index === 0) return row; // ヘッダー行はそのまま
//         const calculatedPrice = calculatedPrices[index - 1];
//         if (!calculatedPrice) return row; // 計算された価格がない場合は元の行をそのまま返す

//         const updatedRow = [...row];
//         updatedRow[startPriceIndex] = calculatedPrice.discountedPrice;
//         if (bestOfferAutoAcceptIndex !== -1) {
//           updatedRow[bestOfferAutoAcceptIndex] = calculatedPrice.bestOfferAutoAcceptPrice;
//         }
//         if (minimumBestOfferIndex !== -1) {
//           updatedRow[minimumBestOfferIndex] = calculatedPrice.minimumBestOfferPrice;
//         }
//         return updatedRow;
//       });

//       await onUpdatePrices(updatedSpreadsheetData);
//       await fetchSpreadsheetData();
//       setSnackbar({ open: true, message: '価格が更新されました', severity: 'success' });
//     } catch (err) {
//       setError('価格の更新に失敗しました: ' + err.message);
//       console.error('Error in handleApplyPrices:', err);
//     }
//   };

//   const runSimulation = () => {
//     const totalRevenue = calculatedPrices.reduce((sum, price) => sum + parseFloat(price.discountedPrice), 0);
//     const totalProfit = calculatedPrices.reduce((sum, price) => sum + parseFloat(price.profit), 0);
//     const averageProfitMargin = calculatedPrices.reduce((sum, price) => sum + parseFloat(price.profitMargin), 0) / calculatedPrices.length;

//     const results = {
//       totalRevenue,
//       totalProfit,
//       averageProfitMargin,
//       itemCount: calculatedPrices.length
//     };

//     setSimulationResults(results);
//     console.log('Simulation Results:', results);

//     setShowRevenueSimulation(true);
//   };

//   const closePriceSimulation = () => setShowPriceSimulation(false);
//   const closeRevenueSimulation = () => setShowRevenueSimulation(false);

//   const formatCurrency = (value, currency = 'USD') => {
//     if (value === undefined || value === null) {
//       return currency === 'USD' ? '$0.00' : '¥0';
//     }
//     if (currency === 'USD') {
//       return `$${parseFloat(value).toFixed(2)}`;
//     } else if (currency === 'JPY') {
//       return `¥${Math.round(parseFloat(value) * exchangeRate)}`;
//     }
//   };

//   const SettingItem = ({ label, value }) => (
//     <Typography variant="body2">
//       <strong>{label}:</strong> {value}
//     </Typography>
//   );

//   return (
//     <Box sx={{ maxWidth: 800, margin: 'auto', p: 2 }}>
//       <Typography variant="h6" gutterBottom>
//         価格計算と収益シミュレーション
//       </Typography>

//       {error && (
//         <Alert severity="error" sx={{ mb: 2 }}>
//           {error}
//         </Alert>
//       )}

//       <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
//         <Typography variant="h6" gutterBottom>
//           現在の設定
//         </Typography>
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={6}>
//             <SettingItem label="目標利益率" value={`${settings.targetProfitMargin}%`} />
//             <SettingItem label="割引率" value={`${settings.discountRate}%`} />
//             <SettingItem label="eBay最終価値手数料" value={`${settings.fees.ebayFinalValue}%`} />
//             <SettingItem label="Payoneer手数料" value={`${settings.fees.payoneer}%`} />
//             <SettingItem label="広告出品手数料" value={`${settings.fees.promotedListing}%`} />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <SettingItem 
//               label="eBay取引手数料" 
//               value={`${formatCurrency(customSettings?.fees.ebayTransactionFee)} (${formatCurrency(customSettings?.fees.ebayTransactionFee, 'JPY')})`} 
//             />
//             <SettingItem label="為替レート" value={`1 USD = ${exchangeRate.toFixed(2)} JPY`} />
//             <SettingItem label="Best Offer自動承認価格" value={`${bestOfferAutoAcceptPercentage}%`} />
//             <SettingItem label="最小Best Offer価格" value={`${minimumBestOfferPercentage}%`} />
//           </Grid>
//         </Grid>
//       </Paper>


//       {/* <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
//         <Typography variant="h6" gutterBottom>
//         Best Offer設定
//         </Typography>
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               fullWidth
//               label="Best Offer自動承認価格 (%)"
//               type="number"
//               value={bestOfferAutoAcceptPercentage}
//               onChange={(e) => setBestOfferAutoAcceptPercentage(parseFloat(e.target.value))}
//               InputProps={{ inputProps: { min: 0, max: 100 } }}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               fullWidth
//               label="最小Best Offer価格 (%)"
//               type="number"
//               value={minimumBestOfferPercentage}
//               onChange={(e) => setMinimumBestOfferPercentage(parseFloat(e.target.value))}
//               InputProps={{ inputProps: { min: 0, max: 100 } }}
//             />
//           </Grid>
//         </Grid>
//       </Paper> */}

//       <Grid container spacing={2} sx={{ mb: 2 }}>
//         <Grid item xs={12} sm={6}>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={calculatePrices}
//             disabled={isCalculating}
//             fullWidth
//           >
//             {isCalculating ? <CircularProgress size={24} /> : '価格を再計算'}
//           </Button>
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           <Button
//             variant="outlined"
//             color="primary"
//             onClick={fetchExchangeRate}
//             disabled={isLoadingExchangeRate}
//             fullWidth
//           >
//             {isLoadingExchangeRate ? <CircularProgress size={24} /> : '為替レートを更新'}
//           </Button>
//         </Grid>
//       </Grid>

//       {calculatedPrices.length > 0 && (
//         <Grid container spacing={2} sx={{ mt: 2 }}>
//           <Grid item xs={12} sm={4}>
//             <Button 
//               variant="contained" 
//               color="secondary" 
//               onClick={() => setShowPriceSimulation(true)}
//               fullWidth
//             >
//               価格シミュレーション結果
//             </Button>
//           </Grid>
//           <Grid item xs={12} sm={4}>
//             <Button 
//               variant="contained" 
//               color="secondary" 
//               onClick={handleApplyPrices}
//               fullWidth
//             >
//               計算した価格を適用
//             </Button>
//           </Grid>
//           <Grid item xs={12} sm={4}>
//             <Button 
//               variant="outlined" 
//               color="primary" 
//               onClick={runSimulation}
//               fullWidth
//             >
//               収益シミュレーション実行
//             </Button>
//           </Grid>
//         </Grid>
//       )}

//       {/* 価格シミュレーション結果ダイアログ */}
//       <Dialog
//         open={showPriceSimulation}
//         onClose={closePriceSimulation}
//         maxWidth="lg"
//         fullWidth
//       >
//         <DialogTitle>価格シミュレーション結果</DialogTitle>
//         <DialogContent sx={{ height: '90vh' }}>
//           <TableContainer component={Paper} sx={{ maxHeight: '100%', overflow: 'auto' }}>
//             <Table stickyHeader>
//               <TableHead>
//                 <TableRow>
//                   <StyledTableCell>元の価格</StyledTableCell>
//                   <StyledTableCell>計算後の価格</StyledTableCell>
//                   <StyledTableCell>割引後価格</StyledTableCell>
//                   <StyledTableCell>ベストオファー自動承認価格</StyledTableCell>
//                   <StyledTableCell>最小ベストオファー価格</StyledTableCell>
//                   <StyledTableCell>利益</StyledTableCell>
//                   <StyledTableCell>利益率</StyledTableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {calculatedPrices.map((price, index) => (
//                   <TableRow key={index}>
//                     <TableCell>
//                       {formatCurrency(price.originalStartPrice)} ({formatCurrency(price.originalStartPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.calculatedPrice)} ({formatCurrency(price.calculatedPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.discountedPrice)} ({formatCurrency(price.discountedPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.bestOfferAutoAcceptPrice)} ({formatCurrency(price.bestOfferAutoAcceptPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.minimumBestOfferPrice)} ({formatCurrency(price.minimumBestOfferPrice, 'JPY')})
//                     </TableCell>
//                     <TableCell>
//                       {formatCurrency(price.profit)} ({formatCurrency(price.profit, 'JPY')})
//                     </TableCell>
//                     <TableCell>{price.profitMargin}%</TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closePriceSimulation}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* 収益シミュレーション結果ダイアログ */}
//       <Dialog 
//         open={showRevenueSimulation} 
//         onClose={closeRevenueSimulation} 
//         maxWidth="md" 
//         fullWidth
//       >
//         <DialogTitle>収益シミュレーション結果</DialogTitle>
//         <DialogContent>
//           {simulationResults && (
//             <Box>
//               <Typography variant="h6" gutterBottom>概要</Typography>
//               <Typography>
//                 総売上: {formatCurrency(simulationResults.totalRevenue)} ({formatCurrency(simulationResults.totalRevenue, 'JPY')})
//               </Typography>
//               <Typography>
//                 総利益: {formatCurrency(simulationResults.totalProfit)} ({formatCurrency(simulationResults.totalProfit, 'JPY')})
//               </Typography>
//               <Typography>平均利益率: {simulationResults.averageProfitMargin.toFixed(2)}%</Typography>
//               <Typography>商品数: {simulationResults.itemCount}</Typography>

//               <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>詳細分析</Typography>
//               <TableContainer component={Paper}>
//                 <Table>
//                   <TableHead>
//                     <TableRow>
//                       <StyledTableCell>指標</StyledTableCell>
//                       <StyledTableCell>値 (USD)</StyledTableCell>
//                       <StyledTableCell>値 (JPY)</StyledTableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     <TableRow>
//                       <TableCell>1商品あたりの平均売上</TableCell>
//                       <TableCell>
//                         {formatCurrency(simulationResults.totalRevenue / simulationResults.itemCount)}
//                       </TableCell>
//                       <TableCell>
//                         {formatCurrency(simulationResults.totalRevenue / simulationResults.itemCount, 'JPY')}
//                       </TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell>1商品あたりの平均利益</TableCell>
//                       <TableCell>
//                         {formatCurrency(simulationResults.totalProfit / simulationResults.itemCount)}
//                       </TableCell>
//                       <TableCell>
//                         {formatCurrency(simulationResults.totalProfit / simulationResults.itemCount, 'JPY')}
//                       </TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell>総売上に対する総利益の割合</TableCell>
//                       <TableCell colSpan={2}>
//                         {((simulationResults.totalProfit / simulationResults.totalRevenue) * 100).toFixed(2)}%
//                       </TableCell>
//                     </TableRow>
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </Box>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closeRevenueSimulation}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbar for notifications */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default PriceCalculationSettings;

