// ImageDownloader.js

import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  Button,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  LinearProgress,
  Slider,
  FormControlLabel,
  Switch,
  Paper,
  Grid,
  IconButton,
  Divider,
  DialogContentText,
} from '@mui/material';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const INITIAL_BATCH_SIZE = 5;
const MAX_BATCH_SIZE = 20;
const MIN_BATCH_SIZE = 1;
const RATE_LIMIT_PERIOD = 24 * 60 * 60 * 1000; // 24時間
const MAX_REQUESTS_PER_DAY = 350;
const MAX_RETRIES = 5;
const INITIAL_RETRY_DELAY = 1000;

const workers = [
  'https://broken-glitter-2eca.kikuchi-shun.workers.dev/',
  'https://bold-block-5adc.kikuchi-shun.workers.dev/',
  'https://broken-fire-195b.kikuchi-shun.workers.dev/',
];

const userAgents = [
  'Mozilla/5.0 (Windows NT 10.0; Win64; x64)...',
  // 他のユーザーエージェント文字列
];

function getRandomWorker() {
  return workers[Math.floor(Math.random() * workers.length)];
}

function getRandomUserAgent() {
  return userAgents[Math.floor(Math.random() * userAgents.length)];
}

const imageCache = new Map();

function checkRateLimit() {
  const now = Date.now();
  const requests = JSON.parse(localStorage.getItem('rateLimitRequests') || '[]');
  const validRequests = requests.filter((time) => now - time < RATE_LIMIT_PERIOD);
  if (validRequests.length >= MAX_REQUESTS_PER_DAY) {
    return false;
  }
  validRequests.push(now);
  localStorage.setItem('rateLimitRequests', JSON.stringify(validRequests));
  return true;
}

function getRemainingRequests() {
  const now = Date.now();
  const requests = JSON.parse(localStorage.getItem('rateLimitRequests') || '[]');
  const validRequests = requests.filter((time) => now - time < RATE_LIMIT_PERIOD);
  return MAX_REQUESTS_PER_DAY - validRequests.length;
}

async function download(url, retryCount = 0, retryDelay = INITIAL_RETRY_DELAY) {
  if (imageCache.has(url)) {
    return imageCache.get(url);
  }

  if (!checkRateLimit()) {
    throw new Error('1日のリクエスト上限を超えました');
  }

  const workerUrl = getRandomWorker();
  const proxyUrl = `${workerUrl}/?url=${encodeURIComponent(url)}`;

  try {
    const response = await axios.get(proxyUrl, {
      responseType: 'arraybuffer',
      timeout: 30000,
      headers: {
        'User-Agent': getRandomUserAgent(),
        Referer: 'https://www.rakuten.co.jp/',
      },
    });
    const data = response.data;
    imageCache.set(url, data);
    return data;
  } catch (error) {
    if (retryCount < MAX_RETRIES) {
      await new Promise((resolve) => setTimeout(resolve, retryDelay));
      return download(url, retryCount + 1, retryDelay * 2);
    }
    throw error;
  }
}

function ImageDownloader({ spreadsheetId, sheetName, token, data, fetchData }) {
  const [startRow, setStartRow] = useState(1);
  const [endRow, setEndRow] = useState(150);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [failCount, setFailCount] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [batchSize, setBatchSize] = useState(INITIAL_BATCH_SIZE);
  const [autoAdjustBatchSize, setAutoAdjustBatchSize] = useState(true);
  const [downloadSpeed, setDownloadSpeed] = useState(0);
  const [estimatedTimeRemaining, setEstimatedTimeRemaining] = useState(0);
  const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
  const [remainingRequests, setRemainingRequests] = useState(getRemainingRequests());
  const [operationType, setOperationType] = useState('download'); // 'download' or 'delete'
  const [useFolderDownload, setUseFolderDownload] = useState(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState(new Set());
  const [downloadOption, setDownloadOption] = useState('first'); // 'first' or 'all'

  const theme = useTheme();

  const startTimeRef = useRef(null);

  useEffect(() => {
    if (data && data.length > 1) {
      setStartRow(1);
      setEndRow(Math.min(startRow + 149, data.length - 1));
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingRequests(getRemainingRequests());
    }, 60000); // 1分ごとに更新

    return () => clearInterval(interval);
  }, []);

  const calculateMaxEndRow = () => {
    if (data && data.length > 1) {
      return Math.min(startRow + 149, data.length - 1);
    } else {
      return startRow + 149;
    }
  };

  const handleStartRowChange = (event) => {
    const value = parseInt(event.target.value);
    setStartRow(value);

    // endRowを新しいstartRowに基づいて調整
    const maxEndRow = calculateMaxEndRow();
    if (endRow < value || endRow > maxEndRow || isNaN(endRow)) {
      setEndRow(maxEndRow);
    }
  };

  const handleEndRowChange = (event) => {
    const value = parseInt(event.target.value);
    const maxEndRow = calculateMaxEndRow();
    setEndRow(Math.min(Math.max(value, startRow), maxEndRow));
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleOperationConfirmation = (operation, option) => {
    setOperationType(operation);
    setDownloadOption(option);
    handleOpenPreviewDialog(operation, option);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleOpenSettingsDialog = () => {
    setOpenSettingsDialog(true);
  };

  const handleCloseSettingsDialog = () => {
    setOpenSettingsDialog(false);
  };

  const adjustBatchSize = (successRate) => {
    if (autoAdjustBatchSize) {
      if (successRate > 0.95 && batchSize < MAX_BATCH_SIZE) {
        setBatchSize((prev) => prev + 1);
      } else if (successRate < 0.8 && batchSize > MIN_BATCH_SIZE) {
        setBatchSize((prev) => Math.max(prev - 2, MIN_BATCH_SIZE));
      } else if (successRate < 0.6 && batchSize > MIN_BATCH_SIZE) {
        setBatchSize((prev) => Math.max(prev - 3, MIN_BATCH_SIZE));
      }
    }
  };

  const processBatch = async (images, startIndex) => {
    const batch = images.slice(startIndex, startIndex + batchSize);
    const results = await Promise.all(
      batch.map(async (imageObj, index) => {
        try {
          const imageData = await download(imageObj.url);
          setRemainingRequests(getRemainingRequests());
          const fileName = `image_row${imageObj.rowIndex}_img${imageObj.imageIndex}.jpg`;
          return {
            success: true,
            data: imageData,
            fileName,
            url: imageObj.url,
            imageObj,
          };
        } catch (err) {
          console.error(`画像のダウンロードに失敗しました ${imageObj.url}:`, err);
          return {
            success: false,
            url: imageObj.url,
            error: err.message || 'Unknown error',
            imageObj,
          };
        }
      })
    );

    const successfulDownloads = results.filter((r) => r.success).length;
    const successRate = successfulDownloads / batch.length;

    adjustBatchSize(successRate);

    return results;
  };

  const updateDownloadStats = (downloadedCount, totalCount, elapsedTime) => {
    const speed = downloadedCount / (elapsedTime / 1000);
    setDownloadSpeed(speed);

    const remainingCount = totalCount - downloadedCount;
    const estimatedTime = remainingCount / speed;
    setEstimatedTimeRemaining(estimatedTime);
  };

  const handleDownload = useCallback(async () => {
    if (useFolderDownload && !('showDirectoryPicker' in window)) {
      alert(
        'このブラウザはフォルダへのダウンロードをサポートしていません。ChromeまたはEdgeを使用してください。'
      );
      return;
    }

    setIsProcessing(true);
    setError('');
    setProgress(0);
    setSuccessCount(0);
    setFailCount(0);
    startTimeRef.current = Date.now();

    let dirHandle;

    if (useFolderDownload) {
      try {
        // ユーザーにフォルダを選択してもらう
        dirHandle = await window.showDirectoryPicker();
      } catch (err) {
        setError('フォルダの選択がキャンセルされました。');
        setIsProcessing(false);
        setOpenConfirmDialog(false);
        return;
      }
    }

    try {
      if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
        throw new Error('Start RowとEnd Rowは有効な数字である必要があります。');
      }

      const picUrlIndex = data[0].findIndex((header) => header.toLowerCase() === 'picurl');
      if (picUrlIndex === -1) {
        throw new Error('PicURLカラムが見つかりません。');
      }

      const actualStartRow = parseInt(startRow);
      const maxEndRow = calculateMaxEndRow();
      const actualEndRow = Math.min(parseInt(endRow), maxEndRow);

      if (actualStartRow < 1 || actualEndRow < actualStartRow) {
        throw new Error('Start RowとEnd Rowが有効ではありません。');
      }

      let imagesToDownload = [];

      // 選択された画像のみをダウンロード対象にする
      previewImages.forEach((product) => {
        product.images.forEach((image) => {
          if (selectedImages.has(image.url)) {
            imagesToDownload.push({
              url: image.url,
              rowIndex: product.rowIndex,
              imageIndex: image.imageIndex,
            });
          }
        });
      });

      let failedImages = [];
      for (let i = 0; i < imagesToDownload.length; i += batchSize) {
        const batchResults = await processBatch(imagesToDownload, i);
        for (const result of batchResults) {
          if (result.success) {
            setSuccessCount((prev) => prev + 1);
            if (useFolderDownload && dirHandle) {
              // フォルダに直接保存
              const fileHandle = await dirHandle.getFileHandle(result.fileName, { create: true });
              const writable = await fileHandle.createWritable();
              await writable.write(new Blob([result.data]));
              await writable.close();
            }
          } else {
            setFailCount((prev) => prev + 1);
            failedImages.push(result.imageObj);
          }
        }

        const totalProgress = Math.min(i + batchSize, imagesToDownload.length);
        setProgress(Math.round((totalProgress / imagesToDownload.length) * 100));

        const elapsedTime = Date.now() - startTimeRef.current;
        updateDownloadStats(totalProgress, imagesToDownload.length, elapsedTime);

        setRemainingRequests(getRemainingRequests());
      }

      // 失敗した画像のリトライ
      if (failedImages.length > 0) {
        await Promise.all(
          failedImages.map(async (imageObj) => {
            try {
              const imageData = await download(imageObj.url, 0, INITIAL_RETRY_DELAY * 2);
              setSuccessCount((prev) => prev + 1);
              setFailCount((prev) => prev - 1);
              setRemainingRequests(getRemainingRequests());
              const fileName = `image_row${imageObj.rowIndex}_img${imageObj.imageIndex}.jpg`;
              if (useFolderDownload && dirHandle) {
                const fileHandle = await dirHandle.getFileHandle(fileName, { create: true });
                const writable = await fileHandle.createWritable();
                await writable.write(new Blob([imageData]));
                await writable.close();
              }
            } catch (err) {
              console.error(`リトライに失敗しました ${imageObj.url}:`, err);
            }
          })
        );
      }

      const finalMessage = `ダウンロードが完了しました。${successCount}枚の画像が正常にダウンロードされ、${failCount}枚が失敗しました。`;
      showSnackbar(finalMessage);
    } catch (err) {
      const errorMessage = `ダウンロードに失敗しました: ${err.message}`;
      setError(errorMessage);
      showSnackbar(errorMessage);
      console.error(errorMessage, err);
    } finally {
      setIsProcessing(false);
      setOpenConfirmDialog(false);
    }
  }, [
    data,
    startRow,
    endRow,
    batchSize,
    useFolderDownload,
    selectedImages,
    previewImages,
  ]);

  const handleDelete = useCallback(async () => {
    setIsProcessing(true);
    setError('');
    setProgress(0);
    startTimeRef.current = Date.now();

    try {
      if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
        throw new Error('Start RowとEnd Rowは有効な数字である必要があります。');
      }

      const picUrlIndex = data[0].findIndex((header) => header.toLowerCase() === 'picurl');
      if (picUrlIndex === -1) {
        throw new Error('PicURLカラムが見つかりません。');
      }

      const actualStartRow = parseInt(startRow);
      const maxEndRow = calculateMaxEndRow();
      const actualEndRow = Math.min(parseInt(endRow), maxEndRow);

      if (actualStartRow < 1 || actualEndRow < actualStartRow) {
        throw new Error('Start RowとEnd Rowが有効ではありません。');
      }

      // 商品データの画像を更新する
      await updateProductImages();

      const finalMessage = `画像の削除が完了しました。`;
      showSnackbar(finalMessage);
    } catch (err) {
      const errorMessage = `画像の削除に失敗しました: ${err.message}`;
      setError(errorMessage);
      showSnackbar(errorMessage);
      console.error(errorMessage, err);
    } finally {
      setIsProcessing(false);
      setOpenConfirmDialog(false);
    }
  }, [data, startRow, endRow, selectedImages, previewImages]);

  // プレビューダイアログを開く処理
  const handleOpenPreviewDialog = (operation, option) => {
    try {
      if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
        throw new Error('Start RowとEnd Rowは有効な数字である必要があります。');
      }

      const picUrlIndex = data[0].findIndex((header) => header.toLowerCase() === 'picurl');
      const titleIndex = data[0].findIndex((header) => header.toLowerCase() === 'title');

      if (picUrlIndex === -1) {
        throw new Error('PicURLカラムが見つかりません。');
      }

      const actualStartRow = parseInt(startRow);
      const maxEndRow = calculateMaxEndRow();
      const actualEndRow = Math.min(parseInt(endRow), maxEndRow);

      if (actualStartRow < 1 || actualEndRow < actualStartRow) {
        throw new Error('Start RowとEnd Rowが有効ではありません。');
      }

      let previewItems = [];

      if (option === 'first') {
        // 1枚目の画像のみを取得
        for (let i = actualStartRow; i <= actualEndRow; i++) {
          const row = data[i];
          const imageUrl = row[picUrlIndex]?.split('|')[0];
          const title = row[titleIndex] || `商品 ${i}`;
          if (imageUrl) {
            previewItems.push({
              title,
              rowIndex: i,
              images: [
                {
                  url: imageUrl,
                  imageIndex: 1,
                },
              ],
            });
          }
        }
      } else {
        // すべての画像を取得
        for (let i = actualStartRow; i <= actualEndRow; i++) {
          const row = data[i];
          const imageUrls = row[picUrlIndex]?.split('|') || [];
          const title = row[titleIndex] || `商品 ${i}`;
          const images = imageUrls
            .map((url, idx) => ({
              url,
              imageIndex: idx + 1,
            }))
            .filter((img) => img.url);
          if (images.length > 0) {
            previewItems.push({
              title,
              rowIndex: i,
              images,
            });
          }
        }
      }

      setPreviewImages(previewItems);
      setSelectedImages(new Set(previewItems.flatMap((product) => product.images.map((img) => img.url))));
      setOpenPreviewDialog(true);
    } catch (err) {
      const errorMessage = `プレビューの準備中にエラーが発生しました: ${err.message}`;
      setError(errorMessage);
      console.error(errorMessage, err);
    }
  };

  // 画像の選択を切り替える
  const toggleImageSelection = (url) => {
    setSelectedImages((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(url)) {
        newSelected.delete(url);
      } else {
        newSelected.add(url);
      }
      return newSelected;
    });
  };

  // 商品データの画像を更新する関数
  const updateProductImages = async () => {
    const picUrlIndex = data[0].findIndex((header) => header.toLowerCase() === 'picurl');
    const picUrlColumn = String.fromCharCode(65 + picUrlIndex);

    // 行ごとに更新するデータを準備
    const updates = {};
    previewImages.forEach((product) => {
      product.images.forEach((image) => {
        if (!selectedImages.has(image.url)) {
          const key = product.rowIndex;
          if (!updates[key]) {
            // 元の画像URLリストを取得
            const originalUrls = data[product.rowIndex][picUrlIndex]?.split('|') || [];
            updates[key] = originalUrls;
          }
          // 対象の画像を削除
          updates[key][image.imageIndex - 1] = null;
        }
      });
    });

    // バッチ更新リクエストのデータを準備
    const batchUpdates = [];
    for (const [rowIndex, urls] of Object.entries(updates)) {
      const filteredUrls = urls.filter((url) => url !== null);
      batchUpdates.push({
        range: `${sheetName}!${picUrlColumn}${parseInt(rowIndex) + 1}`,
        values: [[filteredUrls.join('|')]],
      });
    }

    if (batchUpdates.length > 0) {
      await axios.post(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values:batchUpdate`,
        {
          valueInputOption: 'RAW',
          data: batchUpdates,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // データを再取得
      if (typeof fetchData === 'function') {
        fetchData();
      }
    }
  };

  return (
    <Box
      sx={{
        mt: 0,
        p: 1,
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
      }}
    >
      <Typography variant="h6" gutterBottom>
        画像ダウンロードと削除
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        150商品以上ある場合は、StartRowを151にしてEndRowを調整してください。
        一度に最大150行まで処理できます。
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={3}>
          <TextField
            label="Start Row"
            type="number"
            value={startRow}
            onChange={handleStartRowChange}
            fullWidth
            InputProps={{ inputProps: { min: 1 } }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="End Row"
            type="number"
            value={endRow}
            onChange={handleEndRowChange}
            fullWidth
            InputProps={{ inputProps: { min: startRow, max: calculateMaxEndRow() } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Tooltip title="指定した範囲の商品の1枚目の画像をダウンロード">
                <Button
                  variant="contained"
                  onClick={() => handleOperationConfirmation('download', 'first')}
                  disabled={isProcessing || !data || data.length <= 1 || remainingRequests <= 0}
                  fullWidth
                >
                  {isProcessing && operationType === 'download' && downloadOption === 'first' ? (
                    <CircularProgress size={24} />
                  ) : (
                    '1枚目のみダウンロード'
                  )}
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Tooltip title="指定した範囲の商品のすべての画像をダウンロード">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleOperationConfirmation('download', 'all')}
                  disabled={isProcessing || !data || data.length <= 1 || remainingRequests <= 0}
                  fullWidth
                >
                  {isProcessing && operationType === 'download' && downloadOption === 'all' ? (
                    <CircularProgress size={24} />
                  ) : (
                    'すべての画像をダウンロード'
                  )}
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Tooltip title="指定した範囲の商品の画像を削除">
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleOperationConfirmation('delete', 'all')}
                  disabled={isProcessing || !data || data.length <= 1}
                  fullWidth
                >
                  {isProcessing && operationType === 'delete' ? (
                    <CircularProgress size={24} />
                  ) : (
                    '画像を削除'
                  )}
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {operationType === 'download' && (
        <FormControlLabel
          control={
            <Switch
              checked={useFolderDownload}
              onChange={(e) => setUseFolderDownload(e.target.checked)}
            />
          }
          label="フォルダに直接ダウンロード (Chromeのみ対応)"
          sx={{ mt: 2 }}
        />
      )}

      <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
        1日のリクエスト残数: {remainingRequests} / {MAX_REQUESTS_PER_DAY}
      </Typography>

      {isProcessing && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            進行状況: {progress}%
          </Typography>
          <LinearProgress variant="determinate" value={progress} sx={{ mb: 1 }} />
          <Typography variant="body2" color="text.secondary">
            ダウンロード速度: {downloadSpeed.toFixed(2)} images/second
          </Typography>
          <Typography variant="body2" color="text.secondary">
            残り時間の推定: {formatTimeRemaining(estimatedTimeRemaining)}
          </Typography>
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      <Paper elevation={3} sx={{ mt: 3, p: 2 }}>
        <Typography variant="body2" color="text.secondary">
          正常に処理された画像: {successCount}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          処理に失敗した画像: {failCount}
        </Typography>
        {operationType === 'download' && (
          <Typography variant="body2" color="text.secondary">
            現在のバッチサイズ: {batchSize}
          </Typography>
        )}
      </Paper>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog
        open={openSettingsDialog}
        onClose={handleCloseSettingsDialog}
        aria-labelledby="settings-dialog-title"
      >
        <DialogTitle id="settings-dialog-title">ダウンロード設定</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Switch
                checked={autoAdjustBatchSize}
                onChange={(e) => setAutoAdjustBatchSize(e.target.checked)}
              />
            }
            label="バッチサイズの自動調整"
          />
          <Box sx={{ mt: 2 }}>
            <Typography id="batch-size-slider" gutterBottom>
              バッチサイズ: {batchSize}
            </Typography>
            <Slider
              value={batchSize}
              onChange={(_, newValue) => setBatchSize(newValue)}
              aria-labelledby="batch-size-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={MIN_BATCH_SIZE}
              max={MAX_BATCH_SIZE}
              disabled={autoAdjustBatchSize}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSettingsDialog} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>

      {/* プレビューダイアログ */}
      <Dialog
        open={openPreviewDialog}
        onClose={() => setOpenPreviewDialog(false)}
        maxWidth="xl" // ダイアログの幅を大きくする
        fullWidth
      >
        <DialogTitle>
          {operationType === 'download' ? '画像ダウンロードのプレビュー' : '画像削除のプレビュー'}
          <IconButton
            aria-label="close"
            onClick={() => setOpenPreviewDialog(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {operationType === 'download'
              ? 'ダウンロードしたくない画像の「×」をクリックして選択解除してください。'
              : '削除したい画像の「×」をクリックしてください。'}
          </Typography>
          <Box sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
            {previewImages.map((product, index) => (
              <Box key={index} sx={{ mb: 4 }}>
                <Typography variant="h6">{product.title}</Typography>
                <Grid container spacing={2}>
                  {product.images.map((image, idx) => (
                    <Grid item xs={3} sm={2} md={1.5} lg={1} key={idx}>
                      <Paper
                        elevation={1}
                        sx={{
                          p: 1,
                          position: 'relative',
                          opacity: selectedImages.has(image.url) ? 1 : 0.4, // 選択解除された画像の透明度を下げる
                        }}
                      >
                        <IconButton
                          aria-label="delete"
                          onClick={() => toggleImageSelection(image.url)}
                          sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            color: (theme) => theme.palette.error.main,
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                        <img
                          src={image.url}
                          alt={`Row ${product.rowIndex} Image ${image.imageIndex}`}
                          style={{ width: '100%', height: 'auto' }}
                        />
                        <Typography variant="caption">画像 {image.imageIndex}</Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPreviewDialog(false)}>閉じる</Button>
          <Button
            onClick={() => {
              setOpenPreviewDialog(false);
              setOpenConfirmDialog(true);
            }}
            color="primary"
            variant="contained"
          >
            {operationType === 'download' ? 'ダウンロード開始' : '削除実行'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'確認'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {operationType === 'download'
              ? '選択された画像をダウンロードします。よろしいですか？'
              : '選択解除された画像は商品データから削除されます。よろしいですか？'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            キャンセル
          </Button>
          <Button
            onClick={operationType === 'download' ? handleDownload : handleDelete}
            color="primary"
            autoFocus
          >
            実行
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

// 残り時間をフォーマットするヘルパー関数
function formatTimeRemaining(seconds) {
  if (seconds < 60) {
    return `${Math.round(seconds)}秒`;
  } else if (seconds < 3600) {
    return `${Math.round(seconds / 60)}分`;
  } else {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.round((seconds % 3600) / 60);
    return `${hours}時間${minutes}分`;
  }
}

export default ImageDownloader;






// // ImageDownloader.js

// import React, { useState, useCallback, useEffect, useRef } from 'react';
// import {
//   Button,
//   TextField,
//   Typography,
//   Box,
//   CircularProgress,
//   Alert,
//   Snackbar,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Tooltip,
//   LinearProgress,
//   Slider,
//   FormControlLabel,
//   Switch,
//   Paper,
//   Grid,
//   IconButton,
//   Checkbox,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemSecondaryAction,
//   Divider,
//   DialogContentText,
// } from '@mui/material';
// import axios from 'axios';
// import { useTheme } from '@mui/material/styles';
// import CloseIcon from '@mui/icons-material/Close';

// const INITIAL_BATCH_SIZE = 5;
// const MAX_BATCH_SIZE = 20;
// const MIN_BATCH_SIZE = 1;
// const RATE_LIMIT_PERIOD = 24 * 60 * 60 * 1000; // 24時間
// const MAX_REQUESTS_PER_DAY = 1000;
// const MAX_RETRIES = 5;
// const INITIAL_RETRY_DELAY = 1000;

// const workers = [
//   'https://broken-glitter-2eca.kikuchi-shun.workers.dev/',
//   'https://bold-block-5adc.kikuchi-shun.workers.dev/',
//   'https://broken-fire-195b.kikuchi-shun.workers.dev/',
// ];

// const userAgents = [
//   'Mozilla/5.0 (Windows NT 10.0; Win64; x64)...',
//   // 他のユーザーエージェント文字列
// ];

// function getRandomWorker() {
//   return workers[Math.floor(Math.random() * workers.length)];
// }

// function getRandomUserAgent() {
//   return userAgents[Math.floor(Math.random() * userAgents.length)];
// }

// const imageCache = new Map();

// function checkRateLimit() {
//   const now = Date.now();
//   const requests = JSON.parse(localStorage.getItem('rateLimitRequests') || '[]');
//   const validRequests = requests.filter((time) => now - time < RATE_LIMIT_PERIOD);
//   if (validRequests.length >= MAX_REQUESTS_PER_DAY) {
//     return false;
//   }
//   validRequests.push(now);
//   localStorage.setItem('rateLimitRequests', JSON.stringify(validRequests));
//   return true;
// }

// function getRemainingRequests() {
//   const now = Date.now();
//   const requests = JSON.parse(localStorage.getItem('rateLimitRequests') || '[]');
//   const validRequests = requests.filter((time) => now - time < RATE_LIMIT_PERIOD);
//   return MAX_REQUESTS_PER_DAY - validRequests.length;
// }

// async function download(url, retryCount = 0, retryDelay = INITIAL_RETRY_DELAY) {
//   if (imageCache.has(url)) {
//     return imageCache.get(url);
//   }

//   if (!checkRateLimit()) {
//     throw new Error('1日のリクエスト上限を超えました');
//   }

//   const workerUrl = getRandomWorker();
//   const proxyUrl = `${workerUrl}/?url=${encodeURIComponent(url)}`;

//   try {
//     const response = await axios.get(proxyUrl, {
//       responseType: 'arraybuffer',
//       timeout: 30000,
//       headers: {
//         'User-Agent': getRandomUserAgent(),
//         Referer: 'https://www.rakuten.co.jp/',
//       },
//     });
//     const data = response.data;
//     imageCache.set(url, data);
//     return data;
//   } catch (error) {
//     if (retryCount < MAX_RETRIES) {
//       await new Promise((resolve) => setTimeout(resolve, retryDelay));
//       return download(url, retryCount + 1, retryDelay * 2);
//     }
//     throw error;
//   }
// }

// function ImageDownloader({ spreadsheetId, sheetName, token, data, fetchData }) {
//   const [startRow, setStartRow] = useState(1);
//   const [endRow, setEndRow] = useState(150);
//   const [isProcessing, setIsProcessing] = useState(false);
//   const [error, setError] = useState('');
//   const [progress, setProgress] = useState(0);
//   const [successCount, setSuccessCount] = useState(0);
//   const [failCount, setFailCount] = useState(0);
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
//   const [batchSize, setBatchSize] = useState(INITIAL_BATCH_SIZE);
//   const [autoAdjustBatchSize, setAutoAdjustBatchSize] = useState(true);
//   const [downloadSpeed, setDownloadSpeed] = useState(0);
//   const [estimatedTimeRemaining, setEstimatedTimeRemaining] = useState(0);
//   const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
//   const [remainingRequests, setRemainingRequests] = useState(getRemainingRequests());
//   const [operationType, setOperationType] = useState('download'); // 'download' or 'delete'
//   const [useFolderDownload, setUseFolderDownload] = useState(false);
//   const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
//   const [previewImages, setPreviewImages] = useState([]);
//   const [selectedImages, setSelectedImages] = useState(new Set());
//   const [downloadOption, setDownloadOption] = useState('first'); // 'first' or 'all'

//   const theme = useTheme();

//   const startTimeRef = useRef(null);

//   useEffect(() => {
//     if (data && data.length > 1) {
//       setStartRow(1);
//       setEndRow(Math.min(startRow + 149, data.length - 1));
//     }
//   }, [data]);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setRemainingRequests(getRemainingRequests());
//     }, 60000); // 1分ごとに更新

//     return () => clearInterval(interval);
//   }, []);

//   const calculateMaxEndRow = () => {
//     if (data && data.length > 1) {
//       return Math.min(startRow + 149, data.length - 1);
//     } else {
//       return startRow + 149;
//     }
//   };

//   const handleStartRowChange = (event) => {
//     const value = parseInt(event.target.value);
//     setStartRow(value);

//     // endRowを新しいstartRowに基づいて調整
//     const maxEndRow = calculateMaxEndRow();
//     if (endRow < value || endRow > maxEndRow || isNaN(endRow)) {
//       setEndRow(maxEndRow);
//     }
//   };

//   const handleEndRowChange = (event) => {
//     const value = parseInt(event.target.value);
//     const maxEndRow = calculateMaxEndRow();
//     setEndRow(Math.min(Math.max(value, startRow), maxEndRow));
//   };

//   const showSnackbar = (message) => {
//     setSnackbarMessage(message);
//     setOpenSnackbar(true);
//   };

//   const handleCloseSnackbar = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     setOpenSnackbar(false);
//   };

//   const handleOperationConfirmation = (operation, option) => {
//     setOperationType(operation);
//     setDownloadOption(option);
//     handleOpenPreviewDialog(operation, option);
//   };

//   const handleCloseConfirmDialog = () => {
//     setOpenConfirmDialog(false);
//   };

//   const handleOpenSettingsDialog = () => {
//     setOpenSettingsDialog(true);
//   };

//   const handleCloseSettingsDialog = () => {
//     setOpenSettingsDialog(false);
//   };

//   const adjustBatchSize = (successRate) => {
//     if (autoAdjustBatchSize) {
//       if (successRate > 0.95 && batchSize < MAX_BATCH_SIZE) {
//         setBatchSize((prev) => prev + 1);
//       } else if (successRate < 0.8 && batchSize > MIN_BATCH_SIZE) {
//         setBatchSize((prev) => Math.max(prev - 2, MIN_BATCH_SIZE));
//       } else if (successRate < 0.6 && batchSize > MIN_BATCH_SIZE) {
//         setBatchSize((prev) => Math.max(prev - 3, MIN_BATCH_SIZE));
//       }
//     }
//   };

//   const processBatch = async (images, startIndex) => {
//     const batch = images.slice(startIndex, startIndex + batchSize);
//     const results = await Promise.all(
//       batch.map(async (imageObj, index) => {
//         try {
//           const imageData = await download(imageObj.url);
//           setRemainingRequests(getRemainingRequests());
//           const fileName = `image_row${imageObj.rowIndex}_img${imageObj.imageIndex}.jpg`;
//           return {
//             success: true,
//             data: imageData,
//             fileName,
//             url: imageObj.url,
//             imageObj,
//           };
//         } catch (err) {
//           console.error(`画像のダウンロードに失敗しました ${imageObj.url}:`, err);
//           return {
//             success: false,
//             url: imageObj.url,
//             error: err.message || 'Unknown error',
//             imageObj,
//           };
//         }
//       })
//     );

//     const successfulDownloads = results.filter((r) => r.success).length;
//     const successRate = successfulDownloads / batch.length;

//     adjustBatchSize(successRate);

//     return results;
//   };

//   const updateDownloadStats = (downloadedCount, totalCount, elapsedTime) => {
//     const speed = downloadedCount / (elapsedTime / 1000);
//     setDownloadSpeed(speed);

//     const remainingCount = totalCount - downloadedCount;
//     const estimatedTime = remainingCount / speed;
//     setEstimatedTimeRemaining(estimatedTime);
//   };

//   const handleDownload = useCallback(async () => {
//     if (useFolderDownload && !('showDirectoryPicker' in window)) {
//       alert(
//         'このブラウザはフォルダへのダウンロードをサポートしていません。ChromeまたはEdgeを使用してください。'
//       );
//       return;
//     }

//     setIsProcessing(true);
//     setError('');
//     setProgress(0);
//     setSuccessCount(0);
//     setFailCount(0);
//     startTimeRef.current = Date.now();

//     let dirHandle;

//     if (useFolderDownload) {
//       try {
//         // ユーザーにフォルダを選択してもらう
//         dirHandle = await window.showDirectoryPicker();
//       } catch (err) {
//         setError('フォルダの選択がキャンセルされました。');
//         setIsProcessing(false);
//         setOpenConfirmDialog(false);
//         return;
//       }
//     }

//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error('Start RowとEnd Rowは有効な数字である必要があります。');
//       }

//       const picUrlIndex = data[0].findIndex((header) => header.toLowerCase() === 'picurl');
//       if (picUrlIndex === -1) {
//         throw new Error('PicURLカラムが見つかりません。');
//       }

//       const actualStartRow = parseInt(startRow);
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow), maxEndRow);

//       if (actualStartRow < 1 || actualEndRow < actualStartRow) {
//         throw new Error('Start RowとEnd Rowが有効ではありません。');
//       }

//       let imagesToDownload = [];

//       // 選択された画像のみをダウンロード対象にする
//       previewImages.forEach((item) => {
//         if (selectedImages.has(item.url)) {
//           imagesToDownload.push({
//             url: item.url,
//             rowIndex: item.rowIndex,
//             imageIndex: item.imageIndex,
//           });
//         }
//       });

//       let failedImages = [];
//       for (let i = 0; i < imagesToDownload.length; i += batchSize) {
//         const batchResults = await processBatch(imagesToDownload, i);
//         for (const result of batchResults) {
//           if (result.success) {
//             setSuccessCount((prev) => prev + 1);
//             if (useFolderDownload && dirHandle) {
//               // フォルダに直接保存
//               const fileHandle = await dirHandle.getFileHandle(result.fileName, { create: true });
//               const writable = await fileHandle.createWritable();
//               await writable.write(new Blob([result.data]));
//               await writable.close();
//             }
//           } else {
//             setFailCount((prev) => prev + 1);
//             failedImages.push(result.imageObj);
//           }
//         }

//         const totalProgress = Math.min(i + batchSize, imagesToDownload.length);
//         setProgress(Math.round((totalProgress / imagesToDownload.length) * 100));

//         const elapsedTime = Date.now() - startTimeRef.current;
//         updateDownloadStats(totalProgress, imagesToDownload.length, elapsedTime);

//         setRemainingRequests(getRemainingRequests());
//       }

//       // 失敗した画像のリトライ
//       if (failedImages.length > 0) {
//         const retryResults = await Promise.all(
//           failedImages.map(async (imageObj) => {
//             try {
//               const imageData = await download(imageObj.url, 0, INITIAL_RETRY_DELAY * 2);
//               setSuccessCount((prev) => prev + 1);
//               setFailCount((prev) => prev - 1);
//               setRemainingRequests(getRemainingRequests());
//               const fileName = `image_row${imageObj.rowIndex}_img${imageObj.imageIndex}.jpg`;
//               if (useFolderDownload && dirHandle) {
//                 const fileHandle = await dirHandle.getFileHandle(fileName, { create: true });
//                 const writable = await fileHandle.createWritable();
//                 await writable.write(new Blob([imageData]));
//                 await writable.close();
//               }
//               return { success: true, data: imageData, fileName, url: imageObj.url };
//             } catch (err) {
//               console.error(`リトライに失敗しました ${imageObj.url}:`, err);
//               return { success: false, url: imageObj.url, error: err.message || 'Unknown error' };
//             }
//           })
//         );

//         // リトライで成功した場合の処理（フォルダダウンロード時は既に保存済み）
//       }

//       const finalMessage = `ダウンロードが完了しました。${successCount}枚の画像が正常にダウンロードされ、${failCount}枚が失敗しました。`;
//       showSnackbar(finalMessage);
//     } catch (err) {
//       const errorMessage = `ダウンロードに失敗しました: ${err.message}`;
//       setError(errorMessage);
//       showSnackbar(errorMessage);
//       console.error(errorMessage, err);
//     } finally {
//       setIsProcessing(false);
//       setOpenConfirmDialog(false);
//     }
//   }, [
//     data,
//     startRow,
//     endRow,
//     batchSize,
//     useFolderDownload,
//     selectedImages,
//     previewImages,
//   ]);

//   const handleDelete = useCallback(async () => {
//     setIsProcessing(true);
//     setError('');
//     setProgress(0);
//     startTimeRef.current = Date.now();

//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error('Start RowとEnd Rowは有効な数字である必要があります。');
//       }

//       const picUrlIndex = data[0].findIndex((header) => header.toLowerCase() === 'picurl');
//       if (picUrlIndex === -1) {
//         throw new Error('PicURLカラムが見つかりません。');
//       }

//       const actualStartRow = parseInt(startRow);
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow), maxEndRow);

//       if (actualStartRow < 1 || actualEndRow < actualStartRow) {
//         throw new Error('Start RowとEnd Rowが有効ではありません。');
//       }

//       // 商品データの画像を更新する
//       await updateProductImages();

//       const finalMessage = `画像の削除が完了しました。`;
//       showSnackbar(finalMessage);
//     } catch (err) {
//       const errorMessage = `画像の削除に失敗しました: ${err.message}`;
//       setError(errorMessage);
//       showSnackbar(errorMessage);
//       console.error(errorMessage, err);
//     } finally {
//       setIsProcessing(false);
//       setOpenConfirmDialog(false);
//     }
//   }, [data, startRow, endRow, selectedImages, previewImages]);

//   // プレビューダイアログを開く処理
//   const handleOpenPreviewDialog = (operation, option) => {
//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error('Start RowとEnd Rowは有効な数字である必要があります。');
//       }

//       const picUrlIndex = data[0].findIndex((header) => header.toLowerCase() === 'picurl');
//       if (picUrlIndex === -1) {
//         throw new Error('PicURLカラムが見つかりません。');
//       }

//       const actualStartRow = parseInt(startRow);
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow), maxEndRow);

//       if (actualStartRow < 1 || actualEndRow < actualStartRow) {
//         throw new Error('Start RowとEnd Rowが有効ではありません。');
//       }

//       let previewItems = [];

//       if (option === 'first') {
//         // 1枚目の画像のみを取得
//         for (let i = actualStartRow; i <= actualEndRow; i++) {
//           const row = data[i];
//           const imageUrl = row[picUrlIndex]?.split('|')[0];
//           if (imageUrl) {
//             previewItems.push({
//               url: imageUrl,
//               rowIndex: i,
//               imageIndex: 1,
//             });
//           }
//         }
//       } else {
//         // すべての画像を取得
//         for (let i = actualStartRow; i <= actualEndRow; i++) {
//           const row = data[i];
//           const imageUrls = row[picUrlIndex]?.split('|') || [];
//           imageUrls.forEach((url, idx) => {
//             if (url) {
//               previewItems.push({
//                 url,
//                 rowIndex: i,
//                 imageIndex: idx + 1,
//               });
//             }
//           });
//         }
//       }

//       setPreviewImages(previewItems);
//       setSelectedImages(new Set(previewItems.map((item) => item.url)));
//       setOpenPreviewDialog(true);
//     } catch (err) {
//       const errorMessage = `プレビューの準備中にエラーが発生しました: ${err.message}`;
//       setError(errorMessage);
//       console.error(errorMessage, err);
//     }
//   };

//   // 画像の選択を切り替える
//   const toggleImageSelection = (url) => {
//     setSelectedImages((prevSelected) => {
//       const newSelected = new Set(prevSelected);
//       if (newSelected.has(url)) {
//         newSelected.delete(url);
//       } else {
//         newSelected.add(url);
//       }
//       return newSelected;
//     });
//   };

//   // 商品データの画像を更新する関数
//   const updateProductImages = async () => {
//     const picUrlIndex = data[0].findIndex((header) => header.toLowerCase() === 'picurl');
//     const picUrlColumn = String.fromCharCode(65 + picUrlIndex);

//     // 行ごとに更新するデータを準備
//     const updates = {};
//     previewImages.forEach((item) => {
//       if (!selectedImages.has(item.url)) {
//         const key = item.rowIndex;
//         if (!updates[key]) {
//           // 元の画像URLリストを取得
//           const originalUrls = data[item.rowIndex][picUrlIndex]?.split('|') || [];
//           updates[key] = originalUrls;
//         }
//         // 対象の画像を削除
//         updates[key][item.imageIndex - 1] = null;
//       }
//     });

//     // バッチ更新リクエストのデータを準備
//     const batchUpdates = [];
//     for (const [rowIndex, urls] of Object.entries(updates)) {
//       const filteredUrls = urls.filter((url) => url !== null);
//       batchUpdates.push({
//         range: `${sheetName}!${picUrlColumn}${parseInt(rowIndex) + 1}`,
//         values: [[filteredUrls.join('|')]],
//       });
//     }

//     if (batchUpdates.length > 0) {
//       await axios.post(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values:batchUpdate`,
//         {
//           valueInputOption: 'RAW',
//           data: batchUpdates,
//         },
//         {
//           headers: { Authorization: `Bearer ${token}` },
//         }
//       );
//       // データを再取得
//       fetchData();
//     }
//   };

//   return (
//     <Box
//       sx={{
//         mt: 0,
//         p: 1,
//         backgroundColor: theme.palette.background.paper,
//         borderRadius: theme.shape.borderRadius,
//       }}
//     >
//       <Typography variant="h6" gutterBottom>
//         画像ダウンロードと削除
//       </Typography>
//       <Typography variant="body2" sx={{ mb: 2 }}>
//         150商品以上ある場合は、StartRowを151にしてEndRowを調整してください。
//         一度に最大150行まで処理できます。
//       </Typography>
//       <Grid container spacing={2} alignItems="center">
//         <Grid item xs={12} sm={3}>
//           <TextField
//             label="Start Row"
//             type="number"
//             value={startRow}
//             onChange={handleStartRowChange}
//             fullWidth
//             InputProps={{ inputProps: { min: 1 } }}
//           />
//         </Grid>
//         <Grid item xs={12} sm={3}>
//           <TextField
//             label="End Row"
//             type="number"
//             value={endRow}
//             onChange={handleEndRowChange}
//             fullWidth
//             InputProps={{ inputProps: { min: startRow, max: calculateMaxEndRow() } }}
//           />
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           <Grid container spacing={1}>
//             <Grid item xs={12} sm={4}>
//               <Tooltip title="指定した範囲の商品の1枚目の画像をダウンロード">
//                 <Button
//                   variant="contained"
//                   onClick={() => handleOperationConfirmation('download', 'first')}
//                   disabled={isProcessing || !data || data.length <= 1 || remainingRequests <= 0}
//                   fullWidth
//                 >
//                   {isProcessing && operationType === 'download' && downloadOption === 'first' ? (
//                     <CircularProgress size={24} />
//                   ) : (
//                     '1枚目のみダウンロード'
//                   )}
//                 </Button>
//               </Tooltip>
//             </Grid>
//             <Grid item xs={12} sm={4}>
//               <Tooltip title="指定した範囲の商品のすべての画像をダウンロード">
//                 <Button
//                   variant="contained"
//                   color="secondary"
//                   onClick={() => handleOperationConfirmation('download', 'all')}
//                   disabled={isProcessing || !data || data.length <= 1 || remainingRequests <= 0}
//                   fullWidth
//                 >
//                   {isProcessing && operationType === 'download' && downloadOption === 'all' ? (
//                     <CircularProgress size={24} />
//                   ) : (
//                     'すべての画像をダウンロード'
//                   )}
//                 </Button>
//               </Tooltip>
//             </Grid>
//             <Grid item xs={12} sm={4}>
//               <Tooltip title="指定した範囲の商品の画像を削除">
//                 <Button
//                   variant="outlined"
//                   color="error"
//                   onClick={() => handleOperationConfirmation('delete', 'all')}
//                   disabled={isProcessing || !data || data.length <= 1}
//                   fullWidth
//                 >
//                   {isProcessing && operationType === 'delete' ? (
//                     <CircularProgress size={24} />
//                   ) : (
//                     '画像を削除'
//                   )}
//                 </Button>
//               </Tooltip>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Grid>

//       {operationType === 'download' && (
//         <FormControlLabel
//           control={
//             <Switch
//               checked={useFolderDownload}
//               onChange={(e) => setUseFolderDownload(e.target.checked)}
//             />
//           }
//           label="フォルダに直接ダウンロード (Chromeのみ対応)"
//           sx={{ mt: 2 }}
//         />
//       )}

//       <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
//         1日のリクエスト残数: {remainingRequests} / {MAX_REQUESTS_PER_DAY}
//       </Typography>

//       {isProcessing && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="body2" color="text.secondary" gutterBottom>
//             進行状況: {progress}%
//           </Typography>
//           <LinearProgress variant="determinate" value={progress} sx={{ mb: 1 }} />
//           <Typography variant="body2" color="text.secondary">
//             ダウンロード速度: {downloadSpeed.toFixed(2)} images/second
//           </Typography>
//           <Typography variant="body2" color="text.secondary">
//             残り時間の推定: {formatTimeRemaining(estimatedTimeRemaining)}
//           </Typography>
//         </Box>
//       )}

//       {error && (
//         <Alert severity="error" sx={{ mt: 2 }}>
//           {error}
//         </Alert>
//       )}

//       <Paper elevation={3} sx={{ mt: 3, p: 2 }}>
//         <Typography variant="body2" color="text.secondary">
//           正常に処理された画像: {successCount}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           処理に失敗した画像: {failCount}
//         </Typography>
//         {operationType === 'download' && (
//           <Typography variant="body2" color="text.secondary">
//             現在のバッチサイズ: {batchSize}
//           </Typography>
//         )}
//       </Paper>

//       <Snackbar
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//         open={openSnackbar}
//         autoHideDuration={6000}
//         onClose={handleCloseSnackbar}
//       >
//         <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>

//       <Dialog
//         open={openSettingsDialog}
//         onClose={handleCloseSettingsDialog}
//         aria-labelledby="settings-dialog-title"
//       >
//         <DialogTitle id="settings-dialog-title">ダウンロード設定</DialogTitle>
//         <DialogContent>
//           <FormControlLabel
//             control={
//               <Switch
//                 checked={autoAdjustBatchSize}
//                 onChange={(e) => setAutoAdjustBatchSize(e.target.checked)}
//               />
//             }
//             label="バッチサイズの自動調整"
//           />
//           <Box sx={{ mt: 2 }}>
//             <Typography id="batch-size-slider" gutterBottom>
//               バッチサイズ: {batchSize}
//             </Typography>
//             <Slider
//               value={batchSize}
//               onChange={(_, newValue) => setBatchSize(newValue)}
//               aria-labelledby="batch-size-slider"
//               valueLabelDisplay="auto"
//               step={1}
//               marks
//               min={MIN_BATCH_SIZE}
//               max={MAX_BATCH_SIZE}
//               disabled={autoAdjustBatchSize}
//             />
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseSettingsDialog} color="primary">
//             閉じる
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* プレビューダイアログ */}
//       <Dialog
//         open={openPreviewDialog}
//         onClose={() => setOpenPreviewDialog(false)}
//         maxWidth="lg"
//         fullWidth
//       >
//         <DialogTitle>
//           {operationType === 'download' ? '画像ダウンロードのプレビュー' : '画像削除のプレビュー'}
//           <IconButton
//             aria-label="close"
//             onClick={() => setOpenPreviewDialog(false)}
//             sx={{
//               position: 'absolute',
//               right: 8,
//               top: 8,
//               color: (theme) => theme.palette.grey[500],
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//         </DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             {operationType === 'download'
//               ? 'ダウンロードしたくない画像のチェックを外してください。'
//               : '削除したい画像のチェックを外してください。チェックを外すと、その画像は商品データから削除されます。'}
//           </Typography>
//           <List>
//             {previewImages.map((item, index) => (
//               <div key={index}>
//                 <ListItem>
//                   <Checkbox
//                     checked={selectedImages.has(item.url)}
//                     onChange={() => toggleImageSelection(item.url)}
//                   />
//                   <ListItemText primary={`行 ${item.rowIndex} - 画像 ${item.imageIndex}`} />
//                   <ListItemSecondaryAction>
//                     <img
//                       src={item.url}
//                       alt={`Row ${item.rowIndex} Image ${item.imageIndex}`}
//                       style={{ width: 100, height: 'auto' }}
//                     />
//                   </ListItemSecondaryAction>
//                 </ListItem>
//                 <Divider />
//               </div>
//             ))}
//           </List>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenPreviewDialog(false)}>閉じる</Button>
//           <Button
//             onClick={() => {
//               setOpenPreviewDialog(false);
//               setOpenConfirmDialog(true);
//             }}
//             color="primary"
//             variant="contained"
//           >
//             {operationType === 'download' ? 'ダウンロード開始' : '削除実行'}
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog
//         open={openConfirmDialog}
//         onClose={handleCloseConfirmDialog}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">{'確認'}</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             {operationType === 'download'
//               ? '選択された画像をダウンロードします。よろしいですか？'
//               : '選択解除された画像は商品データから削除されます。よろしいですか？'}
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseConfirmDialog} color="primary">
//             キャンセル
//           </Button>
//           <Button
//             onClick={operationType === 'download' ? handleDownload : handleDelete}
//             color="primary"
//             autoFocus
//           >
//             実行
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// // 残り時間をフォーマットするヘルパー関数
// function formatTimeRemaining(seconds) {
//   if (seconds < 60) {
//     return `${Math.round(seconds)}秒`;
//   } else if (seconds < 3600) {
//     return `${Math.round(seconds / 60)}分`;
//   } else {
//     const hours = Math.floor(seconds / 3600);
//     const minutes = Math.round((seconds % 3600) / 60);
//     return `${hours}時間${minutes}分`;
//   }
// }

// export default ImageDownloader;






// import React, { useState, useCallback, useEffect, useRef } from 'react';
// import { 
//   Button, 
//   TextField, 
//   Typography, 
//   Box, 
//   CircularProgress, 
//   Alert, 
//   Snackbar,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   Tooltip,
//   LinearProgress,
//   Slider,
//   FormControlLabel,
//   Switch,
//   Paper,
//   Grid
// } from '@mui/material';
// import axios from 'axios';
// import { useTheme } from '@mui/material/styles';

// const INITIAL_BATCH_SIZE = 5;
// const MAX_BATCH_SIZE = 20;
// const MIN_BATCH_SIZE = 1;
// const RATE_LIMIT_PERIOD = 24 * 60 * 60 * 1000; // 24時間
// const MAX_REQUESTS_PER_DAY = 500;
// const MAX_RETRIES = 5;
// const INITIAL_RETRY_DELAY = 1000;

// const workers = [
//   'https://broken-glitter-2eca.kikuchi-shun.workers.dev/',
//   'https://bold-block-5adc.kikuchi-shun.workers.dev/',
//   'https://broken-fire-195b.kikuchi-shun.workers.dev/'
// ];

// const userAgents = [
//   'Mozilla/5.0 (Windows NT 10.0; Win64; x64)...',
//   // 他のユーザーエージェント文字列
// ];

// function getRandomWorker() {
//   return workers[Math.floor(Math.random() * workers.length)];
// }

// function getRandomUserAgent() {
//   return userAgents[Math.floor(Math.random() * userAgents.length)];
// }

// const imageCache = new Map();

// function checkRateLimit() {
//   const now = Date.now();
//   const requests = JSON.parse(localStorage.getItem('rateLimitRequests') || '[]');
//   const validRequests = requests.filter(time => now - time < RATE_LIMIT_PERIOD);
//   if (validRequests.length >= MAX_REQUESTS_PER_DAY) {
//     return false;
//   }
//   validRequests.push(now);
//   localStorage.setItem('rateLimitRequests', JSON.stringify(validRequests));
//   return true;
// }

// function getRemainingRequests() {
//   const now = Date.now();
//   const requests = JSON.parse(localStorage.getItem('rateLimitRequests') || '[]');
//   const validRequests = requests.filter(time => now - time < RATE_LIMIT_PERIOD);
//   return MAX_REQUESTS_PER_DAY - validRequests.length;
// }

// async function download(url, retryCount = 0, retryDelay = INITIAL_RETRY_DELAY) {
//   if (imageCache.has(url)) {
//     return imageCache.get(url);
//   }

//   if (!checkRateLimit()) {
//     throw new Error('1日のリクエスト上限を超えました');
//   }

//   const workerUrl = getRandomWorker();
//   const proxyUrl = `${workerUrl}/?url=${encodeURIComponent(url)}`;
  
//   try {
//     const response = await axios.get(proxyUrl, {
//       responseType: 'arraybuffer',
//       timeout: 30000,
//       headers: {
//         'User-Agent': getRandomUserAgent(),
//         'Referer': 'https://www.rakuten.co.jp/'
//       }
//     });
//     const data = response.data;
//     imageCache.set(url, data);
//     return data;
//   } catch (error) {
//     if (retryCount < MAX_RETRIES) {
//       await new Promise(resolve => setTimeout(resolve, retryDelay));
//       return download(url, retryCount + 1, retryDelay * 2);
//     }
//     throw error;
//   }
// }

// function ImageDownloader({ spreadsheetId, sheetName, token, data }) {
//   const [startRow, setStartRow] = useState(1);
//   const [endRow, setEndRow] = useState(150);
//   const [isDownloading, setIsDownloading] = useState(false);
//   const [error, setError] = useState('');
//   const [progress, setProgress] = useState(0);
//   const [successCount, setSuccessCount] = useState(0);
//   const [failCount, setFailCount] = useState(0);
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
//   const [batchSize, setBatchSize] = useState(INITIAL_BATCH_SIZE);
//   const [autoAdjustBatchSize, setAutoAdjustBatchSize] = useState(true);
//   const [downloadSpeed, setDownloadSpeed] = useState(0);
//   const [estimatedTimeRemaining, setEstimatedTimeRemaining] = useState(0);
//   const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
//   const [remainingRequests, setRemainingRequests] = useState(getRemainingRequests());
//   const [downloadOption, setDownloadOption] = useState('first'); // 'first' or 'all'
//   const [useFolderDownload, setUseFolderDownload] = useState(false);

//   const theme = useTheme();

//   const startTimeRef = useRef(null);

//   useEffect(() => {
//     if (data && data.length > 1) {
//       setStartRow(1);
//       setEndRow(Math.min(startRow + 149, data.length - 1));
//     }
//   }, [data]);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setRemainingRequests(getRemainingRequests());
//     }, 60000); // 1分ごとに更新

//     return () => clearInterval(interval);
//   }, []);

//   const calculateMaxEndRow = () => {
//     if (data && data.length > 1) {
//       return Math.min(startRow + 149, data.length - 1);
//     } else {
//       return startRow + 149;
//     }
//   };

//   const handleStartRowChange = (event) => {
//     const value = parseInt(event.target.value);
//     setStartRow(value);

//     // endRowを新しいstartRowに基づいて調整
//     const maxEndRow = calculateMaxEndRow();
//     if (endRow < value || endRow > maxEndRow || isNaN(endRow)) {
//       setEndRow(maxEndRow);
//     }
//   };

//   const handleEndRowChange = (event) => {
//     const value = parseInt(event.target.value);
//     const maxEndRow = calculateMaxEndRow();
//     setEndRow(Math.min(Math.max(value, startRow), maxEndRow));
//   };

//   const showSnackbar = (message) => {
//     setSnackbarMessage(message);
//     setOpenSnackbar(true);
//   };

//   const handleCloseSnackbar = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     setOpenSnackbar(false);
//   };

//   const handleDownloadConfirmation = (option) => {
//     setDownloadOption(option);
//     setOpenConfirmDialog(true);
//   };

//   const handleCloseConfirmDialog = () => {
//     setOpenConfirmDialog(false);
//   };

//   const handleOpenSettingsDialog = () => {
//     setOpenSettingsDialog(true);
//   };

//   const handleCloseSettingsDialog = () => {
//     setOpenSettingsDialog(false);
//   };

//   const adjustBatchSize = (successRate) => {
//     if (autoAdjustBatchSize) {
//       if (successRate > 0.95 && batchSize < MAX_BATCH_SIZE) {
//         setBatchSize(prev => prev + 1);
//       } else if (successRate < 0.8 && batchSize > MIN_BATCH_SIZE) {
//         setBatchSize(prev => Math.max(prev - 2, MIN_BATCH_SIZE));
//       } else if (successRate < 0.6 && batchSize > MIN_BATCH_SIZE) {
//         setBatchSize(prev => Math.max(prev - 3, MIN_BATCH_SIZE));
//       }
//     }
//   };

//   const processBatch = async (images, startIndex) => {
//     const batch = images.slice(startIndex, startIndex + batchSize);
//     const results = await Promise.all(batch.map(async (imageObj, index) => {
//       try {
//         const imageData = await download(imageObj.url);
//         setRemainingRequests(getRemainingRequests());
//         const fileName = `image_row${imageObj.rowIndex}_img${imageObj.imageIndex}.jpg`;
//         return {
//           success: true,
//           data: imageData,
//           fileName,
//           url: imageObj.url,
//           imageObj,
//         };
//       } catch (err) {
//         console.error(`画像のダウンロードに失敗しました ${imageObj.url}:`, err);
//         return {
//           success: false,
//           url: imageObj.url,
//           error: err.message || 'Unknown error',
//           imageObj,
//         };
//       }
//     }));

//     const successfulDownloads = results.filter(r => r.success).length;
//     const successRate = successfulDownloads / batch.length;

//     adjustBatchSize(successRate);

//     return results;
//   };

//   const updateDownloadStats = (downloadedCount, totalCount, elapsedTime) => {
//     const speed = downloadedCount / (elapsedTime / 1000);
//     setDownloadSpeed(speed);

//     const remainingCount = totalCount - downloadedCount;
//     const estimatedTime = remainingCount / speed;
//     setEstimatedTimeRemaining(estimatedTime);
//   };

//   const handleDownload = useCallback(async () => {
//     if (useFolderDownload && !('showDirectoryPicker' in window)) {
//       alert('このブラウザはフォルダへのダウンロードをサポートしていません。ChromeまたはEdgeを使用してください。');
//       return;
//     }

//     setIsDownloading(true);
//     setError('');
//     setProgress(0);
//     setSuccessCount(0);
//     setFailCount(0);
//     startTimeRef.current = Date.now();

//     let dirHandle;

//     if (useFolderDownload) {
//       try {
//         // ユーザーにフォルダを選択してもらう
//         dirHandle = await window.showDirectoryPicker();
//       } catch (err) {
//         setError('フォルダの選択がキャンセルされました。');
//         setIsDownloading(false);
//         setOpenConfirmDialog(false);
//         return;
//       }
//     }

//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error('Start RowとEnd Rowは有効な数字である必要があります。');
//       }

//       const picUrlIndex = data[0].findIndex(header => header.toLowerCase() === 'picurl');
//       if (picUrlIndex === -1) {
//         throw new Error('PicURLカラムが見つかりません。');
//       }

//       const actualStartRow = parseInt(startRow);
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow), maxEndRow);

//       if (actualStartRow < 1 || actualEndRow < actualStartRow) {
//         throw new Error('Start RowとEnd Rowが有効ではありません。');
//       }

//       let imagesToDownload = [];

//       if (downloadOption === 'first') {
//         // 1枚目の画像のみを取得
//         imagesToDownload = data.slice(actualStartRow, actualEndRow + 1).map((row, index) => {
//           const imageUrl = row[picUrlIndex]?.split('|')[0];
//           return {
//             url: imageUrl,
//             rowIndex: actualStartRow + index,
//             imageIndex: 1,
//           };
//         });
//       } else {
//         // すべての画像を取得
//         imagesToDownload = data.slice(actualStartRow, actualEndRow + 1).flatMap((row, index) => {
//           const imageUrls = row[picUrlIndex]?.split('|') || [];
//           return imageUrls.map((url, imgIndex) => ({
//             url,
//             rowIndex: actualStartRow + index,
//             imageIndex: imgIndex + 1,
//           }));
//         });
//       }

//       let failedImages = [];
//       for (let i = 0; i < imagesToDownload.length; i += batchSize) {
//         const batchResults = await processBatch(imagesToDownload, i);
//         for (const result of batchResults) {
//           if (result.success) {
//             setSuccessCount(prev => prev + 1);
//             if (useFolderDownload && dirHandle) {
//               // フォルダに直接保存
//               const fileHandle = await dirHandle.getFileHandle(result.fileName, { create: true });
//               const writable = await fileHandle.createWritable();
//               await writable.write(new Blob([result.data]));
//               await writable.close();
//             }
//           } else {
//             setFailCount(prev => prev + 1);
//             failedImages.push(result.imageObj);
//           }
//         }

//         const totalProgress = Math.min(i + batchSize, imagesToDownload.length);
//         setProgress(Math.round((totalProgress / imagesToDownload.length) * 100));

//         const elapsedTime = Date.now() - startTimeRef.current;
//         updateDownloadStats(totalProgress, imagesToDownload.length, elapsedTime);

//         setRemainingRequests(getRemainingRequests());
//       }

//       // 失敗した画像のリトライ
//       if (failedImages.length > 0) {
//         const retryResults = await Promise.all(failedImages.map(async imageObj => {
//           try {
//             const imageData = await download(imageObj.url, 0, INITIAL_RETRY_DELAY * 2);
//             setSuccessCount(prev => prev + 1);
//             setFailCount(prev => prev - 1);
//             setRemainingRequests(getRemainingRequests());
//             const fileName = `image_row${imageObj.rowIndex}_img${imageObj.imageIndex}.jpg`;
//             if (useFolderDownload && dirHandle) {
//               const fileHandle = await dirHandle.getFileHandle(fileName, { create: true });
//               const writable = await fileHandle.createWritable();
//               await writable.write(new Blob([imageData]));
//               await writable.close();
//             }
//             return { success: true, data: imageData, fileName, url: imageObj.url };
//           } catch (err) {
//             console.error(`リトライに失敗しました ${imageObj.url}:`, err);
//             return { success: false, url: imageObj.url, error: err.message || 'Unknown error' };
//           }
//         }));

//         // リトライで成功した場合の処理（フォルダダウンロード時は既に保存済み）
//       }

//       const finalMessage = `ダウンロードが完了しました。${successCount}枚の画像が正常にダウンロードされ、${failCount}枚が失敗しました。`;
//       showSnackbar(finalMessage);
//     } catch (err) {
//       const errorMessage = `ダウンロードに失敗しました: ${err.message}`;
//       setError(errorMessage);
//       showSnackbar(errorMessage);
//       console.error(errorMessage, err);
//     } finally {
//       setIsDownloading(false);
//       setOpenConfirmDialog(false);
//     }
//   }, [data, startRow, endRow, batchSize, downloadOption, useFolderDownload]);

//   return (
//     <Box sx={{ mt: 0, p: 1, backgroundColor: theme.palette.background.paper, borderRadius: theme.shape.borderRadius }}>
//       <Typography variant="h6" gutterBottom>画像ダウンロード</Typography>
//       <Typography variant="body2" sx={{ mb: 2 }}>
//         150商品以上ある場合は、StartRowを151にしてEndRowを調整してください。
//         一度に最大150行まで処理できます。
//       </Typography>
//       <Grid container spacing={2} alignItems="center">
//         <Grid item xs={12} sm={3}>
//           <TextField
//             label="Start Row"
//             type="number"
//             value={startRow}
//             onChange={handleStartRowChange}
//             fullWidth
//             InputProps={{ inputProps: { min: 1 } }}
//           />
//         </Grid>
//         <Grid item xs={12} sm={3}>
//           <TextField
//             label="End Row"
//             type="number"
//             value={endRow}
//             onChange={handleEndRowChange}
//             fullWidth
//             InputProps={{ inputProps: { min: startRow, max: calculateMaxEndRow() } }}
//           />
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           <Grid container spacing={1}>
//             <Grid item xs={12} sm={6}>
//               <Tooltip title="指定した範囲の商品の1枚目の画像をダウンロード">
//                 <Button
//                   variant="contained"
//                   onClick={() => handleDownloadConfirmation('first')}
//                   disabled={isDownloading || !data || data.length <= 1 || remainingRequests <= 0}
//                   fullWidth
//                 >
//                   {isDownloading && downloadOption === 'first' ? <CircularProgress size={24} /> : '1枚目のみダウンロード'}
//                 </Button>
//               </Tooltip>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <Tooltip title="指定した範囲の商品のすべての画像をダウンロード">
//                 <Button
//                   variant="contained"
//                   color="secondary"
//                   onClick={() => handleDownloadConfirmation('all')}
//                   disabled={isDownloading || !data || data.length <= 1 || remainingRequests <= 0}
//                   fullWidth
//                 >
//                   {isDownloading && downloadOption === 'all' ? <CircularProgress size={24} /> : 'すべての画像をダウンロード'}
//                 </Button>
//               </Tooltip>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Grid>

//       <FormControlLabel
//         control={
//           <Switch
//             checked={useFolderDownload}
//             onChange={(e) => setUseFolderDownload(e.target.checked)}
//           />
//         }
//         label="フォルダに直接ダウンロード (Chromeのみ対応)"
//         sx={{ mt: 2 }}
//       />

//       <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
//         1日のリクエスト残数: {remainingRequests} / {MAX_REQUESTS_PER_DAY}
//       </Typography>

//       {isDownloading && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="body2" color="text.secondary" gutterBottom>
//             進行状況: {progress}%
//           </Typography>
//           <LinearProgress variant="determinate" value={progress} sx={{ mb: 1 }} />
//           <Typography variant="body2" color="text.secondary">
//             ダウンロード速度: {downloadSpeed.toFixed(2)} images/second
//           </Typography>
//           <Typography variant="body2" color="text.secondary">
//             残り時間の推定: {formatTimeRemaining(estimatedTimeRemaining)}
//           </Typography>
//         </Box>
//       )}

//       {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}

//       <Paper elevation={3} sx={{ mt: 3, p: 2 }}>
//         <Typography variant="body2" color="text.secondary">
//           正常にダウンロードされた画像: {successCount}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           ダウンロードに失敗した画像: {failCount}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           現在のバッチサイズ: {batchSize}
//         </Typography>
//       </Paper>

//       <Snackbar
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//         open={openSnackbar}
//         autoHideDuration={6000}
//         onClose={handleCloseSnackbar}
//       >
//         <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>

//       <Dialog
//         open={openConfirmDialog}
//         onClose={handleCloseConfirmDialog}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">{"確認"}</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             {`${startRow}行目から${endRow}行目までの${downloadOption === 'first' ? '1枚目の画像を' : 'すべての画像を'}ダウンロードします。よろしいですか？`}
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseConfirmDialog} color="primary">
//             キャンセル
//           </Button>
//           <Button onClick={handleDownload} color="primary" autoFocus>
//             ダウンロード
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog
//         open={openSettingsDialog}
//         onClose={handleCloseSettingsDialog}
//         aria-labelledby="settings-dialog-title"
//       >
//         <DialogTitle id="settings-dialog-title">ダウンロード設定</DialogTitle>
//         <DialogContent>
//           <FormControlLabel
//             control={
//               <Switch
//                 checked={autoAdjustBatchSize}
//                 onChange={(e) => setAutoAdjustBatchSize(e.target.checked)}
//               />
//             }
//             label="バッチサイズの自動調整"
//           />
//           <Box sx={{ mt: 2 }}>
//             <Typography id="batch-size-slider" gutterBottom>
//               バッチサイズ: {batchSize}
//             </Typography>
//             <Slider
//               value={batchSize}
//               onChange={(_, newValue) => setBatchSize(newValue)}
//               aria-labelledby="batch-size-slider"
//               valueLabelDisplay="auto"
//               step={1}
//               marks
//               min={MIN_BATCH_SIZE}
//               max={MAX_BATCH_SIZE}
//               disabled={autoAdjustBatchSize}
//             />
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseSettingsDialog} color="primary">
//             閉じる
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// // 残り時間をフォーマットするヘルパー関数
// function formatTimeRemaining(seconds) {
//   if (seconds < 60) {
//     return `${Math.round(seconds)}秒`;
//   } else if (seconds < 3600) {
//     return `${Math.round(seconds / 60)}分`;
//   } else {
//     const hours = Math.floor(seconds / 3600);
//     const minutes = Math.round((seconds % 3600) / 60);
//     return `${hours}時間${minutes}分`;
//   }
// }

// export default ImageDownloader;




// // ImageDownloader.js   ZIPファイル版

// import React, { useState, useCallback, useEffect, useRef } from 'react';
// import { 
//   Button, 
//   TextField, 
//   Typography, 
//   Box, 
//   CircularProgress, 
//   Alert, 
//   Snackbar,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   Tooltip,
//   LinearProgress,
//   Slider,
//   FormControlLabel,
//   Switch,
//   Paper,
//   Grid
// } from '@mui/material';
// import axios from 'axios';
// import JSZip from 'jszip';
// import { saveAs } from 'file-saver';
// import { useTheme } from '@mui/material/styles';

// const INITIAL_BATCH_SIZE = 5;
// const MAX_BATCH_SIZE = 20;
// const MIN_BATCH_SIZE = 1;
// const RATE_LIMIT_PERIOD = 24 * 60 * 60 * 1000; // 24時間
// const MAX_REQUESTS_PER_DAY = 500;
// const MAX_RETRIES = 5;
// const INITIAL_RETRY_DELAY = 1000;

// const workers = [
//   'https://broken-glitter-2eca.kikuchi-shun.workers.dev/',
//   'https://bold-block-5adc.kikuchi-shun.workers.dev/',
//   'https://broken-fire-195b.kikuchi-shun.workers.dev/'
// ];

// const userAgents = [
//   'Mozilla/5.0 (Windows NT 10.0; Win64; x64)...',
//   // 他のユーザーエージェント文字列
// ];

// function getRandomWorker() {
//   return workers[Math.floor(Math.random() * workers.length)];
// }

// function getRandomUserAgent() {
//   return userAgents[Math.floor(Math.random() * userAgents.length)];
// }

// const imageCache = new Map();

// function checkRateLimit() {
//   const now = Date.now();
//   const requests = JSON.parse(localStorage.getItem('rateLimitRequests') || '[]');
//   const validRequests = requests.filter(time => now - time < RATE_LIMIT_PERIOD);
//   if (validRequests.length >= MAX_REQUESTS_PER_DAY) {
//     return false;
//   }
//   validRequests.push(now);
//   localStorage.setItem('rateLimitRequests', JSON.stringify(validRequests));
//   return true;
// }

// function getRemainingRequests() {
//   const now = Date.now();
//   const requests = JSON.parse(localStorage.getItem('rateLimitRequests') || '[]');
//   const validRequests = requests.filter(time => now - time < RATE_LIMIT_PERIOD);
//   return MAX_REQUESTS_PER_DAY - validRequests.length;
// }

// async function download(url, retryCount = 0, retryDelay = INITIAL_RETRY_DELAY) {
//   if (imageCache.has(url)) {
//     return imageCache.get(url);
//   }

//   if (!checkRateLimit()) {
//     throw new Error('1日のリクエスト上限を超えました');
//   }

//   const workerUrl = getRandomWorker();
//   const proxyUrl = `${workerUrl}/?url=${encodeURIComponent(url)}`;
  
//   try {
//     const response = await axios.get(proxyUrl, {
//       responseType: 'arraybuffer',
//       timeout: 30000,
//       headers: {
//         'User-Agent': getRandomUserAgent(),
//         'Referer': 'https://www.rakuten.co.jp/'
//       }
//     });
//     const data = response.data;
//     imageCache.set(url, data);
//     return data;
//   } catch (error) {
//     if (retryCount < MAX_RETRIES) {
//       await new Promise(resolve => setTimeout(resolve, retryDelay));
//       return download(url, retryCount + 1, retryDelay * 2);
//     }
//     throw error;
//   }
// }

// function ImageDownloader({ spreadsheetId, sheetName, token, data }) {
//   const [startRow, setStartRow] = useState(1);
//   const [endRow, setEndRow] = useState(150);
//   const [isDownloading, setIsDownloading] = useState(false);
//   const [error, setError] = useState('');
//   const [progress, setProgress] = useState(0);
//   const [successCount, setSuccessCount] = useState(0);
//   const [failCount, setFailCount] = useState(0);
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
//   const [batchSize, setBatchSize] = useState(INITIAL_BATCH_SIZE);
//   const [autoAdjustBatchSize, setAutoAdjustBatchSize] = useState(true);
//   const [downloadSpeed, setDownloadSpeed] = useState(0);
//   const [estimatedTimeRemaining, setEstimatedTimeRemaining] = useState(0);
//   const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
//   const [remainingRequests, setRemainingRequests] = useState(getRemainingRequests());
//   const [downloadOption, setDownloadOption] = useState('first'); // 'first' or 'all'

//   const theme = useTheme();

//   const zipRef = useRef(new JSZip());
//   const imageFolderRef = useRef(null);
//   const startTimeRef = useRef(null);

//   useEffect(() => {
//     if (data && data.length > 1) {
//       setStartRow(1);
//       setEndRow(Math.min(startRow + 149, data.length - 1));
//     }
//   }, [data]);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setRemainingRequests(getRemainingRequests());
//     }, 60000); // 1分ごとに更新

//     return () => clearInterval(interval);
//   }, []);

//   const calculateMaxEndRow = () => {
//     if (data && data.length > 1) {
//       return Math.min(startRow + 149, data.length - 1);
//     } else {
//       return startRow + 149;
//     }
//   };

//   const handleStartRowChange = (event) => {
//     const value = parseInt(event.target.value);
//     setStartRow(value);

//     // endRowを新しいstartRowに基づいて調整
//     const maxEndRow = calculateMaxEndRow();
//     if (endRow < value || endRow > maxEndRow || isNaN(endRow)) {
//       setEndRow(maxEndRow);
//     }
//   };

//   const handleEndRowChange = (event) => {
//     const value = parseInt(event.target.value);
//     const maxEndRow = calculateMaxEndRow();
//     setEndRow(Math.min(Math.max(value, startRow), maxEndRow));
//   };

//   const showSnackbar = (message) => {
//     setSnackbarMessage(message);
//     setOpenSnackbar(true);
//   };

//   const handleCloseSnackbar = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     setOpenSnackbar(false);
//   };

//   const handleDownloadConfirmation = (option) => {
//     setDownloadOption(option);
//     setOpenConfirmDialog(true);
//   };

//   const handleCloseConfirmDialog = () => {
//     setOpenConfirmDialog(false);
//   };

//   const handleOpenSettingsDialog = () => {
//     setOpenSettingsDialog(true);
//   };

//   const handleCloseSettingsDialog = () => {
//     setOpenSettingsDialog(false);
//   };

//   const adjustBatchSize = (successRate) => {
//     if (autoAdjustBatchSize) {
//       if (successRate > 0.95 && batchSize < MAX_BATCH_SIZE) {
//         setBatchSize(prev => prev + 1);
//       } else if (successRate < 0.8 && batchSize > MIN_BATCH_SIZE) {
//         setBatchSize(prev => Math.max(prev - 2, MIN_BATCH_SIZE));
//       } else if (successRate < 0.6 && batchSize > MIN_BATCH_SIZE) {
//         setBatchSize(prev => Math.max(prev - 3, MIN_BATCH_SIZE));
//       }
//     }
//   };

//   const processBatch = async (images, startIndex) => {
//     const batch = images.slice(startIndex, startIndex + batchSize);
//     const results = await Promise.all(batch.map(async (imageObj, index) => {
//       try {
//         const imageData = await download(imageObj.url);
//         setRemainingRequests(getRemainingRequests());
//         const fileName = `image_row${imageObj.rowIndex}_img${imageObj.imageIndex}.jpg`;
//         return {
//           success: true,
//           data: imageData,
//           fileName,
//           url: imageObj.url,
//           imageObj,
//         };
//       } catch (err) {
//         console.error(`画像のダウンロードに失敗しました ${imageObj.url}:`, err);
//         return {
//           success: false,
//           url: imageObj.url,
//           error: err.message || 'Unknown error',
//           imageObj,
//         };
//       }
//     }));

//     const successfulDownloads = results.filter(r => r.success).length;
//     const successRate = successfulDownloads / batch.length;

//     adjustBatchSize(successRate);

//     return results;
//   };

//   const updateDownloadStats = (downloadedCount, totalCount, elapsedTime) => {
//     const speed = downloadedCount / (elapsedTime / 1000);
//     setDownloadSpeed(speed);

//     const remainingCount = totalCount - downloadedCount;
//     const estimatedTime = remainingCount / speed;
//     setEstimatedTimeRemaining(estimatedTime);
//   };

//   const handleDownload = useCallback(async () => {
//     setIsDownloading(true);
//     setError('');
//     setProgress(0);
//     setSuccessCount(0);
//     setFailCount(0);
//     startTimeRef.current = Date.now();
//     zipRef.current = new JSZip(); // ZIPファイルをリセット
//     imageFolderRef.current = zipRef.current.folder('images');

//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error('Start RowとEnd Rowは有効な数字である必要があります。');
//       }

//       const picUrlIndex = data[0].findIndex(header => header.toLowerCase() === 'picurl');
//       if (picUrlIndex === -1) {
//         throw new Error('PicURLカラムが見つかりません。');
//       }

//       const actualStartRow = parseInt(startRow);
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow), maxEndRow);

//       if (actualStartRow < 1 || actualEndRow < actualStartRow) {
//         throw new Error('Start RowとEnd Rowが有効ではありません。');
//       }

//       let imagesToDownload = [];

//       if (downloadOption === 'first') {
//         // 1枚目の画像のみを取得
//         imagesToDownload = data.slice(actualStartRow, actualEndRow + 1).map((row, index) => {
//           const imageUrl = row[picUrlIndex]?.split('|')[0];
//           return {
//             url: imageUrl,
//             rowIndex: actualStartRow + index,
//             imageIndex: 1,
//           };
//         });
//       } else {
//         // すべての画像を取得
//         imagesToDownload = data.slice(actualStartRow, actualEndRow + 1).flatMap((row, index) => {
//           const imageUrls = row[picUrlIndex]?.split('|') || [];
//           return imageUrls.map((url, imgIndex) => ({
//             url,
//             rowIndex: actualStartRow + index,
//             imageIndex: imgIndex + 1,
//           }));
//         });
//       }

//       let failedImages = [];
//       for (let i = 0; i < imagesToDownload.length; i += batchSize) {
//         const batchResults = await processBatch(imagesToDownload, i);
//         batchResults.forEach(result => {
//           if (result.success) {
//             imageFolderRef.current.file(result.fileName, result.data);
//             setSuccessCount(prev => prev + 1);
//           } else {
//             setFailCount(prev => prev + 1);
//             failedImages.push(result.imageObj);
//           }
//         });

//         const totalProgress = Math.min(i + batchSize, imagesToDownload.length);
//         setProgress(Math.round((totalProgress / imagesToDownload.length) * 100));

//         const elapsedTime = Date.now() - startTimeRef.current;
//         updateDownloadStats(totalProgress, imagesToDownload.length, elapsedTime);

//         setRemainingRequests(getRemainingRequests());
//       }

//       // 失敗した画像のリトライ
//       if (failedImages.length > 0) {
//         const retryResults = await Promise.all(failedImages.map(async imageObj => {
//           try {
//             const imageData = await download(imageObj.url, 0, INITIAL_RETRY_DELAY * 2);
//             setSuccessCount(prev => prev + 1);
//             setFailCount(prev => prev - 1);
//             setRemainingRequests(getRemainingRequests());
//             const fileName = `image_row${imageObj.rowIndex}_img${imageObj.imageIndex}.jpg`;
//             return { success: true, data: imageData, fileName, url: imageObj.url };
//           } catch (err) {
//             console.error(`リトライに失敗しました ${imageObj.url}:`, err);
//             return { success: false, url: imageObj.url, error: err.message || 'Unknown error' };
//           }
//         }));

//         retryResults.forEach(result => {
//           if (result.success) {
//             imageFolderRef.current.file(result.fileName, result.data);
//           }
//         });
//       }

//       // ZIPファイルを生成して保存
//       const content = await zipRef.current.generateAsync({ type: 'blob' });
//       saveAs(content, `images_${actualStartRow}_to_${actualEndRow}.zip`);

//       const finalMessage = `ダウンロードが完了しました。${successCount}枚の画像が正常にダウンロードされ、${failCount}枚が失敗しました。`;
//       showSnackbar(finalMessage);
//     } catch (err) {
//       const errorMessage = `ダウンロードに失敗しました: ${err.message}`;
//       setError(errorMessage);
//       showSnackbar(errorMessage);
//       console.error(errorMessage, err);
//     } finally {
//       setIsDownloading(false);
//       setOpenConfirmDialog(false);
//     }
//   }, [data, startRow, endRow, batchSize, downloadOption]);

//   return (
//     <Box sx={{ mt: 0, p: 1, backgroundColor: theme.palette.background.paper, borderRadius: theme.shape.borderRadius }}>
//       <Typography variant="h6" gutterBottom>画像ダウンロード</Typography>
//       <Typography variant="body2" sx={{ mb: 2 }}>
//         150商品以上ある場合は、StartRowを151にしてEndRowを調整してください。
//         一度に最大150行まで処理できます。※ダウンロードするとzipファイルができます。
//       </Typography>
//       <Grid container spacing={2} alignItems="center">
//         <Grid item xs={12} sm={3}>
//           <TextField
//             label="Start Row"
//             type="number"
//             value={startRow}
//             onChange={handleStartRowChange}
//             fullWidth
//             InputProps={{ inputProps: { min: 1 } }}
//           />
//         </Grid>
//         <Grid item xs={12} sm={3}>
//           <TextField
//             label="End Row"
//             type="number"
//             value={endRow}
//             onChange={handleEndRowChange}
//             fullWidth
//             InputProps={{ inputProps: { min: startRow, max: calculateMaxEndRow() } }}
//           />
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           <Grid container spacing={1}>
//             <Grid item xs={12} sm={6}>
//               <Tooltip title="指定した範囲の商品の1枚目の画像をダウンロード">
//                 <Button
//                   variant="contained"
//                   onClick={() => handleDownloadConfirmation('first')}
//                   disabled={isDownloading || !data || data.length <= 1 || remainingRequests <= 0}
//                   fullWidth
//                 >
//                   {isDownloading && downloadOption === 'first' ? <CircularProgress size={24} /> : '1枚目のみダウンロード'}
//                 </Button>
//               </Tooltip>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <Tooltip title="指定した範囲の商品のすべての画像をダウンロード">
//                 <Button
//                   variant="contained"
//                   color="secondary"
//                   onClick={() => handleDownloadConfirmation('all')}
//                   disabled={isDownloading || !data || data.length <= 1 || remainingRequests <= 0}
//                   fullWidth
//                 >
//                   {isDownloading && downloadOption === 'all' ? <CircularProgress size={24} /> : 'すべての画像をダウンロード'}
//                 </Button>
//               </Tooltip>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Grid>

//       <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
//         1日のリクエスト残数: {remainingRequests} / {MAX_REQUESTS_PER_DAY} <br />
//         ※1日のリクエスト使用回数
//       </Typography>

//       {isDownloading && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="body2" color="text.secondary" gutterBottom>
//             進行状況: {progress}%
//           </Typography>
//           <LinearProgress variant="determinate" value={progress} sx={{ mb: 1 }} />
//           <Typography variant="body2" color="text.secondary">
//             ダウンロード速度: {downloadSpeed.toFixed(2)} images/second
//           </Typography>
//           <Typography variant="body2" color="text.secondary">
//             残り時間の推定: {formatTimeRemaining(estimatedTimeRemaining)}
//           </Typography>
//         </Box>
//       )}

//       {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}

//       <Paper elevation={3} sx={{ mt: 3, p: 2 }}>
//         <Typography variant="body2" color="text.secondary">
//           正常にダウンロードされた画像: {successCount}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           ダウンロードに失敗した画像: {failCount}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           現在のバッチサイズ: {batchSize}
//         </Typography>
//       </Paper>

//       <Snackbar
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//         open={openSnackbar}
//         autoHideDuration={6000}
//         onClose={handleCloseSnackbar}
//       >
//         <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>

//       <Dialog
//         open={openConfirmDialog}
//         onClose={handleCloseConfirmDialog}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">{"確認"}</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             {`${startRow}行目から${endRow}行目までの${downloadOption === 'first' ? '1枚目の画像を' : 'すべての画像を'}ダウンロードします。よろしいですか？`}
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseConfirmDialog} color="primary">
//             キャンセル
//           </Button>
//           <Button onClick={handleDownload} color="primary" autoFocus>
//             ダウンロード
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog
//         open={openSettingsDialog}
//         onClose={handleCloseSettingsDialog}
//         aria-labelledby="settings-dialog-title"
//       >
//         <DialogTitle id="settings-dialog-title">ダウンロード設定</DialogTitle>
//         <DialogContent>
//           <FormControlLabel
//             control={
//               <Switch
//                 checked={autoAdjustBatchSize}
//                 onChange={(e) => setAutoAdjustBatchSize(e.target.checked)}
//               />
//             }
//             label="バッチサイズの自動調整"
//           />
//           <Box sx={{ mt: 2 }}>
//             <Typography id="batch-size-slider" gutterBottom>
//               バッチサイズ: {batchSize}
//             </Typography>
//             <Slider
//               value={batchSize}
//               onChange={(_, newValue) => setBatchSize(newValue)}
//               aria-labelledby="batch-size-slider"
//               valueLabelDisplay="auto"
//               step={1}
//               marks
//               min={MIN_BATCH_SIZE}
//               max={MAX_BATCH_SIZE}
//               disabled={autoAdjustBatchSize}
//             />
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseSettingsDialog} color="primary">
//             閉じる
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// // 残り時間をフォーマットするヘルパー関数
// function formatTimeRemaining(seconds) {
//   if (seconds < 60) {
//     return `${Math.round(seconds)}秒`;
//   } else if (seconds < 3600) {
//     return `${Math.round(seconds / 60)}分`;
//   } else {
//     const hours = Math.floor(seconds / 3600);
//     const minutes = Math.round((seconds % 3600) / 60);
//     return `${hours}時間${minutes}分`;
//   }
// }

// export default ImageDownloader;






//現状安定版

// import React, { useState, useCallback, useEffect, useRef } from 'react';
// import { 
//   Button, 
//   TextField, 
//   Typography, 
//   Box, 
//   CircularProgress, 
//   Alert, 
//   Snackbar,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   Tooltip,
//   LinearProgress,
//   Slider,
//   FormControlLabel,
//   Switch,
//   Paper,
//   Grid
// } from '@mui/material';
// import axios from 'axios';
// import JSZip from 'jszip';
// import { saveAs } from 'file-saver';
// import { useTheme } from '@mui/material/styles';

// const INITIAL_BATCH_SIZE = 5;
// const MAX_BATCH_SIZE = 20;
// const MIN_BATCH_SIZE = 1;
// const RATE_LIMIT_PERIOD = 24 * 60 * 60 * 1000; // 24 hours
// const MAX_REQUESTS_PER_DAY = 500;
// const MAX_RETRIES = 5;
// const INITIAL_RETRY_DELAY = 1000;

// const workers = [
//   'https://broken-glitter-2eca.kikuchi-shun.workers.dev/',
//   'https://bold-block-5adc.kikuchi-shun.workers.dev/',
//   'https://broken-fire-195b.kikuchi-shun.workers.dev/'
// ];

// const userAgents = [
//   'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36',
//   'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.1.1 Safari/605.1.15',
//   'Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:89.0) Gecko/20100101 Firefox/89.0'
// ];

// function getRandomWorker() {
//   return workers[Math.floor(Math.random() * workers.length)];
// }

// function getRandomUserAgent() {
//   return userAgents[Math.floor(Math.random() * userAgents.length)];
// }

// const imageCache = new Map();

// function checkRateLimit() {
//   const now = Date.now();
//   const requests = JSON.parse(localStorage.getItem('rateLimitRequests') || '[]');
//   const validRequests = requests.filter(time => now - time < RATE_LIMIT_PERIOD);
//   if (validRequests.length >= MAX_REQUESTS_PER_DAY) {
//     return false;
//   }
//   validRequests.push(now);
//   localStorage.setItem('rateLimitRequests', JSON.stringify(validRequests));
//   return true;
// }

// function getRemainingRequests() {
//   const now = Date.now();
//   const requests = JSON.parse(localStorage.getItem('rateLimitRequests') || '[]');
//   const validRequests = requests.filter(time => now - time < RATE_LIMIT_PERIOD);
//   return MAX_REQUESTS_PER_DAY - validRequests.length;
// }

// async function download(url, retryCount = 0, retryDelay = INITIAL_RETRY_DELAY) {
//   if (imageCache.has(url)) {
//     return imageCache.get(url);
//   }

//   if (!checkRateLimit()) {
//     throw new Error('Daily rate limit exceeded');
//   }

//   const workerUrl = getRandomWorker();
//   const proxyUrl = `${workerUrl}/?url=${encodeURIComponent(url)}`;
  
//   try {
//     const response = await axios.get(proxyUrl, {
//       responseType: 'arraybuffer',
//       timeout: 30000,
//       headers: {
//         'User-Agent': getRandomUserAgent(),
//         'Referer': 'https://www.rakuten.co.jp/'
//       }
//     });
//     const data = response.data;
//     imageCache.set(url, data);
//     return data;
//   } catch (error) {
//     if (retryCount < MAX_RETRIES) {
//       await new Promise(resolve => setTimeout(resolve, retryDelay));
//       return download(url, retryCount + 1, retryDelay * 2);
//     }
//     throw error;
//   }
// }

// function ImageDownloader({ spreadsheetId, sheetName, token, data }) {
//   const [startRow, setStartRow] = useState(1);
//   const [endRow, setEndRow] = useState(150);
//   const [isDownloading, setIsDownloading] = useState(false);
//   const [error, setError] = useState('');
//   const [progress, setProgress] = useState(0);
//   const [successCount, setSuccessCount] = useState(0);
//   const [failCount, setFailCount] = useState(0);
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
//   const [batchSize, setBatchSize] = useState(INITIAL_BATCH_SIZE);
//   const [autoAdjustBatchSize, setAutoAdjustBatchSize] = useState(true);
//   const [downloadSpeed, setDownloadSpeed] = useState(0);
//   const [estimatedTimeRemaining, setEstimatedTimeRemaining] = useState(0);
//   const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
//   const [remainingRequests, setRemainingRequests] = useState(getRemainingRequests());
//   const theme = useTheme();

//   const zipRef = useRef(new JSZip());
//   const imageFolderRef = useRef(null);
//   const startTimeRef = useRef(null);

//   useEffect(() => {
//     if (data && data.length > 1) {
//       setStartRow(1);
//       setEndRow(Math.min(startRow + 149, data.length - 1));
//     }
//   }, [data]);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setRemainingRequests(getRemainingRequests());
//     }, 60000); // Update every minute

//     return () => clearInterval(interval);
//   }, []);

//   const calculateMaxEndRow = () => {
//     if (data && data.length > 1) {
//       return Math.min(startRow + 149, data.length - 1);
//     } else {
//       return startRow + 149;
//     }
//   };

//   const handleStartRowChange = (event) => {
//     const value = parseInt(event.target.value);
//     setStartRow(value);

//     // Adjust endRow based on the new startRow
//     const maxEndRow = calculateMaxEndRow();
//     if (endRow < value || endRow > maxEndRow || isNaN(endRow)) {
//       setEndRow(maxEndRow);
//     }
//   };

//   const handleEndRowChange = (event) => {
//     const value = parseInt(event.target.value);
//     const maxEndRow = calculateMaxEndRow();
//     setEndRow(Math.min(Math.max(value, startRow), maxEndRow));
//   };

//   const showSnackbar = (message) => {
//     setSnackbarMessage(message);
//     setOpenSnackbar(true);
//   };

//   const handleCloseSnackbar = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     setOpenSnackbar(false);
//   };

//   const handleDownloadConfirmation = () => {
//     setOpenConfirmDialog(true);
//   };

//   const handleCloseConfirmDialog = () => {
//     setOpenConfirmDialog(false);
//   };

//   const handleOpenSettingsDialog = () => {
//     setOpenSettingsDialog(true);
//   };

//   const handleCloseSettingsDialog = () => {
//     setOpenSettingsDialog(false);
//   };

//   const adjustBatchSize = (successRate) => {
//     if (autoAdjustBatchSize) {
//       if (successRate > 0.95 && batchSize < MAX_BATCH_SIZE) {
//         setBatchSize(prev => prev + 1);
//       } else if (successRate < 0.8 && batchSize > MIN_BATCH_SIZE) {
//         setBatchSize(prev => Math.max(prev - 2, MIN_BATCH_SIZE));
//       } else if (successRate < 0.6 && batchSize > MIN_BATCH_SIZE) {
//         setBatchSize(prev => Math.max(prev - 3, MIN_BATCH_SIZE));
//       }
//     }
//   };

//   const processBatch = async (images, startIndex, globalStartRow) => {
//     const batch = images.slice(startIndex, startIndex + batchSize);
//     const results = await Promise.all(batch.map(async (imageUrl, index) => {
//       try {
//         const imageData = await download(imageUrl);
//         setRemainingRequests(getRemainingRequests());
//         return {
//           success: true,
//           data: imageData,
//           fileName: `image_${globalStartRow + startIndex + index + 1}.jpg`,
//           url: imageUrl
//         };
//       } catch (err) {
//         console.error(`Failed to download image ${imageUrl}:`, err);
//         return {
//           success: false,
//           url: imageUrl,
//           error: err.message || 'Unknown error'
//         };
//       }
//     }));

//     const successfulDownloads = results.filter(r => r.success).length;
//     const successRate = successfulDownloads / batch.length;

//     adjustBatchSize(successRate);

//     return results;
//   };

//   const updateDownloadStats = (downloadedCount, totalCount, elapsedTime) => {
//     const speed = downloadedCount / (elapsedTime / 1000);
//     setDownloadSpeed(speed);

//     const remainingCount = totalCount - downloadedCount;
//     const estimatedTime = remainingCount / speed;
//     setEstimatedTimeRemaining(estimatedTime);
//   };

//   const handleDownload = useCallback(async () => {
//     setIsDownloading(true);
//     setError('');
//     setProgress(0);
//     setSuccessCount(0);
//     setFailCount(0);
//     startTimeRef.current = Date.now();
//     zipRef.current = new JSZip(); // Reset zip file
//     imageFolderRef.current = zipRef.current.folder('images');

//     try {
//       if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
//         throw new Error('Start Row and End Row must be valid numbers.');
//       }

//       const picUrlIndex = data[0].findIndex(header => header.toLowerCase() === 'picurl');
//       if (picUrlIndex === -1) {
//         throw new Error('PicURL column not found');
//       }

//       const actualStartRow = parseInt(startRow);
//       const maxEndRow = calculateMaxEndRow();
//       const actualEndRow = Math.min(parseInt(endRow), maxEndRow);

//       if (actualStartRow < 1 || actualEndRow < actualStartRow) {
//         throw new Error('Start Row and End Row are not valid.');
//       }

//       const imagesToDownload = data.slice(actualStartRow, actualEndRow + 1).map(row => row[picUrlIndex]?.split('|')[0]);

//       let failedImages = [];
//       for (let i = 0; i < imagesToDownload.length; i += batchSize) {
//         const batchResults = await processBatch(imagesToDownload, i, actualStartRow - 1);
//         batchResults.forEach(result => {
//           if (result.success) {
//             imageFolderRef.current.file(result.fileName, result.data);
//             setSuccessCount(prev => prev + 1);
//           } else {
//             setFailCount(prev => prev + 1);
//             failedImages.push(result.url);
//           }
//         });

//         const totalProgress = Math.min(i + batchSize, imagesToDownload.length);
//         setProgress(Math.round((totalProgress / imagesToDownload.length) * 100));

//         const elapsedTime = Date.now() - startTimeRef.current;
//         updateDownloadStats(totalProgress, imagesToDownload.length, elapsedTime);

//         setRemainingRequests(getRemainingRequests());
//       }

//       // Retry failed images
//       if (failedImages.length > 0) {
//         const retryResults = await Promise.all(failedImages.map(async url => {
//           try {
//             const imageData = await download(url, 0, INITIAL_RETRY_DELAY * 2);
//             setSuccessCount(prev => prev + 1);
//             setFailCount(prev => prev - 1);
//             setRemainingRequests(getRemainingRequests());
//             return { success: true, data: imageData, fileName: `retry_${url.split('/').pop()}`, url };
//           } catch (err) {
//             console.error(`Retry failed for ${url}:`, err);
//             return { success: false, url, error: err.message || 'Unknown error' };
//           }
//         }));

//         retryResults.forEach(result => {
//           if (result.success) {
//             imageFolderRef.current.file(result.fileName, result.data);
//           }
//         });
//       }

//       // Generate ZIP file
//       const content = await zipRef.current.generateAsync({ type: 'blob' });
//       saveAs(content, `images_${actualStartRow}_to_${actualEndRow}.zip`);

//       const finalMessage = `Download completed. ${successCount} images downloaded successfully, ${failCount} failed.`;
//       showSnackbar(finalMessage);
//     } catch (err) {
//       const errorMessage = `Download failed: ${err.message}`;
//       setError(errorMessage);
//       showSnackbar(errorMessage);
//       console.error(errorMessage, err);
//     } finally {
//       setIsDownloading(false);
//       setOpenConfirmDialog(false);
//     }
//   }, [data, startRow, endRow, batchSize]);

//   return (
//     <Box sx={{ mt: 0, p: 1, backgroundColor: theme.palette.background.paper, borderRadius: theme.shape.borderRadius }}>
//       <Typography variant="h6" gutterBottom>Image Downloader</Typography>
//       <Typography variant="body2" sx={{ mb: 2 }}>
//         150商品以上ある場合は、StartRowを151にしてEndRowを調整してください。
//         一度に最大150行まで処理できます。※ダウンロードするとzipファイルができます。
//       </Typography>
//       <Grid container spacing={2} alignItems="center">
//         <Grid item xs={12} sm={3}>
//           <TextField
//             label="Start Row"
//             type="number"
//             value={startRow}
//             onChange={handleStartRowChange}
//             fullWidth
//             InputProps={{ inputProps: { min: 1 } }}
//           />
//         </Grid>
//         <Grid item xs={12} sm={3}>
//           <TextField
//             label="End Row"
//             type="number"
//             value={endRow}
//             onChange={handleEndRowChange}
//             fullWidth
//             InputProps={{ inputProps: { min: startRow, max: calculateMaxEndRow() } }}
//           />
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           <Tooltip title="Download images from the specified range">
//             <Button
//               variant="contained"
//               onClick={handleDownloadConfirmation}
//               disabled={isDownloading || !data || data.length <= 1 || remainingRequests <= 0}
//               fullWidth
//             >
//               {isDownloading ? <CircularProgress size={24} /> : '画像ダウンロード'}
//             </Button>
//           </Tooltip>
//         </Grid>
//       </Grid>

//       <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
//         Remaining daily requests: {remainingRequests} / {MAX_REQUESTS_PER_DAY} <br />
//         ※1日のリクエスト使用回数
//       </Typography>

//       {isDownloading && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="body2" color="text.secondary" gutterBottom>
//             Progress: {progress}%
//           </Typography>
//           <LinearProgress variant="determinate" value={progress} sx={{ mb: 1 }} />
//           <Typography variant="body2" color="text.secondary">
//             Download speed: {downloadSpeed.toFixed(2)} images/second
//           </Typography>
//           <Typography variant="body2" color="text.secondary">
//             Estimated time remaining: {formatTimeRemaining(estimatedTimeRemaining)}
//           </Typography>
//         </Box>
//       )}

//       {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}

//       <Paper elevation={3} sx={{ mt: 3, p: 2 }}>
//         <Typography variant="body2" color="text.secondary">
//           Successfully downloaded: {successCount}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           Failed downloads: {failCount}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           Current batch size: {batchSize}
//         </Typography>
//       </Paper>

//       <Snackbar
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//         open={openSnackbar}
//         autoHideDuration={6000}
//         onClose={handleCloseSnackbar}
//       >
//         <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>

//       <Dialog
//         open={openConfirmDialog}
//         onClose={handleCloseConfirmDialog}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">{"確認"}</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             {`${startRow}行目から${endRow}行目までの画像をダウンロードします。よろしいですか？`}
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseConfirmDialog} color="primary">
//             キャンセル
//           </Button>
//           <Button onClick={handleDownload} color="primary" autoFocus>
//             ダウンロード
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog
//         open={openSettingsDialog}
//         onClose={handleCloseSettingsDialog}
//         aria-labelledby="settings-dialog-title"
//       >
//         <DialogTitle id="settings-dialog-title">ダウンロード設定</DialogTitle>
//         <DialogContent>
//           <FormControlLabel
//             control={
//               <Switch
//                 checked={autoAdjustBatchSize}
//                 onChange={(e) => setAutoAdjustBatchSize(e.target.checked)}
//               />
//             }
//             label="バッチサイズの自動調整"
//           />
//           <Box sx={{ mt: 2 }}>
//             <Typography id="batch-size-slider" gutterBottom>
//               バッチサイズ: {batchSize}
//             </Typography>
//             <Slider
//               value={batchSize}
//               onChange={(_, newValue) => setBatchSize(newValue)}
//               aria-labelledby="batch-size-slider"
//               valueLabelDisplay="auto"
//               step={1}
//               marks
//               min={MIN_BATCH_SIZE}
//               max={MAX_BATCH_SIZE}
//               disabled={autoAdjustBatchSize}
//             />
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseSettingsDialog} color="primary">
//             閉じる
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// // Helper function: Format time remaining in a human-readable format
// function formatTimeRemaining(seconds) {
//   if (seconds < 60) {
//     return `${Math.round(seconds)}秒`;
//   } else if (seconds < 3600) {
//     return `${Math.round(seconds / 60)}分`;
//   } else {
//     const hours = Math.floor(seconds / 3600);
//     const minutes = Math.round((seconds % 3600) / 60);
//     return `${hours}時間${minutes}分`;
//   }
// }

// export default ImageDownloader;