// ImageUrlReplacer.js

import React, { useState } from 'react';
import {
  Button,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Paper,
  Checkbox,
  FormControlLabel,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

function ImageUrlReplacer({ spreadsheetId, sheetName, token, data, fetchData }) {
  const [startRow, setStartRow] = useState('');
  const [endRow, setEndRow] = useState('');
  const [newUrls, setNewUrls] = useState('');
  const [isReplacing, setIsReplacing] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [openPreview, setOpenPreview] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [enlargedImages, setEnlargedImages] = useState(null);
  const [skippedRows, setSkippedRows] = useState(new Set());

  // maxEndRow を計算する関数
  const calculateMaxEndRow = () => {
    if (data && data.length > 1) {
      return Math.min(parseInt(startRow) + 149, data.length - 1);
    } else {
      return parseInt(startRow) + 149;
    }
  };

  const handleStartRowChange = (e) => {
    const value = parseInt(e.target.value);
    setStartRow(value);

    // maxEndRow を再計算
    const newMaxEndRow = calculateMaxEndRow();
    if (endRow < value || endRow > newMaxEndRow || isNaN(endRow)) {
      setEndRow(newMaxEndRow);
    }
  };

  const handleEndRowChange = (e) => {
    const value = parseInt(e.target.value);
    const maxEndRow = calculateMaxEndRow();
    setEndRow(Math.min(Math.max(value, startRow), maxEndRow));
  };

  const generatePreviewData = () => {
    setError('');

    // 行範囲のバリデーション
    const isValidRowRange =
      startRow !== '' &&
      endRow !== '' &&
      !isNaN(startRow) &&
      !isNaN(endRow) &&
      endRow >= startRow &&
      data &&
      data.length > 1;
    if (!isValidRowRange) {
      setError('Start Row と End Row を正しく指定してください');
      return;
    }

    const picUrlIndex = data[0].findIndex(
      (header) => header.toLowerCase() === 'picurl'
    );
    if (picUrlIndex === -1) {
      setError('PicURL 列が見つかりません');
      return;
    }

    // 商品タイトルの列インデックスを取得
    const titleIndex = data[0].findIndex(
      (header) => header.toLowerCase() === 'title'
    );
    if (titleIndex === -1) {
      setError('Title 列が見つかりません');
      return;
    }

    const actualStartRow = parseInt(startRow);
    const actualEndRow = parseInt(endRow);
    let newUrlList = newUrls.split('\n').filter((url) => url.trim() !== '');

    // 画像番号とURLのマップを作成
    const urlMap = new Map();
    newUrlList.forEach((url) => {
      const match = url.match(/image-row(\d+)-img(\d+)/);
      if (match) {
        const rowIndex = parseInt(match[1]);
        const imgIndex = parseInt(match[2]);
        if (!urlMap.has(rowIndex)) {
          urlMap.set(rowIndex, []);
        }
        urlMap.get(rowIndex).push({ imgIndex, url });
      }
    });

    const previewItems = [];
    for (let i = actualStartRow; i <= actualEndRow; i++) {
      const dataRow = data[i];
      if (!dataRow) continue; // データがない行はスキップ

      const title = dataRow[titleIndex] || '';
      const oldUrls = dataRow[picUrlIndex]?.split('|') || [];

      if (urlMap.has(i)) {
        const newImages = urlMap.get(i);
        // oldUrlsのコピーを作成
        const updatedUrls = [...oldUrls];

        newImages.forEach(({ imgIndex, url }) => {
          updatedUrls[imgIndex - 1] = url; // imgIndexは1から始まる
        });

        previewItems.push({
          rowIndex: i,
          title,
          oldUrls,
          updatedUrls,
        });
      }
    }

    if (previewItems.length === 0) {
      setError('選択した行に一致するURLが見つかりません');
      return;
    }

    setPreviewData(previewItems);
    setOpenPreview(true);
  };

  const handleReplace = async () => {
    setIsReplacing(true);
    setError('');
    setSuccess('');

    try {
      // 行範囲のバリデーション
      const isValidRowRange =
        startRow !== '' &&
        endRow !== '' &&
        !isNaN(startRow) &&
        !isNaN(endRow) &&
        endRow >= startRow &&
        data &&
        data.length > 1;
      if (!isValidRowRange) {
        throw new Error('Start Row と End Row を正しく指定してください');
      }

      const picUrlIndex = data[0].findIndex(
        (header) => header.toLowerCase() === 'picurl'
      );
      if (picUrlIndex === -1) {
        throw new Error('PicURL 列が見つかりません');
      }

      const actualStartRow = parseInt(startRow);
      const actualEndRow = parseInt(endRow);
      let newUrlList = newUrls.split('\n').filter((url) => url.trim() !== '');

      // 画像番号とURLのマップを作成
      const urlMap = new Map();
      newUrlList.forEach((url) => {
        const match = url.match(/image-row(\d+)-img(\d+)/);
        if (match) {
          const rowIndex = parseInt(match[1]);
          const imgIndex = parseInt(match[2]);
          if (!urlMap.has(rowIndex)) {
            urlMap.set(rowIndex, []);
          }
          urlMap.get(rowIndex).push({ imgIndex, url });
        }
      });

      const batchUpdates = [];
      const picUrlColumn = String.fromCharCode(65 + picUrlIndex);

      for (let i = actualStartRow; i <= actualEndRow; i++) {
        if (!skippedRows.has(i)) {
          const dataRow = data[i];
          if (!dataRow) continue; // データがない行はスキップ

          if (urlMap.has(i)) {
            const currentUrls = dataRow[picUrlIndex]?.split('|') || [];
            const newImages = urlMap.get(i);

            newImages.forEach(({ imgIndex, url }) => {
              currentUrls[imgIndex - 1] = url; // imgIndexは1から始まる
            });

            batchUpdates.push({
              range: `${sheetName}!${picUrlColumn}${i + 1}`,
              values: [[currentUrls.join('|')]],
            });
          }
        }
      }

      if (batchUpdates.length === 0) {
        throw new Error('選択した行に一致するURLが見つかりません');
      }

      // 複数のセルを一括更新
      await axios.post(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values:batchUpdate`,
        {
          valueInputOption: 'RAW',
          data: batchUpdates,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setSuccess('URLの置換が成功しました');
      fetchData();
    } catch (err) {
      console.error('エラー:', err);
      if (err.response) {
        setError(
          `置換に失敗しました: ${err.response.data.error.message || err.message}`
        );
      } else {
        setError(`置換に失敗しました: ${err.message}`);
      }
    } finally {
      setIsReplacing(false);
    }
  };

  const handleImageClick = (oldUrl, newUrl) => {
    setEnlargedImages({ oldUrl, newUrl });
  };

  const handleSkipToggle = (rowIndex) => {
    setSkippedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(rowIndex)) {
        newSet.delete(rowIndex);
      } else {
        newSet.add(rowIndex);
      }
      return newSet;
    });
  };

  return (
    <Box sx={{ mt: 0 }}>
      <Typography variant="h6">画像URLの一括変更</Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        画像URLリンクをそのまま貼り付けてください。URLの "image-rowX-imgY" のXが行番号、Yが画像番号と一致する画像が変更されます。
        一度に最大150行まで処理できます。
      </Typography>
      <TextField
        label="Start Row"
        type="number"
        value={startRow}
        onChange={handleStartRowChange}
        sx={{ mr: 2 }}
      />
      <TextField
        label="End Row"
        type="number"
        value={endRow}
        onChange={handleEndRowChange}
        sx={{ mr: 2 }}
      />
      <TextField
        label="新しいURL（1行に1つずつ）"
        multiline
        rows={6}
        value={newUrls}
        onChange={(e) => setNewUrls(e.target.value)}
        fullWidth
        sx={{ my: 2 }}
        placeholder={`例:\nhttps://example.com/image-row1-img1.jpg\nhttps://example.com/image-row1-img2.jpg`}
      />
      <Box sx={{ mt: 0 }}>
        <Button
          variant="outlined"
          onClick={generatePreviewData}
          sx={{ mr: 2 }}
        >
          プレビュー
        </Button>
        <Button
          variant="contained"
          onClick={handleReplace}
          disabled={
            isReplacing ||
            !data ||
            data.length <= 1 ||
            previewData.length === 0
          }
        >
          {isReplacing ? (
            <CircularProgress size={24} />
          ) : (
            '画像URLを一括変更する'
          )}
        </Button>
      </Box>
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {success}
        </Alert>
      )}

      {/* プレビューダイアログ */}
      <Dialog
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          画像プレビュー
          <IconButton
            aria-label="close"
            onClick={() => setOpenPreview(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {previewData.map((item, index) => (
            <Box key={index} sx={{ mb: 4 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography variant="h6">行 {item.rowIndex}</Typography>
                  <Typography variant="subtitle1">{item.title}</Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!skippedRows.has(item.rowIndex)}
                        onChange={() => handleSkipToggle(item.rowIndex)}
                      />
                    }
                    label="この商品の画像を変更する"
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Typography variant="subtitle1">変更前の画像</Typography>
                  <Grid container spacing={1}>
                    {item.oldUrls.map((url, idx) => (
                      <Grid item xs={4} sm={3} md={2} key={idx}>
                        <Paper
                          elevation={3}
                          sx={{ p: 1, textAlign: 'center', cursor: 'pointer' }}
                          onClick={() =>
                            handleImageClick(url, item.updatedUrls[idx])
                          }
                        >
                          <img
                            src={url}
                            alt={`Old image ${idx + 1}`}
                            style={{
                              maxWidth: '100%',
                              maxHeight: '100px',
                              objectFit: 'contain'
                            }}
                          />
                          <Typography variant="caption">img{idx + 1}</Typography>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                  <Typography variant="subtitle1" sx={{ mt: 2 }}>
                    変更後の画像
                  </Typography>
                  <Grid container spacing={1}>
                    {item.updatedUrls.map((url, idx) => (
                      <Grid item xs={4} sm={3} md={2} key={idx}>
                        <Paper
                          elevation={3}
                          sx={{ p: 1, textAlign: 'center', cursor: 'pointer' }}
                          onClick={() =>
                            handleImageClick(item.oldUrls[idx], url)
                          }
                        >
                          <img
                            src={url}
                            alt={`Updated image ${idx + 1}`}
                            style={{
                              maxWidth: '100%',
                              maxHeight: '100px',
                              objectFit: 'contain'
                            }}
                          />
                          <Typography variant="caption">img{idx + 1}</Typography>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 2 }} />
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPreview(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* 画像を拡大表示するダイアログ */}
      <Dialog
        open={!!enlargedImages}
        onClose={() => setEnlargedImages(null)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          画像プレビュー
          <IconButton
            aria-label="close"
            onClick={() => setEnlargedImages(null)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" align="center">
                変更前
              </Typography>
              <Paper
                elevation={3}
                sx={{ p: 1, textAlign: 'center', bgcolor: '#f5f5f5' }}
              >
                <img
                  src={enlargedImages?.oldUrl}
                  alt="現在の画像"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '500px',
                    objectFit: 'contain'
                  }}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" align="center">
                変更後
              </Typography>
              <Paper
                elevation={3}
                sx={{ p: 1, textAlign: 'center', bgcolor: '#f5f5f5' }}
              >
                <img
                  src={enlargedImages?.newUrl}
                  alt="変更後の画像"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '500px',
                    objectFit: 'contain'
                  }}
                />
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ImageUrlReplacer;





// import React, { useState } from 'react';
// import {
//   Button,
//   TextField,
//   Typography,
//   Box,
//   CircularProgress,
//   Alert,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Grid,
//   Card,
//   CardMedia,
//   CardContent,
//   IconButton,
//   Paper,
//   Checkbox,
//   FormControlLabel
// } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import axios from 'axios';

// function ImageUrlReplacer({ spreadsheetId, sheetName, token, data, fetchData }) {
//   const [startRow, setStartRow] = useState('');
//   const [endRow, setEndRow] = useState('');
//   const [newUrls, setNewUrls] = useState('');
//   const [isReplacing, setIsReplacing] = useState(false);
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');
//   const [openPreview, setOpenPreview] = useState(false);
//   const [previewData, setPreviewData] = useState([]);
//   const [enlargedImages, setEnlargedImages] = useState(null);
//   const [skippedRows, setSkippedRows] = useState(new Set());

//   // maxEndRow を計算する関数
//   const calculateMaxEndRow = () => {
//     if (data && data.length > 1) {
//       return Math.min(parseInt(startRow) + 149, data.length - 1);
//     } else {
//       return parseInt(startRow) + 149;
//     }
//   };

//   const handleStartRowChange = (e) => {
//     const value = parseInt(e.target.value);
//     setStartRow(value);

//     // maxEndRow を再計算
//     const newMaxEndRow = calculateMaxEndRow();
//     if (endRow < value || endRow > newMaxEndRow || isNaN(endRow)) {
//       setEndRow(newMaxEndRow);
//     }
//   };

//   const handleEndRowChange = (e) => {
//     const value = parseInt(e.target.value);
//     const maxEndRow = calculateMaxEndRow();
//     setEndRow(Math.min(Math.max(value, startRow), maxEndRow));
//   };

//   const generatePreviewData = () => {
//     setError('');

//     // 行範囲のバリデーション
//     const isValidRowRange =
//       startRow !== '' &&
//       endRow !== '' &&
//       !isNaN(startRow) &&
//       !isNaN(endRow) &&
//       endRow >= startRow &&
//       data &&
//       data.length > 1;
//     if (!isValidRowRange) {
//       setError('Start Row と End Row を正しく指定してください');
//       return;
//     }

//     const picUrlIndex = data[0].findIndex(
//       (header) => header.toLowerCase() === 'picurl'
//     );
//     if (picUrlIndex === -1) {
//       setError('PicURL 列が見つかりません');
//       return;
//     }

//     const actualStartRow = parseInt(startRow);
//     const actualEndRow = parseInt(endRow);
//     let newUrlList = newUrls.split('\n').filter((url) => url.trim() !== '');

//     // 画像番号とURLのマップを作成
//     const urlMap = new Map();
//     newUrlList.forEach((url) => {
//       const match = url.match(/image-(\d+)/);
//       if (match) {
//         urlMap.set(parseInt(match[1]), url);
//       }
//     });

//     const previewItems = [];
//     for (let i = actualStartRow; i <= actualEndRow; i++) {
//       const dataRow = data[i];
//       if (!dataRow) continue; // データがない行はスキップ

//       const oldUrl = dataRow[picUrlIndex]?.split('|')[0] || '';
//       const newUrl = urlMap.get(i) || '';
//       if (newUrl) {
//         previewItems.push({ rowIndex: i, oldUrl, newUrl });
//       }
//     }

//     if (previewItems.length === 0) {
//       setError('選択した行に一致するURLが見つかりません');
//       return;
//     }

//     setPreviewData(previewItems);
//     setOpenPreview(true);
//   };

//   const handleReplace = async () => {
//     setIsReplacing(true);
//     setError('');
//     setSuccess('');

//     try {
//       // 行範囲のバリデーション
//       const isValidRowRange =
//         startRow !== '' &&
//         endRow !== '' &&
//         !isNaN(startRow) &&
//         !isNaN(endRow) &&
//         endRow >= startRow &&
//         data &&
//         data.length > 1;
//       if (!isValidRowRange) {
//         throw new Error('Start Row と End Row を正しく指定してください');
//       }

//       const picUrlIndex = data[0].findIndex(
//         (header) => header.toLowerCase() === 'picurl'
//       );
//       if (picUrlIndex === -1) {
//         throw new Error('PicURL 列が見つかりません');
//       }

//       const actualStartRow = parseInt(startRow);
//       const actualEndRow = parseInt(endRow);
//       let newUrlList = newUrls.split('\n').filter((url) => url.trim() !== '');

//       // 画像番号とURLのマップを作成
//       const urlMap = new Map();
//       newUrlList.forEach((url) => {
//         const match = url.match(/image-(\d+)/);
//         if (match) {
//           urlMap.set(parseInt(match[1]), url);
//         }
//       });

//       const batchUpdates = [];
//       const picUrlColumn = String.fromCharCode(65 + picUrlIndex);

//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         if (!skippedRows.has(i)) {
//           const dataRow = data[i];
//           if (!dataRow) continue; // データがない行はスキップ

//           const newUrl = urlMap.get(i);
//           if (newUrl) {
//             const currentUrls = dataRow[picUrlIndex]?.split('|') || [];
//             currentUrls[0] = newUrl;
//             batchUpdates.push({
//               range: `${sheetName}!${picUrlColumn}${i + 1}`,
//               values: [[currentUrls.join('|')]]
//             });
//           }
//         }
//       }

//       if (batchUpdates.length === 0) {
//         throw new Error('選択した行に一致するURLが見つかりません');
//       }

//       // 複数のセルを一括更新
//       await axios.post(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values:batchUpdate`,
//         {
//           valueInputOption: 'RAW',
//           data: batchUpdates
//         },
//         {
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       );

//       setSuccess('URLの置換が成功しました');
//       fetchData();
//     } catch (err) {
//       console.error('エラー:', err);
//       if (err.response) {
//         setError(
//           `置換に失敗しました: ${err.response.data.error.message || err.message}`
//         );
//       } else {
//         setError(`置換に失敗しました: ${err.message}`);
//       }
//     } finally {
//       setIsReplacing(false);
//     }
//   };

//   const handleImageClick = (oldUrl, newUrl) => {
//     setEnlargedImages({ oldUrl, newUrl });
//   };

//   const handleSkipToggle = (rowIndex) => {
//     setSkippedRows((prev) => {
//       const newSet = new Set(prev);
//       if (newSet.has(rowIndex)) {
//         newSet.delete(rowIndex);
//       } else {
//         newSet.add(rowIndex);
//       }
//       return newSet;
//     });
//   };

//   return (
//     <Box sx={{ mt: 0 }}>
//       <Typography variant="h6">メイン画像のURLを変更する</Typography>
//       <Typography variant="body2" sx={{ mb: 2 }}>
//         画像URLリンクをそのまま貼り付けてください。URLの "image-X" の数字が行番号と一致する画像が変更されます。
//         一度に最大150行まで処理できます。
//       </Typography>
//       <TextField
//         label="Start Row"
//         type="number"
//         value={startRow}
//         onChange={handleStartRowChange}
//         sx={{ mr: 2 }}
//       />
//       <TextField
//         label="End Row"
//         type="number"
//         value={endRow}
//         onChange={handleEndRowChange}
//         sx={{ mr: 2 }}
//       />
//       <TextField
//         label="新しいURL（1行に1つずつ）"
//         multiline
//         rows={4}
//         value={newUrls}
//         onChange={(e) => setNewUrls(e.target.value)}
//         fullWidth
//         sx={{ my: 2 }}
//       />
//       <Box sx={{ mt: 0 }}>
//         <Button
//           variant="outlined"
//           onClick={generatePreviewData}
//           sx={{ mr: 2 }}
//         >
//           プレビュー
//         </Button>
//         <Button
//           variant="contained"
//           onClick={handleReplace}
//           disabled={
//             isReplacing ||
//             !data ||
//             data.length <= 1 ||
//             previewData.length === 0
//           }
//         >
//           {isReplacing ? (
//             <CircularProgress size={24} />
//           ) : (
//             'メイン画像のURLを変更する'
//           )}
//         </Button>
//       </Box>
//       {error && (
//         <Alert severity="error" sx={{ mt: 2 }}>
//           {error}
//         </Alert>
//       )}
//       {success && (
//         <Alert severity="success" sx={{ mt: 2 }}>
//           {success}
//         </Alert>
//       )}

//       {/* 以下、プレビューとダイアログの部分は変更なし */}
//       <Dialog
//         open={openPreview}
//         onClose={() => setOpenPreview(false)}
//         maxWidth="lg"
//         fullWidth
//       >
//         <DialogTitle>画像プレビュー</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             画像をクリックすると、eBayでの表示イメージを確認できます。チェックボックスを外すと、その行の画像は変更されません。
//           </Typography>
//           <Grid container spacing={2}>
//             {previewData.map((item, index) => (
//               <Grid item xs={12} sm={6} key={index}>
//                 <Card>
//                   <CardContent>
//                     <Typography variant="h6">行 {item.rowIndex}</Typography>
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={!skippedRows.has(item.rowIndex)}
//                           onChange={() => handleSkipToggle(item.rowIndex)}
//                         />
//                       }
//                       label="この画像を変更する"
//                     />
//                     <Grid container spacing={2}>
//                       <Grid item xs={6}>
//                         <Typography variant="subtitle1">変更前</Typography>
//                         <CardMedia
//                           component="img"
//                           height="140"
//                           image={item.oldUrl}
//                           alt={`Old image ${index + 1}`}
//                           sx={{ cursor: 'pointer' }}
//                           onClick={() =>
//                             handleImageClick(item.oldUrl, item.newUrl)
//                           }
//                         />
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Typography variant="subtitle1">変更後</Typography>
//                         <CardMedia
//                           component="img"
//                           height="140"
//                           image={item.newUrl}
//                           alt={`New image ${index + 1}`}
//                           sx={{ cursor: 'pointer' }}
//                           onClick={() =>
//                             handleImageClick(item.oldUrl, item.newUrl)
//                           }
//                         />
//                       </Grid>
//                     </Grid>
//                   </CardContent>
//                 </Card>
//               </Grid>
//             ))}
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenPreview(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog
//         open={!!enlargedImages}
//         onClose={() => setEnlargedImages(null)}
//         maxWidth="md"
//         fullWidth
//       >
//         <DialogTitle>
//           eBayでの表示イメージ
//           <IconButton
//             aria-label="close"
//             onClick={() => setEnlargedImages(null)}
//             sx={{
//               position: 'absolute',
//               right: 8,
//               top: 8,
//               color: (theme) => theme.palette.grey[500]
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//         </DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             これらの画像がeBayの商品ページに表示されます。左が現在の画像、右が変更後の画像です。
//           </Typography>
//           <Paper elevation={3} sx={{ p: 2, bgcolor: '#f5f5f5' }}>
//             <Grid container spacing={2} justifyContent="center">
//               <Grid item xs={6}>
//                 <img
//                   src={enlargedImages?.oldUrl}
//                   alt="現在の画像"
//                   style={{
//                     width: '100%',
//                     height: 'auto',
//                     maxHeight: '400px',
//                     objectFit: 'contain'
//                   }}
//                 />
//                 <Typography variant="subtitle1" align="center">
//                   現在の画像
//                 </Typography>
//               </Grid>
//               <Grid item xs={6}>
//                 <img
//                   src={enlargedImages?.newUrl}
//                   alt="変更後の画像"
//                   style={{
//                     width: '100%',
//                     height: 'auto',
//                     maxHeight: '400px',
//                     objectFit: 'contain'
//                   }}
//                 />
//                 <Typography variant="subtitle1" align="center">
//                   変更後の画像
//                 </Typography>
//               </Grid>
//             </Grid>
//           </Paper>
//         </DialogContent>
//       </Dialog>
//     </Box>
//   );
// }

// export default ImageUrlReplacer;