// titleGenerationService.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  Typography,
  TextField,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Snackbar,
  Alert,
  Box,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  Switch,
  Chip,
  IconButton,
  Tooltip,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
} from '@mui/material';
import {
  Add,
  Delete,
  Info,
  FormatColorReset as FormatColorResetIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import PQueue from 'p-queue';
import CustomPromptDialog from './CustomPromptDialog'; // カスタムプロンプトダイアログをインポート
import {
  loadCacheFromLocalStorage,
  saveCacheToLocalStorage,
  getCachedTitle,
  setCachedTitle,
  clearTitleCache,
} from './titleCache'; // キャッシュ関連の関数をインポート

// 定数の定義
const BATCH_SIZE = 10;
const INITIAL_RETRY_DELAY = 1000;
const MAX_RETRIES = 3;
const ITEMS_PER_PAGE_OPTIONS = [10, 25, 50, 'All']; // ページあたりの行数のオプション

// コンポーネントの定義
const TitleGenerationComponent = (props) => {
  // 状態管理のフック
  const { spreadsheetId, sheetName, token, apiKey } = props;
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]); // ページングされた商品
  const [currentPage, setCurrentPage] = useState(1); // 現在のページ
  const [totalPages, setTotalPages] = useState(1); // 総ページ数
  const [itemsPerPage, setItemsPerPage] = useState(10); // 表示する商品の数
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [customPromptDialogOpen, setCustomPromptDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [deleteStrings, setDeleteStrings] = useState([]); // 複数の削除文字列を管理
  const [replacePairs, setReplacePairs] = useState([]); // 複数の置換ペアを管理
  const [prependText, setPrependText] = useState('');
  const [appendText, setAppendText] = useState('');
  const [limitTitleLength, setLimitTitleLength] = useState(false);
  const [customizeDialogOpen, setCustomizeDialogOpen] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [customCategories, setCustomCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [focusedProduct, setFocusedProduct] = useState(null); // フォーカスされた商品ID
  const [focusedProductId, setFocusedProductId] = useState(null);


  // キャッシュの読み込み
  useEffect(() => {
    loadCacheFromLocalStorage();
    loadCategoriesFromLocalStorage();
  }, []);

  // カスタムカテゴリーをローカルストレージから読み込む
  function loadCategoriesFromLocalStorage() {
    const savedCategories = localStorage.getItem('customCategories');
    if (savedCategories) {
      setCustomCategories(JSON.parse(savedCategories));
    }
  }

  // カスタムカテゴリーをローカルストレージに保存
  function saveCategoriesToLocalStorage(categories) {
    localStorage.setItem('customCategories', JSON.stringify(categories));
  }

  // p-queueの設定
  const CONCURRENCY = 2;
  const INTERVAL = 1000;
  const INTERVAL_CAP = 5;
  const queue = new PQueue({
    concurrency: CONCURRENCY,
    interval: INTERVAL,
    intervalCap: INTERVAL_CAP,
  });

  useEffect(() => {
    if (open) {
      fetchSheetData();
    }
  }, [open]);

  useEffect(() => {
    // ページネーションの設定
    const total = products.length;
    if (itemsPerPage === 'All') {
      setTotalPages(1);
      setDisplayedProducts(products);
    } else {
      setTotalPages(Math.ceil(total / itemsPerPage));
      updateDisplayedProducts(1);
    }
    setCurrentPage(1);
  }, [products, itemsPerPage]);

  const updateDisplayedProducts = (page) => {
    if (itemsPerPage === 'All') {
      setDisplayedProducts(products);
      return;
    }
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setDisplayedProducts(products.slice(startIndex, endIndex));
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    updateDisplayedProducts(value);
  };

  // シートデータの取得
  const fetchSheetData = async () => {
    if (!spreadsheetId || !sheetName || !token) {
      setError('必要な情報が不足しています');
      return;
    }
    try {
      setLoading(true);
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = response.data.values;
      const headers = data[0];
      const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
      const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');

      if (titleIndex === -1) {
        throw new Error('Titleカラムが見つかりません');
      }

      const productData = data.slice(1).map((row, index) => ({
        id: index,
        title: row[titleIndex] || '',
        jpDesc: jpDescIndex !== -1 ? row[jpDescIndex] || '' : '',
        generatedTitle: '',
        selected: false,
        editableJpDesc: jpDescIndex !== -1 ? row[jpDescIndex] || '' : '',
      }));

      setProducts(productData);
      setSnackbarMessage('シートデータを正常に取得しました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('シートデータの取得エラー:', err);
      setError(`シートデータの取得に失敗しました: ${err.message}`);
      setSnackbarMessage(`エラー: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  // 商品の選択ハンドラー
  const handleSelectProduct = (productId) => {
    const updatedProducts = products.map((product) =>
      product.id === productId ? { ...product, selected: !product.selected } : product
    );
    setProducts(updatedProducts);
    updateDisplayedProducts(currentPage);
  };

  // すべての商品を選択/解除
  const handleSelectAll = () => {
    const allSelected = products.every((product) => product.selected);
    const updatedProducts = products.map((product) => ({
      ...product,
      selected: !allSelected,
    }));
    setProducts(updatedProducts);
    updateDisplayedProducts(currentPage);
  };

  // 現在のページの商品を選択/解除
  const handleSelectAllOnPage = () => {
    const allSelectedOnPage = displayedProducts.every((product) => product.selected);
    const updatedProducts = products.map((product) =>
      displayedProducts.some((dp) => dp.id === product.id)
        ? { ...product, selected: !allSelectedOnPage }
        : product
    );
    setProducts(updatedProducts);
    updateDisplayedProducts(currentPage);
  };

  // 生成されたタイトルの編集ハンドラー
  const handleGeneratedTitleChange = (event, productId) => {
    const updatedProducts = products.map((product) =>
      product.id === productId ? { ...product, generatedTitle: event.target.value } : product
    );
    setProducts(updatedProducts);
    updateDisplayedProducts(currentPage);
  };

  // フォーカスする商品を設定
  const handleFocusProduct = (productId) => {
    setFocusedProduct(productId);
  };

  // AIによるタイトル生成（デフォルトプロンプト）
  const generateProductTitles = async () => {
    if (!apiKey) {
      setError('OpenAI APIキーが設定されていません');
      setSnackbarMessage('OpenAI APIキーが必要です');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const selectedProductsData = products.filter((product) => product.selected);
    if (selectedProductsData.length === 0) {
      setError('少なくとも一つの商品を選択してください');
      setSnackbarMessage('商品が選択されていません');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    setError('');
    setMessage('');
    setProgress(0);

    try {
      const total = selectedProductsData.length;
      let completed = 0;

      const updatedProducts = [...products];

      const generateTitleTasks = selectedProductsData.map((product) => async () => {
        const index = products.findIndex((p) => p.id === product.id);
        try {
          const generatedTitle = await generateSingleTitle(
            product.title,
            product.editableJpDesc,
            apiKey
          );
          updatedProducts[index].generatedTitle = generatedTitle;
          completed++;
          setProgress(Math.round((completed / total) * 100));
        } catch (err) {
          console.error(`商品ID ${product.id} のタイトル生成エラー:`, err);
          updatedProducts[index].generatedTitle = '';
          setError(`タイトルの生成に失敗しました: ${err.message}`);
        }
      });

      await queue.addAll(generateTitleTasks);

      setProducts(updatedProducts);
      setMessage('タイトルの生成が完了しました');
      setSnackbarMessage('タイトルの生成が完了しました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      updateDisplayedProducts(currentPage);
    } catch (err) {
      console.error('タイトル生成エラー:', err);
      setError(`タイトルの生成に失敗しました: ${err.message}`);
      setSnackbarMessage(`エラー: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
      setProgress(0);
    }
  };

  // 生成されたタイトルをスプレッドシートに反映
  const applyUpdatedTitles = async () => {
    const updatedProducts = products.filter((product) => product.selected && product.generatedTitle);
    if (updatedProducts.length === 0) {
      setError('更新するタイトルがありません');
      setSnackbarMessage('更新するタイトルがありません');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    try {
      setLoading(true);

      // シートデータを再取得
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}?valueRenderOption=FORMATTED_VALUE`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = response.data.values;
      const headers = data[0];
      const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');

      if (titleIndex === -1) {
        setError('"Title"カラムがスプレッドシートに存在しません');
        setSnackbarMessage('"Title"カラムが見つかりません');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      // 生成されたタイトルを"Title"カラムに反映
      updatedProducts.forEach((product) => {
        const rowIndex = product.id + 1; // ヘッダー行を考慮
        if (!data[rowIndex]) {
          data[rowIndex] = [];
        }
        data[rowIndex][titleIndex] = product.generatedTitle;
      });

      // シートデータを更新
      await axios.put(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}?valueInputOption=USER_ENTERED`,
        { values: data },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setSnackbarMessage('スプレッドシートの"Title"カラムを更新しました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchSheetData();
    } catch (err) {
      console.error('スプレッドシート更新エラー:', err);
      setError(`スプレッドシートの更新に失敗しました: ${err.message}`);
      setSnackbarMessage(`エラー: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  // 単一の商品タイトルを生成
  async function generateSingleTitle(originalTitle, jpDesc, apiKey) {
    const cacheKey = originalTitle + jpDesc.slice(0, 150);
    const cachedTitle = getCachedTitle(cacheKey);
    if (cachedTitle) {
      return cachedTitle;
    }

    try {
      const url = 'https://api.openai.com/v1/chat/completions';
      const limitedJpDesc = jpDesc.slice(0, 150);
      const requestData = {
        model: 'gpt-4o-2024-08-06',
        messages: [
          {
            role: 'system',
            content: `You are an AI assistant specializing in eBay product title optimization. Based on the given original title and Japanese product information, create an effective English title within 80 characters.
Order of title composition:
1. brand name (if applicable)
2. model name or product name/character name (if applicable)
3. product type (if applicable)
4. key features (color, material, size) (if applicable)
5. distinctive elements or uniqueness (if applicable)
6. condition (new/used) (if applicable)
7. important keywords (if applicable)
NOTES:
- Add or optimize new information while retaining important information from the original title
- Avoid unnecessary adjectives and words such as “eBay
- Use abbreviations and common names appropriately
- Think from the searcher's perspective and include search terms that buyers are likely to use
- If information is unclear or ambiguous, omit it.
- Use only reliable information and do not include guesswork or uncertain information.
- Do not extract from Japanese descriptions that are a list of words.
- Titles should be generated in English and should not exceed 80 characters.`,
          },
          {
            role: 'user',
            content: `元のタイトル：「${originalTitle}」
日本語の商品説明：「${limitedJpDesc}」
この情報を基に、最適化された新しいeBayの商品タイトルを生成してください。また日本語の商品説明が空の場合は元のタイトルから生成してください`,
          },
        ],
        max_tokens: 100,
      };

      const response = await axios.post(url, requestData, {
        headers: { Authorization: `Bearer ${apiKey}` },
      });

      const generatedTitle = response.data.choices[0].message.content.trim();
      setCachedTitle(cacheKey, generatedTitle);
      return generatedTitle;
    } catch (error) {
      throw error;
    }
  }

  // カスタマイズダイアログを開く
  const handleCustomizeDialogOpen = () => {
    setCustomizeDialogOpen(true);
  };

  // カテゴリーの変更ハンドラー
  const handleCategoryChange = (event) => {
    const selectedName = event.target.value;
    setSelectedCategory(selectedName);

    if (selectedName) {
      const category = customCategories.find((cat) => cat.name === selectedName);
      if (category) {
        setDeleteStrings(category.deleteStrings || []);
        setReplacePairs(category.replacePairs || []);
        setPrependText(category.prependText || '');
        setAppendText(category.appendText || '');
        setLimitTitleLength(category.limitTitleLength || false);
        setCategoryName(category.name);
      }
    } else {
      // 新しいカテゴリーを作成する場合、初期状態にリセット
      resetCustomizationSettings();
    }
  };

  // カスタマイズ設定を初期状態にリセット
  const resetCustomizationSettings = () => {
    setDeleteStrings([]);
    setReplacePairs([]);
    setPrependText('');
    setAppendText('');
    setLimitTitleLength(false);
    setCategoryName('');
    setSelectedCategory('');
  };

  // 新しいカテゴリーを作成
  const handleCreateNewCategory = () => {
    // カテゴリー選択をクリアし、初期状態にリセット
    resetCustomizationSettings();
  };

  // カテゴリーを保存または更新する
  const saveOrUpdateCategory = () => {
    if (!categoryName.trim()) {
      setSnackbarMessage('カテゴリー名を入力してください');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    const newCategory = {
      name: categoryName.trim(),
      deleteStrings,
      replacePairs,
      prependText,
      appendText,
      limitTitleLength,
    };

    let updatedCategories;
    if (selectedCategory) {
      // 既存のカテゴリーを更新
      updatedCategories = customCategories.map((cat) =>
        cat.name === selectedCategory ? newCategory : cat
      );
      setSnackbarMessage('カテゴリーを更新しました');
    } else {
      // 新しいカテゴリーを追加
      updatedCategories = [...customCategories, newCategory];
      setSnackbarMessage('新しいカテゴリーを保存しました');
    }

    setCustomCategories(updatedCategories);
    saveCategoriesToLocalStorage(updatedCategories);
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    setSelectedCategory(newCategory.name);
  };

  // カテゴリーを削除する
  const deleteCategory = () => {
    if (!selectedCategory) return;

    const updatedCategories = customCategories.filter((cat) => cat.name !== selectedCategory);
    setCustomCategories(updatedCategories);
    saveCategoriesToLocalStorage(updatedCategories);
    resetCustomizationSettings();
    setSnackbarMessage('カテゴリーを削除しました');
    setSnackbarSeverity('info');
    setSnackbarOpen(true);
  };

  // タイトルカスタマイズの適用
  const customizeTitles = () => {
    const updatedProducts = products.map((product) => {
      if (product.selected && product.generatedTitle) {
        let newTitle = product.generatedTitle;

        // 複数の削除文字列を処理
        deleteStrings.forEach((deleteStr) => {
          if (deleteStr) {
            const regex = new RegExp(deleteStr, 'g');
            newTitle = newTitle.replace(regex, '');
          }
        });

        // 複数の置換ペアを処理
        replacePairs.forEach((pair) => {
          const { find, replace } = pair;
          if (find) {
            const regex = new RegExp(find, 'g');
            newTitle = newTitle.replace(regex, replace);
          }
        });

        // 先頭と末尾の追加文字列を処理
        if (prependText) {
          newTitle = `${prependText} ${newTitle}`;
        }
        if (appendText) {
          newTitle = `${newTitle} ${appendText}`;
        }

        // タイトルを80文字に制限
        if (limitTitleLength) {
          newTitle = newTitle.slice(0, 80);
        }

        return { ...product, generatedTitle: newTitle };
      }
      return product;
    });
    setProducts(updatedProducts);
    updateDisplayedProducts(currentPage);

    setSnackbarMessage('タイトルをカスタマイズしました');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };

  // 生成されたタイトルを更新するための関数
  const updateProducts = (updatedProducts) => {
    const newProducts = products.map((product) => {
      const updatedProduct = updatedProducts.find((p) => p.id === product.id);
      return updatedProduct ? updatedProduct : product;
    });
    setProducts(newProducts);
    updateDisplayedProducts(currentPage);
  };

  // キャッシュをクリア
  const handleClearTitleCache = () => {
    clearTitleCache();
    setSnackbarMessage('キャッシュをクリアしました');
    setSnackbarSeverity('info');
    setSnackbarOpen(true);
  };

  // 削除文字列のチップ関連の関数
  const handleDeleteStringKeyPress = (event) => {
    if (event.key === 'Enter' && event.target.value.trim() !== '') {
      setDeleteStrings([...deleteStrings, event.target.value.trim()]);
      event.target.value = '';
    }
  };

  const handleDeleteChip = (chipToDelete) => {
    setDeleteStrings((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  // 置換ペア関連の関数
  const handleAddReplacePair = () => {
    setReplacePairs([...replacePairs, { find: '', replace: '' }]);
  };

  const handleReplacePairChange = (index, field, value) => {
    const newReplacePairs = [...replacePairs];
    newReplacePairs[index][field] = value;
    setReplacePairs(newReplacePairs);
  };

  const handleRemoveReplacePair = (index) => {
    const newReplacePairs = replacePairs.filter((_, i) => i !== index);
    setReplacePairs(newReplacePairs);
  };

  // 商品説明の編集
  const handleEditJpDesc = (productId, newDesc) => {
    const updatedProducts = products.map((product) =>
      product.id === productId ? { ...product, editableJpDesc: newDesc } : product
    );
    setProducts(updatedProducts);
    updateDisplayedProducts(currentPage);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        mt: 1,
        mb: 1,
        maxWidth: '100%',
        margin: '0 auto',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ maxWidth: '1600px', margin: '0 auto' }}>
        {/* ヘッダーセクション */}
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              AIを活用したタイトル生成
            </Typography>
            <Typography variant="body1" paragraph>
              日本語の商品説明から英語の商品タイトルを自動生成します。
              もし、生成で問題が起こる場合はキャッシュをクリアしてください。
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              主な機能：
            </Typography>
            <Grid container spacing={1}>
              {[
                '日本語の商品説明から英語の商品タイトルを生成',
                'タイトルの一括編集とカスタマイズ',
                
              ].map((feature, index) => (
                <Grid item xs={6} key={index}>
                  <Typography variant="body2">• {feature}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        {/* アクションボタンセクション */}
        <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
          <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<Add />}>
            タイトルをAIで生成する
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClearTitleCache}
            startIcon={<FormatColorResetIcon />}
          >
            キャッシュをクリア
          </Button>
        </Box>

        {/* ダイアログセクション */}
        <Dialog open={open} onClose={handleClose} fullScreen>
          <DialogTitle>AIでタイトルを生成する</DialogTitle>
          <DialogContent>
            {/* レイアウトを左右に分割 */}
            <Grid container spacing={2}>
              {/* 左側のセクション */}
              <Grid item xs={12} md={2} sx={{
                  maxHeight: 'calc(100vh - 32px)', // 左右の高さを一致させる場合
                  overflowY: 'auto',
                }}>
                {/* カテゴリー選択とカスタマイズ */}
                <Typography variant="h6">タイトルカスタマイズ</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="category-select-label">カテゴリーを選択</InputLabel>
                      <Select
                        labelId="category-select-label"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                      >
                        {customCategories.map((cat) => (
                          <MenuItem key={cat.name} value={cat.name}>
                            {cat.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="カテゴリー名"
                      fullWidth
                      margin="normal"
                      value={categoryName}
                      onChange={(e) => setCategoryName(e.target.value)}
                      placeholder="新しいカテゴリー名を入力"
                    />
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleCreateNewCategory}
                      sx={{ mt: 1 }}
                    >
                      新しいカテゴリーを作成
                    </Button>
                  </Grid>
                  {/* 削除する文字列 */}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">削除する文字列</Typography>
                    <TextField
                      placeholder="削除したい文字列を入力し、Enterキーを押してください"
                      onKeyPress={handleDeleteStringKeyPress}
                      fullWidth
                    />
                    <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {deleteStrings.map((string, index) => (
                        <Chip key={index} label={string} onDelete={() => handleDeleteChip(string)} />
                      ))}
                    </Box>
                  </Grid>
                  {/* 置換ペア */}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">置換ペア</Typography>
                    {replacePairs.map((pair, index) => (
                      <Box
                        key={index}
                        sx={{ display: 'flex', gap: 1, alignItems: 'center', mt: 1 }}
                      >
                        <TextField
                          label="置換する文字列"
                          value={pair.find}
                          onChange={(e) => handleReplacePairChange(index, 'find', e.target.value)}
                        />
                        <TextField
                          label="置換後の文字列"
                          value={pair.replace}
                          onChange={(e) => handleReplacePairChange(index, 'replace', e.target.value)}
                        />
                        <IconButton color="secondary" onClick={() => handleRemoveReplacePair(index)}>
                          <Delete />
                        </IconButton>
                      </Box>
                    ))}
                    <Button
                      variant="outlined"
                      startIcon={<Add />}
                      onClick={handleAddReplacePair}
                      sx={{ mt: 2 }}
                    >
                      置換ペアを追加
                    </Button>
                  </Grid>
                  {/* 先頭・末尾に追加する文字列 */}
                  <Grid item xs={12}>
                    <TextField
                      label="先頭に追加する文字列"
                      fullWidth
                      margin="normal"
                      value={prependText}
                      onChange={(e) => setPrependText(e.target.value)}
                      helperText="タイトルの先頭に追加します"
                    />
                    <TextField
                      label="末尾に追加する文字列"
                      fullWidth
                      margin="normal"
                      value={appendText}
                      onChange={(e) => setAppendText(e.target.value)}
                      helperText="タイトルの末尾に追加します"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={limitTitleLength}
                          onChange={(e) => setLimitTitleLength(e.target.checked)}
                        />
                      }
                      label="80文字に制限する"
                    />
                  </Grid>
                  {/* カテゴリーの保存・更新・削除ボタン */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={saveOrUpdateCategory}
                      disabled={!categoryName.trim()}
                      fullWidth
                    >
                      {selectedCategory ? 'カテゴリーを更新' : 'カテゴリーを保存'}
                    </Button>
                    {selectedCategory && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={deleteCategory}
                        fullWidth
                        sx={{ mt: 1 }}
                      >
                        カテゴリーを削除
                      </Button>
                    )}
                  </Grid>
                  {/* タイトルカスタマイズの適用ボタン */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={customizeTitles}
                      fullWidth
                      sx={{ mt: 2 }}
                    >
                      タイトルカスタマイズを適用
                    </Button>
                  </Grid>
                  {/* 表示件数選択コンポーネントを追加 */}
                  <Grid item xs={12}>
                    <FormControl variant="outlined" size="small" fullWidth sx={{ mt: 2 }}>
                      <InputLabel id="items-per-page-label">表示件数</InputLabel>
                      <Select
                        labelId="items-per-page-label"
                        value={itemsPerPage}
                        onChange={(e) => {
                          setItemsPerPage(e.target.value);
                          setCurrentPage(1); // ページ番号をリセット
                        }}
                        label="表示件数"
                      >
                        {ITEMS_PER_PAGE_OPTIONS.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option === 'All' ? '全て' : `${option}件ずつ`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              {/* 右側のセクション */}
              <Grid item xs={12} md={10} sx={{
                  maxHeight: 'calc(100vh - 32px)', // 左右の高さを一致させる場合
                  overflowY: 'auto',
                }}>
                {/* アクションボタン */}
                <Grid container spacing={2} style={{ marginBottom: '20px' }}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={generateProductTitles}
                      disabled={loading || !apiKey}
                    >
                      AIでタイトルを生成する
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={applyUpdatedTitles}
                      disabled={loading}
                    >
                      タイトルをスプレッドシートに更新する
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => setCustomPromptDialogOpen(true)}
                      startIcon={<EditIcon />}
                      disabled={loading}
                    >
                      カスタムプロンプトで生成
                    </Button>
                  </Grid>
                </Grid>

                {/* 進捗状況表示 */}
                {loading && (
                  <Box sx={{ width: '100%', mb: 2 }}>
                    <LinearProgress variant="determinate" value={progress} />
                    <Typography variant="body2" color="text.secondary" align="center">
                      {`${Math.round(progress)}%`}
                    </Typography>
                  </Box>
                )}

                {/* エラーメッセージ */}
                {error && (
                  <Typography color="error" style={{ marginTop: '20px' }}>
                    {error}
                  </Typography>
                )}
                {/* 成功メッセージ */}
                {message && (
                  <Typography color="primary" style={{ marginTop: '20px' }}>
                    {message}
                  </Typography>
                )}

                {/* 商品テーブル */}
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="products-table">
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={products.every((product) => product.selected)}
                            onChange={handleSelectAll}
                            indeterminate={
                              products.some((product) => product.selected) &&
                              !products.every((product) => product.selected)
                            }
                          />
                        </TableCell>
                        <TableCell>商品タイトル</TableCell>
                        <TableCell>日本語の商品説明</TableCell>
                        <TableCell>生成されたタイトル</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {displayedProducts.map((product) => (
                        <TableRow
                          key={product.id}
                          onClick={() => handleFocusProduct(product.id)}
                          selected={focusedProduct === product.id}
                          hover
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={product.selected}
                              onChange={() => handleSelectProduct(product.id)}
                              onClick={(e) => e.stopPropagation()}
                            />
                          </TableCell>
                          <TableCell
                            sx={{
                              fontSize: '0.8rem',
                              whiteSpace: 'pre-wrap',
                              wordWrap: 'break-word',
                              maxWidth: '300px',
                              padding: '4px',
                            }}
                          >
                            {product.title}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: '4px',
                            }}
                          >
                            <TextField
                              multiline
                              fullWidth
                              variant="outlined"
                              value={product.editableJpDesc}
                              onChange={(e) => handleEditJpDesc(product.id, e.target.value)}
                              onFocus={() => setFocusedProductId(product.id)}
                              onBlur={() => setFocusedProductId(null)}
                              InputProps={{
                                sx: {
                                  textarea: {
                                    maxHeight: focusedProductId === product.id ? 'none' : '8em',
                                    overflow: 'auto',
                                    transition: 'max-height 0.3s ease',
                                  },
                                },
                              }}
                              inputProps={{
                                style: {
                                  fontSize: '0.8rem',
                                  padding: '2px',
                                  lineHeight: '1.2',
                                },
                              }}
                            />

                          </TableCell>
                          <TableCell
                            sx={{
                              padding: '4px',
                            }}
                          >
                            <TextField
                              multiline
                              fullWidth
                              variant="outlined"
                              value={product.generatedTitle}
                              onChange={(event) => handleGeneratedTitleChange(event, product.id)}
                              inputProps={{
                                style: {
                                  fontSize: '0.8rem',
                                  padding: '2px',
                                  lineHeight: '1.2',
                                },
                              }}
                              sx={{
                                margin: 0,
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* ページネーション */}
                {itemsPerPage !== 'All' && (
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>閉じる</Button>
          </DialogActions>
        </Dialog>

        {/* カスタムプロンプトダイアログ */}
        <CustomPromptDialog
          open={customPromptDialogOpen}
          onClose={() => setCustomPromptDialogOpen(false)}
          apiKey={apiKey}
          selectedProducts={products.filter((product) => product.selected)}
          updateProducts={updateProducts}
        />

        {/* スナックバー通知 */}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Paper>
  );
};

export default TitleGenerationComponent;




// // titleGenerationService.js

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Button,
//   Typography,
//   TextField,
//   Checkbox,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Grid,
//   Snackbar,
//   Alert,
//   Box,
//   LinearProgress,
//   FormControl,
//   Select,
//   MenuItem,
//   InputLabel,
//   FormControlLabel,
//   Switch,
//   Chip,
//   IconButton,
//   Tooltip,
//   Pagination,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemSecondaryAction,
//   ListItemIcon,
// } from '@mui/material';
// import {
//   Add,
//   Delete,
//   Info,
//   FormatColorReset as FormatColorResetIcon,
//   Visibility as VisibilityIcon,
//   Edit as EditIcon,
// } from '@mui/icons-material';
// import PQueue from 'p-queue';
// import CustomPromptDialog from './CustomPromptDialog'; // カスタムプロンプトダイアログをインポート
// import {
//   loadCacheFromLocalStorage,
//   saveCacheToLocalStorage,
//   getCachedTitle,
//   setCachedTitle,
//   clearTitleCache,
// } from './titleCache'; // キャッシュ関連の関数をインポート

// // 定数の定義
// const BATCH_SIZE = 10;
// const INITIAL_RETRY_DELAY = 1000;
// const MAX_RETRIES = 3;
// const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 'All']; // ページあたりの行数のオプション

// // コンポーネントの定義
// const TitleGenerationComponent = (props) => {
//   // 状態管理のフック
//   const { spreadsheetId, sheetName, token, apiKey } = props;
//   const [open, setOpen] = useState(false);
//   const [products, setProducts] = useState([]);
//   const [displayedProducts, setDisplayedProducts] = useState([]); // ページングされた商品
//   const [currentPage, setCurrentPage] = useState(1); // 現在のページ
//   const [totalPages, setTotalPages] = useState(1); // 総ページ数
//   const [itemsPerPage, setItemsPerPage] = useState(10); // 表示する商品の数
//   const [loading, setLoading] = useState(false);
//   const [progress, setProgress] = useState(0);
//   const [error, setError] = useState('');
//   const [message, setMessage] = useState('');
//   const [customPromptDialogOpen, setCustomPromptDialogOpen] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info');
//   const [deleteStrings, setDeleteStrings] = useState([]); // 複数の削除文字列を管理
//   const [replacePairs, setReplacePairs] = useState([]); // 複数の置換ペアを管理
//   const [prependText, setPrependText] = useState('');
//   const [appendText, setAppendText] = useState('');
//   const [limitTitleLength, setLimitTitleLength] = useState(false);
//   const [customizeDialogOpen, setCustomizeDialogOpen] = useState(false);
//   const [categoryName, setCategoryName] = useState('');
//   const [customCategories, setCustomCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [focusedProduct, setFocusedProduct] = useState(null); // フォーカスされた商品ID

//   // キャッシュの読み込み
//   useEffect(() => {
//     loadCacheFromLocalStorage();
//     loadCategoriesFromLocalStorage();
//   }, []);

//   // カスタムカテゴリーをローカルストレージから読み込む
//   function loadCategoriesFromLocalStorage() {
//     const savedCategories = localStorage.getItem('customCategories');
//     if (savedCategories) {
//       setCustomCategories(JSON.parse(savedCategories));
//     }
//   }

//   // カスタムカテゴリーをローカルストレージに保存
//   function saveCategoriesToLocalStorage(categories) {
//     localStorage.setItem('customCategories', JSON.stringify(categories));
//   }

//   // p-queueの設定
//   const CONCURRENCY = 2;
//   const INTERVAL = 1000;
//   const INTERVAL_CAP = 5;
//   const queue = new PQueue({
//     concurrency: CONCURRENCY,
//     interval: INTERVAL,
//     intervalCap: INTERVAL_CAP,
//   });

//   useEffect(() => {
//     if (open) {
//       fetchSheetData();
//     }
//   }, [open]);
  

//   useEffect(() => {
//     // ページネーションの設定
//     const total = products.length;
//     if (itemsPerPage === 'All') {
//       setTotalPages(1);
//       setDisplayedProducts(products);
//     } else {
//       setTotalPages(Math.ceil(total / itemsPerPage));
//       updateDisplayedProducts(1);
//     }
//     setCurrentPage(1);
//   }, [products, itemsPerPage]);

//   const updateDisplayedProducts = (page) => {
//     if (itemsPerPage === 'All') {
//       setDisplayedProducts(products);
//       return;
//     }
//     const startIndex = (page - 1) * itemsPerPage;
//     const endIndex = startIndex + itemsPerPage;
//     setDisplayedProducts(products.slice(startIndex, endIndex));
//   };

//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//     updateDisplayedProducts(value);
//   };

//   // シートデータの取得
//   const fetchSheetData = async () => {
//     if (!spreadsheetId || !sheetName || !token) {
//       setError('必要な情報が不足しています');
//       return;
//     }
//     try {
//       setLoading(true);
//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       const data = response.data.values;
//       const headers = data[0];
//       const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
//       const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');

//       if (titleIndex === -1) {
//         throw new Error('Titleカラムが見つかりません');
//       }

//       const productData = data.slice(1).map((row, index) => ({
//         id: index,
//         title: row[titleIndex] || '',
//         jpDesc: jpDescIndex !== -1 ? row[jpDescIndex] || '' : '',
//         generatedTitle: '',
//         selected: false,
//         editableJpDesc: jpDescIndex !== -1 ? row[jpDescIndex] || '' : '',
//       }));

//       setProducts(productData);
//       setSnackbarMessage('シートデータを正常に取得しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       console.error('シートデータの取得エラー:', err);
//       setError(`シートデータの取得に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // 商品の選択ハンドラー
//   const handleSelectProduct = (productId) => {
//     const updatedProducts = products.map((product) =>
//       product.id === productId ? { ...product, selected: !product.selected } : product
//     );
//     setProducts(updatedProducts);
//     updateDisplayedProducts(currentPage);
//   };

//   // すべての商品を選択/解除
//   const handleSelectAll = () => {
//     const allSelected = products.every((product) => product.selected);
//     const updatedProducts = products.map((product) => ({
//       ...product,
//       selected: !allSelected,
//     }));
//     setProducts(updatedProducts);
//     updateDisplayedProducts(currentPage);
//   };

//   // 現在のページの商品を選択/解除
//   const handleSelectAllOnPage = () => {
//     const allSelectedOnPage = displayedProducts.every((product) => product.selected);
//     const updatedProducts = products.map((product) =>
//       displayedProducts.some((dp) => dp.id === product.id)
//         ? { ...product, selected: !allSelectedOnPage }
//         : product
//     );
//     setProducts(updatedProducts);
//     updateDisplayedProducts(currentPage);
//   };

//   // 生成されたタイトルの編集ハンドラー
//   const handleGeneratedTitleChange = (event, productId) => {
//     const updatedProducts = products.map((product) =>
//       product.id === productId ? { ...product, generatedTitle: event.target.value } : product
//     );
//     setProducts(updatedProducts);
//     updateDisplayedProducts(currentPage);
//   };

//   // フォーカスする商品を設定
//   const handleFocusProduct = (productId) => {
//     setFocusedProduct(productId);
//   };

//   // AIによるタイトル生成（デフォルトプロンプト）
//   const generateProductTitles = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     const selectedProductsData = products.filter((product) => product.selected);
//     if (selectedProductsData.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     try {
//       const total = selectedProductsData.length;
//       let completed = 0;

//       const updatedProducts = [...products];

//       const generateTitleTasks = selectedProductsData.map((product) => async () => {
//         const index = products.findIndex((p) => p.id === product.id);
//         try {
//           const generatedTitle = await generateSingleTitle(
//             product.title,
//             product.editableJpDesc,
//             apiKey
//           );
//           updatedProducts[index].generatedTitle = generatedTitle;
//           completed++;
//           setProgress(Math.round((completed / total) * 100));
//         } catch (err) {
//           console.error(`商品ID ${product.id} のタイトル生成エラー:`, err);
//           updatedProducts[index].generatedTitle = '';
//           setError(`タイトルの生成に失敗しました: ${err.message}`);
//         }
//       });

//       await queue.addAll(generateTitleTasks);

//       setProducts(updatedProducts);
//       setMessage('タイトルの生成が完了しました');
//       setSnackbarMessage('タイトルの生成が完了しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//       updateDisplayedProducts(currentPage);
//     } catch (err) {
//       console.error('タイトル生成エラー:', err);
//       setError(`タイトルの生成に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//       setProgress(0);
//     }
//   };

//   // 生成されたタイトルをスプレッドシートに反映
//   const applyUpdatedTitles = async () => {
//     const updatedProducts = products.filter((product) => product.selected && product.generatedTitle);
//     if (updatedProducts.length === 0) {
//       setError('更新するタイトルがありません');
//       setSnackbarMessage('更新するタイトルがありません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     try {
//       setLoading(true);

//       // シートデータを再取得
//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}?valueRenderOption=FORMATTED_VALUE`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       const data = response.data.values;
//       const headers = data[0];
//       const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');

//       if (titleIndex === -1) {
//         setError('"Title"カラムがスプレッドシートに存在しません');
//         setSnackbarMessage('"Title"カラムが見つかりません');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//         return;
//       }

//       // 生成されたタイトルを"Title"カラムに反映
//       updatedProducts.forEach((product) => {
//         const rowIndex = product.id + 1; // ヘッダー行を考慮
//         if (!data[rowIndex]) {
//           data[rowIndex] = [];
//         }
//         data[rowIndex][titleIndex] = product.generatedTitle;
//       });

//       // シートデータを更新
//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}?valueInputOption=USER_ENTERED`,
//         { values: data },
//         { headers: { Authorization: `Bearer ${token}` } }
//       );

//       setSnackbarMessage('スプレッドシートの"Title"カラムを更新しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//       fetchSheetData();
//     } catch (err) {
//       console.error('スプレッドシート更新エラー:', err);
//       setError(`スプレッドシートの更新に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // 単一の商品タイトルを生成
//   async function generateSingleTitle(originalTitle, jpDesc, apiKey) {
//     const cacheKey = originalTitle + jpDesc.slice(0, 150);
//     const cachedTitle = getCachedTitle(cacheKey);
//     if (cachedTitle) {
//       return cachedTitle;
//     }

//     try {
//       const url = 'https://api.openai.com/v1/chat/completions';
//       const limitedJpDesc = jpDesc.slice(0, 150);
//       const requestData = {
//         model: 'gpt-4o-2024-08-06',
//         messages: [
//           {
//             role: 'system',
//             content: `You are an AI assistant specializing in eBay product title optimization. Based on the given original title and Japanese product information, create an effective English title within 80 characters.
// Order of title composition:
// 1. brand name (if applicable)
// 2. model name or product name/character name (if applicable)
// 3. product type (if applicable)
// 4. key features (color, material, size) (if applicable)
// 5. distinctive elements or uniqueness (if applicable)
// 6. condition (new/used) (if applicable)
// 7. important keywords (if applicable)
// NOTES:
// - Add or optimize new information while retaining important information from the original title
// - Avoid unnecessary adjectives and words such as “eBay
// - Use abbreviations and common names appropriately
// - Think from the searcher's perspective and include search terms that buyers are likely to use
// - If information is unclear or ambiguous, omit it.
// - Use only reliable information and do not include guesswork or uncertain information.
// - Do not extract from Japanese descriptions that are a list of words.
// - Titles should be generated in English and should not exceed 80 characters.`,
//           },
//           {
//             role: 'user',
//             content: `元のタイトル：「${originalTitle}」
// 日本語の商品説明：「${limitedJpDesc}」
// この情報を基に、最適化された新しいeBayの商品タイトルを生成してください。また日本語の商品説明が空の場合は元のタイトルから生成してください`,
//           },
//         ],
//         max_tokens: 100,
//       };

//       const response = await axios.post(url, requestData, {
//         headers: { Authorization: `Bearer ${apiKey}` },
//       });

//       const generatedTitle = response.data.choices[0].message.content.trim();
//       setCachedTitle(cacheKey, generatedTitle);
//       return generatedTitle;
//     } catch (error) {
//       throw error;
//     }
//   }

//   // カスタマイズダイアログを開く
//   const handleCustomizeDialogOpen = () => {
//     setCustomizeDialogOpen(true);
//   };

//   // カテゴリーの変更ハンドラー
//   const handleCategoryChange = (event) => {
//     const selectedName = event.target.value;
//     setSelectedCategory(selectedName);

//     if (selectedName) {
//       const category = customCategories.find((cat) => cat.name === selectedName);
//       if (category) {
//         setDeleteStrings(category.deleteStrings || []);
//         setReplacePairs(category.replacePairs || []);
//         setPrependText(category.prependText || '');
//         setAppendText(category.appendText || '');
//         setLimitTitleLength(category.limitTitleLength || false);
//         setCategoryName(category.name);
//       }
//     } else {
//       // 新しいカテゴリーを作成する場合、初期状態にリセット
//       resetCustomizationSettings();
//     }
//   };

//   // カスタマイズ設定を初期状態にリセット
//   const resetCustomizationSettings = () => {
//     setDeleteStrings([]);
//     setReplacePairs([]);
//     setPrependText('');
//     setAppendText('');
//     setLimitTitleLength(false);
//     setCategoryName('');
//     setSelectedCategory('');
//   };

//   // 新しいカテゴリーを作成
//   const handleCreateNewCategory = () => {
//     // カテゴリー選択をクリアし、初期状態にリセット
//     resetCustomizationSettings();
//   };

//   // カテゴリーを保存または更新する
//   const saveOrUpdateCategory = () => {
//     if (!categoryName.trim()) {
//       setSnackbarMessage('カテゴリー名を入力してください');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     const newCategory = {
//       name: categoryName.trim(),
//       deleteStrings,
//       replacePairs,
//       prependText,
//       appendText,
//       limitTitleLength,
//     };

//     let updatedCategories;
//     if (selectedCategory) {
//       // 既存のカテゴリーを更新
//       updatedCategories = customCategories.map((cat) =>
//         cat.name === selectedCategory ? newCategory : cat
//       );
//       setSnackbarMessage('カテゴリーを更新しました');
//     } else {
//       // 新しいカテゴリーを追加
//       updatedCategories = [...customCategories, newCategory];
//       setSnackbarMessage('新しいカテゴリーを保存しました');
//     }

//     setCustomCategories(updatedCategories);
//     saveCategoriesToLocalStorage(updatedCategories);
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//     setSelectedCategory(newCategory.name);
//   };

//   // カテゴリーを削除する
//   const deleteCategory = () => {
//     if (!selectedCategory) return;

//     const updatedCategories = customCategories.filter((cat) => cat.name !== selectedCategory);
//     setCustomCategories(updatedCategories);
//     saveCategoriesToLocalStorage(updatedCategories);
//     resetCustomizationSettings();
//     setSnackbarMessage('カテゴリーを削除しました');
//     setSnackbarSeverity('info');
//     setSnackbarOpen(true);
//   };

//   // タイトルカスタマイズの適用
//   const customizeTitles = () => {
//     const updatedProducts = products.map((product) => {
//       if (product.selected && product.generatedTitle) {
//         let newTitle = product.generatedTitle;

//         // 複数の削除文字列を処理
//         deleteStrings.forEach((deleteStr) => {
//           if (deleteStr) {
//             const regex = new RegExp(deleteStr, 'g');
//             newTitle = newTitle.replace(regex, '');
//           }
//         });

//         // 複数の置換ペアを処理
//         replacePairs.forEach((pair) => {
//           const { find, replace } = pair;
//           if (find) {
//             const regex = new RegExp(find, 'g');
//             newTitle = newTitle.replace(regex, replace);
//           }
//         });

//         // 先頭と末尾の追加文字列を処理
//         if (prependText) {
//           newTitle = `${prependText} ${newTitle}`;
//         }
//         if (appendText) {
//           newTitle = `${newTitle} ${appendText}`;
//         }

//         // タイトルを80文字に制限
//         if (limitTitleLength) {
//           newTitle = newTitle.slice(0, 80);
//         }

//         return { ...product, generatedTitle: newTitle };
//       }
//       return product;
//     });
//     setProducts(updatedProducts);
//     updateDisplayedProducts(currentPage);

//     setSnackbarMessage('タイトルをカスタマイズしました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   // 生成されたタイトルを更新するための関数
//   const updateProducts = (updatedProducts) => {
//     const newProducts = products.map((product) => {
//       const updatedProduct = updatedProducts.find((p) => p.id === product.id);
//       return updatedProduct ? updatedProduct : product;
//     });
//     setProducts(newProducts);
//     updateDisplayedProducts(currentPage);
//   };

//   // キャッシュをクリア
//   const handleClearTitleCache = () => {
//     clearTitleCache();
//     setSnackbarMessage('キャッシュをクリアしました');
//     setSnackbarSeverity('info');
//     setSnackbarOpen(true);
//   };

//   // 削除文字列のチップ関連の関数
//   const handleDeleteStringKeyPress = (event) => {
//     if (event.key === 'Enter' && event.target.value.trim() !== '') {
//       setDeleteStrings([...deleteStrings, event.target.value.trim()]);
//       event.target.value = '';
//     }
//   };

//   const handleDeleteChip = (chipToDelete) => {
//     setDeleteStrings((chips) => chips.filter((chip) => chip !== chipToDelete));
//   };

//   // 置換ペア関連の関数
//   const handleAddReplacePair = () => {
//     setReplacePairs([...replacePairs, { find: '', replace: '' }]);
//   };

//   const handleReplacePairChange = (index, field, value) => {
//     const newReplacePairs = [...replacePairs];
//     newReplacePairs[index][field] = value;
//     setReplacePairs(newReplacePairs);
//   };

//   const handleRemoveReplacePair = (index) => {
//     const newReplacePairs = replacePairs.filter((_, i) => i !== index);
//     setReplacePairs(newReplacePairs);
//   };

//   // 商品説明の編集
//   const handleEditJpDesc = (productId, newDesc) => {
//     const updatedProducts = products.map((product) =>
//       product.id === productId ? { ...product, editableJpDesc: newDesc } : product
//     );
//     setProducts(updatedProducts);
//     updateDisplayedProducts(currentPage);
//   };

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <Paper
//       elevation={3}
//       sx={{
//         p: 4,
//         mt: 1,
//         mb: 1,
//         maxWidth: '100%',
//         margin: '0 auto',
//         overflow: 'hidden',
//       }}
//     >
//       <Box sx={{ maxWidth: '1600px', margin: '0 auto' }}>
//         {/* ヘッダーセクション */}
//         <Grid container spacing={3} alignItems="center">
//           <Grid item xs={12} md={6}>
//             <Typography variant="h5" gutterBottom>
//               AIを活用したタイトル生成
//             </Typography>
//             <Typography variant="body1" paragraph>
//               日本語の商品説明から英語の商品タイトルを自動生成します。
//               タイトルカスタマイズは、生成されたタイトルを指定ワードで一括に、置換、追加、削除することができます。
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography variant="subtitle1" gutterBottom>
//               主な機能：
//             </Typography>
//             <Grid container spacing={1}>
//               {[
//                 '日本語の商品説明から英語の商品タイトルを生成',
//                 'タイトルの一括編集とカスタマイズ',
//                 '複数の商品を一括で処理',
//                 '生成されたタイトルのプレビューと編集',
//               ].map((feature, index) => (
//                 <Grid item xs={6} key={index}>
//                   <Typography variant="body2">• {feature}</Typography>
//                 </Grid>
//               ))}
//             </Grid>
//           </Grid>
//         </Grid>

//         {/* アクションボタンセクション */}
//         <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
//           <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<Add />}>
//             タイトルをAIで生成する
//           </Button>
//           <Button
//             variant="outlined"
//             color="primary"
//             onClick={handleClearTitleCache}
//             startIcon={<FormatColorResetIcon />}
//           >
//             キャッシュをクリア
//           </Button>
//         </Box>

//         {/* ダイアログセクション */}
//         <Dialog open={open} onClose={handleClose} fullScreen>
//           <DialogTitle>AIでタイトルを生成する</DialogTitle>
//           <DialogContent>
//             {/* レイアウトを左右に分割 */}
//             <Grid container spacing={2}>
//               {/* 左側のセクション */}
//               <Grid item xs={12} md={2}>
//                 {/* カテゴリー選択とカスタマイズ */}
//                 <Typography variant="h6">タイトルカスタマイズ</Typography>
//                 <Grid container spacing={2}>
//                   <Grid item xs={12}>
//                     <FormControl fullWidth margin="normal">
//                       <InputLabel id="category-select-label">カテゴリーを選択</InputLabel>
//                       <Select
//                         labelId="category-select-label"
//                         value={selectedCategory}
//                         onChange={handleCategoryChange}
//                       >
//                         {customCategories.map((cat) => (
//                           <MenuItem key={cat.name} value={cat.name}>
//                             {cat.name}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       label="カテゴリー名"
//                       fullWidth
//                       margin="normal"
//                       value={categoryName}
//                       onChange={(e) => setCategoryName(e.target.value)}
//                       placeholder="新しいカテゴリー名を入力"
//                     />
//                     <Button
//                       variant="outlined"
//                       color="primary"
//                       onClick={handleCreateNewCategory}
//                       sx={{ mt: 1 }}
//                     >
//                       新しいカテゴリーを作成
//                     </Button>
//                   </Grid>
//                   {/* 削除する文字列 */}
//                   <Grid item xs={12}>
//                     <Typography variant="subtitle1">削除する文字列</Typography>
//                     <TextField
//                       placeholder="削除したい文字列を入力し、Enterキーを押してください"
//                       onKeyPress={handleDeleteStringKeyPress}
//                       fullWidth
//                     />
//                     <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
//                       {deleteStrings.map((string, index) => (
//                         <Chip key={index} label={string} onDelete={() => handleDeleteChip(string)} />
//                       ))}
//                     </Box>
//                   </Grid>
//                   {/* 置換ペア */}
//                   <Grid item xs={12}>
//                     <Typography variant="subtitle1">置換ペア</Typography>
//                     {replacePairs.map((pair, index) => (
//                       <Box
//                         key={index}
//                         sx={{ display: 'flex', gap: 1, alignItems: 'center', mt: 1 }}
//                       >
//                         <TextField
//                           label="置換する文字列"
//                           value={pair.find}
//                           onChange={(e) => handleReplacePairChange(index, 'find', e.target.value)}
//                         />
//                         <TextField
//                           label="置換後の文字列"
//                           value={pair.replace}
//                           onChange={(e) => handleReplacePairChange(index, 'replace', e.target.value)}
//                         />
//                         <IconButton color="secondary" onClick={() => handleRemoveReplacePair(index)}>
//                           <Delete />
//                         </IconButton>
//                       </Box>
//                     ))}
//                     <Button
//                       variant="outlined"
//                       startIcon={<Add />}
//                       onClick={handleAddReplacePair}
//                       sx={{ mt: 2 }}
//                     >
//                       置換ペアを追加
//                     </Button>
//                   </Grid>
//                   {/* 先頭・末尾に追加する文字列 */}
//                   <Grid item xs={12}>
//                     <TextField
//                       label="先頭に追加する文字列"
//                       fullWidth
//                       margin="normal"
//                       value={prependText}
//                       onChange={(e) => setPrependText(e.target.value)}
//                       helperText="タイトルの先頭に追加します"
//                     />
//                     <TextField
//                       label="末尾に追加する文字列"
//                       fullWidth
//                       margin="normal"
//                       value={appendText}
//                       onChange={(e) => setAppendText(e.target.value)}
//                       helperText="タイトルの末尾に追加します"
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <FormControlLabel
//                       control={
//                         <Switch
//                           checked={limitTitleLength}
//                           onChange={(e) => setLimitTitleLength(e.target.checked)}
//                         />
//                       }
//                       label="80文字に制限する"
//                     />
//                   </Grid>
//                   {/* カテゴリーの保存・更新・削除ボタン */}
//                   <Grid item xs={12}>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={saveOrUpdateCategory}
//                       disabled={!categoryName.trim()}
//                       fullWidth
//                     >
//                       {selectedCategory ? 'カテゴリーを更新' : 'カテゴリーを保存'}
//                     </Button>
//                     {selectedCategory && (
//                       <Button
//                         variant="outlined"
//                         color="secondary"
//                         onClick={deleteCategory}
//                         fullWidth
//                         sx={{ mt: 1 }}
//                       >
//                         カテゴリーを削除
//                       </Button>
//                     )}
//                   </Grid>
//                   {/* タイトルカスタマイズの適用ボタン */}
//                   <Grid item xs={12}>
//                     <Button
//                       variant="contained"
//                       color="secondary"
//                       onClick={customizeTitles}
//                       fullWidth
//                       sx={{ mt: 2 }}
//                     >
//                       タイトルカスタマイズを適用
//                     </Button>
//                   </Grid>
//                 </Grid>
//               </Grid>

//               {/* 右側のセクション */}
//               <Grid item xs={12} md={10}>
//                 {/* アクションボタン */}
//                 <Grid container spacing={2} style={{ marginBottom: '20px' }}>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={generateProductTitles}
//                       disabled={loading || !apiKey}
//                     >
//                       AIでタイトルを生成する
//                     </Button>
//                   </Grid>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="secondary"
//                       onClick={applyUpdatedTitles}
//                       disabled={loading}
//                     >
//                       タイトルをスプレッドシートに更新する
//                     </Button>
//                   </Grid>
//                   <Grid item>
//                     <Button
//                       variant="contained"
//                       color="success"
//                       onClick={() => setCustomPromptDialogOpen(true)}
//                       startIcon={<EditIcon />}
//                       disabled={loading}
//                     >
//                       カスタムプロンプトで生成
//                     </Button>
//                   </Grid>
//                 </Grid>

//                 {/* 進捗状況表示 */}
//                 {loading && (
//                   <Box sx={{ width: '100%', mb: 2 }}>
//                     <LinearProgress variant="determinate" value={progress} />
//                     <Typography variant="body2" color="text.secondary" align="center">
//                       {`${Math.round(progress)}%`}
//                     </Typography>
//                   </Box>
//                 )}

//                 {/* エラーメッセージ */}
//                 {error && (
//                   <Typography color="error" style={{ marginTop: '20px' }}>
//                     {error}
//                   </Typography>
//                 )}
//                 {/* 成功メッセージ */}
//                 {message && (
//                   <Typography color="primary" style={{ marginTop: '20px' }}>
//                     {message}
//                   </Typography>
//                 )}

//                 {/* 商品テーブル */}
//                 <TableContainer component={Paper}>
//                   <Table stickyHeader aria-label="products-table">
//                     <TableHead>
//                       <TableRow>
//                         <TableCell padding="checkbox">
//                           <Checkbox
//                             checked={products.every((product) => product.selected)}
//                             onChange={handleSelectAll}
//                             indeterminate={
//                               products.some((product) => product.selected) &&
//                               !products.every((product) => product.selected)
//                             }
//                           />
//                         </TableCell>
//                         <TableCell>商品タイトル</TableCell>
//                         <TableCell>日本語の商品説明</TableCell>
//                         <TableCell>生成されたタイトル</TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>
//                       {displayedProducts.map((product) => (
//                         <TableRow
//                           key={product.id}
//                           onClick={() => handleFocusProduct(product.id)}
//                           selected={focusedProduct === product.id}
//                           hover
//                         >
//                           <TableCell padding="checkbox">
//                             <Checkbox
//                               checked={product.selected}
//                               onChange={() => handleSelectProduct(product.id)}
//                               onClick={(e) => e.stopPropagation()}
//                             />
//                           </TableCell>
//                           <TableCell>{product.title}</TableCell>
//                           <TableCell>
//                             <TextField
//                               multiline
//                               fullWidth
//                               variant="outlined"
//                               value={product.editableJpDesc}
//                               onChange={(e) => handleEditJpDesc(product.id, e.target.value)}
//                               inputProps={{
//                                 style: {
//                                   fontSize: '0.8rem',
//                                   padding: '2px', // 内部のパディングを指定
//                                   lineHeight: '1.2', // 行間を調整
//                                 },
//                               }}
//                             />
//                           </TableCell>
//                           <TableCell>
//                             <TextField
//                               multiline
//                               fullWidth
//                               variant="outlined"
//                               value={product.generatedTitle}
//                               onChange={(event) => handleGeneratedTitleChange(event, product.id)}
//                             />
//                           </TableCell>
//                         </TableRow>
//                       ))}
//                     </TableBody>
//                   </Table>
//                 </TableContainer>

//                 {/* ページネーション */}
//                 {itemsPerPage !== 'All' && (
//                   <Pagination
//                     count={totalPages}
//                     page={currentPage}
//                     onChange={handlePageChange}
//                     color="primary"
//                     style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
//                   />
//                 )}
//               </Grid>
//             </Grid>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleClose}>閉じる</Button>
//           </DialogActions>
//         </Dialog>

//         {/* カスタムプロンプトダイアログ */}
//         <CustomPromptDialog
//           open={customPromptDialogOpen}
//           onClose={() => setCustomPromptDialogOpen(false)}
//           apiKey={apiKey}
//           selectedProducts={products.filter((product) => product.selected)}
//           updateProducts={updateProducts}
//         />

//         {/* スナックバー通知 */}
//         <Snackbar
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'left',
//           }}
//           open={snackbarOpen}
//           autoHideDuration={6000}
//           onClose={() => setSnackbarOpen(false)}
//         >
//           <Alert
//             onClose={() => setSnackbarOpen(false)}
//             severity={snackbarSeverity}
//             sx={{ width: '100%' }}
//           >
//             {snackbarMessage}
//           </Alert>
//         </Snackbar>
//       </Box>
//     </Paper>
//   );
// };

// export default TitleGenerationComponent;



// // titleGenerationService.js

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Typography,
//   TextField,
//   Checkbox,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Grid,
//   Snackbar,
//   Alert,
//   Box,
//   LinearProgress,
//   FormControl,
//   Select,
//   MenuItem,
//   InputLabel,
//   FormControlLabel,
//   Switch,
//   Chip,
//   IconButton,
// } from '@mui/material';
// import { Add, Delete } from '@mui/icons-material';
// import PQueue from 'p-queue';
// import CustomPromptDialog from './CustomPromptDialog'; // カスタムプロンプトダイアログをインポート
// import {
//   loadCacheFromLocalStorage,
//   saveCacheToLocalStorage,
//   getCachedTitle,
//   setCachedTitle,
//   clearTitleCache,
// } from './titleCache'; // キャッシュ関連の関数をインポート

// // 定数の定義
// const BATCH_SIZE = 10;
// const INITIAL_RETRY_DELAY = 1000;
// const MAX_RETRIES = 3;

// // コンポーネントの定義
// const TitleGenerationComponent = (props) => {
//   // 状態管理のフック
//   const { spreadsheetId, sheetName, token, apiKey } = props;
//   const [open, setOpen] = useState(false);
//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [progress, setProgress] = useState(0);
//   const [error, setError] = useState('');
//   const [message, setMessage] = useState('');
//   const [customPromptDialogOpen, setCustomPromptDialogOpen] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info');
//   const [deleteStrings, setDeleteStrings] = useState([]); // 複数の削除文字列を管理
//   const [replacePairs, setReplacePairs] = useState([]); // 複数の置換ペアを管理
//   const [prependText, setPrependText] = useState('');
//   const [appendText, setAppendText] = useState('');
//   const [limitTitleLength, setLimitTitleLength] = useState(false);
//   const [customizeDialogOpen, setCustomizeDialogOpen] = useState(false);
//   const [categoryName, setCategoryName] = useState('');
//   const [customCategories, setCustomCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState('');

//   // キャッシュの読み込み
//   useEffect(() => {
//     loadCacheFromLocalStorage();
//     loadCategoriesFromLocalStorage();
//   }, []);

//   // カスタムカテゴリーをローカルストレージから読み込む
//   function loadCategoriesFromLocalStorage() {
//     const savedCategories = localStorage.getItem('customCategories');
//     if (savedCategories) {
//       setCustomCategories(JSON.parse(savedCategories));
//     }
//   }

//   // カスタムカテゴリーをローカルストレージに保存
//   function saveCategoriesToLocalStorage(categories) {
//     localStorage.setItem('customCategories', JSON.stringify(categories));
//   }

//   // p-queueの設定
//   const CONCURRENCY = 2;
//   const INTERVAL = 1000;
//   const INTERVAL_CAP = 5;
//   const queue = new PQueue({
//     concurrency: CONCURRENCY,
//     interval: INTERVAL,
//     intervalCap: INTERVAL_CAP,
//   });

//   useEffect(() => {
//     if (open) {
//       fetchSheetData();
//     }
//   }, [open]);

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   // シートデータの取得
//   const fetchSheetData = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       const data = response.data.values;
//       const headers = data[0];
//       const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
//       const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');

//       if (titleIndex === -1) {
//         throw new Error('Titleカラムが見つかりません');
//       }

//       const productData = data.slice(1).map((row, index) => ({
//         id: index,
//         title: row[titleIndex] || '',
//         jpDesc: jpDescIndex !== -1 ? row[jpDescIndex] || '' : '',
//         generatedTitle: '',
//         selected: false,
//       }));

//       setProducts(productData);
//       setSnackbarMessage('シートデータを正常に取得しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       console.error('シートデータの取得エラー:', err);
//       setError(`シートデータの取得に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // 商品の選択ハンドラー
//   const handleSelectProduct = (event, productId) => {
//     const updatedProducts = products.map((product) =>
//       product.id === productId ? { ...product, selected: event.target.checked } : product
//     );
//     setProducts(updatedProducts);
//   };

//   // 生成されたタイトルの編集ハンドラー
//   const handleGeneratedTitleChange = (event, productId) => {
//     const updatedProducts = products.map((product) =>
//       product.id === productId ? { ...product, generatedTitle: event.target.value } : product
//     );
//     setProducts(updatedProducts);
//   };

//   // AIによるタイトル生成（デフォルトプロンプト）
//   const generateProductTitles = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     const selectedProductsData = products.filter((product) => product.selected);
//     if (selectedProductsData.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     try {
//       const total = selectedProductsData.length;
//       let completed = 0;

//       const updatedProducts = [...products];

//       const generateTitleTasks = selectedProductsData.map((product) => async () => {
//         const index = products.findIndex((p) => p.id === product.id);
//         try {
//           const generatedTitle = await generateSingleTitle(product.title, product.jpDesc, apiKey);
//           updatedProducts[index].generatedTitle = generatedTitle;
//           completed++;
//           setProgress(Math.round((completed / total) * 100));
//         } catch (err) {
//           console.error(`商品ID ${product.id} のタイトル生成エラー:`, err);
//           updatedProducts[index].generatedTitle = '';
//           setError(`タイトルの生成に失敗しました: ${err.message}`);
//         }
//       });

//       await queue.addAll(generateTitleTasks);

//       setProducts(updatedProducts);
//       setMessage('タイトルの生成が完了しました');
//       setSnackbarMessage('タイトルの生成が完了しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       console.error('タイトル生成エラー:', err);
//       setError(`タイトルの生成に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//       setProgress(0);
//     }
//   };

//   // 生成されたタイトルをスプレッドシートに反映
//   const applyUpdatedTitles = async () => {
//     const updatedProducts = products.filter((product) => product.selected && product.generatedTitle);
//     if (updatedProducts.length === 0) {
//       setError('更新するタイトルがありません');
//       setSnackbarMessage('更新するタイトルがありません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     try {
//       setLoading(true);

//       // シートデータを再取得
//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}?valueRenderOption=FORMATTED_VALUE`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       const data = response.data.values;
//       const headers = data[0];
//       const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');

//       if (titleIndex === -1) {
//         setError('"Title"カラムがスプレッドシートに存在しません');
//         setSnackbarMessage('"Title"カラムが見つかりません');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//         return;
//       }

//       // 生成されたタイトルを"Title"カラムに反映
//       updatedProducts.forEach((product) => {
//         const rowIndex = product.id + 1; // ヘッダー行を考慮
//         if (!data[rowIndex]) {
//           data[rowIndex] = [];
//         }
//         data[rowIndex][titleIndex] = product.generatedTitle;
//       });

//       // シートデータを更新
//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}?valueInputOption=USER_ENTERED`,
//         { values: data },
//         { headers: { Authorization: `Bearer ${token}` } }
//       );

//       setSnackbarMessage('スプレッドシートの"Title"カラムを更新しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//       fetchSheetData();
//     } catch (err) {
//       console.error('スプレッドシート更新エラー:', err);
//       setError(`スプレッドシートの更新に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // 単一の商品タイトルを生成
//   async function generateSingleTitle(originalTitle, jpDesc, apiKey) {
//     const cacheKey = originalTitle + jpDesc.slice(0, 150);
//     const cachedTitle = getCachedTitle(cacheKey);
//     if (cachedTitle) {
//       return cachedTitle;
//     }

//     try {
//       const url = 'https://api.openai.com/v1/chat/completions';
//       const limitedJpDesc = jpDesc.slice(0, 150);
//       const requestData = {
//         model: 'gpt-4o-2024-08-06',
//         messages: [
//           {
//             role: 'system',
//             content: `You are an AI assistant specializing in eBay product title optimization. Based on the given original title and Japanese product information, create an effective English title within 80 characters.
// Order of title composition:
// 1. brand name (if applicable)
// 2. model name or product name/character name (if applicable)
// 3. product type (if applicable)
// 4. key features (color, material, size) (if applicable)
// 5. distinctive elements or uniqueness (if applicable)
// 6. condition (new/used) (if applicable)
// 7. important keywords (if applicable)
// NOTES:
// - Add or optimize new information while retaining important information from the original title
// - Avoid unnecessary adjectives and words such as “eBay
// - Use abbreviations and common names appropriately
// - Think from the searcher's perspective and include search terms that buyers are likely to use
// - If information is unclear or ambiguous, omit it.
// - Use only reliable information and do not include guesswork or uncertain information.
// - Do not extract from Japanese descriptions that are a list of words.
// - Titles should be generated in English and should not exceed 80 characters.`,
//           },
//           {
//             role: 'user',
//             content: `元のタイトル：「${originalTitle}」
// 日本語の商品説明：「${limitedJpDesc}」
// この情報を基に、最適化された新しいeBayの商品タイトルを生成してください。また日本語の商品説明が空の場合は元のタイトルから生成してください`,
//           },
//         ],
//         max_tokens: 100,
//       };

//       const response = await axios.post(url, requestData, {
//         headers: { Authorization: `Bearer ${apiKey}` },
//       });

//       const generatedTitle = response.data.choices[0].message.content.trim();
//       setCachedTitle(cacheKey, generatedTitle);
//       return generatedTitle;
//     } catch (error) {
//       throw error;
//     }
//   }

//   // カスタマイズダイアログを開く
//   const handleCustomizeDialogOpen = () => {
//     setCustomizeDialogOpen(true);
//   };

//   // カテゴリーの変更ハンドラー
//   const handleCategoryChange = (event) => {
//     const selectedName = event.target.value;
//     setSelectedCategory(selectedName);

//     if (selectedName) {
//       const category = customCategories.find((cat) => cat.name === selectedName);
//       if (category) {
//         setDeleteStrings(category.deleteStrings || []);
//         setReplacePairs(category.replacePairs || []);
//         setPrependText(category.prependText || '');
//         setAppendText(category.appendText || '');
//         setLimitTitleLength(category.limitTitleLength || false);
//       }
//     } else {
//       // 新しいカテゴリーを作成する場合、初期状態にリセット
//       resetCustomizationSettings();
//     }
//   };

//   // カスタマイズ設定を初期状態にリセット
//   const resetCustomizationSettings = () => {
//     setDeleteStrings([]);
//     setReplacePairs([]);
//     setPrependText('');
//     setAppendText('');
//     setLimitTitleLength(false);
//     setCategoryName('');
//   };

//   // 新しいカテゴリーを作成
//   const handleCreateNewCategory = () => {
//     // カテゴリー選択をクリアし、初期状態にリセット
//     setSelectedCategory('');
//     resetCustomizationSettings();
//   };

//   // カテゴリーを保存または更新する
//   const saveOrUpdateCategory = () => {
//     if (!categoryName.trim()) {
//       setSnackbarMessage('カテゴリー名を入力してください');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     const newCategory = {
//       name: categoryName.trim(),
//       deleteStrings,
//       replacePairs,
//       prependText,
//       appendText,
//       limitTitleLength,
//     };

//     let updatedCategories;
//     if (selectedCategory) {
//       // 既存のカテゴリーを更新
//       updatedCategories = customCategories.map((cat) =>
//         cat.name === selectedCategory ? newCategory : cat
//       );
//       setSnackbarMessage('カテゴリーを更新しました');
//     } else {
//       // 新しいカテゴリーを追加
//       updatedCategories = [...customCategories, newCategory];
//       setSnackbarMessage('新しいカテゴリーを保存しました');
//     }

//     setCustomCategories(updatedCategories);
//     saveCategoriesToLocalStorage(updatedCategories);
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//     setSelectedCategory(newCategory.name);
//   };

//   // カテゴリーを削除する
//   const deleteCategory = () => {
//     if (!selectedCategory) return;

//     const updatedCategories = customCategories.filter((cat) => cat.name !== selectedCategory);
//     setCustomCategories(updatedCategories);
//     saveCategoriesToLocalStorage(updatedCategories);
//     setSelectedCategory('');
//     resetCustomizationSettings();
//     setSnackbarMessage('カテゴリーを削除しました');
//     setSnackbarSeverity('info');
//     setSnackbarOpen(true);
//   };

//   // タイトルカスタマイズの適用
//   const customizeTitles = () => {
//     const updatedProducts = products.map((product) => {
//       if (product.selected && product.generatedTitle) {
//         let newTitle = product.generatedTitle;

//         // 複数の削除文字列を処理
//         deleteStrings.forEach((deleteStr) => {
//           if (deleteStr) {
//             const regex = new RegExp(deleteStr, 'g');
//             newTitle = newTitle.replace(regex, '');
//           }
//         });

//         // 複数の置換ペアを処理
//         replacePairs.forEach((pair) => {
//           const { find, replace } = pair;
//           if (find) {
//             const regex = new RegExp(find, 'g');
//             newTitle = newTitle.replace(regex, replace);
//           }
//         });

//         // 先頭と末尾の追加文字列を処理
//         if (prependText) {
//           newTitle = `${prependText} ${newTitle}`;
//         }
//         if (appendText) {
//           newTitle = `${newTitle} ${appendText}`;
//         }

//         // タイトルを80文字に制限
//         if (limitTitleLength) {
//           newTitle = newTitle.slice(0, 80);
//         }

//         return { ...product, generatedTitle: newTitle };
//       }
//       return product;
//     });
//     setProducts(updatedProducts);

//     setCustomizeDialogOpen(false);
//     setSnackbarMessage('タイトルをカスタマイズしました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   // 生成されたタイトルを更新するための関数
//   const updateProducts = (updatedProducts) => {
//     const newProducts = products.map((product) => {
//       const updatedProduct = updatedProducts.find((p) => p.id === product.id);
//       return updatedProduct ? updatedProduct : product;
//     });
//     setProducts(newProducts);
//   };

//   // キャッシュをクリア
//   const handleClearTitleCache = () => {
//     clearTitleCache();
//     setSnackbarMessage('キャッシュをクリアしました');
//     setSnackbarSeverity('info');
//     setSnackbarOpen(true);
//   };

//   // 削除文字列のチップ関連の関数
//   const handleDeleteStringKeyPress = (event) => {
//     if (event.key === 'Enter' && event.target.value.trim() !== '') {
//       setDeleteStrings([...deleteStrings, event.target.value.trim()]);
//       event.target.value = '';
//     }
//   };

//   const handleDeleteChip = (chipToDelete) => {
//     setDeleteStrings((chips) => chips.filter((chip) => chip !== chipToDelete));
//   };

//   // 置換ペア関連の関数
//   const handleAddReplacePair = () => {
//     setReplacePairs([...replacePairs, { find: '', replace: '' }]);
//   };

//   const handleReplacePairChange = (index, field, value) => {
//     const newReplacePairs = [...replacePairs];
//     newReplacePairs[index][field] = value;
//     setReplacePairs(newReplacePairs);
//   };

//   const handleRemoveReplacePair = (index) => {
//     const newReplacePairs = replacePairs.filter((_, i) => i !== index);
//     setReplacePairs(newReplacePairs);
//   };

//   return (
//     <Paper>
//       <Box p={3} sx={{ paddingLeft: 6 }}>
//         <Typography variant="h5">AIを活用したタイトル生成</Typography>
//         <Typography variant="body1" sx={{ mt: 5 }}>
//           このツールを使用すると、AIを活用して商品タイトルを自動生成および最適化できます。さらに、カスタマイズ機能により、タイトルの一括編集や調整が可能です。
//           タイトルカスタマイズは、生成されたタイトルを指定ワードで一括に、置換、追加、削除することができます。
//         </Typography>
//         <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
//           <Button variant="contained" color="primary" onClick={handleOpen}>
//             タイトルをAIで生成する
//           </Button>
//           <Button variant="outlined" color="primary" onClick={handleClearTitleCache}>
//             キャッシュをクリア
//           </Button>
//         </Box>
//       </Box>

//       {/* タイトル生成ダイアログ */}
//       <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
//         <DialogTitle>タイトルをAIで生成する</DialogTitle>
//         <DialogContent>
//           {/* アクションボタン */}
//           <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
//             <Grid item>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={generateProductTitles}
//                 disabled={loading || !apiKey}
//               >
//                 AIでタイトルを生成する
//               </Button>
//             </Grid>
//             <Grid item>
//               <Button
//                 variant="contained"
//                 color="secondary"
//                 onClick={applyUpdatedTitles}
//                 disabled={loading}
//               >
//                 タイトルをスプレッドシートに更新する
//               </Button>
//             </Grid>
//             <Grid item>
//               <Button
//                 variant="outlined"
//                 color="primary"
//                 onClick={handleCustomizeDialogOpen}
//                 disabled={loading}
//               >
//                 タイトルをカスタマイズ
//               </Button>
//             </Grid>
//             {/* カスタムプロンプトで生成ボタンを追加 */}
//             <Grid item>
//               <Button
//                 variant="outlined"
//                 color="secondary"
//                 onClick={() => setCustomPromptDialogOpen(true)}
//                 disabled={loading || !apiKey}
//               >
//                 カスタムプロンプトで生成
//               </Button>
//             </Grid>
//           </Grid>

//           {/* カスタムプロンプトダイアログ */}
//           <CustomPromptDialog
//             open={customPromptDialogOpen}
//             onClose={() => setCustomPromptDialogOpen(false)}
//             apiKey={apiKey}
//             selectedProducts={products.filter((product) => product.selected)}
//             updateProducts={updateProducts}
//           />

//           {/* 進捗状況 */}
//           {loading && (
//             <Box sx={{ width: '100%', marginBottom: '20px' }}>
//               <LinearProgress variant="determinate" value={progress} />
//             </Box>
//           )}

//           {/* 商品テーブル */}
//           <TableContainer component={Paper} style={{ maxHeight: '70vh' }}>
//             <Table stickyHeader aria-label="products-table">
//               <TableHead>
//                 <TableRow>
//                   <TableCell padding="checkbox">
//                     <Checkbox
//                       indeterminate={
//                         products.some((product) => product.selected) &&
//                         !products.every((product) => product.selected)
//                       }
//                       checked={products.every((product) => product.selected)}
//                       onChange={(event) => {
//                         const updatedProducts = products.map((product) => ({
//                           ...product,
//                           selected: event.target.checked,
//                         }));
//                         setProducts(updatedProducts);
//                       }}
//                     />
//                   </TableCell>
//                   <TableCell style={{ minWidth: 150 }}>元のタイトル</TableCell>
//                   <TableCell style={{ minWidth: 150 }}>日本語の商品説明</TableCell>
//                   <TableCell style={{ minWidth: 150 }}>生成されたタイトル</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {products.map((product) => (
//                   <TableRow key={product.id}>
//                     <TableCell padding="checkbox">
//                       <Checkbox
//                         checked={product.selected}
//                         onChange={(event) => handleSelectProduct(event, product.id)}
//                       />
//                     </TableCell>
//                     <TableCell
//                       style={{
//                         whiteSpace: 'pre-wrap',
//                         wordWrap: 'break-word',
//                         maxWidth: '300px',
//                       }}
//                     >
//                       {product.title}
//                     </TableCell>
//                     <TableCell
//                       style={{
//                         whiteSpace: 'pre-wrap',
//                         wordWrap: 'break-word',
//                         maxWidth: '300px',
//                       }}
//                     >
//                       {product.jpDesc}
//                     </TableCell>
//                     <TableCell style={{ maxWidth: '300px' }}>
//                       <TextField
//                         multiline
//                         fullWidth
//                         variant="outlined"
//                         value={product.generatedTitle}
//                         onChange={(event) => handleGeneratedTitleChange(event, product.id)}
//                       />
//                     </TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>

//           {/* エラーメッセージ */}
//           {error && (
//             <Typography color="error" style={{ marginTop: '20px' }}>
//               {error}
//             </Typography>
//           )}
//           {/* 成功メッセージ */}
//           {message && (
//             <Typography color="primary" style={{ marginTop: '20px' }}>
//               {message}
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* カスタマイズダイアログ */}
//       <Dialog
//         open={customizeDialogOpen}
//         onClose={() => setCustomizeDialogOpen(false)}
//         maxWidth="md"
//         fullWidth
//       >
//         <DialogTitle>タイトルをカスタマイズ</DialogTitle>
//         <DialogContent>
//           <FormControl fullWidth margin="normal">
//             <InputLabel id="category-select-label">カテゴリーを選択</InputLabel>
//             <Select
//               labelId="category-select-label"
//               value={selectedCategory}
//               onChange={handleCategoryChange}
//             >
//               {customCategories.map((cat) => (
//                 <MenuItem key={cat.name} value={cat.name}>
//                   {cat.name}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
//             <TextField
//               label="カテゴリー名"
//               fullWidth
//               margin="normal"
//               value={categoryName}
//               onChange={(e) => setCategoryName(e.target.value)}
//             />
//             <Button
//               variant="outlined"
//               color="primary"
//               onClick={handleCreateNewCategory}
//               sx={{ ml: 2 }}
//             >
//               新しいカテゴリーを作成
//             </Button>
//           </Box>

//           {/* 削除する文字列の入力 */}
//           <Typography variant="subtitle1" sx={{ mt: 2 }}>
//             削除する文字列
//           </Typography>
//           <TextField
//             placeholder="削除したい文字列を入力し、Enterキーを押してください"
//             onKeyPress={handleDeleteStringKeyPress}
//             fullWidth
//           />
//           <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
//             {deleteStrings.map((string, index) => (
//               <Chip key={index} label={string} onDelete={() => handleDeleteChip(string)} />
//             ))}
//           </Box>

//           {/* 置換ペアの入力 */}
//           <Typography variant="subtitle1" sx={{ mt: 4 }}>
//             置換ペア
//           </Typography>
//           {replacePairs.map((pair, index) => (
//             <Box key={index} sx={{ display: 'flex', gap: 1, alignItems: 'center', mt: 1 }}>
//               <TextField
//                 label="置換する文字列"
//                 value={pair.find}
//                 onChange={(e) => handleReplacePairChange(index, 'find', e.target.value)}
//               />
//               <TextField
//                 label="置換後の文字列"
//                 value={pair.replace}
//                 onChange={(e) => handleReplacePairChange(index, 'replace', e.target.value)}
//               />
//               <IconButton color="secondary" onClick={() => handleRemoveReplacePair(index)}>
//                 <Delete />
//               </IconButton>
//             </Box>
//           ))}
//           <Button
//             variant="outlined"
//             startIcon={<Add />}
//             onClick={handleAddReplacePair}
//             sx={{ mt: 2 }}
//           >
//             置換ペアを追加
//           </Button>

//           {/* 先頭・末尾に追加する文字列 */}
//           <Grid container spacing={2} sx={{ mt: 4 }}>
//             <Grid item xs={6}>
//               <TextField
//                 label="先頭に追加する文字列"
//                 fullWidth
//                 margin="normal"
//                 value={prependText}
//                 onChange={(e) => setPrependText(e.target.value)}
//                 helperText="タイトルの先頭に追加します"
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <TextField
//                 label="末尾に追加する文字列"
//                 fullWidth
//                 margin="normal"
//                 value={appendText}
//                 onChange={(e) => setAppendText(e.target.value)}
//                 helperText="タイトルの末尾に追加します"
//               />
//             </Grid>
//           </Grid>

//           <FormControlLabel
//             control={
//               <Switch
//                 checked={limitTitleLength}
//                 onChange={(e) => setLimitTitleLength(e.target.checked)}
//               />
//             }
//             label="80文字に制限する"
//           />

//           {/* カテゴリーの保存・更新・削除ボタン */}
//           <Box mt={2} display="flex" justifyContent="space-between">
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={saveOrUpdateCategory}
//               disabled={!categoryName.trim()}
//             >
//               {selectedCategory ? 'カテゴリーを更新' : 'カテゴリーを保存'}
//             </Button>
//             {selectedCategory && (
//               <Button variant="outlined" color="secondary" onClick={deleteCategory}>
//                 カテゴリーを削除
//               </Button>
//             )}
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setCustomizeDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={customizeTitles} color="primary">
//             適用
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* スナックバー通知 */}
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={() => setSnackbarOpen(false)}
//       >
//         <Alert
//           onClose={() => setSnackbarOpen(false)}
//           severity={snackbarSeverity}
//           sx={{ width: '100%' }}
//         >
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </Paper>
//   );
// };

// export default TitleGenerationComponent;






// // titleGenerationService.js

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Typography,
//   TextField,
//   Checkbox,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Grid,
//   Snackbar,
//   Alert,
//   Box,
//   LinearProgress,
//   FormControl,
//   Select,
//   MenuItem,
//   InputLabel,
//   FormControlLabel,
//   Switch,
// } from '@mui/material';
// import PQueue from 'p-queue';
// import CustomPromptDialog from './CustomPromptDialog'; // カスタムプロンプトダイアログをインポート
// import {
//   loadCacheFromLocalStorage,
//   saveCacheToLocalStorage,
//   getCachedTitle,
//   setCachedTitle,
//   clearTitleCache,
// } from './titleCache'; // キャッシュ関連の関数をインポート

// // 定数の定義
// const BATCH_SIZE = 10;
// const INITIAL_RETRY_DELAY = 1000;
// const MAX_RETRIES = 3;

// // コンポーネントの定義
// const TitleGenerationComponent = (props) => {
//   // 状態管理のフック
//   const { spreadsheetId, sheetName, token, apiKey } = props;
//   const [open, setOpen] = useState(false);
//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [progress, setProgress] = useState(0);
//   const [error, setError] = useState('');
//   const [message, setMessage] = useState('');
//   const [customPromptDialogOpen, setCustomPromptDialogOpen] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info');
//   const [deleteText, setDeleteText] = useState('');
//   const [replaceText, setReplaceText] = useState('');
//   const [replaceWithText, setReplaceWithText] = useState('');
//   const [prependText, setPrependText] = useState('');
//   const [appendText, setAppendText] = useState('');
//   const [limitTitleLength, setLimitTitleLength] = useState(false);
//   const [customizeDialogOpen, setCustomizeDialogOpen] = useState(false);
//   const [categoryName, setCategoryName] = useState('');
//   const [customCategories, setCustomCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState('');

//   // キャッシュの読み込み
//   useEffect(() => {
//     loadCacheFromLocalStorage();
//     loadCategoriesFromLocalStorage();
//   }, []);

//   // カスタムカテゴリーをローカルストレージから読み込む
//   function loadCategoriesFromLocalStorage() {
//     const savedCategories = localStorage.getItem('customCategories');
//     if (savedCategories) {
//       setCustomCategories(JSON.parse(savedCategories));
//     }
//   }

//   // カスタムカテゴリーをローカルストレージに保存
//   function saveCategoriesToLocalStorage() {
//     localStorage.setItem('customCategories', JSON.stringify(customCategories));
//   }

//   // p-queueの設定
//   const CONCURRENCY = 2;
//   const INTERVAL = 1000;
//   const INTERVAL_CAP = 5;
//   const queue = new PQueue({
//     concurrency: CONCURRENCY,
//     interval: INTERVAL,
//     intervalCap: INTERVAL_CAP,
//   });

//   useEffect(() => {
//     if (open) {
//       fetchSheetData();
//     }
//   }, [open]);

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   // シートデータの取得
//   const fetchSheetData = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       const data = response.data.values;
//       const headers = data[0];
//       const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
//       const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');

//       if (titleIndex === -1) {
//         throw new Error('Titleカラムが見つかりません');
//       }

//       const productData = data.slice(1).map((row, index) => ({
//         id: index,
//         title: row[titleIndex] || '',
//         jpDesc: jpDescIndex !== -1 ? row[jpDescIndex] || '' : '',
//         generatedTitle: '',
//         selected: false,
//       }));

//       setProducts(productData);
//       setSnackbarMessage('シートデータを正常に取得しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       console.error('シートデータの取得エラー:', err);
//       setError(`シートデータの取得に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // 商品の選択ハンドラー
//   const handleSelectProduct = (event, productId) => {
//     const updatedProducts = products.map((product) =>
//       product.id === productId ? { ...product, selected: event.target.checked } : product
//     );
//     setProducts(updatedProducts);
//   };

//   // 生成されたタイトルの編集ハンドラー
//   const handleGeneratedTitleChange = (event, productId) => {
//     const updatedProducts = products.map((product) =>
//       product.id === productId ? { ...product, generatedTitle: event.target.value } : product
//     );
//     setProducts(updatedProducts);
//   };

//   // AIによるタイトル生成（デフォルトプロンプト）
//   const generateProductTitles = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     const selectedProductsData = products.filter((product) => product.selected);
//     if (selectedProductsData.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     try {
//       const total = selectedProductsData.length;
//       let completed = 0;

//       const updatedProducts = [...products];

//       const generateTitleTasks = selectedProductsData.map((product) => async () => {
//         const index = products.findIndex((p) => p.id === product.id);
//         try {
//           const generatedTitle = await generateSingleTitle(product.title, product.jpDesc, apiKey);
//           updatedProducts[index].generatedTitle = generatedTitle;
//           completed++;
//           setProgress(Math.round((completed / total) * 100));
//         } catch (err) {
//           console.error(`商品ID ${product.id} のタイトル生成エラー:`, err);
//           updatedProducts[index].generatedTitle = '';
//           setError(`タイトルの生成に失敗しました: ${err.message}`);
//         }
//       });

//       await queue.addAll(generateTitleTasks);

//       setProducts(updatedProducts);
//       setMessage('タイトルの生成が完了しました');
//       setSnackbarMessage('タイトルの生成が完了しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       console.error('タイトル生成エラー:', err);
//       setError(`タイトルの生成に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//       setProgress(0);
//     }
//   };

//   // 生成されたタイトルをスプレッドシートに反映
//   const applyUpdatedTitles = async () => {
//     const updatedProducts = products.filter((product) => product.selected && product.generatedTitle);
//     if (updatedProducts.length === 0) {
//       setError('更新するタイトルがありません');
//       setSnackbarMessage('更新するタイトルがありません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     try {
//       setLoading(true);

//       // シートデータを再取得
//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}?valueRenderOption=FORMATTED_VALUE`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       const data = response.data.values;
//       const headers = data[0];
//       const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');

//       if (titleIndex === -1) {
//         setError('"Title"カラムがスプレッドシートに存在しません');
//         setSnackbarMessage('"Title"カラムが見つかりません');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//         return;
//       }

//       // 生成されたタイトルを"Title"カラムに反映
//       updatedProducts.forEach((product) => {
//         const rowIndex = product.id + 1; // ヘッダー行を考慮
//         if (!data[rowIndex]) {
//           data[rowIndex] = [];
//         }
//         data[rowIndex][titleIndex] = product.generatedTitle;
//       });

//       // シートデータを更新
//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}?valueInputOption=USER_ENTERED`,
//         { values: data },
//         { headers: { Authorization: `Bearer ${token}` } }
//       );

//       setSnackbarMessage('スプレッドシートの"Title"カラムを更新しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//       fetchSheetData();
//     } catch (err) {
//       console.error('スプレッドシート更新エラー:', err);
//       setError(`スプレッドシートの更新に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // 単一の商品タイトルを生成
//   async function generateSingleTitle(originalTitle, jpDesc, apiKey) {
//     const cacheKey = originalTitle + jpDesc.slice(0, 150);
//     const cachedTitle = getCachedTitle(cacheKey);
//     if (cachedTitle) {
//       return cachedTitle;
//     }

//     try {
//       const url = 'https://api.openai.com/v1/chat/completions';
//       const limitedJpDesc = jpDesc.slice(0, 150);
//       const requestData = {
//         model: 'gpt-4o-2024-08-06',
//         messages: [
//           {
//             role: 'system',
//             content: `You are an AI assistant specializing in eBay product title optimization. Based on the given original title and Japanese product information, create an effective English title within 80 characters.
// Order of title composition:
// 1. brand name (if applicable)
// 2. model name or product name/character name (if applicable)
// 3. product type (if applicable)
// 4. key features (color, material, size) (if applicable)
// 5. distinctive elements or uniqueness (if applicable)
// 6. condition (new/used) (if applicable)
// 7. important keywords (if applicable)
// NOTES:
// - Add or optimize new information while retaining important information from the original title
// - Avoid unnecessary adjectives and words such as “eBay
// - Use abbreviations and common names appropriately
// - Think from the searcher's perspective and include search terms that buyers are likely to use
// - If information is unclear or ambiguous, omit it.
// - Use only reliable information and do not include guesswork or uncertain information.
// - Do not extract from Japanese descriptions that are a list of words.
// - Titles should be generated in English and should not exceed 80 characters.`,
//           },
//           {
//             role: 'user',
//             content: `元のタイトル：「${originalTitle}」
// 日本語の商品説明：「${limitedJpDesc}」
// この情報を基に、最適化された新しいeBayの商品タイトルを生成してください。また日本語の商品説明が空の場合は元のタイトルから生成してください`,
//           },
//         ],
//         max_tokens: 100,
//       };

//       const response = await axios.post(url, requestData, {
//         headers: { Authorization: `Bearer ${apiKey}` },
//       });

//       const generatedTitle = response.data.choices[0].message.content.trim();
//       setCachedTitle(cacheKey, generatedTitle);
//       return generatedTitle;
//     } catch (error) {
//       throw error;
//     }
//   }

//   // カスタマイズダイアログを開く
//   const handleCustomizeDialogOpen = () => {
//     if (selectedCategory) {
//       const category = customCategories.find((cat) => cat.name === selectedCategory);
//       if (category) {
//         setDeleteText(category.deleteText);
//         setReplaceText(category.replaceText);
//         setReplaceWithText(category.replaceWithText);
//         setPrependText(category.prependText);
//         setAppendText(category.appendText);
//         setLimitTitleLength(category.limitTitleLength);
//       }
//     }
//     setCustomizeDialogOpen(true);
//   };

//   // カテゴリーの変更ハンドラー
//   const handleCategoryChange = (event) => {
//     const selectedName = event.target.value;
//     setSelectedCategory(selectedName);
//     const category = customCategories.find((cat) => cat.name === selectedName);
//     if (category) {
//       setDeleteText(category.deleteText);
//       setReplaceText(category.replaceText);
//       setReplaceWithText(category.replaceWithText);
//       setPrependText(category.prependText);
//       setAppendText(category.appendText);
//       setLimitTitleLength(category.limitTitleLength);
//     }
//   };

//   // 新しいカテゴリーを保存する
//   const saveNewCategory = () => {
//     const newCategory = {
//       name: categoryName,
//       deleteText,
//       replaceText,
//       replaceWithText,
//       prependText,
//       appendText,
//       limitTitleLength,
//     };
//     setCustomCategories([...customCategories, newCategory]);
//     saveCategoriesToLocalStorage();
//     setCategoryName('');
//     setSnackbarMessage('新しいカテゴリーを保存しました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   // 既存のカテゴリーを更新する
//   const updateCategory = () => {
//     const updatedCategories = customCategories.map((cat) => {
//       if (cat.name === selectedCategory) {
//         return {
//           ...cat,
//           deleteText,
//           replaceText,
//           replaceWithText,
//           prependText,
//           appendText,
//           limitTitleLength,
//         };
//       }
//       return cat;
//     });
//     setCustomCategories(updatedCategories);
//     saveCategoriesToLocalStorage();
//     setSnackbarMessage('カテゴリーを更新しました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   // カテゴリーを削除する
//   const deleteCategory = () => {
//     const updatedCategories = customCategories.filter((cat) => cat.name !== selectedCategory);
//     setCustomCategories(updatedCategories);
//     saveCategoriesToLocalStorage();
//     setSelectedCategory('');
//     setSnackbarMessage('カテゴリーを削除しました');
//     setSnackbarSeverity('info');
//     setSnackbarOpen(true);
//   };

//   // タイトルカスタマイズの適用
//   const customizeTitles = () => {
//     const updatedProducts = products.map((product) => {
//       if (product.selected && product.generatedTitle) {
//         let newTitle = product.generatedTitle;

//         // 指定した文字列を削除
//         if (deleteText) {
//           const regex = new RegExp(deleteText, 'g');
//           newTitle = newTitle.replace(regex, '');
//         }

//         // 指定した単語を置換
//         if (replaceText && replaceWithText) {
//           const regex = new RegExp(`\\b${replaceText}\\b`, 'gi');
//           newTitle = newTitle.replace(regex, replaceWithText);
//         }

//         // タイトルの先頭に指定した単語を追加
//         if (prependText) {
//           newTitle = `${prependText} ${newTitle}`;
//         }

//         // タイトルの末尾に指定した単語を追加
//         if (appendText) {
//           newTitle = `${newTitle} ${appendText}`;
//         }

//         // タイトルを80文字に制限
//         if (limitTitleLength) {
//           newTitle = newTitle.slice(0, 80);
//         }

//         return { ...product, generatedTitle: newTitle };
//       }
//       return product;
//     });
//     setProducts(updatedProducts);

//     // カスタマイズ設定を保存
//     if (categoryName) {
//       saveNewCategory();
//     } else if (selectedCategory) {
//       updateCategory();
//     }

//     setCustomizeDialogOpen(false);
//     setSnackbarMessage('タイトルをカスタマイズしました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   // 生成されたタイトルを更新するための関数
//   const updateProducts = (updatedProducts) => {
//     const newProducts = products.map((product) => {
//       const updatedProduct = updatedProducts.find((p) => p.id === product.id);
//       return updatedProduct ? updatedProduct : product;
//     });
//     setProducts(newProducts);
//   };

//   // キャッシュをクリア
//   const handleClearTitleCache = () => {
//     clearTitleCache();
//     setSnackbarMessage('キャッシュをクリアしました');
//     setSnackbarSeverity('info');
//     setSnackbarOpen(true);
//   };

//   return (
//     <Paper>
//       <Box p={3} sx={{ paddingLeft: 6 }}>
//         <Typography variant="h5">AIを活用したタイトル生成</Typography>
//         <Typography variant="body1" sx={{ mt: 5 }}>
//           このツールを使用すると、AIを活用して商品タイトルを自動生成および最適化できます。さらに、カスタマイズ機能により、タイトルの一括編集や調整が可能です。
//           タイトルカスタマイズは、生成されたタイトルを指定ワードで一括に、置換、追加、削除することができます。
//         </Typography>
//         <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
//           <Button variant="contained" color="primary" onClick={handleOpen}>
//             タイトルをAIで生成する
//           </Button>
//           <Button variant="outlined" color="primary" onClick={handleClearTitleCache}>
//             キャッシュをクリア
//           </Button>
//         </Box>
//       </Box>

//       {/* タイトル生成ダイアログ */}
//       <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
//         <DialogTitle>タイトルをAIで生成する</DialogTitle>
//         <DialogContent>
//           {/* アクションボタン */}
//           <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
//             <Grid item>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={generateProductTitles}
//                 disabled={loading || !apiKey}
//               >
//                 AIでタイトルを生成する
//               </Button>
//             </Grid>
//             <Grid item>
//               <Button
//                 variant="contained"
//                 color="secondary"
//                 onClick={applyUpdatedTitles}
//                 disabled={loading}
//               >
//                 タイトルをスプレッドシートに更新する
//               </Button>
//             </Grid>
//             <Grid item>
//               <Button
//                 variant="outlined"
//                 color="primary"
//                 onClick={handleCustomizeDialogOpen}
//                 disabled={loading}
//               >
//                 タイトルをカスタマイズ
//               </Button>
//             </Grid>
//             {/* カスタムプロンプトで生成ボタンを追加 */}
//             <Grid item>
//               <Button
//                 variant="outlined"
//                 color="secondary"
//                 onClick={() => setCustomPromptDialogOpen(true)}
//                 disabled={loading || !apiKey}
//               >
//                 カスタムプロンプトで生成
//               </Button>
//             </Grid>
//           </Grid>

//           {/* カスタムプロンプトダイアログ */}
//           <CustomPromptDialog
//             open={customPromptDialogOpen}
//             onClose={() => setCustomPromptDialogOpen(false)}
//             apiKey={apiKey}
//             selectedProducts={products.filter((product) => product.selected)}
//             updateProducts={updateProducts}
//           />

//           {/* 進捗状況 */}
//           {loading && (
//             <Box sx={{ width: '100%', marginBottom: '20px' }}>
//               <LinearProgress variant="determinate" value={progress} />
//             </Box>
//           )}

//           {/* 商品テーブル */}
//           <TableContainer component={Paper} style={{ maxHeight: '70vh' }}>
//             <Table stickyHeader aria-label="products-table">
//               <TableHead>
//                 <TableRow>
//                   <TableCell padding="checkbox">
//                     <Checkbox
//                       indeterminate={
//                         products.some((product) => product.selected) &&
//                         !products.every((product) => product.selected)
//                       }
//                       checked={products.every((product) => product.selected)}
//                       onChange={(event) => {
//                         const updatedProducts = products.map((product) => ({
//                           ...product,
//                           selected: event.target.checked,
//                         }));
//                         setProducts(updatedProducts);
//                       }}
//                     />
//                   </TableCell>
//                   <TableCell style={{ minWidth: 150 }}>元のタイトル</TableCell>
//                   <TableCell style={{ minWidth: 150 }}>日本語の商品説明</TableCell>
//                   <TableCell style={{ minWidth: 150 }}>生成されたタイトル</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {products.map((product) => (
//                   <TableRow key={product.id}>
//                     <TableCell padding="checkbox">
//                       <Checkbox
//                         checked={product.selected}
//                         onChange={(event) => handleSelectProduct(event, product.id)}
//                       />
//                     </TableCell>
//                     <TableCell
//                       style={{
//                         whiteSpace: 'pre-wrap',
//                         wordWrap: 'break-word',
//                         maxWidth: '300px',
//                       }}
//                     >
//                       {product.title}
//                     </TableCell>
//                     <TableCell
//                       style={{
//                         whiteSpace: 'pre-wrap',
//                         wordWrap: 'break-word',
//                         maxWidth: '300px',
//                       }}
//                     >
//                       {product.jpDesc}
//                     </TableCell>
//                     <TableCell style={{ maxWidth: '300px' }}>
//                       <TextField
//                         multiline
//                         fullWidth
//                         variant="outlined"
//                         value={product.generatedTitle}
//                         onChange={(event) => handleGeneratedTitleChange(event, product.id)}
//                       />
//                     </TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>

//           {/* エラーメッセージ */}
//           {error && (
//             <Typography color="error" style={{ marginTop: '20px' }}>
//               {error}
//             </Typography>
//           )}
//           {/* 成功メッセージ */}
//           {message && (
//             <Typography color="primary" style={{ marginTop: '20px' }}>
//               {message}
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* カスタマイズダイアログ */}
//       <Dialog
//         open={customizeDialogOpen}
//         onClose={() => setCustomizeDialogOpen(false)}
//         maxWidth="md"
//         fullWidth
//       >
//         <DialogTitle>タイトルをカスタマイズ</DialogTitle>
//         <DialogContent>
//           <FormControl fullWidth margin="normal">
//             <InputLabel id="category-select-label">カテゴリーを選択</InputLabel>
//             <Select
//               labelId="category-select-label"
//               value={selectedCategory}
//               onChange={handleCategoryChange}
//             >
//               {customCategories.map((cat) => (
//                 <MenuItem key={cat.name} value={cat.name}>
//                   {cat.name}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           <TextField
//             label="新しいカテゴリー名"
//             fullWidth
//             margin="normal"
//             value={categoryName}
//             onChange={(e) => setCategoryName(e.target.value)}
//             helperText="新しいカテゴリーを作成する場合に入力してください"
//           />

//           <Grid container spacing={2}>
//             <Grid item xs={12}>
//               <TextField
//                 label="削除する文字列"
//                 fullWidth
//                 margin="normal"
//                 value={deleteText}
//                 onChange={(e) => setDeleteText(e.target.value)}
//                 helperText="指定した文字列をタイトルから削除します"
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <TextField
//                 label="置換する単語"
//                 fullWidth
//                 margin="normal"
//                 value={replaceText}
//                 onChange={(e) => setReplaceText(e.target.value)}
//                 helperText="タイトル内の指定した単語を置換します"
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <TextField
//                 label="置換後の単語"
//                 fullWidth
//                 margin="normal"
//                 value={replaceWithText}
//                 onChange={(e) => setReplaceWithText(e.target.value)}
//                 helperText="置換後の単語"
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <TextField
//                 label="先頭に追加する文字列"
//                 fullWidth
//                 margin="normal"
//                 value={prependText}
//                 onChange={(e) => setPrependText(e.target.value)}
//                 helperText="タイトルの先頭に追加します"
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <TextField
//                 label="末尾に追加する文字列"
//                 fullWidth
//                 margin="normal"
//                 value={appendText}
//                 onChange={(e) => setAppendText(e.target.value)}
//                 helperText="タイトルの末尾に追加します"
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <FormControlLabel
//                 control={
//                   <Switch
//                     checked={limitTitleLength}
//                     onChange={(e) => setLimitTitleLength(e.target.checked)}
//                   />
//                 }
//                 label="80文字に制限する"
//               />
//             </Grid>
//           </Grid>

//           {/* カテゴリーの保存・更新・削除ボタン */}
//           <Box mt={2} display="flex" justifyContent="space-between">
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={categoryName ? saveNewCategory : updateCategory}
//               disabled={!categoryName && !selectedCategory}
//             >
//               {categoryName ? '新しいカテゴリーを保存' : 'カテゴリーを更新'}
//             </Button>
//             {selectedCategory && (
//               <Button variant="outlined" color="secondary" onClick={deleteCategory}>
//                 カテゴリーを削除
//               </Button>
//             )}
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setCustomizeDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={customizeTitles} color="primary">
//             適用
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* スナックバー通知 */}
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={() => setSnackbarOpen(false)}
//       >
//         <Alert
//           onClose={() => setSnackbarOpen(false)}
//           severity={snackbarSeverity}
//           sx={{ width: '100%' }}
//         >
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </Paper>
//   );
// };

// export default TitleGenerationComponent;





// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Typography,
//   TextField,
//   Checkbox,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Grid,
//   Snackbar,
//   Alert,
//   Box,
//   LinearProgress,
//   FormControl,
//   Select,
//   MenuItem,
//   InputLabel,
//   FormControlLabel,
//   Switch,
//   List,
//   ListItem,
//   ListItemText,
// } from '@mui/material';
// import PQueue from 'p-queue';
// import CustomPromptDialog from './CustomPromptDialog';


// // 定数の定義
// const BATCH_SIZE = 10;
// const INITIAL_RETRY_DELAY = 1000;
// const MAX_RETRIES = 3;

// // キャッシュとエラーログの管理
// let titleCache = {};

// // キャッシュをローカルストレージに保存
// function saveCacheToLocalStorage() {
//   localStorage.setItem('titleGenerationCache', JSON.stringify(titleCache));
// }

// // キャッシュをローカルストレージから読み込み
// function loadCacheFromLocalStorage() {
//   const savedCache = localStorage.getItem('titleGenerationCache');
//   if (savedCache) {
//     titleCache = JSON.parse(savedCache);
//   }
// }

// // コンポーネントの定義
// const TitleGenerationComponent = (props) => {
//   // 状態管理のフック
//   const { spreadsheetId, sheetName, token, apiKey } = props;
//   const [open, setOpen] = useState(false);
//   const [products, setProducts] = useState([]);
//   const [selectedProducts, setSelectedProducts] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [progress, setProgress] = useState(0);
//   const [error, setError] = useState('');
//   const [message, setMessage] = useState('');
//   // const [customPrompt, setCustomPrompt] = useState('');
//   const [customPromptDialogOpen, setCustomPromptDialogOpen] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info');
//   const [deleteText, setDeleteText] = useState('');
//   const [replaceText, setReplaceText] = useState('');
//   const [replaceWithText, setReplaceWithText] = useState('');
//   const [prependText, setPrependText] = useState('');
//   const [appendText, setAppendText] = useState('');
//   const [limitTitleLength, setLimitTitleLength] = useState(false);
//   const [customizeDialogOpen, setCustomizeDialogOpen] = useState(false);
//   const [categoryName, setCategoryName] = useState('');
//   const [customCategories, setCustomCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState('');

//   // キャッシュの読み込み
//   useEffect(() => {
//     loadCacheFromLocalStorage();
//     loadCategoriesFromLocalStorage();
//   }, []);

//   // カスタムカテゴリーをローカルストレージから読み込む
//   function loadCategoriesFromLocalStorage() {
//     const savedCategories = localStorage.getItem('customCategories');
//     if (savedCategories) {
//       setCustomCategories(JSON.parse(savedCategories));
//     }
//   }

//   // カスタムカテゴリーをローカルストレージに保存
//   function saveCategoriesToLocalStorage() {
//     localStorage.setItem('customCategories', JSON.stringify(customCategories));
//   }

//   // p-queueの設定
//   const CONCURRENCY = 2;
//   const INTERVAL = 1000;
//   const INTERVAL_CAP = 5;
//   const queue = new PQueue({
//     concurrency: CONCURRENCY,
//     interval: INTERVAL,
//     intervalCap: INTERVAL_CAP,
//   });

//   useEffect(() => {
//     if (open) {
//       fetchSheetData();
//     }
//   }, [open]);

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   // シートデータの取得
//   const fetchSheetData = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       const data = response.data.values;
//       const headers = data[0];
//       const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
//       const jpDescIndex = headers.findIndex((header) => header.toLowerCase() === 'jp_desc');

//       if (titleIndex === -1) {
//         throw new Error('Titleカラムが見つかりません');
//       }

//       const productData = data.slice(1).map((row, index) => ({
//         id: index,
//         title: row[titleIndex] || '',
//         jpDesc: jpDescIndex !== -1 ? row[jpDescIndex] || '' : '',
//         generatedTitle: '',
//         selected: false,
//       }));

//       setProducts(productData);
//       setSnackbarMessage('シートデータを正常に取得しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       console.error('シートデータの取得エラー:', err);
//       setError(`シートデータの取得に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // 商品の選択ハンドラー
//   const handleSelectProduct = (event, productId) => {
//     const updatedProducts = products.map((product) =>
//       product.id === productId ? { ...product, selected: event.target.checked } : product
//     );
//     setProducts(updatedProducts);
//   };

//   // 生成されたタイトルの編集ハンドラー
//   const handleGeneratedTitleChange = (event, productId) => {
//     const updatedProducts = products.map((product) =>
//       product.id === productId ? { ...product, generatedTitle: event.target.value } : product
//     );
//     setProducts(updatedProducts);
//   };

//   // AIによるタイトル生成
//   const generateProductTitles = async () => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     const selectedProductsData = products.filter((product) => product.selected);
//     if (selectedProductsData.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     try {
//       const total = selectedProductsData.length;
//       let completed = 0;

//       const updatedProducts = [...products];

//       const generateTitleTasks = selectedProductsData.map((product) => async () => {
//         const index = products.findIndex((p) => p.id === product.id);
//         try {
//           const generatedTitle = await generateSingleTitle(product.title, product.jpDesc, apiKey);
//           updatedProducts[index].generatedTitle = generatedTitle;
//           completed++;
//           setProgress(Math.round((completed / total) * 100));
//         } catch (err) {
//           console.error(`商品ID ${product.id} のタイトル生成エラー:`, err);
//           updatedProducts[index].generatedTitle = '';
//           setError(`タイトルの生成に失敗しました: ${err.message}`);
//         }
//       });

//       await queue.addAll(generateTitleTasks);

//       setProducts(updatedProducts);
//       setMessage('タイトルの生成が完了しました');
//       setSnackbarMessage('タイトルの生成が完了しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       console.error('タイトル生成エラー:', err);
//       setError(`タイトルの生成に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//       setProgress(0);
//     }
//   };

//   // カスタムプロンプトでタイトルを生成する関数
//   const handleGenerateWithCustomPrompt = async (customPrompt) => {
//     if (!apiKey) {
//       setError('OpenAI APIキーが設定されていません');
//       setSnackbarMessage('OpenAI APIキーが必要です');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     const selectedProductsData = products.filter((product) => product.selected);
//     if (selectedProductsData.length === 0) {
//       setError('少なくとも一つの商品を選択してください');
//       setSnackbarMessage('商品が選択されていません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (!customPrompt.trim()) {
//       setError('カスタムプロンプトが入力されていません');
//       setSnackbarMessage('カスタムプロンプトを入力してください');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     try {
//       const total = selectedProductsData.length;
//       let completed = 0;

//       const updatedProducts = [...products];

//       const generateTitleTasks = selectedProductsData.map((product) => async () => {
//         const index = products.findIndex((p) => p.id === product.id);
//         try {
//           const generatedTitle = await generateSingleTitleWithCustomPrompt(
//             product.title,
//             product.jpDesc,
//             customPrompt,
//             apiKey
//           );
//           updatedProducts[index].generatedTitle = generatedTitle;
//           completed++;
//           setProgress(Math.round((completed / total) * 100));
//         } catch (err) {
//           console.error(`商品ID ${product.id} のタイトル生成エラー:`, err);
//           updatedProducts[index].generatedTitle = '';
//           setError(`タイトルの生成に失敗しました: ${err.message}`);
//         }
//       });

//       await queue.addAll(generateTitleTasks);

//       setProducts(updatedProducts);
//       setMessage('カスタムプロンプトによるタイトルの生成が完了しました');
//       setSnackbarMessage('カスタムプロンプトによるタイトルの生成が完了しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       console.error('タイトル生成エラー:', err);
//       setError(`タイトルの生成に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//       setProgress(0);
//       setCustomPromptDialogOpen(false); // ダイアログを閉じる
//       // setCustomPrompt(''); // プロンプトをクリア
//     }
//   };

//   // 生成されたタイトルをスプレッドシートに反映
//     const applyUpdatedTitles = async () => {
//     const updatedProducts = products.filter((product) => product.selected && product.generatedTitle);
//     if (updatedProducts.length === 0) {
//       setError('更新するタイトルがありません');
//       setSnackbarMessage('更新するタイトルがありません');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     try {
//       setLoading(true);

//       // シートデータを再取得
//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}?valueRenderOption=FORMATTED_VALUE`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       const data = response.data.values;
//       const headers = data[0];
//       const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');

//       if (titleIndex === -1) {
//         // "Title"カラムが見つからない場合のエラーハンドリング
//         setError('"Title"カラムがスプレッドシートに存在しません');
//         setSnackbarMessage('"Title"カラムが見つかりません');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//         return;
//       }

//       // 生成されたタイトルを"Title"カラムに反映
//       updatedProducts.forEach((product) => {
//         const rowIndex = product.id + 1; // ヘッダー行を考慮
//         if (!data[rowIndex]) {
//           data[rowIndex] = [];
//         }
//         data[rowIndex][titleIndex] = product.generatedTitle;
//       });

//       // シートデータを更新
//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}?valueInputOption=USER_ENTERED`,
//         { values: data },
//         { headers: { Authorization: `Bearer ${token}` } }
//       );

//       setSnackbarMessage('スプレッドシートの"Title"カラムを更新しました');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//       fetchSheetData();
//     } catch (err) {
//       console.error('スプレッドシート更新エラー:', err);
//       setError(`スプレッドシートの更新に失敗しました: ${err.message}`);
//       setSnackbarMessage(`エラー: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // // 生成されたタイトルをスプレッドシートに反映
//   // const applyUpdatedTitles = async () => {
//   //   const updatedProducts = products.filter((product) => product.selected && product.generatedTitle);
//   //   if (updatedProducts.length === 0) {
//   //     setError('更新するタイトルがありません');
//   //     setSnackbarMessage('更新するタイトルがありません');
//   //     setSnackbarSeverity('warning');
//   //     setSnackbarOpen(true);
//   //     return;
//   //   }

//   //   try {
//   //     setLoading(true);

//   //     // シートデータを再取得
//   //     const response = await axios.get(
//   //       `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}?valueRenderOption=FORMATTED_VALUE`,
//   //       { headers: { Authorization: `Bearer ${token}` } }
//   //     );
//   //     const data = response.data.values;
//   //     const headers = data[0];
//   //     const titleIndex = headers.findIndex((header) => header.toLowerCase() === 'title');
//   //     let newTitleIndex = headers.findIndex((header) => header.toLowerCase() === 'new_title');

//   //     if (newTitleIndex === -1) {
//   //       // New_Title列が存在しない場合は追加
//   //       newTitleIndex = headers.length;
//   //       headers.push('New_Title');
//   //       data[0] = headers;
//   //     }

//   //     updatedProducts.forEach((product) => {
//   //       const rowIndex = product.id + 1; // ヘッダー行を考慮
//   //       if (!data[rowIndex]) {
//   //         data[rowIndex] = [];
//   //       }
//   //       data[rowIndex][newTitleIndex] = product.generatedTitle;
//   //     });

//   //     // シートデータを更新
//   //     await axios.put(
//   //       `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}?valueInputOption=USER_ENTERED`,
//   //       { values: data },
//   //       { headers: { Authorization: `Bearer ${token}` } }
//   //     );

//   //     setSnackbarMessage('スプレッドシートを更新しました');
//   //     setSnackbarSeverity('success');
//   //     setSnackbarOpen(true);
//   //     fetchSheetData();
//   //   } catch (err) {
//   //     console.error('スプレッドシート更新エラー:', err);
//   //     setError(`スプレッドシートの更新に失敗しました: ${err.message}`);
//   //     setSnackbarMessage(`エラー: ${err.message}`);
//   //     setSnackbarSeverity('error');
//   //     setSnackbarOpen(true);
//   //   } finally {
//   //     setLoading(false);
//   //   }
//   // };

//   // キャッシュをクリア
//   const clearTitleCache = () => {
//     titleCache = {};
//     saveCacheToLocalStorage();
//     setSnackbarMessage('キャッシュをクリアしました');
//     setSnackbarSeverity('info');
//     setSnackbarOpen(true);
//   };

//   // カスタムプロンプトを使用して単一のタイトルを生成する関数
//   async function generateSingleTitleWithCustomPrompt(originalTitle, jpDesc, prompt, apiKey) {
//     const cacheKey = `${originalTitle}_${jpDesc.slice(0, 150)}_${prompt}`;
//     if (titleCache[cacheKey]) {
//       return titleCache[cacheKey];
//     }

//     try {
//       const url = 'https://api.openai.com/v1/chat/completions';

//       const messages = [
//         {
//           role: 'system',
//           content: `あなたはeBayの商品のタイトル最適化を専門とするAIアシスタントです。${prompt}`,
//         },
//         {
//           role: 'user',
//           content: `商品タイトル：「${originalTitle}」\n商品説明：「${jpDesc}」\nこの情報を基に、最適な英語の商品タイトルを生成してください。`,
//         },
//       ];

//       const response = await axios.post(
//         url,
//         {
//           model: 'gpt-4o-2024-08-06', // 使用するモデルを指定
//           messages: messages,
//           max_tokens: 100,
//           temperature: 0.7,
//         },
//         {
//           headers: { Authorization: `Bearer ${apiKey}` },
//         }
//       );

//       const generatedTitle = response.data.choices[0].message.content.trim();
//       titleCache[cacheKey] = generatedTitle;
//       saveCacheToLocalStorage();
//       return generatedTitle;
//     } catch (error) {
//       throw error;
//     }
//   }

//   // 単一の商品タイトルを生成
//   async function generateSingleTitle(originalTitle, jpDesc, apiKey) {
//     const cacheKey = originalTitle + jpDesc.slice(0, 150);
//     if (titleCache[cacheKey]) {
//       return titleCache[cacheKey];
//     }

//     try {
//       const url = 'https://api.openai.com/v1/chat/completions';
//       const limitedJpDesc = jpDesc.slice(0, 150);
//       const requestData = {
//         model: 'gpt-4o-2024-08-06',
//         messages: [
//           {
//             role: 'system',
//             content: `You are an AI assistant specializing in eBay product title optimization. Based on the given original title and Japanese product information, create an effective English title within 80 characters.
// Order of title composition:
// 1. brand name (if applicable)
// 2. model name or product name/character name (if applicable)
// 3. product type (if applicable)
// 4. key features (color, material, size) (if applicable)
// 5. distinctive elements or uniqueness (if applicable)
// 6. condition (new/used) (if applicable)
// 7. important keywords (if applicable)
// NOTES:
// - Add or optimize new information while retaining important information from the original title
// - Avoid unnecessary adjectives and words such as “eBay
// - Use abbreviations and common names appropriately
// - Think from the searcher's perspective and include search terms that buyers are likely to use
// - If information is unclear or ambiguous, omit it.
// - Use only reliable information and do not include guesswork or uncertain information.
// - Do not extract from Japanese descriptions that are a list of words.
// - Titles should be generated in English and should not exceed 80 characters.`,
//           },
//           {
//             role: 'user',
//             content: `元のタイトル：「${originalTitle}」
// 日本語の商品説明：「${limitedJpDesc}」
// この情報を基に、最適化された新しいeBayの商品タイトルを生成してください。また日本語の商品説明が空の場合は元のタイトルから生成してください`,
//           },
//         ],
//         max_tokens: 100,
//       };

//       const response = await axios.post(url, requestData, {
//         headers: { Authorization: `Bearer ${apiKey}` },
//       });

//       const generatedTitle = response.data.choices[0].message.content.trim();
//       titleCache[cacheKey] = generatedTitle;
//       saveCacheToLocalStorage();
//       return generatedTitle;
//     } catch (error) {
//       throw error;
//     }
//   }

//   // カスタマイズダイアログを開く
//   const handleCustomizeDialogOpen = () => {
//     if (selectedCategory) {
//       const category = customCategories.find((cat) => cat.name === selectedCategory);
//       if (category) {
//         setDeleteText(category.deleteText);
//         setReplaceText(category.replaceText);
//         setReplaceWithText(category.replaceWithText);
//         setPrependText(category.prependText);
//         setAppendText(category.appendText);
//         setLimitTitleLength(category.limitTitleLength);
//       }
//     }
//     setCustomizeDialogOpen(true);
//   };

//   // カテゴリーの変更ハンドラー
//   const handleCategoryChange = (event) => {
//     const selectedName = event.target.value;
//     setSelectedCategory(selectedName);
//     const category = customCategories.find((cat) => cat.name === selectedName);
//     if (category) {
//       setDeleteText(category.deleteText);
//       setReplaceText(category.replaceText);
//       setReplaceWithText(category.replaceWithText);
//       setPrependText(category.prependText);
//       setAppendText(category.appendText);
//       setLimitTitleLength(category.limitTitleLength);
//     }
//   };

//   // 新しいカテゴリーを保存する
//   const saveNewCategory = () => {
//     const newCategory = {
//       name: categoryName,
//       deleteText,
//       replaceText,
//       replaceWithText,
//       prependText,
//       appendText,
//       limitTitleLength,
//     };
//     setCustomCategories([...customCategories, newCategory]);
//     saveCategoriesToLocalStorage();
//     setCategoryName('');
//     setSnackbarMessage('新しいカテゴリーを保存しました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   // 既存のカテゴリーを更新する
//   const updateCategory = () => {
//     const updatedCategories = customCategories.map((cat) => {
//       if (cat.name === selectedCategory) {
//         return {
//           ...cat,
//           deleteText,
//           replaceText,
//           replaceWithText,
//           prependText,
//           appendText,
//           limitTitleLength,
//         };
//       }
//       return cat;
//     });
//     setCustomCategories(updatedCategories);
//     saveCategoriesToLocalStorage();
//     setSnackbarMessage('カテゴリーを更新しました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   // カテゴリーを削除する
//   const deleteCategory = () => {
//     const updatedCategories = customCategories.filter((cat) => cat.name !== selectedCategory);
//     setCustomCategories(updatedCategories);
//     saveCategoriesToLocalStorage();
//     setSelectedCategory('');
//     setSnackbarMessage('カテゴリーを削除しました');
//     setSnackbarSeverity('info');
//     setSnackbarOpen(true);
//   };

//   // タイトルカスタマイズの適用
//   const customizeTitles = () => {
//     const updatedProducts = products.map((product) => {
//       if (product.selected && product.generatedTitle) {
//         let newTitle = product.generatedTitle;

//         // 指定した文字列を削除
//         if (deleteText) {
//           const regex = new RegExp(deleteText, 'g');
//           newTitle = newTitle.replace(regex, '');
//         }

//         // 指定した単語を置換
//         if (replaceText && replaceWithText) {
//           const regex = new RegExp(`\\b${replaceText}\\b`, 'gi');
//           newTitle = newTitle.replace(regex, replaceWithText);
//         }

//         // タイトルの先頭に指定した単語を追加
//         if (prependText) {
//           newTitle = `${prependText} ${newTitle}`;
//         }

//         // タイトルの末尾に指定した単語を追加
//         if (appendText) {
//           newTitle = `${newTitle} ${appendText}`;
//         }

//         // タイトルを80文字に制限
//         if (limitTitleLength) {
//           newTitle = newTitle.slice(0, 80);
//         }

//         return { ...product, generatedTitle: newTitle };
//       }
//       return product;
//     });
//     setProducts(updatedProducts);

//     // カスタマイズ設定を保存
//     if (categoryName) {
//       saveNewCategory();
//     } else if (selectedCategory) {
//       updateCategory();
//     }

//     setCustomizeDialogOpen(false);
//     setSnackbarMessage('タイトルをカスタマイズしました');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   return (
//     <Paper>
//       <Box p={3} sx={{ paddingLeft: 6 }}>
//         <Typography variant="h5">AIを活用したタイトル生成</Typography>
//         <Typography variant="body1" sx={{ mt: 5 }}>
//           このツールを使用すると、AIを活用して商品タイトルを自動生成および最適化できます。さらに、カスタマイズ機能により、タイトルの一括編集や調整が可能です。
//           タイトルカスタマイズは、生成されたタイトルを指定ワードで一括に、置換、追加、削除することができます。
//         </Typography>
//         <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
//           <Button variant="contained" color="primary" onClick={handleOpen}>
//             タイトルをAIで生成する
//           </Button>
//           <Button variant="outlined" color="primary" onClick={clearTitleCache}>
//             キャッシュをクリア
//           </Button>
//         </Box>
//       </Box>

//       {/* タイトル生成ダイアログ */}
//       <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
//         <DialogTitle>タイトルをAIで生成する</DialogTitle>
//         <DialogContent>
//           {/* アクションボタン */}
//           <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
//             <Grid item>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={generateProductTitles}
//                 disabled={loading || !apiKey}
//               >
//                 AIでタイトルを生成する
//               </Button>
//             </Grid>
//             <Grid item>
//               <Button
//                 variant="contained"
//                 color="secondary"
//                 onClick={applyUpdatedTitles}
//                 disabled={loading}
//               >
//                 タイトルをスプレッドシートに更新する
//               </Button>
//             </Grid>
//             <Grid item>
//               <Button
//                 variant="outlined"
//                 color="primary"
//                 onClick={handleCustomizeDialogOpen}
//                 disabled={loading}
//               >
//                 タイトルをカスタマイズ
//               </Button>
//             </Grid>
//             {/* カスタムプロンプトで生成ボタンを追加 */}
//             <Grid item>
//               <Button
//                 variant="outlined"
//                 color="secondary"
//                 onClick={() => setCustomPromptDialogOpen(true)}
//                 disabled={loading || !apiKey}
//               >
//                 カスタムプロンプトで生成
//               </Button>
//             </Grid>
//           </Grid>

//           {/* カスタムプロンプトダイアログ */}
//           {/* <Dialog
//             open={customPromptDialogOpen}
//             onClose={() => setCustomPromptDialogOpen(false)}
//             maxWidth="sm"
//             fullWidth
//           >
//             <DialogTitle>カスタムプロンプトでタイトルを生成</DialogTitle>
//             <DialogContent>
//               <TextField
//                 autoFocus
//                 margin="dense"
//                 label="カスタムプロンプト"
//                 type="text"
//                 fullWidth
//                 multiline
//                 rows={4}
//                 value={customPrompt}
//                 onChange={(e) => setCustomPrompt(e.target.value)}
//                 placeholder="ここにカスタムプロンプトを入力してください..."
//               />
//             </DialogContent>
//             <DialogActions>
//               <Button onClick={() => setCustomPromptDialogOpen(false)}>キャンセル</Button>
//               <Button
//                 onClick={handleGenerateWithCustomPrompt}
//                 variant="contained"
//                 color="primary"
//                 disabled={!customPrompt.trim() || loading}
//               >
//                 生成
//               </Button>
//             </DialogActions>
//           </Dialog> */}

//           <CustomPromptDialog
//             open={customPromptDialogOpen}
//             onClose={() => setCustomPromptDialogOpen(false)}
//             onGenerate={handleGenerateWithCustomPrompt}
//             loading={loading}
//           />


//           {/* 進捗状況 */}
//           {loading && (
//             <Box sx={{ width: '100%', marginBottom: '20px' }}>
//               <LinearProgress variant="determinate" value={progress} />
//             </Box>
//           )}

//           {/* 商品テーブル */}
//           <TableContainer component={Paper} style={{ maxHeight: '70vh' }}>
//             <Table stickyHeader aria-label="products-table">
//               <TableHead>
//                 <TableRow>
//                   <TableCell padding="checkbox">
//                     <Checkbox
//                       indeterminate={
//                         products.some((product) => product.selected) &&
//                         !products.every((product) => product.selected)
//                       }
//                       checked={products.every((product) => product.selected)}
//                       onChange={(event) => {
//                         const updatedProducts = products.map((product) => ({
//                           ...product,
//                           selected: event.target.checked,
//                         }));
//                         setProducts(updatedProducts);
//                       }}
//                     />
//                   </TableCell>
//                   <TableCell style={{ minWidth: 150 }}>元のタイトル</TableCell>
//                   <TableCell style={{ minWidth: 150 }}>日本語の商品説明</TableCell>
//                   <TableCell style={{ minWidth: 150 }}>生成されたタイトル</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {products.map((product) => (
//                   <TableRow key={product.id}>
//                     <TableCell padding="checkbox">
//                       <Checkbox
//                         checked={product.selected}
//                         onChange={(event) => handleSelectProduct(event, product.id)}
//                       />
//                     </TableCell>
//                     <TableCell
//                       style={{
//                         whiteSpace: 'pre-wrap',
//                         wordWrap: 'break-word',
//                         maxWidth: '300px',
//                       }}
//                     >
//                       {product.title}
//                     </TableCell>
//                     <TableCell
//                       style={{
//                         whiteSpace: 'pre-wrap',
//                         wordWrap: 'break-word',
//                         maxWidth: '300px',
//                       }}
//                     >
//                       {product.jpDesc}
//                     </TableCell>
//                     <TableCell style={{ maxWidth: '300px' }}>
//                       <TextField
//                         multiline
//                         fullWidth
//                         variant="outlined"
//                         value={product.generatedTitle}
//                         onChange={(event) => handleGeneratedTitleChange(event, product.id)}
//                       />
//                     </TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>

//           {/* エラーメッセージ */}
//           {error && (
//             <Typography color="error" style={{ marginTop: '20px' }}>
//               {error}
//             </Typography>
//           )}
//           {/* 成功メッセージ */}
//           {message && (
//             <Typography color="primary" style={{ marginTop: '20px' }}>
//               {message}
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* カスタマイズダイアログ */}
//       <Dialog
//         open={customizeDialogOpen}
//         onClose={() => setCustomizeDialogOpen(false)}
//         maxWidth="md"
//         fullWidth
//       >
//         <DialogTitle>タイトルをカスタマイズ</DialogTitle>
//         <DialogContent>
//           <FormControl fullWidth margin="normal">
//             <InputLabel id="category-select-label">カテゴリーを選択</InputLabel>
//             <Select
//               labelId="category-select-label"
//               value={selectedCategory}
//               onChange={handleCategoryChange}
//             >
//               {customCategories.map((cat) => (
//                 <MenuItem key={cat.name} value={cat.name}>
//                   {cat.name}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           <TextField
//             label="新しいカテゴリー名"
//             fullWidth
//             margin="normal"
//             value={categoryName}
//             onChange={(e) => setCategoryName(e.target.value)}
//             helperText="新しいカテゴリーを作成する場合に入力してください"
//           />

//           <Grid container spacing={2}>
//             <Grid item xs={12}>
//               <TextField
//                 label="削除する文字列"
//                 fullWidth
//                 margin="normal"
//                 value={deleteText}
//                 onChange={(e) => setDeleteText(e.target.value)}
//                 helperText="指定した文字列をタイトルから削除します"
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <TextField
//                 label="置換する単語"
//                 fullWidth
//                 margin="normal"
//                 value={replaceText}
//                 onChange={(e) => setReplaceText(e.target.value)}
//                 helperText="タイトル内の指定した単語を置換します"
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <TextField
//                 label="置換後の単語"
//                 fullWidth
//                 margin="normal"
//                 value={replaceWithText}
//                 onChange={(e) => setReplaceWithText(e.target.value)}
//                 helperText="置換後の単語"
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <TextField
//                 label="先頭に追加する文字列"
//                 fullWidth
//                 margin="normal"
//                 value={prependText}
//                 onChange={(e) => setPrependText(e.target.value)}
//                 helperText="タイトルの先頭に追加します"
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <TextField
//                 label="末尾に追加する文字列"
//                 fullWidth
//                 margin="normal"
//                 value={appendText}
//                 onChange={(e) => setAppendText(e.target.value)}
//                 helperText="タイトルの末尾に追加します"
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <FormControlLabel
//                 control={
//                   <Switch
//                     checked={limitTitleLength}
//                     onChange={(e) => setLimitTitleLength(e.target.checked)}
//                   />
//                 }
//                 label="80文字に制限する"
//               />
//             </Grid>
//           </Grid>

//           {/* カテゴリーの保存・更新・削除ボタン */}
//           <Box mt={2} display="flex" justifyContent="space-between">
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={categoryName ? saveNewCategory : updateCategory}
//               disabled={!categoryName && !selectedCategory}
//             >
//               {categoryName ? '新しいカテゴリーを保存' : 'カテゴリーを更新'}
//             </Button>
//             {selectedCategory && (
//               <Button variant="outlined" color="secondary" onClick={deleteCategory}>
//                 カテゴリーを削除
//               </Button>
//             )}
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setCustomizeDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={customizeTitles} color="primary">
//             適用
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* スナックバー通知 */}
//       <Snackbar
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={() => setSnackbarOpen(false)}
//       >
//         <Alert
//           onClose={() => setSnackbarOpen(false)}
//           severity={snackbarSeverity}
//           sx={{ width: '100%' }}
//         >
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </Paper>
//   );
// };

// export default TitleGenerationComponent;