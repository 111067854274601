// EditableSpreadsheet.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BulkColumnEdit from './BulkColumnEdit';
import Select from 'react-select/creatable';
import Modal from 'react-modal';
import './EditableSpreadsheet.css';

// モーダルのアクセシビリティ設定
Modal.setAppElement('#root');

function EditableSpreadsheet({ spreadsheetId, sheetName, token, data: initialData, fetchData, onDataChange }) {
  // State variables
  const [data, setData] = useState(initialData || []);
  const [error, setError] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [sheetId, setSheetId] = useState(null);
  const [deleteWords, setDeleteWords] = useState([]); // 削除ワードのステート
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [rowsToDelete, setRowsToDelete] = useState([]);
  const [deletionLog, setDeletionLog] = useState([]);
  const [titleIndex, setTitleIndex] = useState(-1);
  const [jpTitleIndex, setJpTitleIndex] = useState(-1);
  const [isDeletionLogOpen, setIsDeletionLogOpen] = useState(false);
  const [isManageModalOpen, setIsManageModalOpen] = useState(false); // 管理モーダルのステート
  const [importText, setImportText] = useState(''); // 一括インポート用のローカルステート
  // モーダルのステートを追加
  const [isCellModalOpen, setIsCellModalOpen] = useState(false);
  const [cellModalValue, setCellModalValue] = useState('');
  const [cellModalRowIndex, setCellModalRowIndex] = useState(null);
  const [cellModalColIndex, setCellModalColIndex] = useState(null);
  const [isUpdatingCell, setIsUpdatingCell] = useState(false);



  // テンプレート関連のステート
  const [templates, setTemplates] = useState([]); // テンプレートのリスト
  const [selectedTemplateName, setSelectedTemplateName] = useState(''); // 選択されたテンプレート名
  const [newTemplateName, setNewTemplateName] = useState(''); // 新しいテンプレート名

  // プレビュー用のステート
  const [previewItem, setPreviewItem] = useState(null); // プレビューするアイテム
  const [isPreviewOpen, setIsPreviewOpen] = useState(false); // プレビューモーダルの開閉

  // Effect hooks
  useEffect(() => {
    setData(initialData || []);
  }, [initialData]);

  useEffect(() => {
    const fetchSheetId = async () => {
      try {
        const response = await axios.get(
          `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const sheet = response.data.sheets.find(s => s.properties.title === sheetName);
        if (sheet) {
          setSheetId(sheet.properties.sheetId);
        } else {
          setError(`シート名 "${sheetName}" が見つかりません。`);
        }
      } catch (err) {
        console.error('Error fetching sheet ID:', err);
        setError('シートIDの取得に失敗しました');
      }
    };

    if (spreadsheetId && sheetName) {
      fetchSheetId();
    }
  }, [spreadsheetId, sheetName, token]);

  useEffect(() => {
    if (data && data.length > 0) {
      const headers = data[0];
      setTitleIndex(headers.findIndex(header => header.toLowerCase() === 'title'));
      setJpTitleIndex(headers.findIndex(header => header.toLowerCase() === 'jp_title'));
    }
  }, [data]);

  // テンプレートをローカルストレージから読み込む
  useEffect(() => {
    const storedTemplates = localStorage.getItem('deleteWordTemplates');
    if (storedTemplates) {
      const templatesArray = JSON.parse(storedTemplates);
      setTemplates(templatesArray);
    }
  }, []);

  // テンプレートの変更をローカルストレージに保存
  useEffect(() => {
    localStorage.setItem('deleteWordTemplates', JSON.stringify(templates));
  }, [templates]);

  // 削除ワードをローカルストレージから読み込む
  useEffect(() => {
    const storedWords = localStorage.getItem('deleteWords');
    if (storedWords) {
      const wordsArray = JSON.parse(storedWords);
      const formattedWords = wordsArray.map(word => ({ value: word, label: word }));
      setDeleteWords(formattedWords);
    }
  }, []);

  // 削除ワードの変更をローカルストレージに保存
  useEffect(() => {
    const wordsArray = deleteWords.map(word => word.value);
    localStorage.setItem('deleteWords', JSON.stringify(wordsArray));
  }, [deleteWords]);

  // 選択されたテンプレート名をローカルストレージから読み込む
  useEffect(() => {
    const storedSelectedTemplateName = localStorage.getItem('selectedTemplateName');
    if (storedSelectedTemplateName) {
      setSelectedTemplateName(storedSelectedTemplateName);
    }
  }, []);

  // 選択されたテンプレート名をローカルストレージに保存
  useEffect(() => {
    localStorage.setItem('selectedTemplateName', selectedTemplateName);
  }, [selectedTemplateName]);

  // テンプレートまたはテンプレートのリストが更新されたときに、選択されたテンプレートを適用
  useEffect(() => {
    if (selectedTemplateName && templates.length > 0) {
      const template = templates.find(t => t.name === selectedTemplateName);
      if (template) {
        // テンプレートを適用
        const formattedWords = template.words.map(word => ({ value: word, label: word }));
        setDeleteWords(formattedWords);
      } else {
        // テンプレートが見つからない場合、選択を解除
        setSelectedTemplateName('');
      }
    }
  }, [selectedTemplateName, templates]);

  // Functions
  const updateCell = async (rowIndex, colIndex, newValue) => {
    try {
      setIsUpdatingCell(true); // 更新開始
      const range = `${sheetName}!${columnToLetter(colIndex + 1)}${rowIndex + 1}`;
      await axios.put(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?valueInputOption=RAW`,
        {
          values: [[newValue]]
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const newData = [...data];
      newData[rowIndex][colIndex] = newValue;
      setData(newData);
      onDataChange(newData); // データ変更を親コンポーネントに伝える
    } catch (err) {
      console.error('Error updating cell:', err);
      setError('セルの更新に失敗しました');
    } finally {
      setIsUpdatingCell(false); // 更新終了
    }
  };
  

  const handleColumnClick = (colIndex) => {
    setSelectedColumn(colIndex);
  };

  const columnToLetter = (column) => {
    let temp, letter = '';
    while (column > 0) {
      temp = (column - 1) % 26;
      letter = String.fromCharCode(temp + 65) + letter;
      column = Math.floor((column - temp - 1) / 26);
    }
    return letter;
  };

  const renderCell = (cell, rowIndex, colIndex) => {
    const header = data[0][colIndex]?.toLowerCase();
    if (header === 'picurl' && rowIndex > 0) {
      const imageUrls = cell?.split('|') || [];
      return (
        <img 
          src={imageUrls[0]} 
          alt="Product" 
          style={{ maxWidth: '100px', maxHeight: '100px' }}
        />
      );
    } else if (header === '画像' && rowIndex > 0) {
      const picUrlIndex = data[0].findIndex(h => h.toLowerCase() === 'picurl');
      const imageUrls = data[rowIndex][picUrlIndex]?.split('|') || [];
      return (
        <img 
          src={imageUrls[0]} 
          alt="Product" 
          style={{ maxWidth: '100px', maxHeight: '100px' }}
        />
      );
    } else {
      return (
        <input
        value={cell || ''}
        onClick={() => {
          setCellModalValue(cell || '');
          setCellModalRowIndex(rowIndex);
          setCellModalColIndex(colIndex);
          setIsCellModalOpen(true);
        }}
        readOnly // 直接編集を防ぐ場合はreadOnlyを設定
        style={{ cursor: 'pointer' }} // ユーザーにクリック可能であることを示す
      />
      );
    }
  };

  const handleDeleteRowsByWord = async () => {
    if (deleteWords.length === 0) {
      setError('削除したいワードを入力してください。');
      return;
    }

    if (!sheetId) {
      setError('シートIDが取得されていません。');
      return;
    }

    try {
      if (titleIndex === -1 && jpTitleIndex === -1) {
        setError('"Title" カラムまたは "jp_title" カラムが見つかりません。');
        return;
      }

      const words = deleteWords.map(word => word.value).filter(w => w);

      if (words.length === 0) {
        setError('有効な削除ワードがありません。');
        return;
      }

      // 正規表現を使用して完全一致を判定（大文字小文字を無視）
      const regexes = words.map(word => new RegExp(`\\b${escapeRegExp(word)}\\b`, 'i'));

      const rowsToDeleteTemp = [];
      const deletionLogTemp = [];

      for (let i = 1; i < data.length; i++) {
        const titleCell = titleIndex !== -1 ? data[i][titleIndex] : '';
        const jpTitleCell = jpTitleIndex !== -1 ? data[i][jpTitleIndex] : '';

        const titleMatches = regexes.some(regex => regex.test(titleCell));
        const jpTitleMatches = regexes.some(regex => regex.test(jpTitleCell));

        if (titleMatches || jpTitleMatches) {
          rowsToDeleteTemp.push(i);
          deletionLogTemp.push({
            rowIndex: i,
            rowData: data[i],
          });
        }
      }

      if (rowsToDeleteTemp.length === 0) {
        setError(`指定したワードを含む行は見つかりませんでした。`);
        return;
      }

      rowsToDeleteTemp.sort((a, b) => b - a);

      setRowsToDelete(rowsToDeleteTemp);
      setDeletionLog(prevLog => [...prevLog, ...deletionLogTemp]);
      setIsConfirmOpen(true);
      setError(null);
    } catch (err) {
      console.error('Error preparing rows for deletion:', err);
      setError('行の削除準備中にエラーが発生しました');
    }
  };

  // 正規表現で特殊文字をエスケープする関数
  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& はマッチした文字列全体
  };

  const confirmDeleteRows = async () => {
    try {
      const requests = rowsToDelete.map(rowIndex => ({
        deleteDimension: {
          range: {
            sheetId: sheetId,
            dimension: "ROWS",
            startIndex: rowIndex,
            endIndex: rowIndex + 1
          }
        }
      }));

      await axios.post(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
        { requests },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      let newData = [...data];
      for (let rowIndex of rowsToDelete) {
        newData.splice(rowIndex, 1);
      }
      setData(newData);
      onDataChange(newData); // データ変更を親コンポーネントに伝える

      setIsConfirmOpen(false);
      setRowsToDelete([]);
      setError(null);
    } catch (err) {
      console.error('Error deleting rows:', err);
      setError('行の削除に失敗しました');
      setIsConfirmOpen(false);
    }
  };

  const cancelDeleteRows = () => {
    setIsConfirmOpen(false);
    setRowsToDelete([]);
  };

  const handleDeleteRow = async (rowIndex) => {
    if (rowIndex <= 0) {
      setError('ヘッダー行は削除できません。');
      return;
    }

    if (!sheetId) {
      setError('シートIDが取得されていません。');
      return;
    }

    try {
      const requestBody = {
        requests: [
          {
            deleteDimension: {
              range: {
                sheetId: sheetId,
                dimension: "ROWS",
                startIndex: rowIndex,
                endIndex: rowIndex + 1
              }
            }
          }
        ]
      };

      await axios.post(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      setDeletionLog(prevLog => [
        ...prevLog,
        {
          rowIndex: rowIndex,
          rowData: data[rowIndex],
        }
      ]);

      const newData = data.filter((_, index) => index !== rowIndex);
      setData(newData);
      onDataChange(newData); // データ変更を親コンポーネントに伝える

      setError(null);
    } catch (err) {
      console.error('Error deleting row:', err);
      setError('行の削除に失敗しました');
    }
  };

  const handleRestoreRow = async (log) => {
    if (!sheetId) {
      setError('シートIDが取得されていません。');
      return;
    }

    try {
      const insertRowIndex = data.length;

      const insertRequest = {
        requests: [
          {
            insertDimension: {
              range: {
                sheetId: sheetId,
                dimension: "ROWS",
                startIndex: insertRowIndex,
                endIndex: insertRowIndex + 1
              },
              inheritFromBefore: false
            }
          }
        ]
      };

      await axios.post(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
        insertRequest,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      const range = `${sheetName}!A${insertRowIndex + 1}`;
      const values = [log.rowData];

      await axios.put(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${encodeURIComponent(range)}?valueInputOption=RAW`,
        { values },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      const newData = [...data];
      newData.push(log.rowData);
      setData(newData);
      onDataChange(newData); // データ変更を親コンポーネントに伝える

      setDeletionLog(prevLog => prevLog.filter(item => item !== log));

      setError(null);
    } catch (err) {
      console.error('Error restoring row:', err);
      setError('行の復元に失敗しました');
    }
  };

  // 削除ワードの変更ハンドラー
  const handleDeleteWordsChange = (newWords) => {
    setDeleteWords(newWords || []);
  };

  // テンプレートを保存
  const saveTemplate = () => {
    if (!newTemplateName.trim()) {
      setError('テンプレート名を入力してください。');
      return;
    }

    // 同じ名前のテンプレートが存在するか確認
    const existingTemplate = templates.find(t => t.name === newTemplateName.trim());
    if (existingTemplate) {
      setError('同じ名前のテンプレートが既に存在します。別の名前を使用してください。');
      return;
    }

    // 削除ワードを文字列の配列に変換
    const wordsArray = deleteWords.map(word => word.value);

    const newTemplate = {
      name: newTemplateName.trim(),
      words: wordsArray,
    };

    setTemplates([...templates, newTemplate]);
    setNewTemplateName('');
    setError(null);
  };

  // テンプレートを適用
  const applyTemplate = (template) => {
    // 削除ワードを { value, label } の形式に変換
    const formattedWords = template.words.map(word => ({ value: word, label: word }));
    setDeleteWords(formattedWords);
    setSelectedTemplateName(template.name);
  };

  // テンプレートを削除
  const deleteTemplate = (templateToDelete) => {
    setTemplates(templates.filter(t => t.name !== templateToDelete.name));
    if (selectedTemplateName === templateToDelete.name) {
      setSelectedTemplateName('');
      setDeleteWords([]);
    }
  };

  // ワード管理用モーダルのハンドラー
  const openManageModal = () => {
    setIsManageModalOpen(true);
  };

  const closeManageModal = () => {
    setIsManageModalOpen(false);
  };

  // モーダル内でのワード管理（追加・削除）
  const handleManageAddWord = (newWord) => {
    if (newWord && !deleteWords.some(word => word.value === newWord)) {
      setDeleteWords([...deleteWords, { value: newWord, label: newWord }]);
    }
  };

  const handleManageDeleteWord = (wordToDelete) => {
    setDeleteWords(deleteWords.filter(word => word.value !== wordToDelete.value));
  };

  // 一括インポート機能のハンドラー
  const handleImportWords = () => {
    if (importText.trim() === '') return;

    // カンマ、全角カンマ、改行で分割
    const importedWords = importText.split(/[,、\n]+/).map(w => w.trim()).filter(w => w);
    const uniqueImportedWords = Array.from(new Set(importedWords));
    const formattedImportedWords = uniqueImportedWords.map(w => ({ value: w, label: w }));

    setDeleteWords(prevWords => {
      const updatedWords = [...prevWords];
      formattedImportedWords.forEach(w => {
        if (!updatedWords.some(word => word.value === w.value)) {
          updatedWords.push(w);
        }
      });
      return updatedWords;
    });

    setImportText(''); // テキストエリアをクリア
  };

  // プレビューを表示する関数
  const handlePreview = (row) => {
    // ヘッダー行を除外
    if (row === data[0]) return;

    const item = {};
    data[0].forEach((header, index) => {
      item[header] = row[index];
    });
    setPreviewItem(item);
    setIsPreviewOpen(true);
  };

  // プレビューモーダルを閉じる関数
  const closePreviewModal = () => {
    setIsPreviewOpen(false);
    setPreviewItem(null);
  };

  // 削除ログモーダルのスタイル
  const deletionLogModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxHeight: '80%',
      overflow: 'auto',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  };

  // 確認ダイアログのスタイル
  const confirmDialogStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      maxHeight: '70%',
      overflow: 'auto',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  };

  return (
    <div className="editable-spreadsheet-container">
      {/* Bulk Column Edit component */}
      <BulkColumnEdit 
        spreadsheetId={spreadsheetId} 
        sheetName={sheetName}
        fetchData={fetchData}
        selectedColumn={selectedColumn !== null ? columnToLetter(selectedColumn + 1) : ''}
        token={token}
      />

      {/* 削除ワード入力と管理ボタン */}
      <div className="delete-rows-container">
        <Select
          isMulti
          isClearable
          onChange={handleDeleteWordsChange}
          options={deleteWords}
          value={deleteWords}
          placeholder="削除したいワードを入力"
          components={{
            DropdownIndicator: null,
          }}
          styles={{
            multiValue: (styles) => ({
              ...styles,
              backgroundColor: '#e0e0e0',
            }),
            multiValueLabel: (styles) => ({
              ...styles,
              color: '#333',
            }),
            multiValueRemove: (styles) => ({
              ...styles,
              color: '#666',
              ':hover': {
                backgroundColor: '#ccc',
                color: '#000',
              },
            }),
          }}
        />
        <button className="manage-words-button" onClick={openManageModal}>
          ワードを管理
        </button>
        <button className="delete-rows-button" onClick={handleDeleteRowsByWord}>
          指定ワードを含む行を削除
        </button>
        <button className="view-deletion-log-button" onClick={() => setIsDeletionLogOpen(true)}>
          削除された商品を見る
        </button>
      </div>

      {/* エラーメッセージ表示 */}
      {error && <div className="error-message">{error}</div>}

      {/* 確認ダイアログ */}
      <Modal
        isOpen={isConfirmOpen}
        onRequestClose={cancelDeleteRows}
        contentLabel="削除確認"
        style={confirmDialogStyles}
      >
        <div className="confirm-dialog-content">
          <p>以下の {rowsToDelete.length} 行を削除します。よろしいですか？</p>
          <ul>
            {rowsToDelete.map((rowIndex, idx) => (
              <li key={idx}>
                行 {rowIndex}: {titleIndex !== -1 && `Title="${data[rowIndex][titleIndex]}"`} {jpTitleIndex !== -1 && `jp_title="${data[rowIndex][jpTitleIndex]}"`}
              </li>
            ))}
          </ul>
          <button className="confirm-button" onClick={confirmDeleteRows}>削除する</button>
          <button className="cancel-button" onClick={cancelDeleteRows}>キャンセル</button>
        </div>
      </Modal>

      {/* 削除ログモーダル */}
      <Modal
        isOpen={isDeletionLogOpen}
        onRequestClose={() => setIsDeletionLogOpen(false)}
        contentLabel="削除された商品"
        style={deletionLogModalStyles}
      >
        <div className="deletion-log-content">
          <h3>削除された商品</h3>
          <button className="close-modal-button" onClick={() => setIsDeletionLogOpen(false)}>×</button>
          {deletionLog.length > 0 ? (
            <table className="deleted-items-table">
              <thead>
                <tr>
                  <th>行番号</th>
                  {titleIndex !== -1 && <th>Title</th>}
                  {jpTitleIndex !== -1 && <th>jp_title</th>}
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                {deletionLog.map((log, index) => (
                  <tr key={index}>
                    <td>{log.rowIndex}</td>
                    {titleIndex !== -1 && <td>{log.rowData[titleIndex]}</td>}
                    {jpTitleIndex !== -1 && <td>{log.rowData[jpTitleIndex]}</td>}
                    <td>
                      <button className="restore-button" onClick={() => handleRestoreRow(log)}>復元</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>削除された商品はありません。</p>
          )}
          <p>復元された商品はシートの末尾に追加されます。</p>
        </div>
      </Modal>

      {/* ワード管理モーダル */}
      <Modal
        isOpen={isManageModalOpen}
        onRequestClose={closeManageModal}
        contentLabel="削除ワード管理"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '60%',
            maxHeight: '80%',
            overflow: 'auto',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <h2>削除ワードを管理</h2>
        {/* テンプレートの選択 */}
        <div className="template-selection">
          <h3>テンプレートを選択</h3>
          <ul className="template-list">
            {templates.map((template, index) => (
              <li key={index}>
                <span onClick={() => applyTemplate(template)} style={{ cursor: 'pointer', color: selectedTemplateName === template.name ? 'blue' : 'black' }}>
                  {template.name}
                </span>
                <button onClick={() => deleteTemplate(template)}>削除</button>
              </li>
            ))}
          </ul>
          <div className="new-template">
            <input
              type="text"
              placeholder="新しいテンプレート名"
              value={newTemplateName}
              onChange={(e) => setNewTemplateName(e.target.value)}
            />
            <button onClick={saveTemplate}>テンプレートを保存</button>
          </div>
        </div>

        <div className="manage-words-input">
          <input
            type="text"
            placeholder="新しいワードを入力"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                const newWord = e.target.value.trim();
                if (newWord) {
                  handleManageAddWord(newWord);
                  e.target.value = '';
                }
              }
            }}
          />
          <button onClick={() => {
            const input = document.querySelector('.manage-words-input input');
            const newWord = input.value.trim();
            if (newWord) {
              handleManageAddWord(newWord);
              input.value = '';
            }
          }}>追加</button>
        </div>
        {/* 一括インポート機能 */}
        <div className="manage-words-import">
          <textarea
            placeholder="一括でワードを入力（カンマ、改行で区切る）"
            rows="4"
            value={importText}
            onChange={(e) => setImportText(e.target.value)}
          />
          <button onClick={handleImportWords}>一括インポート</button>
        </div>
        <ul className="manage-words-list">
          {deleteWords.map((word, index) => (
            <li key={index}>
              {word.label}
              <button onClick={() => handleManageDeleteWord(word)}>削除</button>
            </li>
          ))}
        </ul>
        <button onClick={closeManageModal} className="close-modal-button">閉じる</button>
      </Modal>

      {/* プレビューモーダル */}
      <Modal
        isOpen={isPreviewOpen}
        onRequestClose={closePreviewModal}
        contentLabel="商品プレビュー"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '800px',
            maxHeight: '90%',
            overflow: 'auto',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        {previewItem && (
          <div className="preview-modal-content">
            <button className="close-modal-button" onClick={closePreviewModal}>×</button>
            <div className="preview-header">
              <h2>{previewItem['Title'] || previewItem['title']}</h2>
            </div>
            <div className="preview-body">
              <div className="preview-image">
                <img 
                  src={(previewItem['PicURL'] || previewItem['picurl'] || '').split('|')[0]} 
                  alt="Product" 
                  style={{ maxWidth: '100%', maxHeight: '400px' }}
                />
              </div>
              <div className="preview-details">
                <p><strong>価格：</strong> {previewItem['StartPrice'] || previewItem['startprice'] || 'N/A'}</p>
                <p><strong>説明：</strong></p>
                <div className="item-description" dangerouslySetInnerHTML={{ __html: previewItem['Description'] || previewItem['description'] || '' }} />
              </div>
            </div>
          </div>
        )}
      </Modal>

      {/* セル編集用のモーダル */}
      <Modal
        isOpen={isCellModalOpen}
        onRequestClose={() => setIsCellModalOpen(false)}
        contentLabel="セルの編集"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            width: '600px',
            maxHeight: '80%',
            overflow: 'auto',
            padding: '20px',
            boxSizing: 'border-box',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <div>
          <h2 className="cell-modal-title">セルの編集</h2>
          <textarea
            value={cellModalValue}
            onChange={(e) => setCellModalValue(e.target.value)}
            style={{ width: '100%', height: '200px' ,fontSize: '18px',     // 文字サイズを18pxに設定
              lineHeight: '1.5',    // 行間を1.5に設定
              fontFamily: 'Arial, sans-serif', // フォントを指定
              color: '#333',        // テキストの色を濃いグレーに設定
              padding: '10px',      // テキストエリア内の余白を設定
              boxSizing: 'border-box', // パディングを含めて幅と高さを計算
              }}
            disabled={isUpdatingCell} // 更新中は入力を無効化
          />
          <div style={{ marginTop: '20px', textAlign: 'right' }}>
            <button
              onClick={async () => {
                await updateCell(cellModalRowIndex, cellModalColIndex, cellModalValue);
                setIsCellModalOpen(false);
              }}
              className="cell-modal-button"
              style={{ marginRight: '10px' }}
              disabled={isUpdatingCell} // 更新中はボタンを無効化
            >
              保存
            </button>
            <button
              onClick={() => setIsCellModalOpen(false)}
              className="cell-modal-button"
              disabled={isUpdatingCell} // 更新中はボタンを無効化
            >
              キャンセル
            </button>
            {isUpdatingCell && (
              <div className="loader" style={{ display: 'inline-block', marginLeft: '10px' }}></div>
            )}
          </div>
        </div>
      </Modal>



      {/* Spreadsheet display */}
      {data.length === 0 ? (
        <div className="no-data-message">
          データがありません。上部の機能を使ってスプレッドシートデータを読み込み・取得してください。
        </div>
      ) : (
        <div className="spreadsheet-container">
          <table className="editable-spreadsheet">
            <thead>
              <tr>
                <th className="row-number-header">#</th>
                <th>操作</th> {/* 操作列のヘッダー */}
                {data[0].map((header, colIndex) => (
                  <th 
                    key={colIndex} 
                    onClick={() => handleColumnClick(colIndex)}
                    className={selectedColumn === colIndex ? 'selected-column' : ''}
                  >
                    {columnToLetter(colIndex + 1)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td className="row-number">{rowIndex === 0 ? '0' : rowIndex}</td>
                  {rowIndex > 0 ? (
                    <td>
                      <div className="action-buttons">
                        <button 
                          className="preview-button" 
                          onClick={() => handlePreview(row)}
                        >
                          プレビュー
                        </button>
                        <button 
                          className="delete-button" 
                          onClick={() => handleDeleteRow(rowIndex)}
                        >
                          削除
                        </button>
                      </div>
                    </td>
                  ) : (
                    <td></td> // ヘッダー行の空白セル
                  )}
                  {row.map((cell, colIndex) => (
                    <td 
                      key={colIndex}
                      className={selectedColumn === colIndex ? 'selected-column' : ''}
                      style={{ minWidth: '100px' }}
                    >
                      {renderCell(cell, rowIndex, colIndex)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default EditableSpreadsheet;
