import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Select,
  MenuItem,
  Button,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  CardContent,
  CardActions,
  Drawer,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Menu,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Folder as FolderIcon,
  Description as DescriptionIcon,
  Add as AddIcon,
  Edit as EditIcon,
  PlayArrow as ApplyIcon,
  FileCopy as DuplicateIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

function ColumnNameBasedTemplateManager({ spreadsheetId, sheetName, token, fetchData }) {
  const [categories, setCategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newTemplateName, setNewTemplateName] = useState('');
  const [newOperation, setNewOperation] = useState({ columnName: '', action: '', value: '' });
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleteCategoryDialogOpen, setIsDeleteCategoryDialogOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isNewCategoryDialogOpen, setIsNewCategoryDialogOpen] = useState(false);
  const [isNewTemplateDialogOpen, setIsNewTemplateDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuTarget, setMenuTarget] = useState(null);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [newName, setNewName] = useState('');
  const [renameTarget, setRenameTarget] = useState(null);

  useEffect(() => {
    const savedCategories = JSON.parse(localStorage.getItem('categorizedTemplates') || '{}');
    const validatedCategories = Object.fromEntries(
      Object.entries(savedCategories).map(([key, value]) => [key, Array.isArray(value) ? value : []])
    );
    setCategories(validatedCategories);
    updateLocalStorage(validatedCategories);
  }, []);

  useEffect(() => {
    updateLocalStorage(categories);
  }, [categories]);

  const updateLocalStorage = (updatedCategories) => {
    localStorage.setItem('categorizedTemplates', JSON.stringify(updatedCategories));
  };

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
  };

  const handleNewCategory = () => {
    if (newCategoryName && !categories[newCategoryName]) {
      const updatedCategories = { ...categories, [newCategoryName]: [] };
      setCategories(updatedCategories);
      setNewCategoryName('');
      setSelectedCategory(newCategoryName);
      setIsNewCategoryDialogOpen(false);
    }
  };

  const handleNewTemplate = () => {
    if (newTemplateName && selectedCategory) {
      const newTemplate = { name: newTemplateName, operations: [] };
      const updatedCategories = { ...categories };

      if (!updatedCategories[selectedCategory] || !Array.isArray(updatedCategories[selectedCategory])) {
        updatedCategories[selectedCategory] = [];
      }

      updatedCategories[selectedCategory] = [...updatedCategories[selectedCategory], newTemplate];

      setCategories(updatedCategories);
      setNewTemplateName('');
      setSelectedTemplate(newTemplate);
      setIsNewTemplateDialogOpen(false);
    }
  };

  const handleDeleteTemplate = () => {
    if (selectedTemplate && selectedCategory) {
      setIsDeleteDialogOpen(true);
    }
  };

  const handleDeleteCategory = () => {
    if (selectedCategory) {
      setIsDeleteCategoryDialogOpen(true);
    }
  };

  const confirmDeleteTemplate = () => {
    const updatedTemplates = categories[selectedCategory].filter(t => t.name !== selectedTemplate.name);
    const updatedCategories = { ...categories, [selectedCategory]: updatedTemplates };
    setCategories(updatedCategories);
    setSelectedTemplate(null);
    setIsDeleteDialogOpen(false);
  };

  const confirmDeleteCategory = () => {
    const { [selectedCategory]: deletedCategory, ...remainingCategories } = categories;
    setCategories(remainingCategories);
    setSelectedCategory('');
    setSelectedTemplate(null);
    setIsDeleteCategoryDialogOpen(false);
  };

  const handleAddOperation = () => {
    if (selectedTemplate && newOperation.columnName && newOperation.action) {
      const updatedTemplate = {
        ...selectedTemplate,
        operations: [...selectedTemplate.operations, newOperation]
      };
      updateTemplate(updatedTemplate);
      setNewOperation({ columnName: '', action: '', value: '' });
    }
  };

  const handleDeleteOperation = (index) => {
    if (selectedTemplate) {
      const updatedOperations = selectedTemplate.operations.filter((_, i) => i !== index);
      const updatedTemplate = { ...selectedTemplate, operations: updatedOperations };
      updateTemplate(updatedTemplate);
    }
  };

  const updateTemplate = (updatedTemplate) => {
    const updatedTemplates = categories[selectedCategory].map(t => t.name === updatedTemplate.name ? updatedTemplate : t);
    const updatedCategories = { ...categories, [selectedCategory]: updatedTemplates };
    setCategories(updatedCategories);
    setSelectedTemplate(updatedTemplate);
  };

  const handleApplyTemplate = async () => {
    setIsConfirmDialogOpen(true);
  };

  const confirmApplyTemplate = async () => {
    setIsConfirmDialogOpen(false);
    if (!selectedTemplate) return;

    try {
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      let allValues = response.data.values || [];
      if (allValues.length === 0) {
        throw new Error("スプレッドシートにデータがありません。");
      }

      let headers = allValues[0];
      let data = allValues.slice(1);

      for (const op of selectedTemplate.operations) {
        let columnIndex = headers.findIndex(header => header.toLowerCase() === op.columnName.toLowerCase());

        if (columnIndex === -1) {
          headers.push(op.columnName);
          columnIndex = headers.length - 1;
          data.forEach(row => row.push(""));
        }

        data = data.map(row => {
          let cell = row[columnIndex] || "";
          switch (op.action) {
            case 'replace':
              cell = op.value;
              break;
            case 'prefix':
              cell = op.value + cell;
              break;
            case 'suffix':
              cell = cell + op.value;
              break;
            default:
              break;
          }
          row[columnIndex] = cell;
          return row;
        });
      }

      await axios.put(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!A1`,
        {
          values: [headers, ...data],
          range: `${sheetName}!A1`
        },
        {
          params: { valueInputOption: 'RAW' },
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      alert('テンプレートが正常に適用されました！');
      fetchData();
    } catch (error) {
      console.error('Error applying template:', error);
      alert(`テンプレートの適用に失敗しました。エラー: ${error.message}`);
    }
  };

  const exportTemplates = () => {
    const blob = new Blob([JSON.stringify(categories, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'categorized_templates_export.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const importTemplates = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function(e) {
      try {
        const importedCategories = JSON.parse(e.target.result);
        setCategories(importedCategories);
        alert('テンプレートのインポートが成功しました。');
      } catch (error) {
        console.error('テンプレートのインポートに失敗しました:', error);
        alert('テンプレートのインポートに失敗しました。ファイルを確認してください。');
      }
    };
    reader.readAsText(file);
  };

  const handleMenuOpen = (event, target) => {
    setAnchorEl(event.currentTarget);
    setMenuTarget(target);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuTarget(null);
  };

  const handleDuplicate = () => {
    handleMenuClose();
    if (menuTarget.type === 'category') {
      duplicateCategory(menuTarget.name);
    } else if (menuTarget.type === 'template') {
      duplicateTemplate(menuTarget.category, menuTarget.template);
    }
  };

  const duplicateCategory = (categoryName) => {
    const newCategoryName = `${categoryName} (Copy)`;
    const updatedCategories = {
      ...categories,
      [newCategoryName]: JSON.parse(JSON.stringify(categories[categoryName])),
    };
    setCategories(updatedCategories);
  };

  const duplicateTemplate = (categoryName, template) => {
    const newTemplateName = `${template.name} (Copy)`;
    const updatedCategories = { ...categories };
    updatedCategories[categoryName] = [
      ...updatedCategories[categoryName],
      { ...JSON.parse(JSON.stringify(template)), name: newTemplateName },
    ];
    setCategories(updatedCategories);
  };

  const handleRename = () => {
    handleMenuClose();
    if (menuTarget) {
      setRenameTarget(menuTarget);
      setNewName(menuTarget.type === 'category' ? menuTarget.name : menuTarget.template.name);
      setIsRenameDialogOpen(true);
    }
  };

  const confirmRename = () => {
    if (renameTarget && newName) {
      if (renameTarget.type === 'category') {
        renameCategory(renameTarget.name, newName);
      } else if (renameTarget.type === 'template') {
        renameTemplate(renameTarget.category, renameTarget.template, newName);
      }
    }
    setIsRenameDialogOpen(false);
    setRenameTarget(null);
    setNewName('');
  };

  const renameCategory = (oldName, newName) => {
    if (oldName !== newName && !categories[newName]) {
      const updatedCategories = { ...categories };
      updatedCategories[newName] = updatedCategories[oldName];
      delete updatedCategories[oldName];
      setCategories(updatedCategories);
      if (selectedCategory === oldName) {
        setSelectedCategory(newName);
      }
    }
  };

  const renameTemplate = (categoryName, template, newName) => {
    if (template.name !== newName) {
      const updatedCategories = { ...categories };
      const templateIndex = updatedCategories[categoryName].findIndex(t => t.name === template.name);
      if (templateIndex !== -1) {
        updatedCategories[categoryName][templateIndex] = { ...template, name: newName };
        setCategories(updatedCategories);
        if (selectedTemplate && selectedTemplate.name === template.name) {
          setSelectedTemplate({ ...selectedTemplate, name: newName });
        }
      }
    }
  };

  // ドラッグ＆ドロップ関連の関数
  const handleDragEnd = (result) => {
    const { source, destination, type } = result;

    if (!destination) return;

    if (type === 'CATEGORY') {
      const categoryKeys = Object.keys(categories);
      const newCategoryKeys = Array.from(categoryKeys);
      const [movedCategoryKey] = newCategoryKeys.splice(source.index, 1);
      newCategoryKeys.splice(destination.index, 0, movedCategoryKey);

      const newCategories = {};
      newCategoryKeys.forEach(key => {
        newCategories[key] = categories[key];
      });

      setCategories(newCategories);
    } else if (type === 'TEMPLATE') {
      const sourceCategoryName = source.droppableId.replace('templates-', '');
      const destCategoryName = destination.droppableId.replace('templates-', '');

      const sourceTemplates = Array.from(categories[sourceCategoryName]);
      const [movedTemplate] = sourceTemplates.splice(source.index, 1);

      if (sourceCategoryName === destCategoryName) {
        sourceTemplates.splice(destination.index, 0, movedTemplate);
        const updatedCategories = {
          ...categories,
          [sourceCategoryName]: sourceTemplates
        };
        setCategories(updatedCategories);
      } else {
        const destTemplates = Array.from(categories[destCategoryName]);
        destTemplates.splice(destination.index, 0, movedTemplate);

        const updatedCategories = {
          ...categories,
          [sourceCategoryName]: sourceTemplates,
          [destCategoryName]: destTemplates
        };
        setCategories(updatedCategories);
      }
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* AppBar */}
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            テンプレート管理
          </Typography>
          <Button color="inherit" onClick={() => setIsDrawerOpen(true)}>
            テンプレートを開く
          </Button>
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        sx={{ width: 350 }}
      >
        <Box sx={{ width: 350, p: 2 }}>
          <Typography variant="h6" gutterBottom>
            カテゴリーとテンプレート
          </Typography>
          <Button
            startIcon={<AddIcon />}
            onClick={() => setIsNewCategoryDialogOpen(true)}
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
          >
            新しいカテゴリー
          </Button>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="categories" type="CATEGORY">
              {(provided) => (
                <List ref={provided.innerRef} {...provided.droppableProps}>
                  {Object.keys(categories).map((categoryName, index) => (
                    <Draggable key={categoryName} draggableId={`category-${categoryName}`} index={index}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <ListItem
                            secondaryAction={
                              <IconButton
                                edge="end"
                                aria-label="more"
                                onClick={(event) => handleMenuOpen(event, { type: 'category', name: categoryName })}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            }
                          >
                            <ListItemIcon {...provided.dragHandleProps}>
                              <FolderIcon />
                            </ListItemIcon>
                            <ListItemText primary={categoryName} />
                          </ListItem>
                          <Droppable droppableId={`templates-${categoryName}`} type="TEMPLATE">
                            {(provided) => (
                              <List ref={provided.innerRef} {...provided.droppableProps}>
                                {categories[categoryName].map((template, index) => (
                                  <Draggable key={template.name} draggableId={`template-${categoryName}-${template.name}`} index={index}>
                                    {(provided) => (
                                      <ListItem
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        sx={{ pl: 4 }}
                                        secondaryAction={
                                          <IconButton
                                            edge="end"
                                            aria-label="more"
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              handleMenuOpen(event, { type: 'template', category: categoryName, template: template });
                                            }}
                                          >
                                            <MoreVertIcon />
                                          </IconButton>
                                        }
                                        onClick={() => {
                                          setSelectedCategory(categoryName);
                                          handleTemplateSelect(template);
                                          setIsDrawerOpen(false);
                                        }}
                                      >
                                        <ListItemIcon>
                                          <DescriptionIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={template.name} />
                                      </ListItem>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                                <ListItem sx={{ pl: 4 }}>
                                  <Button
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                      setSelectedCategory(categoryName);
                                      setIsNewTemplateDialogOpen(true);
                                    }}
                                  >
                                    新しいテンプレート
                                  </Button>
                                </ListItem>
                              </List>
                            )}
                          </Droppable>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button variant="contained" onClick={exportTemplates} sx={{ mr: 1 }}>
              エクスポート
            </Button>
            <input
              accept=".json"
              style={{ display: 'none' }}
              id="raised-button-file"
              type="file"
              onChange={importTemplates}
            />
            <label htmlFor="raised-button-file">
              <Button variant="contained" component="span">
                インポート
              </Button>
            </label>
          </Box>
        </Box>
      </Drawer>

      {/* メニュー */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleRename}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>名前変更</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDuplicate}>
          <ListItemIcon>
            <DuplicateIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>複製</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => {
          handleMenuClose();
          if (menuTarget.type === 'category') {
            setSelectedCategory(menuTarget.name);
            handleDeleteCategory();
          } else if (menuTarget.type === 'template') {
            setSelectedCategory(menuTarget.category);
            setSelectedTemplate(menuTarget.template);
            handleDeleteTemplate();
          }
        }}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>削除</ListItemText>
        </MenuItem>
      </Menu>

      {/* メインコンテンツ */}
      <Box sx={{ p: 3 }}>
        {selectedTemplate ? (
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              {selectedTemplate.name} の詳細
            </Typography>
            <Box sx={{
              display: 'flex',
              flexWrap: 'wrap',
              overflowX: 'auto',
              pb: 1,
              mb: 2,
              '&::-webkit-scrollbar': { height: 6 },
              '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0,0,0,.2)' }
            }}>
              {selectedTemplate.operations.map((operation, index) => (
                <Card key={index} sx={{
                  width: 160,
                  minWidth: 160,
                  mr: 1,
                  mb: 1,
                  flexShrink: 0
                }}>
                  <CardContent sx={{ p: 1 }}>
                    <Typography variant="subtitle2" component="div" noWrap>
                      {operation.columnName}
                    </Typography>
                    <Typography variant="caption" color="text.secondary" display="block" noWrap>
                      {operation.action}
                    </Typography>
                    <Typography variant="body2" noWrap sx={{ fontSize: '0.75rem' }}>
                      {operation.value}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ p: 0, justifyContent: 'flex-end' }}>
                    <IconButton size="small" onClick={() => handleDeleteOperation(index)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </CardActions>
                </Card>
              ))}
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                新しい操作を追加
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="カラム名"
                    value={newOperation.columnName}
                    onChange={(e) => setNewOperation({ ...newOperation, columnName: e.target.value })}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel>アクション</InputLabel>
                    <Select
                      value={newOperation.action}
                      onChange={(e) => setNewOperation({ ...newOperation, action: e.target.value })}
                    >
                      <MenuItem value="replace">置換</MenuItem>
                      <MenuItem value="prefix">接頭辞追加</MenuItem>
                      <MenuItem value="suffix">接尾辞追加</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="値"
                    value={newOperation.value}
                    onChange={(e) => setNewOperation({ ...newOperation, value: e.target.value })}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Button variant="contained" onClick={handleAddOperation} sx={{ mt: 2 }}>
                操作を追加
              </Button>
            </Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ApplyIcon />}
              onClick={handleApplyTemplate}
              sx={{ mt: 4 }}
            >
              テンプレートを適用
            </Button>
          </Paper>
        ) : (
          <Typography variant="body1">
            右側のドロワーからテンプレートを選択してください。
          </Typography>
        )}
      </Box>

      {/* ダイアログ類 */}
      <Dialog open={isNewCategoryDialogOpen} onClose={() => setIsNewCategoryDialogOpen(false)}>
        <DialogTitle>新しいカテゴリー</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="カテゴリー名"
            fullWidth
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsNewCategoryDialogOpen(false)}>キャンセル</Button>
          <Button onClick={handleNewCategory}>作成</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isNewTemplateDialogOpen} onClose={() => setIsNewTemplateDialogOpen(false)}>
        <DialogTitle>新しいテンプレート</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="テンプレート名"
            fullWidth
            value={newTemplateName}
            onChange={(e) => setNewTemplateName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsNewTemplateDialogOpen(false)}>キャンセル</Button>
          <Button onClick={handleNewTemplate}>作成</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
      >
        <DialogTitle>確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            このテンプレートを適用しますか？この操作は元に戻せません。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmDialogOpen(false)}>キャンセル</Button>
          <Button onClick={confirmApplyTemplate} autoFocus>
            適用
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>テンプレート削除の確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            テンプレート "{selectedTemplate?.name}" を削除しますか？この操作は元に戻せません。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)}>キャンセル</Button>
          <Button onClick={confirmDeleteTemplate} color="error" autoFocus>
            削除
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDeleteCategoryDialogOpen}
        onClose={() => setIsDeleteCategoryDialogOpen(false)}
      >
        <DialogTitle>カテゴリー削除の確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            カテゴリー "{selectedCategory}" とそれに含まれるすべてのテンプレートを削除しますか？この操作は元に戻せません。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteCategoryDialogOpen(false)}>キャンセル</Button>
          <Button onClick={confirmDeleteCategory} color="error" autoFocus>
            削除
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isRenameDialogOpen} onClose={() => setIsRenameDialogOpen(false)}>
        <DialogTitle>
          {renameTarget?.type === 'category' ? 'カテゴリー名の変更' : 'テンプレート名の変更'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="新しい名前"
            fullWidth
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsRenameDialogOpen(false)}>キャンセル</Button>
          <Button onClick={confirmRename}>変更</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ColumnNameBasedTemplateManager;




// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Grid,
//   Paper,
//   Typography,
//   Select,
//   MenuItem,
//   Button,
//   TextField,
//   IconButton,
//   FormControl,
//   InputLabel,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   Card,
//   CardContent,
//   CardActions,
//   Drawer,
//   AppBar,
//   Toolbar,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   Divider,
//   Menu,
// } from '@mui/material';
// import {
//   Delete as DeleteIcon,
//   Folder as FolderIcon,
//   Description as DescriptionIcon,
//   Add as AddIcon,
//   Edit as EditIcon,
//   PlayArrow as ApplyIcon,
//   FileCopy as DuplicateIcon,
//   MoreVert as MoreVertIcon,
// } from '@mui/icons-material';
// import axios from 'axios';

// function ColumnNameBasedTemplateManager({ spreadsheetId, sheetName, token, fetchData }) {
//   const [categories, setCategories] = useState({});
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [selectedTemplate, setSelectedTemplate] = useState(null);
//   const [newCategoryName, setNewCategoryName] = useState('');
//   const [newTemplateName, setNewTemplateName] = useState('');
//   const [newOperation, setNewOperation] = useState({ columnName: '', action: '', value: '' });
//   const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
//   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
//   const [isDeleteCategoryDialogOpen, setIsDeleteCategoryDialogOpen] = useState(false);
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const [isNewCategoryDialogOpen, setIsNewCategoryDialogOpen] = useState(false);
//   const [isNewTemplateDialogOpen, setIsNewTemplateDialogOpen] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [menuTarget, setMenuTarget] = useState(null);
//   const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
//   const [newName, setNewName] = useState('');
//   const [renameTarget, setRenameTarget] = useState(null);

//   useEffect(() => {
//     const savedCategories = JSON.parse(localStorage.getItem('categorizedTemplates') || '{}');
//     const validatedCategories = Object.fromEntries(
//       Object.entries(savedCategories).map(([key, value]) => [key, Array.isArray(value) ? value : []])
//     );
//     setCategories(validatedCategories);
//     updateLocalStorage(validatedCategories);
//   }, []);

//   const updateLocalStorage = (updatedCategories) => {
//     localStorage.setItem('categorizedTemplates', JSON.stringify(updatedCategories));
//   };

//   // const handleCategorySelect = (category) => {
//   //   setSelectedCategory(category);
//   //   setSelectedTemplate(null);
    
//   //   if (!categories[category] || !Array.isArray(categories[category])) {
//   //     const updatedCategories = { ...categories, [category]: [] };
//   //     setCategories(updatedCategories);
//   //     updateLocalStorage(updatedCategories);
//   //   }
//   // };

//   const handleTemplateSelect = (template) => {
//     setSelectedTemplate(template);
//   };

//   const handleNewCategory = () => {
//     if (newCategoryName && !categories[newCategoryName]) {
//       const updatedCategories = { ...categories, [newCategoryName]: [] };
//       setCategories(updatedCategories);
//       updateLocalStorage(updatedCategories);
//       setNewCategoryName('');
//       setSelectedCategory(newCategoryName);
//       setIsNewCategoryDialogOpen(false);
//     }
//   };

//   const handleNewTemplate = () => {
//     if (newTemplateName && selectedCategory) {
//       const newTemplate = { name: newTemplateName, operations: [] };
//       const updatedCategories = { ...categories };
      
//       if (!updatedCategories[selectedCategory] || !Array.isArray(updatedCategories[selectedCategory])) {
//         updatedCategories[selectedCategory] = [];
//       }
      
//       updatedCategories[selectedCategory] = [...updatedCategories[selectedCategory], newTemplate];
      
//       setCategories(updatedCategories);
//       updateLocalStorage(updatedCategories);
//       setNewTemplateName('');
//       setSelectedTemplate(newTemplate);
//       setIsNewTemplateDialogOpen(false);
//     }
//   };

//   const handleDeleteTemplate = () => {
//     if (selectedTemplate && selectedCategory) {
//       setIsDeleteDialogOpen(true);
//     }
//   };

//   const handleDeleteCategory = () => {
//     if (selectedCategory) {
//       setIsDeleteCategoryDialogOpen(true);
//     }
//   };

//   const confirmDeleteTemplate = () => {
//     const updatedTemplates = categories[selectedCategory].filter(t => t.name !== selectedTemplate.name);
//     const updatedCategories = { ...categories, [selectedCategory]: updatedTemplates };
//     setCategories(updatedCategories);
//     updateLocalStorage(updatedCategories);
//     setSelectedTemplate(null);
//     setIsDeleteDialogOpen(false);
//   };

//   const confirmDeleteCategory = () => {
//     const { [selectedCategory]: deletedCategory, ...remainingCategories } = categories;
//     setCategories(remainingCategories);
//     updateLocalStorage(remainingCategories);
//     setSelectedCategory('');
//     setSelectedTemplate(null);
//     setIsDeleteCategoryDialogOpen(false);
//   };

//   const handleAddOperation = () => {
//     if (selectedTemplate && newOperation.columnName && newOperation.action) {
//       const updatedTemplate = {
//         ...selectedTemplate,
//         operations: [...selectedTemplate.operations, newOperation]
//       };
//       updateTemplate(updatedTemplate);
//       setNewOperation({ columnName: '', action: '', value: '' });
//     }
//   };

//   const handleDeleteOperation = (index) => {
//     if (selectedTemplate) {
//       const updatedOperations = selectedTemplate.operations.filter((_, i) => i !== index);
//       const updatedTemplate = { ...selectedTemplate, operations: updatedOperations };
//       updateTemplate(updatedTemplate);
//     }
//   };

//   const updateTemplate = (updatedTemplate) => {
//     const updatedTemplates = categories[selectedCategory].map(t => t.name === updatedTemplate.name ? updatedTemplate : t);
//     const updatedCategories = { ...categories, [selectedCategory]: updatedTemplates };
//     setCategories(updatedCategories);
//     setSelectedTemplate(updatedTemplate);
//     updateLocalStorage(updatedCategories);
//   };

//   const handleApplyTemplate = async () => {
//     setIsConfirmDialogOpen(true);
//   };

//   const confirmApplyTemplate = async () => {
//     setIsConfirmDialogOpen(false);
//     if (!selectedTemplate) return;

//     try {
//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );

//       let allValues = response.data.values || [];
//       if (allValues.length === 0) {
//         throw new Error("スプレッドシートにデータがありません。");
//       }

//       let headers = allValues[0];
//       let data = allValues.slice(1);

//       for (const op of selectedTemplate.operations) {
//         let columnIndex = headers.findIndex(header => header.toLowerCase() === op.columnName.toLowerCase());

//         if (columnIndex === -1) {
//           headers.push(op.columnName);
//           columnIndex = headers.length - 1;
//           data.forEach(row => row.push(""));
//         }

//         data = data.map(row => {
//           let cell = row[columnIndex] || "";
//           switch (op.action) {
//             case 'replace':
//               cell = op.value;
//               break;
//             case 'prefix':
//               cell = op.value + cell;
//               break;
//             case 'suffix':
//               cell = cell + op.value;
//               break;
//             default:
//               break;
//           }
//           row[columnIndex] = cell;
//           return row;
//         });
//       }

//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!A1`,
//         {
//           values: [headers, ...data],
//           range: `${sheetName}!A1`
//         },
//         {
//           params: { valueInputOption: 'RAW' },
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       );

//       alert('テンプレートが正常に適用されました！');
//       fetchData();
//     } catch (error) {
//       console.error('Error applying template:', error);
//       alert(`テンプレートの適用に失敗しました。エラー: ${error.message}`);
//     }
//   };

//   const exportTemplates = () => {
//     const blob = new Blob([JSON.stringify(categories, null, 2)], { type: 'application/json' });
//     const url = URL.createObjectURL(blob);
//     const a = document.createElement('a');
//     a.href = url;
//     a.download = 'categorized_templates_export.json';
//     document.body.appendChild(a);
//     a.click();
//     document.body.removeChild(a);
//     URL.revokeObjectURL(url);
//   };

//   const importTemplates = (event) => {
//     const file = event.target.files[0];
//     const reader = new FileReader();
//     reader.onload = function(e) {
//       try {
//         const importedCategories = JSON.parse(e.target.result);
//         setCategories(importedCategories);
//         updateLocalStorage(importedCategories);
//         alert('テンプレートのインポートが成功しました。');
//       } catch (error) {
//         console.error('テンプレートのインポートに失敗しました:', error);
//         alert('テンプレートのインポートに失敗しました。ファイルを確認してください。');
//       }
//     };
//     reader.readAsText(file);
//   };

//   const handleMenuOpen = (event, target) => {
//     setAnchorEl(event.currentTarget);
//     setMenuTarget(target);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//     setMenuTarget(null);
//   };

//   const handleDuplicate = () => {
//     handleMenuClose();
//     if (menuTarget.type === 'category') {
//       duplicateCategory(menuTarget.name);
//     } else if (menuTarget.type === 'template') {
//       duplicateTemplate(menuTarget.category, menuTarget.template);
//     }
//   };

//   const duplicateCategory = (categoryName) => {
//     const newCategoryName = `${categoryName} (Copy)`;
//     const updatedCategories = {
//       ...categories,
//       [newCategoryName]: JSON.parse(JSON.stringify(categories[categoryName])),
//     };
//     setCategories(updatedCategories);
//     updateLocalStorage(updatedCategories);
//   };

//   const duplicateTemplate = (categoryName, template) => {
//     const newTemplateName = `${template.name} (Copy)`;
//     const updatedCategories = { ...categories };
//     updatedCategories[categoryName] = [
//       ...updatedCategories[categoryName],
//       { ...JSON.parse(JSON.stringify(template)), name: newTemplateName },
//     ];
//     setCategories(updatedCategories);
//     updateLocalStorage(updatedCategories);
//   };

//   const handleRename = () => {
//     handleMenuClose();
//     if (menuTarget) {
//       setRenameTarget(menuTarget);
//       setNewName(menuTarget.type === 'category' ? menuTarget.name : menuTarget.template.name);
//       setIsRenameDialogOpen(true);
//     }
//   };

//   const confirmRename = () => {
//     if (renameTarget && newName) {
//       if (renameTarget.type === 'category') {
//         renameCategory(renameTarget.name, newName);
//       } else if (renameTarget.type === 'template') {
//         renameTemplate(renameTarget.category, renameTarget.template, newName);
//       }
//     }
//     setIsRenameDialogOpen(false);
//     setRenameTarget(null);
//     setNewName('');
//   };

//   const renameCategory = (oldName, newName) => {
//     if (oldName !== newName && !categories[newName]) {
//       const updatedCategories = { ...categories };
//       updatedCategories[newName] = updatedCategories[oldName];
//       delete updatedCategories[oldName];
//       setCategories(updatedCategories);
//       updateLocalStorage(updatedCategories);
//       if (selectedCategory === oldName) {
//         setSelectedCategory(newName);
//       }
//     }
//   };

//   const renameTemplate = (categoryName, template, newName) => {
//     if (template.name !== newName) {
//       const updatedCategories = { ...categories };
//       const templateIndex = updatedCategories[categoryName].findIndex(t => t.name === template.name);
//       if (templateIndex !== -1) {
//         updatedCategories[categoryName][templateIndex] = { ...template, name: newName };
//         setCategories(updatedCategories);
//         updateLocalStorage(updatedCategories);
//         if (selectedTemplate && selectedTemplate.name === template.name) {
//           setSelectedTemplate({ ...selectedTemplate, name: newName });
//         }
//       }
//     }
//   };

//   return (
//     <Box sx={{ flexGrow: 1 }}>
//       <AppBar position="static">
//         <Toolbar>
//           <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
//             テンプレート管理
//           </Typography>
//           <Button color="inherit" onClick={() => setIsDrawerOpen(true)}>
//             テンプレートを開く
//           </Button>
//         </Toolbar>
//       </AppBar>

//       <Drawer
//         anchor="right"
//         open={isDrawerOpen}
//         onClose={() => setIsDrawerOpen(false)}
//         sx={{ width: 350 }}
//       >
//         <Box sx={{ width: 350, p: 2 }}>
//           <Typography variant="h6" gutterBottom>
//             カテゴリーとテンプレート
//           </Typography>
//           <Button
//             startIcon={<AddIcon />}
//             onClick={() => setIsNewCategoryDialogOpen(true)}
//             fullWidth
//             variant="outlined"
//             sx={{ mb: 2 }}
//           >
//             新しいカテゴリー
//           </Button>
//           <List>
//             {Object.entries(categories).map(([categoryName, templates]) => (
//               <React.Fragment key={categoryName}>
//                 <ListItem
//                   secondaryAction={
//                     <IconButton
//                       edge="end"
//                       aria-label="more"
//                       onClick={(event) => handleMenuOpen(event, { type: 'category', name: categoryName })}
//                     >
//                       <MoreVertIcon />
//                     </IconButton>
//                   }
//                 >
//                   <ListItemIcon>
//                     <FolderIcon />
//                   </ListItemIcon>
//                   <ListItemText primary={categoryName} />
//                 </ListItem>
//                 <List component="div" disablePadding>
//                   {Array.isArray(templates) && templates.map((template) => (
//                     <ListItem
//                       key={template.name}
//                       sx={{ pl: 4 }}
//                       secondaryAction={
//                         <IconButton
//                           edge="end"
//                           aria-label="more"
//                           onClick={(event) => {
//                             event.stopPropagation();
//                             handleMenuOpen(event, { type: 'template', category: categoryName, template: template });
//                           }}
//                         >
//                           <MoreVertIcon />
//                         </IconButton>
//                       }
//                       onClick={() => {
//                         setSelectedCategory(categoryName);
//                         handleTemplateSelect(template);
//                         setIsDrawerOpen(false);
//                       }}
//                     >
//                       <ListItemIcon>
//                         <DescriptionIcon />
//                       </ListItemIcon>
//                       <ListItemText primary={template.name} />
//                     </ListItem>
//                   ))}
//                   <ListItem sx={{ pl: 4 }}>
//                     <Button
//                       startIcon={<AddIcon />}
//                       onClick={() => {
//                         setSelectedCategory(categoryName);
//                         setIsNewTemplateDialogOpen(true);
//                       }}
//                     >
//                       新しいテンプレート
//                     </Button>
//                   </ListItem>
//                 </List>
//                 <Divider />
//               </React.Fragment>
//             ))}
//           </List>
//           <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
//             <Button variant="contained" onClick={exportTemplates} sx={{ mr: 1 }}>
//               エクスポート
//             </Button>
//             <input
//               accept=".json"
//               style={{ display: 'none' }}
//               id="raised-button-file"
//               type="file"
//               onChange={importTemplates}
//             />
//             <label htmlFor="raised-button-file">
//               <Button variant="contained" component="span">
//                 インポート
//               </Button>
//             </label>
//           </Box>
//         </Box>
//       </Drawer>

//       <Menu
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={handleMenuClose}
//       >
//         <MenuItem onClick={handleRename}>
//           <ListItemIcon>
//             <EditIcon fontSize="small" />
//           </ListItemIcon>
//           <ListItemText>名前変更</ListItemText>
//         </MenuItem>
//         <MenuItem onClick={handleDuplicate}>
//           <ListItemIcon>
//             <DuplicateIcon fontSize="small" />
//           </ListItemIcon>
//           <ListItemText>複製</ListItemText>
//         </MenuItem>
//         <MenuItem onClick={() => {
//           handleMenuClose();
//           if (menuTarget.type === 'category') {
//             setSelectedCategory(menuTarget.name);
//             handleDeleteCategory();
//           } else if (menuTarget.type === 'template') {
//             setSelectedCategory(menuTarget.category);
//             setSelectedTemplate(menuTarget.template);
//             handleDeleteTemplate();
//           }
//         }}>
//           <ListItemIcon>
//             <DeleteIcon fontSize="small" />
//           </ListItemIcon>
//           <ListItemText>削除</ListItemText>
//         </MenuItem>
//       </Menu>

//       <Box sx={{ p: 3 }}>
//         {selectedTemplate ? (
//           <Paper elevation={3} sx={{ p: 2 }}>
//             <Typography variant="h6" gutterBottom>
//               {selectedTemplate.name} の詳細
//             </Typography>
//             <Box sx={{ 
//               display: 'flex', 
//               flexWrap: 'wrap',
//               overflowX: 'auto', 
//               pb: 1, 
//               mb: 2,
//               '&::-webkit-scrollbar': { height: 6 },
//               '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0,0,0,.2)' }
//             }}>
//               {selectedTemplate.operations.map((operation, index) => (
//                 <Card key={index} sx={{ 
//                   width: 160,
//                   minWidth: 160,
//                   mr: 1,
//                   mb: 1,
//                   flexShrink: 0 
//                 }}>
//                   <CardContent sx={{ p: 1 }}>
//                     <Typography variant="subtitle2" component="div" noWrap>
//                       {operation.columnName}
//                     </Typography>
//                     <Typography variant="caption" color="text.secondary" display="block" noWrap>
//                       {operation.action}
//                     </Typography>
//                     <Typography variant="body2" noWrap sx={{ fontSize: '0.75rem' }}>
//                       {operation.value}
//                     </Typography>
//                   </CardContent>
//                   <CardActions sx={{ p: 0, justifyContent: 'flex-end' }}>
//                     <IconButton size="small" onClick={() => handleDeleteOperation(index)}>
//                       <DeleteIcon fontSize="small" />
//                     </IconButton>
//                   </CardActions>
//                 </Card>
//               ))}
//             </Box>
//             <Box sx={{ mt: 2 }}>
//               <Typography variant="subtitle1" gutterBottom>
//                 新しい操作を追加
//               </Typography>
//               <Grid container spacing={2}>
//                 <Grid item xs={12} sm={4}>
//                   <TextField
//                     label="カラム名"
//                     value={newOperation.columnName}
//                     onChange={(e) => setNewOperation({...newOperation, columnName: e.target.value})}
//                     fullWidth
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={4}>
//                   <FormControl fullWidth>
//                     <InputLabel>アクション</InputLabel>
//                     <Select
//                       value={newOperation.action}
//                       onChange={(e) => setNewOperation({...newOperation, action: e.target.value})}
//                     >
//                       <MenuItem value="replace">置換</MenuItem>
//                       <MenuItem value="prefix">接頭辞追加</MenuItem>
//                       <MenuItem value="suffix">接尾辞追加</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} sm={4}>
//                   <TextField
//                     label="値"
//                     value={newOperation.value}
//                     onChange={(e) => setNewOperation({...newOperation, value: e.target.value})}
//                     fullWidth
//                   />
//                 </Grid>
//               </Grid>
//               <Button variant="contained" onClick={handleAddOperation} sx={{ mt: 2 }}>
//                 操作を追加
//               </Button>
//             </Box>
//             <Button
//               variant="contained"
//               color="primary"
//               startIcon={<ApplyIcon />}
//               onClick={handleApplyTemplate}
//               sx={{ mt: 4 }}
//             >
//               テンプレートを適用
//             </Button>
//           </Paper>
//         ) : (
//           <Typography variant="body1">
//             右側のドロワーからテンプレートを選択してください。
//           </Typography>
//         )}
//       </Box>

//       {/* 新しいカテゴリーダイアログ */}
//       <Dialog open={isNewCategoryDialogOpen} onClose={() => setIsNewCategoryDialogOpen(false)}>
//         <DialogTitle>新しいカテゴリー</DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             margin="dense"
//             label="カテゴリー名"
//             fullWidth
//             value={newCategoryName}
//             onChange={(e) => setNewCategoryName(e.target.value)}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsNewCategoryDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={handleNewCategory}>作成</Button>
//         </DialogActions>
//       </Dialog>

//       {/* 新しいテンプレートダイアログ */}
//       <Dialog open={isNewTemplateDialogOpen} onClose={() => setIsNewTemplateDialogOpen(false)}>
//         <DialogTitle>新しいテンプレート</DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             margin="dense"
//             label="テンプレート名"
//             fullWidth
//             value={newTemplateName}
//             onChange={(e) => setNewTemplateName(e.target.value)}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsNewTemplateDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={handleNewTemplate}>作成</Button>
//         </DialogActions>
//       </Dialog>

//       {/* テンプレート適用確認ダイアログ */}
//       <Dialog
//         open={isConfirmDialogOpen}
//         onClose={() => setIsConfirmDialogOpen(false)}
//       >
//         <DialogTitle>確認</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             このテンプレートを適用しますか？この操作は元に戻せません。
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsConfirmDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={confirmApplyTemplate} autoFocus>
//             適用
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* テンプレート削除確認ダイアログ */}
//       <Dialog
//         open={isDeleteDialogOpen}
//         onClose={() => setIsDeleteDialogOpen(false)}
//       >
//         <DialogTitle>テンプレート削除の確認</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             テンプレート "{selectedTemplate?.name}" を削除しますか？この操作は元に戻せません。
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsDeleteDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={confirmDeleteTemplate} color="error" autoFocus>
//             削除
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* カテゴリー削除確認ダイアログ */}
//       <Dialog
//         open={isDeleteCategoryDialogOpen}
//         onClose={() => setIsDeleteCategoryDialogOpen(false)}
//       >
//         <DialogTitle>カテゴリー削除の確認</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             カテゴリー "{selectedCategory}" とそれに含まれるすべてのテンプレートを削除しますか？この操作は元に戻せません。
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsDeleteCategoryDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={confirmDeleteCategory} color="error" autoFocus>
//             削除
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* 名前変更ダイアログ */}
//       <Dialog open={isRenameDialogOpen} onClose={() => setIsRenameDialogOpen(false)}>
//         <DialogTitle>
//           {renameTarget?.type === 'category' ? 'カテゴリー名の変更' : 'テンプレート名の変更'}
//         </DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             margin="dense"
//             label="新しい名前"
//             fullWidth
//             value={newName}
//             onChange={(e) => setNewName(e.target.value)}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsRenameDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={confirmRename}>変更</Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// export default ColumnNameBasedTemplateManager;

