// cryptoUtils.js

import CryptoJS from 'crypto-js';

export const encryptKey = (key) => {
  const salt = CryptoJS.lib.WordArray.random(128 / 8);
  const iv = CryptoJS.lib.WordArray.random(128 / 8);
  const encrypted = CryptoJS.AES.encrypt(key, salt.toString(), {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return salt.toString() + iv.toString() + encrypted.toString();
};

export const decryptKey = (encryptedKey) => {
  try {
    const salt = CryptoJS.enc.Hex.parse(encryptedKey.substr(0, 32));
    const iv = CryptoJS.enc.Hex.parse(encryptedKey.substr(32, 32));
    const encrypted = encryptedKey.substring(64);
    const key = CryptoJS.PBKDF2(salt.toString(), salt, {
      keySize: 256 / 32,
      iterations: 1000,
    });
    const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error('Failed to decrypt stored API key:', error);
    return '';
  }
};
