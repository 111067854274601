// // firebaseConfig.js

// import { initializeApp } from 'firebase/app';
// import { getAuth } from 'firebase/auth';
// import { getFirestore } from 'firebase/firestore';

// // Firebaseプロジェクトの設定オブジェクト
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY, // FirebaseプロジェクトのAPIキー
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN, // 認証ドメイン
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID, // プロジェクトID
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET, // ストレージバケット
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID, // メッセージング送信者ID
//   appId: process.env.REACT_APP_FIREBASE_APP_ID, // アプリID
// };

// // Firebaseアプリを初期化
// const app = initializeApp(firebaseConfig);

// // Firebase Authenticationを初期化
// export const auth = getAuth(app);

// // Firestoreを初期化
// export const db = getFirestore(app);



import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);