// APIKeyInput.js

import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, FormControlLabel, Checkbox, Typography, Alert, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CryptoJS from 'crypto-js';
import { encryptKey, decryptKey } from './cryptoUtils'; // ユーティリティ関数をインポート

function APIKeyInput({ onApiKeySet }) {
  const [apiKeyInput, setApiKeyInput] = useState('');
  const [rememberKey, setRememberKey] = useState(false);
  const [showKey, setShowKey] = useState(false);
  const [isKeyCleared, setIsKeyCleared] = useState(false);
  const [lastUsedKeyHint, setLastUsedKeyHint] = useState('');

  const maskApiKey = (key) => {
    if (!key) return '';
    if (key.length < 10) return '****';
    return key.slice(0, 4) + '*'.repeat(key.length - 8) + key.slice(-4);
  };

  const clearStoredKey = () => {
    sessionStorage.removeItem('openai_api_key');
    localStorage.removeItem('openai_api_key');
    setApiKeyInput('');
    setIsKeyCleared(true);
    setLastUsedKeyHint(maskApiKey(apiKeyInput));
    onApiKeySet('', false); // rememberKey を false として渡す
  };

  const handleQuickReset = () => {
    const storedKey = localStorage.getItem('openai_api_key') || sessionStorage.getItem('openai_api_key');
    if (storedKey) {
      const decryptedKey = decryptKey(storedKey);
      if (decryptedKey) {
        setApiKeyInput(decryptedKey);
        setIsKeyCleared(false);
        setRememberKey(!!localStorage.getItem('openai_api_key'));
        onApiKeySet(decryptedKey, !!localStorage.getItem('openai_api_key'));
        alert('APIキーが正常に再設定されました。');
      } else {
        alert('APIキーの再設定に失敗しました。新しいキーを入力してください。');
      }
    } else {
      alert('保存されたAPIキーが見つかりません。新しいキーを入力してください。');
    }
  };

  useEffect(() => {
    const storedApiKey = sessionStorage.getItem('openai_api_key') || localStorage.getItem('openai_api_key');
    if (storedApiKey) {
      const decryptedKey = decryptKey(storedApiKey);
      if (decryptedKey) {
        setApiKeyInput(decryptedKey);
        setRememberKey(!!localStorage.getItem('openai_api_key'));
        setLastUsedKeyHint(maskApiKey(decryptedKey));
        onApiKeySet(decryptedKey, !!localStorage.getItem('openai_api_key'));
      } else {
        clearStoredKey();
      }
    }
  }, [onApiKeySet]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const encryptedKey = encryptKey(apiKeyInput);
    if (rememberKey) {
      localStorage.setItem('openai_api_key', encryptedKey);
      sessionStorage.removeItem('openai_api_key');
    } else {
      sessionStorage.setItem('openai_api_key', encryptedKey);
      localStorage.removeItem('openai_api_key');
    }
    onApiKeySet(apiKeyInput, rememberKey); // rememberKey を渡す
    setIsKeyCleared(false);
    setLastUsedKeyHint(maskApiKey(apiKeyInput));
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <Alert severity="warning" sx={{ mb: 1 }}>
        セキュリティ警告: APIキーはクライアント側で保存されます。公共のデバイスでは使用しないでください。
      </Alert>
      {isKeyCleared && (
        <Alert severity="info" sx={{ mb: 2 }}>
          APIキーがクリアされました。再設定するには下のボタンをクリックするか、新しいキーを入力してください。
          <Button onClick={handleQuickReset} variant="outlined" size="small" sx={{ mt: 1 }}>
            前回のキーを再設定
          </Button>
        </Alert>
      )}
      {/* 非表示のユーザー名フィールドを追加 */}
      <input
        type="text"
        autoComplete="username"
        style={{ display: 'none' }}
        aria-hidden="true"
      />
      <TextField
        label="OpenAI API Key"
        value={apiKeyInput}
        onChange={(e) => setApiKeyInput(e.target.value)}
        type={showKey ? 'text' : 'password'}
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => setShowKey(!showKey)} edge="end">
              {showKey ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          ),
        }}
        inputProps={{
          autoComplete: 'current-password',
        }}
      />
      {isKeyCleared && lastUsedKeyHint && (
        <Typography variant="caption" display="block" gutterBottom>
          前回使用したキーのヒント: {lastUsedKeyHint}
        </Typography>
      )}
      <FormControlLabel
        control={
          <Checkbox
            checked={rememberKey}
            onChange={(e) => setRememberKey(e.target.checked)}
          />
        }
        label="APIキーを記憶する"
      />
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 0 }}>
        APIキーを設定
      </Button>
      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
        注意: このアプリケーションは、入力されたAPIキーを使用してOpenAI APIにリクエストを送信します。
        キーの使用に関連するすべての料金はユーザーの責任となります。
      </Typography>
    </Box>
  );
}

export default APIKeyInput;




// import React, { useState, useEffect } from 'react';
// import { TextField, Button, Box, FormControlLabel, Checkbox, Typography, Alert, IconButton } from '@mui/material';
// import { Visibility, VisibilityOff } from '@mui/icons-material';
// import CryptoJS from 'crypto-js';
// import { encryptKey, decryptKey } from './cryptoUtils';


// function APIKeyInput({ onApiKeySet }) {
//   const [apiKeyInput, setApiKeyInput] = useState('');
//   const [rememberKey, setRememberKey] = useState(false);
//   const [showKey, setShowKey] = useState(false);
//   const [isKeyCleared, setIsKeyCleared] = useState(false);
//   const [lastUsedKeyHint, setLastUsedKeyHint] = useState('');

//   // const encryptKey = (key) => {
//   //   const salt = CryptoJS.lib.WordArray.random(128 / 8);
//   //   const iv = CryptoJS.lib.WordArray.random(128 / 8);
//   //   const encrypted = CryptoJS.AES.encrypt(key, salt.toString(), {
//   //     iv: iv,
//   //     mode: CryptoJS.mode.CBC,
//   //     padding: CryptoJS.pad.Pkcs7,
//   //   });
//   //   return salt.toString() + iv.toString() + encrypted.toString();
//   // };

//   // const decryptKey = (encryptedKey) => {
//   //   try {
//   //     const salt = CryptoJS.enc.Hex.parse(encryptedKey.substr(0, 32));
//   //     const iv = CryptoJS.enc.Hex.parse(encryptedKey.substr(32, 32));
//   //     const encrypted = encryptedKey.substring(64);
//   //     const key = CryptoJS.PBKDF2(salt.toString(), salt, {
//   //       keySize: 256 / 32,
//   //       iterations: 1000,
//   //     });
//   //     const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
//   //       iv: iv,
//   //       mode: CryptoJS.mode.CBC,
//   //       padding: CryptoJS.pad.Pkcs7,
//   //     });
//   //     return decrypted.toString(CryptoJS.enc.Utf8);
//   //   } catch (error) {
//   //     console.error('Failed to decrypt stored API key:', error);
//   //     return '';
//   //   }
//   // };

//   const maskApiKey = (key) => {
//     if (!key) return '';
//     if (key.length < 10) return '****';
//     return key.slice(0, 4) + '*'.repeat(key.length - 8) + key.slice(-4);
//   };

//   const clearStoredKey = () => {
//     sessionStorage.removeItem('openai_api_key');
//     localStorage.removeItem('openai_api_key');
//     setApiKeyInput('');
//     setIsKeyCleared(true);
//     setLastUsedKeyHint(maskApiKey(apiKeyInput));
//     onApiKeySet('');
//   };

//   const handleQuickReset = () => {
//     const storedKey = localStorage.getItem('openai_api_key') || sessionStorage.getItem('openai_api_key');
//     if (storedKey) {
//       const decryptedKey = decryptKey(storedKey);
//       if (decryptedKey) {
//         setApiKeyInput(decryptedKey);
//         setIsKeyCleared(false);
//         onApiKeySet(decryptedKey);
//         alert('APIキーが正常に再設定されました。');
//       } else {
//         alert('APIキーの再設定に失敗しました。新しいキーを入力してください。');
//       }
//     } else {
//       alert('保存されたAPIキーが見つかりません。新しいキーを入力してください。');
//     }
//   };

//   useEffect(() => {
//     const storedApiKey = sessionStorage.getItem('openai_api_key') || localStorage.getItem('openai_api_key');
//     if (storedApiKey) {
//       const decryptedKey = decryptKey(storedApiKey);
//       if (decryptedKey) {
//         setApiKeyInput(decryptedKey); // 復号化したAPIキーをセット
//         setRememberKey(!!localStorage.getItem('openai_api_key'));
//         setLastUsedKeyHint(maskApiKey(decryptedKey));
//         onApiKeySet(decryptedKey);
//       } else {
//         clearStoredKey();
//       }
//     }
//   }, []);
  

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const encryptedKey = encryptKey(apiKeyInput);
//     if (rememberKey) {
//       localStorage.setItem('openai_api_key', encryptedKey);
//       sessionStorage.removeItem('openai_api_key');
//     } else {
//       sessionStorage.setItem('openai_api_key', encryptedKey);
//       localStorage.removeItem('openai_api_key');
//     }
//     onApiKeySet(apiKeyInput);
//     setIsKeyCleared(false);
//     setLastUsedKeyHint(maskApiKey(apiKeyInput));
//   };

//   return (
//     <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
//       <Alert severity="warning" sx={{ mb: 1 }}>
//         セキュリティ警告: APIキーはクライアント側で保存されます。公共のデバイスでは使用しないでください。
//       </Alert>
//       {isKeyCleared && (
//         <Alert severity="info" sx={{ mb: 2 }}>
//           APIキーがクリアされました。再設定するには下のボタンをクリックするか、新しいキーを入力してください。
//           <Button onClick={handleQuickReset} variant="outlined" size="small" sx={{ mt: 1 }}>
//             前回のキーを再設定
//           </Button>
//         </Alert>
//       )}
//       {/* 非表示のユーザー名フィールドを追加 */}
//       <input
//         type="text"
//         autoComplete="username"
//         style={{ display: 'none' }}
//         aria-hidden="true"
//       />
//       <TextField
//         label="OpenAI API Key"
//         value={apiKeyInput}
//         onChange={(e) => setApiKeyInput(e.target.value)}
//         type={showKey ? 'text' : 'password'}
//         fullWidth
//         margin="normal"
//         InputProps={{
//           endAdornment: (
//             <IconButton onClick={() => setShowKey(!showKey)} edge="end">
//               {showKey ? <VisibilityOff /> : <Visibility />}
//             </IconButton>
//           ),
//         }}
//         inputProps={{
//           autoComplete: 'current-password',
//         }}
//       />
//       {isKeyCleared && lastUsedKeyHint && (
//         <Typography variant="caption" display="block" gutterBottom>
//           前回使用したキーのヒント: {lastUsedKeyHint}
//         </Typography>
//       )}
//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={rememberKey}
//             onChange={(e) => setRememberKey(e.target.checked)}
//           />
//         }
//         label="APIキーを記憶する"
//       />
//       <Button type="submit" variant="contained" color="primary" sx={{ mt: 0 }}>
//         APIキーを設定
//       </Button>
//       <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
//         注意: このアプリケーションは、入力されたAPIキーを使用してOpenAI APIにリクエストを送信します。
//         キーの使用に関連するすべての料金はユーザーの責任となります。
//       </Typography>
//     </Box>
//   );
// }

// export default APIKeyInput;







// import React, { useState, useEffect } from 'react';
// import { TextField, Button, Box, FormControlLabel, Checkbox, Typography, Alert, IconButton } from '@mui/material';
// import { Visibility, VisibilityOff } from '@mui/icons-material';
// import CryptoJS from 'crypto-js';

// function APIKeyInput({ onApiKeySet }) {
//   const [apiKeyInput, setApiKeyInput] = useState('');
//   const [rememberKey, setRememberKey] = useState(false);
//   const [showKey, setShowKey] = useState(false);
//   const [isKeyCleared, setIsKeyCleared] = useState(false);
//   const [lastUsedKeyHint, setLastUsedKeyHint] = useState('');

//   const encryptKey = (key) => {
//     const salt = CryptoJS.lib.WordArray.random(128 / 8);
//     const iv = CryptoJS.lib.WordArray.random(128 / 8);
//     const encrypted = CryptoJS.AES.encrypt(key, salt.toString(), {
//       iv: iv,
//       mode: CryptoJS.mode.CBC,
//       padding: CryptoJS.pad.Pkcs7,
//     });
//     return salt.toString() + iv.toString() + encrypted.toString();
//   };

//   const decryptKey = (encryptedKey) => {
//     try {
//       const salt = CryptoJS.enc.Hex.parse(encryptedKey.substr(0, 32));
//       const iv = CryptoJS.enc.Hex.parse(encryptedKey.substr(32, 32));
//       const encrypted = encryptedKey.substring(64);
//       const key = CryptoJS.PBKDF2(salt.toString(), salt, {
//         keySize: 256 / 32,
//         iterations: 1000,
//       });
//       const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
//         iv: iv,
//         mode: CryptoJS.mode.CBC,
//         padding: CryptoJS.pad.Pkcs7,
//       });
//       return decrypted.toString(CryptoJS.enc.Utf8);
//     } catch (error) {
//       console.error('Failed to decrypt stored API key:', error);
//       return '';
//     }
//   };

//   const maskApiKey = (key) => {
//     if (!key) return '';
//     if (key.length < 10) return '****';
//     return key.slice(0, 4) + '*'.repeat(key.length - 8) + key.slice(-4);
//   };

//   const clearStoredKey = () => {
//     sessionStorage.removeItem('openai_api_key');
//     localStorage.removeItem('openai_api_key');
//     setApiKeyInput('');
//     setIsKeyCleared(true);
//     setLastUsedKeyHint(maskApiKey(apiKeyInput));
//     onApiKeySet('');
//   };

//   const handleQuickReset = () => {
//     const storedKey = localStorage.getItem('openai_api_key') || sessionStorage.getItem('openai_api_key');
//     if (storedKey) {
//       const decryptedKey = decryptKey(storedKey);
//       if (decryptedKey) {
//         setApiKeyInput(decryptedKey);
//         setIsKeyCleared(false);
//         onApiKeySet(decryptedKey);
//         alert('APIキーが正常に再設定されました。');
//       } else {
//         alert('APIキーの再設定に失敗しました。新しいキーを入力してください。');
//       }
//     } else {
//       alert('保存されたAPIキーが見つかりません。新しいキーを入力してください。');
//     }
//   };

//   useEffect(() => {
//     const storedApiKey = sessionStorage.getItem('openai_api_key') || localStorage.getItem('openai_api_key');
//     if (storedApiKey) {
//       const decryptedKey = decryptKey(storedApiKey);
//       if (decryptedKey) {
//         setApiKeyInput(decryptedKey);
//         setRememberKey(!!localStorage.getItem('openai_api_key'));
//         setLastUsedKeyHint(maskApiKey(decryptedKey));
//         onApiKeySet(decryptedKey);
//       } else {
//         clearStoredKey();
//       }
//     }
//   }, []);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const encryptedKey = encryptKey(apiKeyInput);
//     if (rememberKey) {
//       localStorage.setItem('openai_api_key', encryptedKey);
//       sessionStorage.removeItem('openai_api_key');
//     } else {
//       sessionStorage.setItem('openai_api_key', encryptedKey);
//       localStorage.removeItem('openai_api_key');
//     }
//     onApiKeySet(apiKeyInput);
//     setIsKeyCleared(false);
//     setLastUsedKeyHint(maskApiKey(apiKeyInput));
//   };

//   return (
//     <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
//       <Alert severity="warning" sx={{ mb: 1 }}>
//         セキュリティ警告: APIキーはクライアント側で保存されます。公共のデバイスでは使用しないでください。
//       </Alert>
//       {isKeyCleared && (
//         <Alert severity="info" sx={{ mb: 2 }}>
//           APIキーがクリアされました。再設定するには下のボタンをクリックするか、新しいキーを入力してください。
//           <Button onClick={handleQuickReset} variant="outlined" size="small" sx={{ mt: 1 }}>
//             前回のキーを再設定
//           </Button>
//         </Alert>
//       )}
//       {/* 非表示のユーザー名フィールドを追加 */}
//       <input
//         type="text"
//         autoComplete="username"
//         style={{ display: 'none' }}
//         aria-hidden="true"
//       />
//       <TextField
//         label="OpenAI API Key"
//         value={apiKeyInput}
//         onChange={(e) => setApiKeyInput(e.target.value)}
//         type={showKey ? 'text' : 'password'}
//         fullWidth
//         margin="normal"
//         InputProps={{
//           endAdornment: (
//             <IconButton onClick={() => setShowKey(!showKey)} edge="end">
//               {showKey ? <VisibilityOff /> : <Visibility />}
//             </IconButton>
//           ),
//         }}
//         inputProps={{
//           autoComplete: 'current-password',
//         }}
//       />
//       {isKeyCleared && lastUsedKeyHint && (
//         <Typography variant="caption" display="block" gutterBottom>
//           前回使用したキーのヒント: {lastUsedKeyHint}
//         </Typography>
//       )}
//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={rememberKey}
//             onChange={(e) => setRememberKey(e.target.checked)}
//           />
//         }
//         label="APIキーを記憶する"
//       />
//       <Button type="submit" variant="contained" color="primary" sx={{ mt: 0 }}>
//         APIキーを設定
//       </Button>
//       <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
//         注意: このアプリケーションは、入力されたAPIキーを使用してOpenAI APIにリクエストを送信します。
//         キーの使用に関連するすべての料金はユーザーの責任となります。
//       </Typography>
//     </Box>
//   );
// }

// export default APIKeyInput;

