// DescriptionValidator.js

import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  DialogActions,
} from '@mui/material';

const DescriptionValidator = ({ htmlContent, onUpdate }) => {
  const [open, setOpen] = useState(false);
  const [sanitizedHtml, setSanitizedHtml] = useState('');

  const handleValidate = () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const aiContentElement = doc.getElementById('ai-generated-content');

    if (aiContentElement) {
      // AI生成部分のHTMLを取得
      let aiHtml = aiContentElement.innerHTML;

      // 空白削除処理を適用
      aiHtml = aiHtml
        .replace(/\s+/g, ' ')        // 連続する空白を単一の空白に置換
        .replace(/>\s+</g, '><')     // タグ間の空白を削除
        .trim();                     // 先頭と末尾の空白を削除

      // クリーンアップされたHTMLをAI生成部分に反映
      aiContentElement.innerHTML = aiHtml;

      // 修正後の全体のHTMLを取得
      const updatedHtml = doc.body.innerHTML;

      setSanitizedHtml(updatedHtml);

      // onUpdateコールバックがあれば呼び出す
      if (onUpdate) {
        onUpdate(updatedHtml);
      }
    } else {
      // AI生成部分が見つからない場合、そのまま表示
      setSanitizedHtml(htmlContent);
    }

    setOpen(true);
  };

  return (
    <>
      <Button variant="outlined" onClick={handleValidate} style={{ marginTop: '10px' }}>
        商品説明の空白を削除する
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>空白削除の結果</DialogTitle>
        <DialogContent>
          <Typography color="primary">空白の削除が完了しました。</Typography>
          <Typography variant="h6" style={{ marginTop: '20px' }}>プレビュー：</Typography>
          <div
            style={{ border: '1px solid #ccc', padding: '10px', marginTop: '10px' }}
            dangerouslySetInnerHTML={{ __html: sanitizedHtml || htmlContent }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DescriptionValidator;




// import React, { useState } from 'react';
// import {
//   Button,
//   Dialog,
//   DialogContent,
//   DialogTitle,
//   Typography,
//   List,
//   ListItem,
//   ListItemText,
//   DialogActions,
//   Checkbox,
//   FormControlLabel,
// } from '@mui/material';
// import DOMPurify from 'dompurify';

// export const allowedTags = [
//   'a', 'b', 'blockquote', 'br', 'center', 'code', 'dd', 'div', 'dl', 'dt', 'em', 'font',
//   'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hr', 'i', 'img', 'li', 'ol', 'p', 'pre', 'span',
//   'strong', 'sub', 'sup', 'table', 'tbody', 'td', 'tfoot', 'th', 'thead', 'tr', 'u', 'ul'
// ];

// export const allowedAttributes = [
//   'align', 'alt', 'border', 'cellpadding', 'cellspacing', 'color', 'colspan', 'face', 'height',
//   'href', 'src', 'valign', 'width', 'style', 'size'
// ];

// const DescriptionValidator = ({ htmlContent, onUpdate }) => {
//   const [open, setOpen] = useState(false);
//   const [errors, setErrors] = useState([]);
//   const [autoCorrect, setAutoCorrect] = useState(true);
//   const [sanitizedHtml, setSanitizedHtml] = useState('');

//   const handleValidate = () => {
//     const parser = new DOMParser();
//     const doc = parser.parseFromString(htmlContent, 'text/html');
//     const aiContentElement = doc.getElementById('ai-generated-content');

//     if (aiContentElement) {
//       const elements = aiContentElement.getElementsByTagName('*');
//       const errorList = [];
//       let corrected = false;

//       for (let i = elements.length - 1; i >= 0; i--) {
//         const el = elements[i];
//         const tagName = el.tagName.toLowerCase();
//         if (!allowedTags.includes(tagName)) {
//           errorList.push(`許可されていないタグ: <${tagName}>`);
//           if (autoCorrect) {
//             el.parentNode.removeChild(el);
//             corrected = true;
//             continue;
//           }
//         } else {
//           const attrsToRemove = [];
//           for (let attr of el.attributes) {
//             if (!allowedAttributes.includes(attr.name.toLowerCase())) {
//               errorList.push(`許可されていない属性: ${attr.name} in <${tagName}>`);
//               if (autoCorrect) {
//                 attrsToRemove.push(attr.name);
//                 corrected = true;
//               }
//             }
//           }
//           attrsToRemove.forEach(attrName => el.removeAttribute(attrName));
//         }
//       }

//       const serializedHtml = aiContentElement.innerHTML;
//       const cleanHtml = DOMPurify.sanitize(serializedHtml, {
//         ALLOWED_TAGS: allowedTags,
//         ALLOWED_ATTR: allowedAttributes,
//       });

//       aiContentElement.innerHTML = cleanHtml;

//       setErrors(errorList);
//       setSanitizedHtml(doc.body.innerHTML);

//       if (autoCorrect && corrected && onUpdate) {
//         onUpdate(doc.body.innerHTML);
//       }
//     } else {
//       setErrors([]);
//       setSanitizedHtml(htmlContent);
//     }

//     setOpen(true);
//   };

//   return (
//     <>
//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={autoCorrect}
//             onChange={(e) => setAutoCorrect(e.target.checked)}
//             color="primary"
//           />
//         }
//         label="自動的に修正する"
//         style={{ marginTop: '10px' }}
//       />
//       <Button variant="outlined" onClick={handleValidate} style={{ marginTop: '10px', marginLeft: '10px' }}>
//         商品説明を検証する
//       </Button>
//       <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
//         <DialogTitle>商品説明の検証結果</DialogTitle>
//         <DialogContent>
//           {errors.length > 0 ? (
//             <>
//               <Typography color="error">以下の問題があります：</Typography>
//               <List>
//                 {errors.map((error, index) => (
//                   <ListItem key={index}>
//                     <ListItemText primary={error} />
//                   </ListItem>
//                 ))}
//               </List>
//               {autoCorrect ? (
//                 <Typography color="primary" style={{ marginTop: '10px' }}>
//                   許可されていないタグや属性は自動的に削除されました。
//                 </Typography>
//               ) : (
//                 <Typography color="primary" style={{ marginTop: '10px' }}>
//                   自動修正はオフになっています。
//                 </Typography>
//               )}
//             </>
//           ) : (
//             <Typography color="primary">商品説明はeBayのガイドラインに準拠しています。</Typography>
//           )}
//           <Typography variant="h6" style={{ marginTop: '20px' }}>プレビュー：</Typography>
//           <div
//             style={{ border: '1px solid #ccc', padding: '10px', marginTop: '10px' }}
//             dangerouslySetInnerHTML={{ __html: sanitizedHtml || htmlContent }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };

// export default DescriptionValidator;




// import React, { useState } from 'react';
// import {
//   Button,
//   Dialog,
//   DialogContent,
//   DialogTitle,
//   Typography,
//   List,
//   ListItem,
//   ListItemText,
//   DialogActions,
//   Checkbox,
//   FormControlLabel,
// } from '@mui/material';
// import DOMPurify from 'dompurify';

// // 許可されているタグと属性のリストをエクスポート
// export const allowedTags = [
//   'a', 'b', 'blockquote', 'br', 'center', 'code', 'dd', 'div', 'dl', 'dt', 'em', 'font',
//   'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hr', 'i', 'img', 'li', 'ol', 'p', 'pre', 'span',
//   'strong', 'sub', 'sup', 'table', 'tbody', 'td', 'tfoot', 'th', 'thead', 'tr', 'u', 'ul'
// ];

// export const allowedAttributes = [
//   'align', 'alt', 'border', 'cellpadding', 'cellspacing', 'color', 'colspan', 'face', 'height',
//   'href', 'src', 'valign', 'width', 'style', 'size'
//   // 'class' 属性は許可されていないため、ここには含めていません
// ];

// const DescriptionValidator = ({ htmlContent, onUpdate }) => {
//   const [open, setOpen] = useState(false);
//   const [errors, setErrors] = useState([]);
//   const [autoCorrect, setAutoCorrect] = useState(true);
//   const [sanitizedHtml, setSanitizedHtml] = useState('');

//   const handleValidate = () => {
//     const parser = new DOMParser();
//     const doc = parser.parseFromString(htmlContent, 'text/html');
//     const elements = doc.body.getElementsByTagName('*');
//     const errorList = [];
//     let corrected = false;

//     for (let i = elements.length - 1; i >= 0; i--) {
//       const el = elements[i];
//       const tagName = el.tagName.toLowerCase();
//       if (!allowedTags.includes(tagName)) {
//         errorList.push(`許可されていないタグ: <${tagName}>`);
//         if (autoCorrect) {
//           el.parentNode.removeChild(el);
//           corrected = true;
//           continue;
//         }
//       } else {
//         const attrsToRemove = [];
//         for (let attr of el.attributes) {
//           if (!allowedAttributes.includes(attr.name.toLowerCase())) {
//             errorList.push(`許可されていない属性: ${attr.name} in <${tagName}>`);
//             if (autoCorrect) {
//               attrsToRemove.push(attr.name);
//               corrected = true;
//             }
//           }
//         }
//         // 許可されていない属性を削除
//         attrsToRemove.forEach(attrName => el.removeAttribute(attrName));
//       }
//     }

//     const serializedHtml = doc.body.innerHTML;
//     const cleanHtml = DOMPurify.sanitize(serializedHtml, {
//       ALLOWED_TAGS: allowedTags,
//       ALLOWED_ATTR: allowedAttributes,
//     });

//     setErrors(errorList);
//     setSanitizedHtml(cleanHtml);
//     setOpen(true);

//     if (autoCorrect && corrected && onUpdate) {
//       onUpdate(cleanHtml);
//     }
//   };

//   return (
//     <>
//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={autoCorrect}
//             onChange={(e) => setAutoCorrect(e.target.checked)}
//             color="primary"
//           />
//         }
//         label="自動的に修正する"
//         style={{ marginTop: '10px' }}
//       />
//       <Button variant="outlined" onClick={handleValidate} style={{ marginTop: '10px', marginLeft: '10px' }}>
//         商品説明を検証する
//       </Button>
//       <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
//         <DialogTitle>商品説明の検証結果</DialogTitle>
//         <DialogContent>
//           {errors.length > 0 ? (
//             <>
//               <Typography color="error">以下の問題があります：</Typography>
//               <List>
//                 {errors.map((error, index) => (
//                   <ListItem key={index}>
//                     <ListItemText primary={error} />
//                   </ListItem>
//                 ))}
//               </List>
//               {autoCorrect ? (
//                 <Typography color="primary" style={{ marginTop: '10px' }}>
//                   許可されていないタグや属性は自動的に削除されました。
//                 </Typography>
//               ) : (
//                 <Typography color="primary" style={{ marginTop: '10px' }}>
//                   自動修正はオフになっています。
//                 </Typography>
//               )}
//             </>
//           ) : (
//             <Typography color="primary">商品説明はeBayのガイドラインに準拠しています。</Typography>
//           )}
//           <Typography variant="h6" style={{ marginTop: '20px' }}>プレビュー：</Typography>
//           <div
//             style={{ border: '1px solid #ccc', padding: '10px', marginTop: '10px' }}
//             dangerouslySetInnerHTML={{ __html: sanitizedHtml || htmlContent }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };

// export default DescriptionValidator;

