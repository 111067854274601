import React from 'react';
import { Box, Typography, DialogContent, DialogContentText } from '@mui/material';

function PrivacyPolicy() {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>プライバシーポリシー</Typography>
      <Typography variant="subtitle1" sx={{ px: 0, pt: 0, pb: 2 }}>
        eBayスプレッドシート管理アプリケーション
      </Typography>
      <DialogContent dividers>
        <DialogContentText component="div">
          <Typography variant="h6" gutterBottom>1. 収集する情報と現状</Typography>
          <Typography component="div">
            <ul>
              <li>アカウント情報（メールアドレス）</li>
              <li>現状では、上記以外の情報をサーバーサイドなどで管理・保存していませんので上記以外の情報は活用しません。</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom>2. 情報の使用目的</Typography>
          <Typography component="div">
            <ul>
              <li>サービスの提供と改善</li>
              <li>ユーザーサポート</li>
              <li>テンプレート推奨システムの開発</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom>3. 情報の共有</Typography>
          <Typography paragraph>
            法的要請がある場合を除き、ユーザーの同意なく第三者と情報を共有することはありません。
          </Typography>

          <Typography variant="h6" gutterBottom>4. データセキュリティ</Typography>
          <Typography paragraph>
            適切な技術的・組織的措置を講じて、ユーザー情報を保護します。OpenAI APIキーは暗号化して保存され、サーバー側では保持していません。
          </Typography>

          <Typography variant="h6" gutterBottom>5. ユーザーの権利</Typography>
          <Typography component="div">
            <ul>
              <li>本ツールの使用の自由</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom>6. クッキーの使用</Typography>
          <Typography paragraph>
            本サービスでは、ユーザーエクスペリエンス向上のためにクッキーを使用しています。これには、テンプレート管理機能の設定保存なども含まれます。
          </Typography>

          <Typography variant="h6" gutterBottom>7. 第三者サービス</Typography>
          <Typography paragraph>
            Google認証、eBay API、OpenAI APIなどの第三者サービスを利用しています。これらのサービスの利用には、それぞれのプライバシーポリシーが適用されます。
          </Typography>

          <Typography variant="h6" gutterBottom>8. AI生成データの取り扱い</Typography>
          <Typography paragraph>
            AI機能により生成されたデータ（解析結果、生成タイトル等）は、サービス改善のために匿名化して使用する場合があります。個人を特定できる情報は含まれません。
          </Typography>

          <Typography variant="h6" gutterBottom>9. プライバシーポリシーの変更</Typography>
          <Typography paragraph>
            本ポリシーは随時更新される可能性があります。重要な変更がある場合は、サービス内で通知します。
          </Typography>

          <Typography variant="h6" gutterBottom>10. 画像データの取り扱い</Typography>
          <Typography paragraph>
            ユーザーがダウンロードした画像は当社は保存していません。本サービスの画像ダウンロード機能を使用する場合、ユーザーのスプレッドシートに含まれる画像URLから画像をダウンロードし、ZIP形式でユーザーに提供しますが、保持していません。当社はユーザーの画像データを保持せず、第三者と共有することもありません。
          </Typography>

          <Typography variant="h6" gutterBottom>11. 画像の使用と責任</Typography>
          <Typography paragraph>
            ユーザーは、本サービスを通じてダウンロードまたはアップロードする画像について、適切な権利を有していることを確認する責任があります。当社は、ユーザーが不適切な方法で取得した画像や、著作権を侵害する画像の使用について一切の責任を負いません。
          </Typography>

          <Typography variant="h6" gutterBottom>12. お問い合わせ</Typography>
          <Typography paragraph>
            プライバシーに関するお問い合わせは、info@awiiin.comまでご連絡ください。
          </Typography>

          <Typography variant="body2" align="right" sx={{ mt: 2 }}>
            最終更新日：2024年8月24日
          </Typography>
        </DialogContentText>
      </DialogContent>
    </Box>
  );
}

export default PrivacyPolicy;
