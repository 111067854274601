// App.js

import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { initializeApp } from 'firebase/app';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import axios from 'axios';
import { 
  Box, 
  Button, 
  Typography, 
  Grid, 
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  TextField,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  Snackbar,
  Alert,
  Switch,
  FormControlLabel,
  Link
} from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import LinkIcon from '@mui/icons-material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LaunchIcon from '@mui/icons-material/Launch';
import EditableSpreadsheet from './EditableSpreadsheet';
import ColumnNameBasedTemplateManager from './ColumnNameBasedTemplateManager';
import InteractiveManual from './InteractiveManual';
import TitleGenerationComponent from './titleGenerationService';
// import { generateTitles, createUpdatedSpreadsheetData, validateAndProcessTitles, overwriteExistingTitles, truncateTitles, preparePreviewData, updateTitlesFromPreview } from './titleGenerationService';
import ColumnDeletion from './ColumnDeletion';
import APIKeyInput from './APIKeyInput';
// import PreviewDialog from './PreviewDialog';
// import TermsAndPrivacy from './TermsAndPrivacy';
import ImageDownloader from './ImageDownloader';
import ImageUrlReplacer from './ImageUrlReplacer';
import CustomColumnSettings from './CustomColumnSettings';
import PriceCalculationSettings from './PriceCalculationSettings';
import OpenAIDescriptionGenerator from './OpenAIDescriptionGenerator';
import ItemSpecificsSettings from './ItemSpecificsSettings';
import ItemSpecificsApplier from './ItemSpecificsApplier';
import { Link as RouterLink } from 'react-router-dom'; // 追加: React RouterのLinkコンポーネント
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import LegalDisclosure from './LegalDisclosure';
import Login from './Login';
import { encryptKey, decryptKey } from './cryptoUtils';
import './App.css';

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

function AppContent({ setIsAuthenticated }) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [currentSpreadsheet, setCurrentSpreadsheet] = useState({ id: '', name: '', sheetName: '' });
  const [savedSpreadsheets, setSavedSpreadsheets] = useState([]);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sheetNames, setSheetNames] = useState([]);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [analysisResults, setAnalysisResults] = useState([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isCustomSettingsOpen, setIsCustomSettingsOpen] = useState(false);
  const [customSettings, setCustomSettings] = useState(null);
  const [spreadsheetData, setSpreadsheetData] = useState([]);
  const [isPriceCalculationOpen, setIsPriceCalculationOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isItemSpecificsSettingsOpen, setIsItemSpecificsSettingsOpen] = useState(false);
  const [itemSpecificsSettings, setItemSpecificsSettings] = useState([]);
  const [generatedTitles, setGeneratedTitles] = useState([]);
  const [previewData, setPreviewData] = useState([]);
  
  

  const navigate = useNavigate();

  // handleSignOut関数をuseCallbackでメモ化
  const handleSignOut = useCallback(async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('googleToken');
      localStorage.removeItem('userInfo');
      setIsAuthenticated(false);
      navigate('/login');
    } catch (error) {
      console.error("Error signing out", error);
    }
  }, [navigate, setIsAuthenticated]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        const fetchUserSettings = async () => {
          await loadUserSettings(currentUser.uid);
        };
        fetchUserSettings();
      } else {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [navigate]);


  const loadUserSettings = async (userId) => {
    try {
      const docRef = doc(db, "userSettings", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userSettings = docSnap.data();
        setCustomSettings(userSettings);
        // 他の必要な設定の更新
      }
    } catch (error) {
      console.error('Failed to load user settings:', error);
      setSnackbar({ open: true, message: 'ユーザー設定の読み込みに失敗しました', severity: 'error' });

    }
  };

  useEffect(() => {
    const savedToken = localStorage.getItem('googleToken');
    if (savedToken) {
      setToken(savedToken);
    }
    const saved = JSON.parse(localStorage.getItem('savedSpreadsheets')) || [];
    setSavedSpreadsheets(saved);
  }, []);

  const checkAndRefreshToken = useCallback(async () => {
    const currentToken = localStorage.getItem('googleToken');
    if (!currentToken) {
      setError('アクセストークンが見つかりません。再度サインインしてください。');
      return false;
    }
    setToken(currentToken);
    return true;
  }, []);

  // fetchSheetNames関数をuseCallbackでメモ化し、handleSignOutを依存に追加
  const fetchSheetNames = useCallback(async (spreadsheetId) => {
    if (!await checkAndRefreshToken()) return;
    if (!spreadsheetId) {
      setError('Spreadsheet IDを入力してください');
      return;
    }
    setError(null);
    setLoading(true);
    try {
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const sheets = response.data.sheets.map(sheet => sheet.properties.title);
      setSheetNames(sheets);
      if (sheets.length > 0) {
        setCurrentSpreadsheet(prev => ({ ...prev, sheetName: sheets[0] }));
      }
      setLoading(false);
      return sheets;
    } catch (err) {
      // 認証エラーの検出
      if (err.response && (err.response.status === 401 || err.response.status === 403)) {
        setError('認証エラーが発生しました。再度ログインしてください。');
        console.error('Authentication error:', err);
        await handleSignOut();
      } else {
        setError('シート名の取得に失敗しました。Spreadsheet IDを確認してください。');
        console.error('Error:', err);
        setLoading(false);
      }
      return null;
    }
  }, [token, checkAndRefreshToken, handleSignOut]);

  // fetchData関数をuseCallbackでメモ化し、handleSignOutを依存に追加
  const fetchData = useCallback(async (spreadsheetId, sheetName) => {
    if (!await checkAndRefreshToken()) return;
    if (!spreadsheetId || !sheetName) {
      setError('Spreadsheet IDとシート名を入力してください');
      return;
    }
    setError(null);
    setLoading(true);
    try {
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!A1:BZ1000`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setData(response.data.values);
      setSpreadsheetData(response.data.values);
      setLoading(false);
    } catch (err) {
      // 認証エラーの検出
      if (err.response && (err.response.status === 401 || err.response.status === 403)) {
        setError('認証エラーが発生しました。再度ログインしてください。');
        console.error('Authentication error:', err);
        await handleSignOut();
      } else {
        setError('スプレッドシートデータの取得に失敗しました。IDとシート名を確認してください。');
        console.error('Error:', err);
        setLoading(false);
      }
    }
  }, [token, checkAndRefreshToken, handleSignOut]);

  const saveSpreadsheet = () => {
    if (!currentSpreadsheet.id || !currentSpreadsheet.name) {
      setError('Spreadsheet IDと名前の両方を入力してください');
      return;
    }
    const updatedSavedSpreadsheets = [
      ...savedSpreadsheets.filter(s => s.id !== currentSpreadsheet.id),
      currentSpreadsheet
    ];
    setSavedSpreadsheets(updatedSavedSpreadsheets);
    localStorage.setItem('savedSpreadsheets', JSON.stringify(updatedSavedSpreadsheets));
    setError(null);
  };

  const loadSavedSpreadsheet = useCallback(async (spreadsheet) => {
    setCurrentSpreadsheet(spreadsheet);
    const sheets = await fetchSheetNames(spreadsheet.id);
    if (sheets && sheets.length > 0) {
      const sheetName = spreadsheet.sheetName || sheets[0];
      setCurrentSpreadsheet(prev => ({ ...prev, sheetName }));
      await fetchData(spreadsheet.id, sheetName);
    }
  }, [fetchSheetNames, fetchData]);

  const deleteSavedSpreadsheet = (idToDelete) => {
    const updatedSavedSpreadsheets = savedSpreadsheets.filter(s => s.id !== idToDelete);
    setSavedSpreadsheets(updatedSavedSpreadsheets);
    localStorage.setItem('savedSpreadsheets', JSON.stringify(updatedSavedSpreadsheets));
  };

  const updateSheetData = useCallback(() => {
    if (currentSpreadsheet.id && currentSpreadsheet.sheetName) {
      fetchData(currentSpreadsheet.id, currentSpreadsheet.sheetName);
    }
  }, [currentSpreadsheet.id, currentSpreadsheet.sheetName, fetchData]);

  const columnToLetter = useCallback((column) => {
    let temp, letter = '';
    while (column > 0) {
      temp = (column - 1) % 26;
      letter = String.fromCharCode(temp + 65) + letter;
      column = (column - temp - 1) / 26;
    }
    return letter;
  }, []);


  useEffect(() => {
    const storedApiKey = sessionStorage.getItem('openai_api_key') || localStorage.getItem('openai_api_key');
    if (storedApiKey) {
      const decryptedKey = decryptKey(storedApiKey);
      if (decryptedKey) {
        setApiKey(decryptedKey);
      } else {
        console.error('Failed to decrypt the API key');
        // 必要に応じてエラーハンドリングを追加
      }
    }
  }, []);
  
  // 変更後
  const handleApiKeySet = (key, rememberKey) => {
    setApiKey(key);
    const encryptedKey = encryptKey(key);
    
    if (rememberKey) {
      localStorage.setItem('openai_api_key', encryptedKey);
      sessionStorage.removeItem('openai_api_key');
    } else {
      sessionStorage.setItem('openai_api_key', encryptedKey);
      localStorage.removeItem('openai_api_key');
    }
  };


  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleOpenCustomSettings = useCallback(() => {
    setIsCustomSettingsOpen(true);
  }, []);

  const handleCloseCustomSettings = useCallback(() => {
    setIsCustomSettingsOpen(false);
  }, []);

  const handleSaveCustomSettings = useCallback((settings) => {
    setCustomSettings(settings);
    setIsCustomSettingsOpen(false);
    localStorage.setItem('customPriceSettings', JSON.stringify(settings));
    setSnackbar({ open: true, message: '設定が保存されました', severity: 'success' });
  }, []);

  const handleClosePriceCalculation = useCallback(() => {
    setIsPriceCalculationOpen(false);
  }, []);

  const handleUpdatePrices = useCallback(async (updatedSpreadsheetData) => {
    setLoading(true);
    setError(null);
    try {
      const updateRange = `${currentSpreadsheet.sheetName}!A1:${columnToLetter(updatedSpreadsheetData[0].length)}${updatedSpreadsheetData.length}`;
      await axios.put(
        `https://sheets.googleapis.com/v4/spreadsheets/${currentSpreadsheet.id}/values/${encodeURIComponent(updateRange)}`,
        {
          range: updateRange,
          values: updatedSpreadsheetData,
          majorDimension: "ROWS"
        },
        {
          params: { valueInputOption: 'USER_ENTERED' },
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );

      await fetchData(currentSpreadsheet.id, currentSpreadsheet.sheetName);
      setSnackbar({ open: true, message: '価格が更新されました', severity: 'success' });
    } catch (error) {
      console.error('Error updating prices:', error);
      setError(`価格の更新中にエラーが発生しました: ${error.message}`);
      setSnackbar({ open: true, message: '価格の更新に失敗しました', severity: 'error' });
    } finally {
      setLoading(false);
    }
  }, [currentSpreadsheet, token, fetchData, columnToLetter]);

  const handleItemSpecificsSettingsOpen = () => {
    setIsItemSpecificsSettingsOpen(true);
    setIsDrawerOpen(false);
  };

  const handleItemSpecificsSettingsClose = () => {
    setIsItemSpecificsSettingsOpen(false);
  };

  const handleItemSpecificsSettingsSave = (settings) => {
    setItemSpecificsSettings(settings);
    handleItemSpecificsSettingsClose();
  };

  // EditableSpreadsheetでのデータ変更を受け取る関数
  const handleDataChange = (newData) => {
    setData(newData);
  };

  const menuItems = [
    { 
      text: '価格計算設定', 
      icon: <SettingsIcon />, 
      action: handleOpenCustomSettings
    },
    {
      text: 'Item Specifics設定',
      icon: <SettingsIcon />,
      action: handleItemSpecificsSettingsOpen
    },
    {
      text: 'マニュアル',
      icon: <AttachMoneyIcon />,
      action: () => {
        window.open('https://ebay-marketing-tool.com/ebay-spread-manual-price/', '_blank', 'noopener,noreferrer');
      },
      renderItem: (item) => (
        <>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
          <Box component="span" sx={{ ml: 1 }}>
            <LaunchIcon fontSize="small" />
          </Box>
        </>
      )
    },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Specifics-IN ver 1.3.4
          </Typography>
          <Button 
            color="inherit" 
            href="https://www.ebay.com/sh/reports/uploads" 
            target="_blank" 
            rel="noopener noreferrer"
            startIcon={<CloudUploadIcon />}
          >
            eBayアップロード
          </Button>
          <Button 
            color="inherit" 
            href="https://app.photoroom.com/batch" 
            target="_blank" 
            rel="noopener noreferrer"
            startIcon={<PhotoCameraIcon />}
          >
            Photoroom
          </Button>
          <Button 
            color="inherit" 
            href="https://postimages.org/#google_vignette" 
            target="_blank" 
            rel="noopener noreferrer"
            startIcon={<LinkIcon />}
          >
            画像URL変換
          </Button>
          <Button color="inherit" onClick={handleSignOut}>
            Sign Out
          </Button>
          <IconButton 
            color="inherit" 
            onClick={() => setIsHelpOpen(!isHelpOpen)}
            aria-label="ヘルプを表示/非表示"
          >
            <HelpIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: '200px',
            boxSizing: 'border-box',
          },
        }}
      >
        <List sx={{ width: '100%' }}>
          {menuItems.map((item, index) => (
            <ListItem 
              button 
              key={index} 
              onClick={item.action}
              sx={{ 
                py: 2,
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              {item.renderItem ? item.renderItem(item) : (
                <>
                  <ListItemIcon sx={{ minWidth: '56px' }}>{item.icon}</ListItemIcon>
                  <ListItemText 
                    primary={item.text} 
                    primaryTypographyProps={{ 
                      fontSize: '1.1rem',
                      fontWeight: 'medium'
                    }} 
                  />
                </>
              )}
            </ListItem>
          ))}
        </List>
      </Drawer>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Grid container spacing={1} className="input-container">
              <Grid item className="input-field">
                <TextField
                  fullWidth
                  label="Spreadsheet ID"
                  value={currentSpreadsheet.id}
                  onChange={(e) => setCurrentSpreadsheet({...currentSpreadsheet, id: e.target.value})}
                  size="small"
                />
              </Grid>
              <Grid item className="input-field">
                <TextField
                  fullWidth
                  label="Spreadsheet Name"
                  value={currentSpreadsheet.name}
                  onChange={(e) => setCurrentSpreadsheet({...currentSpreadsheet, name: e.target.value})}
                  size="small"
                />
              </Grid>
              <Grid item className="button-group">
                <Button 
                  variant="contained" 
                  onClick={() => fetchSheetNames(currentSpreadsheet.id)}
                  size="small"
                >
                  読み込み
                </Button>
              </Grid>
              <Grid item className="button-group">
                <Button 
                  variant="contained" 
                  onClick={saveSpreadsheet}
                  size="small"
                >
                  保存
                </Button>
              </Grid>
              <Grid item className="input-field">
                {sheetNames.length > 0 ? (
                  <Select
                    fullWidth
                    value={currentSpreadsheet.sheetName}
                    onChange={(e) => setCurrentSpreadsheet({...currentSpreadsheet, sheetName: e.target.value})}
                    size="small"
                  >
                    {sheetNames.map((name, index) => (
                      <MenuItem key={index} value={name}>{name}</MenuItem>
                    ))}
                  </Select>
                ) : (
                  <TextField
                    fullWidth
                    label="Sheet Name"
                    value={currentSpreadsheet.sheetName}
                    onChange={(e) => setCurrentSpreadsheet({...currentSpreadsheet, sheetName: e.target.value})}
                    size="small"
                  />
                )}
              </Grid>
              <Grid item className="button-group">
                <Button
                  variant="contained"
                  onClick={() => fetchData(currentSpreadsheet.id, currentSpreadsheet.sheetName)}
                  disabled={loading || !currentSpreadsheet.sheetName}
                  size="small"
                >
                  {loading ? 'Loading...' : 'データ取得'}
                </Button>
              </Grid>
            </Grid>

            <Box sx={{ mt: 4, mb: 2 }}>
              <Typography variant="h6" gutterBottom>
                保存したIDsheets
              </Typography>
              <Paper elevation={2} sx={{ p: 2, overflowX: 'auto' }}>
                <Grid container spacing={2} sx={{ flexWrap: 'wrap' }}>
                  {savedSpreadsheets.map((spreadsheet, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography variant="subtitle2" noWrap title={spreadsheet.name}>
                            {spreadsheet.name}
                          </Typography>
                          <Typography variant="caption" color="text.secondary" display="block" noWrap title={spreadsheet.id}>
                            ID: {spreadsheet.id}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => loadSavedSpreadsheet(spreadsheet)}
                          >
                            読み込み
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            color="error"
                            onClick={() => deleteSavedSpreadsheet(spreadsheet.id)}
                          >
                            消去
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Box>

            {error && <p className="error-message">{error}</p>}
            {message && <p className="success-message">{message}</p>}

            <Grid container spacing={4}>
              <Grid item xs={12}>
                <ColumnNameBasedTemplateManager
                  spreadsheetId={currentSpreadsheet.id}
                  sheetName={currentSpreadsheet.sheetName}
                  token={token}
                  fetchData={updateSheetData}
                />
              </Grid>



              {/* ブロックの高さを揃えるためにGridコンテナにstyleを追加 */}
              <Grid container spacing={4} sx={{ display: 'flex', p: 2 }}>
                
                {/* ColumnDeletionコンポーネント */}
                <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Paper elevation={3} sx={{ flexGrow: 1, p: 2, m: 1 }}>
                    <ColumnDeletion
                      currentSpreadsheet={currentSpreadsheet}
                      token={token}
                      data={data}
                      fetchData={fetchData}
                      columnToLetter={columnToLetter}
                      setError={setError}
                      setMessage={setMessage}
                      setLoading={setLoading}
                    />
                  </Paper>
                </Grid>

                {/* PriceCalculationSettingsコンポーネント */}
                <Grid item xs={4} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Paper elevation={3} sx={{ flexGrow: 1, p: 2, m: 1 }}>
                    <PriceCalculationSettings
                      customSettings={customSettings}
                      spreadsheetData={spreadsheetData}
                      onUpdatePrices={handleUpdatePrices}
                      fetchSpreadsheetData={fetchData}
                    />
                  </Paper>
                </Grid>

                {/* ItemSpecificsApplierコンポーネント */}
                <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Paper elevation={3} sx={{ flexGrow: 1, p: 2, m: 1 }}>
                    <ItemSpecificsApplier
                      spreadsheetId={currentSpreadsheet.id}
                      sheetName={currentSpreadsheet.sheetName}
                      token={token}
                    />
                  </Paper>
                </Grid>

              </Grid>

             {/* ブロックの高さを揃えるためにGridコンテナにstyleを追加 */}
             <Grid container spacing={4} sx={{ display: 'flex', p: 2 }}>

                <Grid item xs={12} md={6}>
                  <Paper elevation={3} sx={{ p: 3, mb: 0 ,ml: 1, mt: 0, }}>
                    <ImageDownloader
                      spreadsheetId={currentSpreadsheet.id}
                      sheetName={currentSpreadsheet.sheetName}
                      token={token}
                      data={data}
                    />
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper elevation={3} sx={{ p: 3, mb: 0}}>
                    <ImageUrlReplacer
                      spreadsheetId={currentSpreadsheet.id}
                      sheetName={currentSpreadsheet.sheetName}
                      token={token}
                      data={data}
                      fetchData={updateSheetData}
                    />
                  </Paper>
                </Grid>            
            </Grid>

              <Grid item xs={12}>
                <AppBar position="static" color="primary" sx={{ mt: 0 }}>
                  <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                      AI機能 ※API利用
                    </Typography>
                  </Toolbar>
                </AppBar>
              </Grid>

     
              <Grid item xs={12}>
              {!apiKey ? (
                <Paper elevation={3} sx={{ p: 2, my: 1 , ml: 0}}>
                  <Typography variant="h6" gutterBottom>
                    OpenAI APIキー設定
                  </Typography>
                  <APIKeyInput onApiKeySet={(key, remember) => handleApiKeySet(key, remember)} />
                </Paper>
              ) : (
                <Paper elevation={3} sx={{ p: 1, my: 1, ml: 0 }}>
                  <Typography variant="body1">
                    OpenAI APIキーが設定されています。
                  </Typography>
                  <Button onClick={() => {
                    setApiKey('');
                    localStorage.removeItem('openai_api_key');
                  }}>
                    APIキーをリセット
                  </Button>
                </Paper>
              )}
              </Grid>



           <Grid container spacing={4} sx={{ display: 'flex', p: 2 }}>
            <Grid item xs={6}>
                <TitleGenerationComponent
                  spreadsheetId={currentSpreadsheet.id}
                  sheetName={currentSpreadsheet.sheetName}
                  token={token}
                  fetchData={updateSheetData}
                  apiKey={apiKey}
                />
              </Grid>
              

              <Grid item xs={6} >
                <OpenAIDescriptionGenerator
                  spreadsheetId={currentSpreadsheet.id}
                  sheetName={currentSpreadsheet.sheetName}
                  token={token}
                  fetchData={updateSheetData}
                  apiKey={apiKey}
                />
             </Grid>
          </Grid>

              <Grid item xs={12}>
                <EditableSpreadsheet 
                  spreadsheetId={currentSpreadsheet.id} 
                  sheetName={currentSpreadsheet.sheetName}
                  token={token}
                  data={data || []}
                  fetchData={updateSheetData}
                  onDataChange={handleDataChange} // 追加
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Drawer
        anchor="right"
        open={isHelpOpen}
        onClose={() => setIsHelpOpen(false)}
      >
        <Box sx={{ width: 300, p: 2 }}>
          <InteractiveManual />
        </Box>
      </Drawer>

      <Dialog
        open={isCustomSettingsOpen}
        onClose={handleCloseCustomSettings}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>価格計算設定</DialogTitle>
        <DialogContent>
          <CustomColumnSettings 
            onSave={handleSaveCustomSettings}
            initialSettings={customSettings}
            userId={user ? user.uid : null}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isPriceCalculationOpen}
        onClose={handleClosePriceCalculation}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>価格計算</DialogTitle>
        <DialogContent>
          <PriceCalculationSettings
            customSettings={customSettings}
            spreadsheetData={spreadsheetData}
            onUpdatePrices={handleUpdatePrices}
            fetchSpreadsheetData={fetchData}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isItemSpecificsSettingsOpen}
        onClose={handleItemSpecificsSettingsClose}
        fullWidth
        maxWidth="md"
        >
        <DialogTitle>Item Specificsの管理画面</DialogTitle>
        <DialogContent>
          <ItemSpecificsSettings
            onSave={handleItemSpecificsSettingsSave}
            onClose={handleItemSpecificsSettingsClose}
            headers={data && data.length > 0 ? data[0] : []} // ヘッダーを渡す
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleItemSpecificsSettingsClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>


      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="body2">
          <Link component={RouterLink} to="/terms">
            利用規約
          </Link>
          {' | '}
          <Link component={RouterLink} to="/privacy">
            プライバシーポリシー
          </Link>
          {' | '}
           <Link component={RouterLink} to="/legal-disclosure">
             特定商取引法に基づく表記
           </Link>
        </Typography>
      </Box>


    </Box>
  );
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setIsLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20%' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <Routes>
          <Route 
            path="/login" 
            element={
              isAuthenticated ? 
                <Navigate to="/" replace /> : 
                <Login setIsAuthenticated={setIsAuthenticated} />
            } 
          />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/legal-disclosure" element={<LegalDisclosure />} />
          <Route 
            path="/" 
            element={
              isAuthenticated ? 
                <AppContent setIsAuthenticated={setIsAuthenticated} /> : 
                <Navigate to="/login" replace />
            } 
          />
          {/* 存在しないルートへの対応 */}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
